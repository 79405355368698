












// Utils
import { Component, Vue } from 'vue-property-decorator'
// Components
import GoogleLogin from '@/components/auth/GoogleLogin.vue'
import NormalLogin from '@/components/auth/NormalLogin.vue'

@Component({
  components: { GoogleLogin, NormalLogin }
})
export default class ModalLoginPartner extends Vue {
  modal: any = {
    showModalLoginPartner: false,
    company_id: '',
    email: '',
    address: 'Dashboard'
  }

  created () {
    this.modal = this.$attrs.value
  }
}
