



















































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
import ModalLoginPartner from '@/components/invitedPartner/ModalLoginPartner.vue'
// Types
import InvitedTypes from '@/store/types/InvitedTypes'
// Services
import MatiService from '@/services/Mati'
import PartnersService from '@/services/Partners'

@Component({
  components: { Footer, ModalLoginPartner, Loader }
})
export default class Start extends Vue {
  modal: any = {
    showModalLoginPartner: false,
    company_id: '',
    email: '',
    address: 'isPartnerStart'
  }
  loading = false
  matiCheck = true
  complementaries = false
  cont = 0
  
  mounted () {
    this.checkPartner()
  }

  checkPartner () {
    // Exist documents refused mati
    const documentsError = this.state.partner.documents.filter((document: any) => !document.is_approved && document.rejection_category)
    const passport = documentsError.filter((document: any) => document.document_type === 'passport')
    const national_identity = documentsError.filter((document: any) => document.document_type === 'national_identity')
    if (passport.length > 0 || national_identity.length > 0 || this.state.partner.mati_verification !== true) this.matiCheck = false
    // Mati status check
    if (this.state.partner.mati_verification !== null && this.state.partner.mati_status && this.state.partner.mati_status.length && (this.state.partner.mati_status[0].status === 'Reject' || this.state.partner.mati_status[0].status === 'Denied')) this.matiCheck = false
    if (this.matiCheck === false && this.state.partner.mati_status && this.state.partner.mati_status.length && (this.state.partner.mati_status[0].status !== 'Reject' && this.state.partner.mati_status[0].status !== 'Denied')) this.matiCheck = true
    // Is mexican
    let isMexican = false
    if (this.state.partner.nationalities !== null && this.state.partner.nationalities.length) this.state.partner.nationalities.map((nationality: any) => { if (nationality.name === 'México' || nationality.issue_country === 'MX') isMexican = true })
    else isMexican = true
    // RFC is generic
    const foreignRFC = this.state.partner.rfc === 'EXTF900101NI1'
    // Complementaries check
    const cfi = this.state.partner.cfi.document_url === null ? false : this.state.partner.cfi.rejection_category === null
    const id_spouse = this.state.partner.id_spouse.document_url === null ? false : this.state.partner.id_spouse.rejection_category === null
    const marriage_certificate = this.state.partner.marriage_certificate.document_url === null ? false : this.state.partner.marriage_certificate.rejection_category === null
    const address_identity = this.state.partner.address_identity.document_url === null ? false : this.state.partner.address_identity.rejection_category === null
    if (this.state.partner.civil_status === 'casado') {
      this.complementaries = isMexican ?
        cfi && id_spouse && marriage_certificate && this.state.partner.rfc !== '' :
        foreignRFC ? 
          cfi && id_spouse && marriage_certificate && address_identity :
          cfi && id_spouse && marriage_certificate && this.state.partner.rfc !== ''
    } else {
      this.complementaries = isMexican ?
        cfi && this.state.partner.rfc !== '' :
        foreignRFC ? 
          cfi && address_identity :
          cfi && this.state.partner.rfc !== ''
    }
  }

  async matiNext () {
    this.loading = true
    await MatiService.setMatiCheck(this.state.partner.id)
    await this.updateUser({ partner: this.state.partner.id, company_id: this.state.company_id })
    return !this.complementaries ? this.$router.push({ name: 'InvitedComplementaries', query: this.$route.query }) : this.$router.push({ name: 'End', query: this.$route.query })
  }

  async next () {
    this.loading = true
    if (this.state.partner.user_id !== null) {
      this.modal.email = this.state.partner.email
      this.modal.company_id = this.state.company_id
      return this.modal.showModalLoginPartner = true
    } else if (this.state.partner.invitation_accepted === null) {
      // eslint-disable-next-line
      const [error, response] = await PartnersService.invitationResponse(this.state.partner.id, this.state.company_id, true)
      if (error) {
        this.loading = false
        return this.$toasted.global.error(error)
      }
    }
    return !this.complementaries ?
      this.$router.push({ name: 'InvitedComplementaries', query: this.$route.query }) : !this.matiCheck ?
      this.$router.push({ name: 'InvitedValidation', query: this.$route.query }) : this.$router.push({ name: 'End', query: this.$route.query })
  }

  async refuse () {
    this.loading = true
    await PartnersService.invitationResponse(this.state.partner.id, this.state.company_id, false)
    return this.$router.push({ name: 'Login', query: this.$route.query })
  }

  @Action(`InvitedModule/${InvitedTypes.actions.UPDATE_DATA}`) updateUser: any
  @State('InvitedModule') state: any
}
