import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { State } from '@/store/interfaces/Invited'
import InvitedTypes from '@/store/types/InvitedTypes'
import PartnersService from '@/services/Partners'

const state: State = {
  company_id: '',
  partner: {}
}

function getDocumentPartnerByType (partner: any, type: string, label: string) {
  const findDocument = partner.documents.find((document: any) => document.document_type === type)
  if (findDocument) {
    return {
      document_url: findDocument.document_url,
      is_approved: findDocument.is_approved,
      rejection_category: findDocument.rejection_category,
      id: findDocument.id,
      owner_category: findDocument.owner_category,
      partner_id: partner.id,
      label,
      type,
      company_id: state.company_id
    }
  }
  return { document_url: null }
}

function formattedDataPartners () {
  state.partner.cfi = getDocumentPartnerByType(state.partner, 'CFI', 'Constancia de Situación Fiscal')
  state.partner.id_spouse = getDocumentPartnerByType(state.partner, 'id_spouse', 'identificación del cónyuge')
  state.partner.id_spouse_back = getDocumentPartnerByType(state.partner, 'id_spouse_back', 'Reverso de la identificación del cónyuge')
  state.partner.marriage_certificate = getDocumentPartnerByType(state.partner, 'marriage_certificate', 'acta de matrimonio')
  state.partner.address_identity = getDocumentPartnerByType(state.partner, 'address', 'Comprobante de domicilio')
  return state.partner
}

const getters: GetterTree<State, any> = {
  [InvitedTypes.getters.GET_DATA]: stateGet => stateGet
}

const mutations: MutationTree<State> = {
  [InvitedTypes.mutations.SET_DATA]: async (stateMut, payload: State) => {
    state.company_id = payload.company_id
    state.partner = payload.partner
    formattedDataPartners()
  },
  [InvitedTypes.mutations.CLEAR_DATA]: async () => {
    state.company_id = ''
    state.partner = {}
  }
}

const actions: ActionTree<State, any> = {
  [InvitedTypes.actions.UPDATE_DATA]: async ({ commit }, {partner, company_id}) => {
    const [err, response] = await PartnersService.getPartner(partner, company_id)
    if (err) return [err]
    await commit(InvitedTypes.mutations.SET_DATA, {company_id, partner: response.data.data})
    return [null, response.data.data]
  },
  [InvitedTypes.actions.CLEAR_DATA]: async ({ commit }) => {
    await commit(InvitedTypes.mutations.CLEAR_DATA)
    return [null]
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
