



















































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Types
import AuthTypes from '@/store/types/AuthTypes'

@Component({
  components: {}
})
export default class UserDropdown extends Vue {
  userName = ''

  async mounted () {
    this.getName()
  }

  getName () {
    if (this.state.name === null) return
    const spaceName = this.state.name.indexOf(' ')
    const spaceFirstName = this.state.first_names.indexOf(' ')
    this.userName = spaceName !== -1 ? this.state.name.slice(0, spaceName) : this.state.name
    this.userName = spaceFirstName !== -1 ? this.userName + ' ' + this.state.first_names.slice(0, spaceFirstName) : this.userName + ' ' + this.state.first_names
  }

  async signOff () {
    await this.logout()
    return this.$router.push({ name: 'Login', query: this.$route.query })
  }

  @Action(`AuthModule/${AuthTypes.actions.LOGOUT}`) logout: any
  @State('UserModule') state: any
}
