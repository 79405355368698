









// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import Benefit from '@/components/benefits/Benefit.vue'

@Component({
  components: { Footer, Benefit }
})
export default class BBVASpark extends Vue {
  benefitData: any = {
    name: 'BBVASpark',
    textReturn: 'Regresar',
    openAccount: {
      img1: {
        url: require('@/assets/images/Benefits/BBVA.png'),
        style1: 'width: 122px; height: 46px;',
        style2: 'width: 262px; height: 84px; background: #0E2043;'
      },
      img2: {
        url: require('@/assets/images/Benefits/BBVA2.png'),
        classStyle: 'col-12 col-lg-6 col-xl-5 mt-4 px-0 pl-lg-4 pl-xl-0'
      },
      title: 'El mejor aliado para llevar tu empresa al siguiente nivel',
      subTitle: '',
      text: '',
      url: 'https://tallylegal.typeform.com/to/S0jLVahE',
      point: '',
      points: [
        'Financiación flexible y a medida',
        'Oferta bancaria integral, en un solo lugar',
        'Conexión estrecha con el ecosistema',
        'Los mejores especialistaspara acompañarte'
      ],
    },
    companyDocs: {
      title: 'Sube tus documentos',
      subTitle: 'Los necesitamos para crear tu cuenta empresarial',
      msj: {
        Title: 'Revisa tus documentos',
        Text: 'Deberás subir tus documentos originales, sin tachaduras ni enmendaduras y con fecha de expedición no mayor a 2 meses.'
      }
    },
    authorization: {
      title: 'Autorizas el envío de tus documentos',
      subTitle: 'Enviaremos a BBVA México tu información',
      protection: 'Tu información está protegida',
      authorization: 'Autorizo a Tally compartir mis documentos con BBVA México, S.A. para la apertura de mi cuenta empresarial'
    },
    complete: {
      title: '¡Solicitud realizada con éxito!',
      subTitle: '',
      button: 'Finalizar',
      points: [],
    }
  }

  @State('UserModule') state: any
}
