









// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import Benefit from '@/components/benefits/Benefit.vue'

@Component({
  components: { Footer, Benefit }
})
export default class Dgmxtech extends Vue {
  benefitData: any = {
    name: 'Dgmxtech',
    textReturn: 'Regresar',
    openAccount: {
      img1: {
        url: require('@/assets/images/Benefits/Dgmxtech.png'),
        style1: 'width: 260px; height: 65px;',
        style2: ''
      },
      img2: {
        url: require('@/assets/images/Benefits/Dgmxtech2.png'),
        classStyle: 'col-12 col-lg-5 col-xl-4 mt-0 px-0'
      },
      title: 'Nos aseguramos de crear un producto digital que te ayude a generar ventas',
      subTitle: '',
      text: '',
      url: 'https://tallylegals.notion.site/Desarrolla-tecnolog-a-a-la-medid-41eae550ef1448bd9424730aa5608020',
      point: '',
      points: [
        'Auditoría de tu página web o e-commerce a ctual gratis',
        '10% de descuento en el diseño o desarollo de tu página web o e-commerce',
        '5% de descuento en diseño y desarrollo de tu Marketplace',
        '5% de descuento en desarrollo de software a la medida '
      ],
    },
    companyDocs: {
      title: 'Sube tus documentos',
      subTitle: 'Los necesitamos para crear tu cuenta empresarial',
      msj: {
        Title: 'Revisa tus documentos',
        Text: 'Deberás subir tus documentos originales, sin tachaduras ni enmendaduras y con fecha de expedición no mayor a 2 meses.'
      }
    },
    authorization: {
      title: 'Autorizas el envío de tus documentos',
      subTitle: '',
      protection: 'Tu información está protegida',
      authorization: ''
    },
    complete: {
      title: '¡Solicitud realizada con éxito!',
      subTitle: '',
      button: 'Finalizar',
      points: []
    }
  }

  @State('UserModule') state: any
}
