















// Utils
import { Component, Vue } from 'vue-property-decorator'

@Component({
})
export default class TooltipRFC extends Vue {
  points = [
    'El RFC (Registro Federal de Contribuyentes) es un identificador fiscal utilizado en México. La FIEL (Firma Electrónica Avanzada) es un certificado digital que se utiliza para realizar trámites en línea con el SAT. Ambos son necesarios para cumplir con las obligaciones fiscales en México.'
  ]
}
