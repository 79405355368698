
















// Utils
import { Component, Vue } from 'vue-property-decorator'
// Components
import ProgressBar from '@/components/signup/ProgressBar.vue'
import SetLegalNameOld from '@/components/signup/SetLegalNameOld.vue'
import Footer from '@/components/Footer.vue'

@Component({
  components: { ProgressBar, SetLegalNameOld, Footer }
})
export default class LegalNameOld extends Vue {
}
