








































































































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import EventBus from '@/event-bus'
import moment from 'moment'
// Components
import Loader from '@/components/Loader.vue'
// Types
import UserTypes from '@/store/types/UserTypes'
// Services
import Notary from '@/services/Notary'

@Component({
  components: { Loader }
})
export default class AppointmentCheck extends Vue {
  loading = false
  confirm = false
  digital = false
  eventDate = ''

  mounted () {
    this.prepareData()
  }

  async prepareData () {
    const findEvent = this.state.company.events.find((event: any) => event.event_description === 'constituir_empresa')
    if (!findEvent) return this.$toasted.global.error({ message: 'No se pudo cargar fecha del evento' })
    const date = new Date(findEvent.event_timestamp)
    const aux = moment(date)
    this.eventDate += aux.format('dddd') + ', '
    this.eventDate += aux.format('DD') + ' de ' + aux.format('MMMM') + ' de ' + aux.format('YYYY')
    this.eventDate += ' - ' + aux.format('hh:mm A')
  }

  async cancelEvent () {
    this.loading = true
    const [error] = await Notary.cancelEvent(this.state.company.id)
    if (error) return this.$toasted.global.error(error)
    await this.updateCompany(this.state.company.id)
    this.$toasted.global.success({ message: 'Se ha cancelado la cita con éxito' })
    EventBus.$emit('update-dashboard')
    this.confirm = this.loading = false
  }

  copyLink () {
    navigator.clipboard.writeText("Link de la cita")
    this.$toasted.global.success({ message: 'Se ha copiado con éxito el link' })
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
