// Utils
import { RouteConfig } from 'vue-router'
import multiguard from 'vue-router-multiguard'
// Views
import Index from '@/views/beginning/Index.vue'
import Beginning from '@/views/beginning/Beginning.vue'
import BeginningBeforePayment from '@/views/beginning/BeginningBeforePayment.vue'
import Before from '@/views/beginning/Before.vue'
import Resume from '@/views/beginning/Resume.vue'
// Middlewares
import Auth from '@/middlewares/Auth'
import User from '@/middlewares/User'

const routes: Array<RouteConfig> = [
  {
    path: '/beginning/:company_id',
    component: Index,
    beforeEnter: multiguard([Auth.isLogged, User.validateData]),
    children: [
      {
        path: '',
        name: 'Beginning',
        component: Beginning
      },
      {
        path: 'company_info',
        name: 'BeginningBeforePayment',
        component: BeginningBeforePayment
      },
      {
        path: 'resume',
        name: 'Resume',
        component: Resume
      },
      {
        path: 'company',
        name: 'Before',
        component: Before
      }
    ]
  }
]

export default routes
