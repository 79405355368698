import Axios from 'axios'
import router from '@/router/index'
import store from '@/store/index'
import AuthTypes from '@/store/types/AuthTypes'

const axios = Axios.create({})

axios.interceptors.response.use((config) => config,
  async (error) => {
    const originalRequest = error.config
    if (originalRequest.url.includes('api.exchangerate.host')) {
      return
    } else if (originalRequest.url.includes('user/refresh')) {
      await store.dispatch(`AuthModule/${AuthTypes.actions.LOGOUT}`)
      await store.dispatch('UPDATE_STATE_LOADING', false)
      router.push({ name: 'Login' })
      return window.location.reload()
    } else if ((error.response.data.message && error.response.data.message[0].includes('token')) || (error.response.message && error.response.message[0].includes('token'))) {
      const [err, response] = await store.dispatch(`AuthModule/${AuthTypes.actions.REFRESH_TOKEN}`)
      if (err) return router.push({ name: 'Login' })
      const retryOriginalRequest = new Promise((resolve) => {
        originalRequest.headers.Authorization = 'Bearer ' + response.access_token
        return resolve(axios(originalRequest))
      })
      return retryOriginalRequest
    }
    return Promise.reject(error)
  })

axios.interceptors.request.use((config) => {
  if (config.url?.includes('api.exchangerate.host')) { return config }
  const payload = store.getters[`AuthModule/${AuthTypes.getters.GET_PAYLOAD}`]
  if (config.url?.includes('user/refresh')) config.headers.authorization = `Bearer ${payload.refresh_token}`
  else if (payload.access_token !== '') config.headers.authorization = `Bearer ${payload.access_token}`
  return config
})

axios.interceptors.request.use((config) => {
  if (config.url?.includes('api.exchangerate.host')) { return config }
  const params = new URLSearchParams(window.location.search)
  config.headers.x_utm_tags = JSON.stringify({
    utm_id: params.get('utm_id'),
    utm_source: params.get('utm_source'),
    utm_medium: params.get('utm_medium'),
    utm_campaign: params.get('utm_campaign'),
    utm_term: params.get('utm_term'),
    utm_content: params.get('utm_content')
  })
  return config
})

export default axios
