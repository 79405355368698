















































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
import moment from 'moment'

@Component({
  components: { }
})
export default class TableCompanies extends Vue {
  moment = moment
  bands = []

  get allCompanies () {
    const companiesInvitation = this.state.companies.map((company: any) => { return { ...company, invitation_accepted: true }})
    const all_companies = companiesInvitation.concat(this.state.company_as_a_partner.filter((company: any ) => company.user_id !== this.state.id))
    const all_companies_invitation_accepted = all_companies.filter((company: any ) => company.invitation_accepted)
    this.bands = all_companies_invitation_accepted.map(() => { return { redirect: false }})
    return all_companies_invitation_accepted
  }

  @State('UserModule') state: any
}
