
























































































// Utils
import { Component, Prop } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
import { mixins } from 'vue-class-component'
// Mixins
import QueryParamsMixin from '@/mixins/QueryParams'
// Components
import Loader from '@/components/Loader.vue'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'
// Services
import ConstitutiveService from '@/services/Constitutive'

@Component({
  components: { Loader }
})
export default class SetLegalNameOld extends mixins(QueryParamsMixin) {
  @Prop() address!: string
  legal_name = ''
  validName = true
  loading = false

  created () {
    this.legal_name = this.state.legal_name
  }

  reset () {
    this.legal_name = ''
    this.validName = true
  }

  filterLegalName (value: string) { return value.replace(/[^a-zA-Z0-9ÑñÁáÉéÍíÓóÚú ]/g, '').trimStart() }

  async validate () {
    this.loading = true
    // Validate legal name
    const [error, response] = await ConstitutiveService.validateLegalName(this.legal_name)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    if (!response.data.data) {
      this.loading = false
      return this.validName = false
    }
    // User in registration process
    if (this.address === 'PersonalInformationOld') {
      await this.setLegalName(this.legal_name)
      return this.$router.push({ name: this.address, query: this.$route.query }).catch(() => {return})
    }
    // Save legal name
    const [error2] = await ConstitutiveService.updateLegalNames(this.userState.company.id, [this.legal_name], false)
    if (error2) {
      this.loading = false
      return this.$toasted.global.error(error2)
    }
    if (this.$route.name === 'EditLegalName') window.analytics.track('AC_EditInformation_OK', { edited_info: 'Legal name' })
    return this.$router.push({ name: this.address, query: this.$route.query }).catch(() => {return})
  }

  @State('CompanyModule') state: any
  @State('UserModule') userState: any
  @Action(`CompanyModule/${CompanyTypes.actions.SET_LEGAL_NAME}`) setLegalName: any;
}
