















































































































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import EventBus from '@/event-bus'
// Types
import UserTypes from '@/store/types/UserTypes'
// Components
import Loader from '@/components/Loader.vue'
// Services
import SATService from '@/services/SAT'
import PaymentService from '@/services/Payment'

@Component({
  components: { Loader }
})
export default class ModalRescheduleSAT extends Vue {
  modal: any = { showModalRescheduleSAT: false, update: false }
  loading = false
  loadingModule = false
  loadingStripe = false
  reschedule = false
  isCancel = false
  payment = false
  open = false
  Efirma = false
  partners: any = []
  partner: any = {}
  office = ''
  entityModule = ''
  priceAppoinmentSAT = ''
  officesSAT: any = []
  entityModules: any = []
  reasonsRejection = [
    'No pude asistir a mi cita',
    'No llego la paquetería a tiempo',
    'Mi acta tiene errores',
    'Tuve un error en el comprobante de domicilio',
    'Otro'
  ]
  reasonRejection = ''
  rejection = ''

  @Watch('office')
  updateEntityModules () {
    if (this.office === '') return
    this.getEntityModules()
  }

  @Watch('modal.showModalRescheduleSAT')
  getStatusDelivery () {
    if (!this.modal.showModalRescheduleSAT || (this.officesSAT.length > 0 && this.priceAppoinmentSAT !== '')) return EventBus.$emit('update-final')
    this.getOffices()
    this.getPrices()
  }

  created () {
    this.partners = this.state.company.partners.filter((partner: any ) => partner.rfc !== '' && partner.rfc !== 'EXTF900101NI1')
    this.isCancel = this.state.company.status_federal_registration.is_scheduled
    if (this.state.company.shipping_address !== null) this.partner = this.partners.filter((partner: any ) => partner.id === this.state.company.shipping_address.shipping_person)[0]
  }

  mounted () {
    this.modal = this.$attrs.value
    this.Efirma = (this.state.company.rfc !== null && this.state.company.rfc !== '') || this.state.company.rfc_later || this.state.company.appointment_type_efirma
  }

  noSpacesStart (value: string) { return value.trimStart() }

  get style () {
    return this.partners.length === 1 || this.partners.length === 0 ?
      'height: 55px;' : this.partners.length === 2 ?
      'height: 110px;' : this.partners.length === 3 ?
      'height: 165px;' : this.partners.length === 4 ?
      'height: 220px;' : this.partners.length === 5 ?
      'height: 275px;' : 'height: 290px;'
  }

  async getOffices () {
    this.loading = true
    const [error, response] = await SATService.getModules(this.Efirma ? 'efirma' : 'inscripcion_persona_moral')
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.officesSAT = response.data
    this.loading = false
  }

  async getEntityModules () {
    this.loadingModule = true
    this.entityModule = ''
    const [error, response] = await SATService.getEntityModules(this.office, this.Efirma ? 'efirma' : 'inscripcion_persona_moral')
    if (error) {
      this.loadingModule = false
      return this.$toasted.global.error(error)
    }
    this.entityModules = response.data
    this.loadingModule = false
  }

  async getPrices () {
    const [error, response] = await PaymentService.getPrices()
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    const itemSAT = response.data.data.filter((item: any ) => item.name === "Cita en el SAT")[0]
    const priceSAT = itemSAT.prices.filter((price: any ) => price.is_default === true)[0]
    this.priceAppoinmentSAT = (Math.trunc(Number(priceSAT.price) + Number(priceSAT.tax))).toString()
  }

  async rescheduleSAT () {
    this.loading = true
    if (this.isCancel || (this.Efirma && !this.state.company.appointment_type_efirma)) {
      if (this.reasonRejection !== '') {
        const [error] = await SATService.setReasonRejectionSAT(this.state.company.status_federal_registration.id, this.reasonRejection === 'Otro' ? this.rejection : this.reasonRejection, false)
        if (error) {
          this.loading = false
          return this.$toasted.global.error(error)
        }
      }
      const [error2] = await SATService.setAppoinmentSAT(this.state.company.id, { rfc: this.partner.rfc, entity_module: this.entityModule, service: this.Efirma ? 'efirma' : 'inscripcion_persona_moral' })
      if (error2) {
        this.loading = false
        return this.$toasted.global.error(error2)
      }
    } else {
      const [error] = await SATService.updateAppoinmentSAT(this.state.company.status_federal_registration.id, { rfc: this.partner.rfc, entity_module: this.entityModule })
      if (error) {
        this.loading = false
        return this.$toasted.global.error(error)
      }
    }
    await this.updateCompany(this.state.company.id)
    const contAppoimentSAT = this.state.company.stages.find((stage: any) => stage.stage_name === 'appointment_sat').retries_count
    if (!this.Efirma && contAppoimentSAT >= 2) {
      this.payment = true
      return this.loading = false
    }
    EventBus.$emit('update-final')
    this.modal.update = true
    this.loading = false
    this.modal.showModalRescheduleSAT = false
  }

  async goPaymentLink () {
    this.loadingStripe = true
    let items: any = []
    items.push({ name : 'Cita en el SAT', quantity : 1 })
    const [error2, response] = await PaymentService.createPaymentLink(items, this.state.company.id, this.state.id)
    if (error2) {
      this.loadingStripe = false
      return this.$toasted.global.error(error2)
    }
    let linkToPayment = ''
    if (response) linkToPayment = response.data.data.payment_link
    if (linkToPayment !== '') return location.href = linkToPayment
    return this.loadingStripe = false
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
