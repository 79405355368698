






































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import HeaderProgressBar from '@/components/HeaderProgressBar.vue'
import ReturnAddress from '@/components/Return.vue'
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'

@Component({
  components: { HeaderProgressBar, ReturnAddress, Footer, Loader }
})
export default class Register extends Vue {
  points = [
    {img: require('@/assets/icons/register-point-1.svg'), text: 'Elegir un tipo de sociedad'},
    {img: require('@/assets/icons/register-point-2.svg'), text: 'Escoger un nombre para tu empresa'},
    {img: require('@/assets/icons/register-point-4.svg'), text: 'Platicarnos más de tus objetivos'},
    {img: require('@/assets/icons/register-point-3.svg'), text: 'Pagar'}
  ]
  pointsRepresentation = [
    {img: require('@/assets/icons/register-point-1.svg'), text: 'Platicarnos más de tus objetivos'},
    {img: require('@/assets/icons/register-point-2.svg'), text: 'Elegir un tipo de sociedad.'},
    {img: require('@/assets/icons/register-point-4.svg'), text: 'Escoger un Paquete.'},
    {img: require('@/assets/icons/register-point-3.svg'), text: 'Suscribirte'}
  ]
  agent = {
    title: '¡Hola soy Pablo, CEO de Tally!',
    subTitle: 'Te ayudaré a crear una empresa en México',
    text: 'Necesitas:'
  }
  loader = false

  async next () {
    this.loader = true
    return this.$router.push({ name: 'TypeSociety', query: this.$route.query })
  }

  @State('CompanyModule') state: any
}
