






































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import CardPayment from '@/components/payment/CardPayment.vue'
import CardPaymentItems from '@/components/payment/CardPaymentItems.vue'
import HeaderProgressBar from '@/components/HeaderProgressBar.vue'
import ReturnAddress from '@/components/Return.vue'
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
// Services
import Payments from '@/services/Payment'

@Component({
  components: { HeaderProgressBar, Footer, ReturnAddress, CardPayment, CardPaymentItems, Loader }
})
export default class Payment extends Vue {
  loading = true
  pay = {
    items: [{
      id: '',
      name: '',
      price: '',
      price_id: ''
    }],
    selected: [{
      id: '',
      item: '',
      cost: '',
      quantity: '',
      price_id: ''
    }]
  }

  async mounted () {
    await this.getPrices()
    await Payments.slackPaymentAlert(this.state.company.id)
  }

  async getPrices () {
    const [error, response] = await Payments.getPrices()
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.pay.items = []
    this.pay.selected = []
    response.data.data.map((item: any) => {
      let priceActive = item.prices.filter((price: any ) => price.is_default === true)[0]
      const price = (Math.trunc(Number(priceActive.price) + Number(priceActive.tax))).toString()
      this.pay.items.push({id: item.id, name: item.name, price: price.replace(/\d(?=(?:\d{3})+$)/g, '$&,'), price_id: priceActive.id})
      if (item.name === 'Anticipo Acta Constitutiva') this.pay.selected.push({id: item.id, item: item.name, cost: price.replace(/\d(?=(?:\d{3})+$)/g, '$&,'), quantity: '1', price_id: priceActive.id})
    })
    this.loading = false
  }

  @State('UserModule') state: any
}
