













































































































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import ModalCompanyBank from '@/components/dashboard/modals/ModalCompanyBank.vue'
// Services
import CompanyService from '@/services/Company'


@Component({
  components: { ModalCompanyBank }
})
export default class CardBankData extends Vue {
  modal: any = {
    showModalCompanyBank: false,
    update: false,
    accountType: '',
    account: '',
    clabe: '',
    swift: ''
  }
  bank = {
    name: 'Banco BBVA',
    TAS: '',
    access_code: '',
    operation_code: '',
    account_mxn: '',
    CLABE_mxn: '',
    account_dls: '',
    CLABE_dls: '',
    SWIFT_code: ''
  }
  eyeTAS = 'eye'
  eyeAccess = 'eye'
  eyeOperation = 'eye'
  eyeMXN = 'eye'
  eyeMXNClabe = 'eye'
  eyeUSD = 'eye'
  eyeUSDClabe = 'eye'
  eyeSwift = 'eye'
  renderKeyCompanyBank = 0

  mounted () {
    this.getBankAccounts()
  }

  switchVisibility (eye: string) {
    return eye === 'eye' ? 'eye-slash' : 'eye'
  }

  copyLink (copy: string) {
    navigator.clipboard.writeText(copy)
    this.$toasted.global.success({ message: 'Se ha copiado con éxito' })
  }

  showModal (type: string, account: string, clabe: string) {
    this.modal.accountType = type
    this.modal.account = account
    this.modal.clabe = clabe
    this.modal.swift = this.bank.SWIFT_code
    this.modal.showModalCompanyBank = true
  }

  async getBankAccounts () {
    const [error, response] = await CompanyService.getBankAccounts(this.state.company.id)
    if (error) return this.$toasted.global.error(error)
    this.bank = response.data.data
    this.bank.name = 'Banco BBVA'
  }

  @State('UserModule') state: any
}
