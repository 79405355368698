









// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import Benefit from '@/components/benefits/Benefit.vue'

@Component({
  components: { Footer, Benefit }
})
export default class BancoBase extends Vue {
  benefitData: any = {
    name: 'BancoBase',
    textReturn: 'Regresar',
    openAccount: {
      img1: {
        url: require('@/assets/images/Benefits/Banco_Base.png'),
        style1: 'width: 182px; height: 46px;',
        style2: ''
      },
      img2: {
        url: require('@/assets/images/Benefits/Banco_Base2.png'),
        classStyle: 'col-12 col-lg-5 col-xl-5 mt-0 px-0'
      },
      title: 'Simplifica los pagos internacionales de tu empresa',
      subTitle: '',
      text: '',
      url: 'https://tallylegal.typeform.com/to/T3ZZ53wE',
      point: '',
      points: [
        'Cubre tus necesidades de compraventa de divisas',
        'Administra fácilmente los recursos de tu empresa',
        'Envía y recibe pagos internacionales en el horario más amplio del mercado.',
        'Más de 30 cruces de divisas',
        'Asesoría experta en derivados',
        'Ahorra hasta un 80% en operaciones en dólares'
      ],
    },
    companyDocs: {
      title: 'Sube tus documentos',
      subTitle: 'Los necesitamos para crear tu cuenta empresarial',
      msj: {
        Title: 'Revisa tus documentos',
        Text: 'Deberás subir tus documentos originales, sin tachaduras ni enmendaduras y con fecha de expedición no mayor a 2 meses.'
      }
    },
    authorization: {
      title: 'Autorizas el envío de tus documentos',
      subTitle: 'Enviaremos a Banco Base tu información',
      protection: 'Tu información está protegida',
      authorization: 'Autorizo a Tally compartir mis documentos con Banco Base, S.A. Institucion de Banca Multiple, para la apertura de mi cuenta empresarial'
    },
    complete: {
      title: '¡Solicitud realizada con éxito!',
      subTitle: '',
      button: 'Finalizar',
      points: [],
    }
  }

  @State('UserModule') state: any
}
