
















// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component
export default class Buttons extends Vue {
  buttons: any = [{ name: 'Todos', type: 'all', active: true }]

  created () {
    this.buttons = this.$attrs.value
  }

  isActive (name: string) {
    this.buttons.map((button: any) => {
      if (button.name === name) button.active = true
      else if (button.active && button.name !== name) button.active = false
    })
  }

  @State('UserModule') state: any
}
