





































































































































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
import { validationMixin } from 'vuelidate'
// Helpers
import { UTM } from '@/helpers/UTM'
// Validations
import validations from '@/validations/singup'
// Components
import HeaderProgressBar from '@/components/HeaderProgressBar.vue'
import ReturnAddress from '@/components/Return.vue'
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
// Types
import AuthTypes from '@/store/types/AuthTypes'
import CompanyTypes from '@/store/types/CompanyTypes'
// Services
import CountryService from '@/services/Country'
import UserService from '@/services/User'
import CompanyService from '@/services/Company'


@Component({
  components: { HeaderProgressBar, Footer, ReturnAddress, Loader },
  mixins: [validationMixin],
  validations: validations
})
export default class PersonalInformation extends Vue {
  loading = true
  loadingModal = false
  email = ''
  phone = ''
  password = ''
  name = ''
  first_names = ''
  legal_name = ''
  company_type = ''
  agreement = false
  country = { lada: '52', alpha_code: 'MX' }
  countries = []
  nationalities = []
  passwordFieldType = 'password'
  confirmpasswordFieldType = 'password'
  eye = 'eye'
  eye2 = 'eye'
  validEmail = 0
  googleCredential: any = ''
  showModal = false

  created () {
    this.legal_name = this.state.legal_name
    this.company_type = this.state.type_Society
  }

  async mounted () {
    await this.getCountries()
    window.google.accounts.id.initialize({
      client_id: process.env.VUE_APP_GOOGLE_ID,
      callback: this.handleCredentialResponse
    });
    window.google.accounts.id.renderButton(
      document.getElementById("buttonDiv"),
      { theme: "outline", width: 315, text: "continue_with", logo_alignment: "left" }
    );
    window.google.accounts.id.prompt();
  }

  switchVisibility () {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    this.eye = this.eye === 'eye' ? 'eye-slash' : 'eye'
  }

  handleCredentialResponse(response: any) {
    if (!response || !response.credential) return
    this.googleCredential = response
    this.showModal = true
  }

  switchVisibility2 () {
    this.confirmpasswordFieldType = this.confirmpasswordFieldType === 'password' ? 'text' : 'password'
    this.eye2 = this.eye2 === 'eye' ? 'eye-slash' : 'eye'
  }

  onlyNumbers (value: string) {
    return value.replace(/[^0-9.]/g,'');
  }

  validate (email: string, value: boolean) {
    if (email === '') return this.validEmail = 0
    if (!value) return this.validEmail = 1
    return this.validEmail = 0
  }

  async getCountries () {
    const [error, response] = await CountryService.all()
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.loading = false
    this.countries = response.data.data
  }

  async saveCompany () {
    const [error] = await CompanyService.store({
      company_name: this.state.legal_name,
      legal_names: [this.state.legal_name],
      company_type: this.company_type,
      legal_representation: this.state.legal_representation,
      name_authorization: false
    })
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
  }

  async next () {
    this.loading = true
    const [errorEmail, response] = await UserService.availableEmail(this.email)
    if (errorEmail) {
      this.loading = false
      this.validEmail = 1
      return this.$toasted.global.error(errorEmail)
    }
    if (response.data.data) {
      this.loading = false
      return this.validEmail = 2
    }
    try {
      /* eslint-disable-next-line */
      const [error, res] = await this.register({
        name: this.name,
        first_names: this.first_names,
        last_names: '',
        email: this.email,
        phone: this.phone,
        password: this.password,
        country_iso_code: this.country.alpha_code,
        package: this.state.package,
        phone_country_code: this.country.lada,
        company_name: this.state.legal_name,
        legal_names: (this.state.legal_name === '') ? [] : [this.state.legal_name],
        company_type: this.company_type,
        activities: this.state.activities,
        legal_representation: this.state.legal_representation,
        subsidiary: this.state.company_type === 'subsidiary',
        name_authorization: this.state.name_authorization
      })
    } catch (e) {
      this.loading = false
      return this.$toasted.global.error(e)
    }
    if (process.env.NODE_ENV === 'production') {
      window.dataLayer.push({ event: 'tally_registerSuccess' })
      if (this.state.legal_representation) window.dataLayer.push({ event: 'UserRegister_Representacion' })
      else window.dataLayer.push({ event: 'UserRegister_Constitutiva' })
    }
    this.$toasted.global.success({ message: 'Te has registrado con éxito' })
    await UTM.setLocalUTMs(this.$router)
    return this.$router.push({ name: 'VerifyAccount', query: this.$route.query })
  }

  async RegisterGoogle () {
    this.loadingModal = true
    this.loading = true
    try {
      /* eslint-disable-next-line */
      const [error, res] = await this.registerGoogle({
        googleCredential:  this.googleCredential,
        phone: this.phone,
        country_iso_code: this.country.alpha_code,
        phone_country_code: this.country.lada,
        package: this.state.package,
        company_name: this.state.legal_name,
        legal_names: (this.state.legal_name === '') ? [] : [this.state.legal_name],
        company_type: this.company_type,
        activities: this.state.activities,
        legal_representation: this.state.legal_representation,
        subsidiary: this.state.company_type === 'subsidiary',
        company_package: this.state.package,
        name_authorization: this.state.name_authorization
      })
    } catch (e) {
      this.loadingModal = false
      this.loading = false
      return this.$toasted.global.error(e)
    }
    if (process.env.NODE_ENV === 'production') window.dataLayer.push({ event: 'tally_registerSuccess' })
    this.$toasted.global.success({ message: 'Te has registrado con éxito' })
    await this.clearData()
    await UTM.setLocalUTMs(this.$router)
    return this.$router.push({ name: 'VerifyAccount', query: this.$route.query })
  }

  @Action(`AuthModule/${AuthTypes.actions.REGISTER}`) register: any;
  @Action(`AuthModule/${AuthTypes.actions.REGISTERGOOGLE}`) registerGoogle: any;
  @Action(`CompanyModule/${CompanyTypes.actions.CLEAR_DATA}`) clearData: any;
  @State('CompanyModule') state: any
}
