







































































































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
// Types
import UserTypes from '@/store/types/UserTypes'
// Services
import ConstitutiveService from '@/services/Constitutive'
import CompanyService from '@/services/Company'
import SATService from '@/services/SAT'

@Component({
  components: { Loader }
})
export default class ShippingAddress extends Vue {
  loading = false
  loadingAddress = false
  open = false
  states = []
  officesSAT: any = []
  partners: any = []
  partner: any = {}
  zipCode = ''
  suburb = ''
  addressState = ''
  city = ''
  street = ''
  outdoor_number = ''
  interior_number = ''
  name = ''
  addressReferences = ''

  @Watch('zipCode')
  changeZipCode () {
    if (this.zipCode.length === 5) this.getStatesByZipCode()
  }

  created () {
    this.partners = this.state.company.partners.filter((partner: any ) => partner.rfc !== '' && partner.rfc !== 'EXTF900101NI1')
    this.getModules()
  }

  get style () {
    return this.partners.length === 1 || this.partners.length === 0 ?
      'height: 55px;' : this.partners.length === 2 ?
      'height: 110px;' : this.partners.length === 3 ?
      'height: 165px;' : this.partners.length === 4 ?
      'height: 220px;' : this.partners.length === 5 ?
      'height: 275px;' : 'height: 290px;'
  }

  noSpacesStart (value: string) {
    return value.trimStart()
  }

  onlyNumbers (value: string) {
    return value.trimStart().replace(/[^0-9.]/g,'');
  }

  updateCity (city: any) {
    this.addressState = city.city
    this.suburb = city.suburb
  }

  async getStatesByZipCode () {
    this.states = []
    this.loadingAddress = true
    const response = await ConstitutiveService.getStatesByZipCode(this.zipCode)
    if (response[1]) this.states = response[1].data.postalCodes.data
    else {
      this.loadingAddress = false
      return this.$toasted.global.error({ message: 'No se encontro el código postal' })
    }
    const state = response[1].data.city.name.replace(/_/g, ' ') === 'CDMX' ? 'Ciudad de México' : response[1].data.city.name.replace(/_/g, ' ')
    for (const [key, value] of Object.entries(this.officesSAT)) {
      if (value === state) {
        this.addressState = key
        break
      }
    }
    this.loadingAddress = false
  }

  async getModules () {
    this.loading = true
    const Efirma = (this.state.company.rfc !== null && this.state.company.rfc !== '') || this.state.company.rfc_later || this.state.company.appointment_type_efirma
    const [error, response] = await SATService.getModules(Efirma ? 'efirma' : 'inscripcion_persona_moral')
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.officesSAT = response.data
    this.loading = false
  }

  getPartnerFullName (partner: any) {
    let full_name: string = partner.name != null ? partner.name : ''
    full_name = partner.first_names != null ? full_name + ' ' + partner.first_names : full_name
    full_name = partner.last_names != null ? full_name + ' ' + partner.last_names : full_name
    return full_name.trimEnd()
  }

  async saveAddress () {
    this.loading = true
    const name = this.getPartnerFullName(this.partner)
    const [error] = await CompanyService.setAddress(this.state.company.id, {
      shipping_person : this.partner.id,
      street: this.street,
      num_ext: this.outdoor_number,
      num_int: this.interior_number,
      suburb: this.suburb,
      postal_code: this.zipCode,
      city: this.city,
      state: this.officesSAT[this.addressState],
      entity_modules: [this.addressState],
      references: this.addressReferences,
      who_receives: name
    })
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    await this.updateCompany(this.state.company.id)
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
