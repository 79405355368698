





























































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
// Services
import CommissaryService from '@/services/Commissary'
import PaymentService from '@/services/Payment'

@Component({
  components: { Loader }
})
export default class CardCommissaryTally extends Vue {
  loading = false
  documentsApproved = false
  checkoutComplete = false
  companyArticlesMock = false
  price = ''
  priceTotal = ''
  pointsCommissary = [
    'Te asignamos un comisario',
    'Contabilidad',
    'Acta de Asamblea Anual',
    'Declaraciones fiscales',
    'Te conectamos con el SAT'
  ]
  pointsAccountant = [
    'Contabilidad',
    'Asesoría Fiscal',
    'Facturación',
    'Declaraciones fiscales',
    'Te conectamos con el SAT'
  ]
  commissaryTally: any = {
    isTally: true
  }

  async created () {
    this.commissaryTally = this.$attrs.value
    this.checkoutComplete = this.state.company.orders.filter((order: any) => order.status === 'complete' && order.items.filter((item: any) => item.name === 'Complemento Acta Constitutiva' || item.name === 'Acta Constitutiva').length > 0).length > 0
    this.companyArticlesMock = this.state.company.stages.find((stage: any) => stage.stage_name === 'signing_complete').is_completed === true
    await this.getPrices()
  }

  async getPrices () {
    const [error, response] = await PaymentService.getPrices()
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    response.data.data.map((item: any) => {
      if (item.name === 'Servicio de Comisario') {
        // const commissaryPrice1 = item.prices.filter((prices: any ) => prices.slug === 'with_commissary')
        const commissaryPrice2 = item.prices.filter((price: any ) => price.is_default === true)
        // const price1 = (Math.trunc(Number(commissaryPrice1[0].price) + Number(commissaryPrice1[0].tax))).toString().replace(/\d(?=(?:\d{3})+$)/g, '$&,')
        const price2 = (Math.trunc(Number(commissaryPrice2[0].price) + Number(commissaryPrice2[0].tax))).toString().replace(/\d(?=(?:\d{3})+$)/g, '$&,')
        // this.price = !this.companyArticlesMock ? price1 : price2
        this.price = price2
        this.priceTotal = price2
      }
    })
  }

  async saveCommissary () {
    this.loading = true
    if (this.companyArticlesMock) return this.$router.push({ name: 'CheckoutAccountant', query: this.$route.query, params: { company_id: this.state.company.id } }).catch(() => {return})
    if (this.checkoutComplete) return this.$router.push({ name: 'CheckoutCommissary', query: this.$route.query, params: { company_id: this.state.company.id } }).catch(() => {return})
    const [error] = await CommissaryService.setCommissaryTally(this.state.company.id)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.$toasted.global.success({ message: 'Se guardo el comisario' })
    return this.$router.push({ name: 'Welcome', query: this.$route.query, params: { company_id: this.state.company.id } }).catch(() => {return})
  }

  @State('UserModule') state: any
}
