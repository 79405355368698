

















// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component
export default class CardsBenefits extends Vue {
  buttons: any = [{ name: 'Todos', type: 'all', active: true }]
  cards = [
    { 
      name: 'Fondeadora',
      type: 'business account',
      description: 'Recibe pagos en menos de 48 horas',
      img: require('@/assets/images/Benefits/Fondeadora.png'),
      background: '#FFFFFF',
      style: 'max-width: 193px; max-height: 47px;',
      go: 'Fondeadora'
    },
    { 
      name: 'Banco Base',
      type: 'business account',
      description: 'Resuelve tu operación internacional.',
      img: require('@/assets/images/Benefits/Banco_Base.png'),
      background: '#FFFFFF',
      style: 'max-width: 182px; max-height: 46px;',
      go: 'BancoBase'
    },
    { 
      name: 'BBVA Spark',
      type: 'business account',
      description: 'La cuenta de banco para Startups',
      img: require('@/assets/images/Benefits/BBVA.png'),
      background: '#0E2043',
      style: 'max-width: 122px; max-height: 66px;',
      go: 'BBVASpark'
    },
    { 
      name: 'Clara',
      type: 'Credit card',
      description: 'Accede a una tarjeta de crédito corporativa.',
      img: require('@/assets/images/Benefits/Clara.png'),
      background: '#000000',
      style: 'max-width: 192px; max-height: 58px;',
      go: 'Clara'
    },
    /* { 
      name: 'Kapital',
      type: 'business account',
      description: 'Centraliza toda la gestión de tu negocio',
      img: require('@/assets/images/Benefits/Kapital.png'),
      background: '#000000',
      style: 'max-width: 184px; max-height: 42px;',
      go: 'Kapital'
    }, */
    { 
      name: 'Stripe',
      type: 'Payments and billing',
      description: 'La mejor infraestructura para recibir pagos en linea.',
      img: require('@/assets/images/Benefits/Stripe.png'),
      background: '#FFFFFF',
      style: 'max-width: 176px; max-height: 84px;',
      go: 'Stripe'
    },
    { 
      name: 'Gigstack',
      type: 'Payments and billing',
      description: 'Automatiza todos tus flujos de pago y facturación',
      img: require('@/assets/images/Benefits/Gigstack.png'),
      background: '#FFFFFF',
      style: 'max-width: 180px; max-height: 60px;',
      go: 'Gigstack'
    },
    { 
      name: 'Delt.ai',
      type: 'Credit card',
      description: 'Rendimiento desde el primer peso, Controla tus Gastos. ',
      img: require('@/assets/images/Benefits/Deltai.png'),
      background: '#FFFFFF',
      style: 'max-width: 184px; max-height: 60px;',
      go: 'Delt'
    },
    { 
      name: 'Jeeves',
      type: 'Credit card',
      description: 'Rendimiento desde el primer peso, Controla tus Gastos. ',
      img: require('@/assets/images/Benefits/Jeeves.png'),
      background: '#000000',
      style: 'max-width: 180px; max-height: 60px;',
      go: 'Jeeves'
    },
    { 
      name: 'Around',
      type: 'Office',
      description: 'Consigue oficina en CDMX',
      img: require('@/assets/images/Benefits/Around.png'),
      background: '#FFFFFF',
      style: 'max-width: 132px; max-height: 38px;',
      go: 'Around'
    },
    { 
      name: 'Dgmxtech',
      type: 'Technology',
      description: 'Crea tu tienda en linea o sitio web',
      img: require('@/assets/images/Benefits/Dgmxtech.png'),
      background: '#FFFFFF',
      style: 'max-width: 179px; max-height: 49px;',
      go: 'Dgmxtech'
    },
    { 
      name: 'Krowdy',
      type: 'Recruitment',
      description: 'La forma más fácil de reclutar talento',
      img: require('@/assets/images/Benefits/Krowdy.png'),
      background: '#FFFFFF',
      style: 'max-width: 180px; max-height: 60px;',
      go: 'Krowdy'
    }
  ]

  created () {
    this.buttons = this.$attrs.value
    if (!this.state.company.legal_representation) this.cards = this.cards.filter((card: any ) => card.name !== 'Banco Base' && card.name !== 'BBVA Spark')
  }

  get filter () {
    const filter = this.buttons.find((button: any) => button.active === true).type
    if (filter === 'all') return this.cards
    const cardsAux = this.cards.filter((card: any ) => card.type === filter)
    return cardsAux
  }

  async event (go: string) {
    if (go !== null && go !== '') return this.$router.push({ name: go, query: this.$route.query, params: { company_id: this.state.company.id } })
  }

  @State('UserModule') state: any
}
