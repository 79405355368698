









































































// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'
// Mixins
import ConversMixin from '@/mixins/Converts'

@Component({
  mixins: [ConversMixin]
})
export default class InputFileDocumentsError extends Vue {
  @Prop() document_type!: string
  supportedFiles = '.jpg, .jpeg, .png, .pdf'
  file: any = null;
  fileDetail = { name: '', size: null }

  mounted () {
    // @ts-ignore
    if (this.$attrs.value && this.$attrs.value.name) this.fileDetail.name = this.$attrs.value.name
    if (this.file === null && this.fileDetail.name) this.file = []
    if (this.document_type === 'CFI') this.supportedFiles = '.pdf'
  }

  updateFile (file: any) {
    this.fileDetail.name = this.file === null ? '' : file.name
    this.fileDetail.size = this.file === null ? null : file.size
    this.$emit('input', file)
  }
}
