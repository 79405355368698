







































































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
import InputFile from '@/components/InputFile.vue'
import TooltipDenomination from '@/components/company/TooltipDenomination.vue'
// Types
import UserTypes from '@/store/types/UserTypes'
// Services
import ConstitutiveService from '@/services/Constitutive'
import CompanyService from '@/services/Company'

@Component({
  components: { Loader, InputFile, TooltipDenomination }
})
export default class ModalCreateCompany extends Vue {
  modal: any = {
    showModalCreateCompany: false,
    update: false,
  }
  options = [{
      type: 'foreign',
      title: 'Soy Extranjero',
      text: 'Ninguno de los socios tiene ',
      textBold: 'RFC (número de identificación fiscal mexicano).',
      img: require('@/assets/images/typeSAPI.png'),
    },{
      type: 'mexican',
      title: 'Resido en México',
      text: 'Tú o alguno de tus socios es 100% mexicano o tiene tarjeta de residente y ',
      textBold: 'RFC válido y vigente.',
      img: require('@/assets/images/mexican.png'),
    }
  ]
  typesSociety = [{
      type: 'sapi',
      title: 'S.A.P.I de C.V.',
      text: 'La favorita de los fondos de inversión, este tipo de sociedad te permite tener un consejo, emitir acciones a trabajadores clave y a inversionistas con diferentes derechos de voto.',
      img: require('@/assets/images/Circle-rocket.png'),
    },{
      type: 'sa',
      title: 'S.A. de C.V.',
      text: 'La Sociedad Anónima es ideal para cualquier tipo de negocio. Puedes nombrar a un administrador único o consejo de administración.',
      img: require('@/assets/images/Circle-chess.png'),
    },{
      type: 'srl',
      title: 'S. de R.L. de C.V.',
      text: 'Recomendada para empresas familiares donde se pretende que los socios no varien a lo largo del tiempo, puede ser administrada por una o varias personas. ',
      img: require('@/assets/images/mexican.png'),
    }
  ]
  points = [{
      text: 'Evita usar palabras restringidas "banco" o "inversión". ',
      url: 'https://www.tallylegal.io/palabras-prohibidas',
      bold: false
    },{
      text: 'Puede ser diferente al nombre de tu marca.',
      url: '',
      bold: true
    },{
      text: 'Se creativo, puedes usar números.',
      url: '',
      bold: false
    },{
      text: 'No se permiten carácteres especiales.',
      url: '',
      bold: false
    }
  ]
  types: Array<string> = ['sapi', 'sa', 'srl']
  step = 0
  company_type = ''
  optionSelected = ''
  legal_name = ''
  loading = false
  validName = true
  authorization = false

  mounted () {
    this.modal = this.$attrs.value
  }

  filterLegalName (value: string) { return value.replace(/[^a-zA-Z0-9ÑñÁáÉéÍíÓóÚú ]/g, '').trimStart() }

  async createCompany () {
    this.loading = true
    const [error, response] = await ConstitutiveService.validateLegalName(this.legal_name)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    if (!response.data.data) {
      this.loading = false
      return this.validName = false
    }
    const [errorCreate, responseCreate] = await CompanyService.store({
      company_name: this.legal_name,
      legal_names: [this.legal_name],
      company_type: this.company_type,
      legal_representation: this.optionSelected === 'foreign',
      name_authorization: this.authorization
    })
    if (errorCreate) {
      this.loading = false
      return this.$toasted.global.error(errorCreate)
    }
    await this.updateUser()
    this.modal.update = true
    this.modal.showModalCreateCompany = false
    this.$toasted.global.success({ message: 'Se ha creado la empresa con éxito' })
    return this.$router.push({ name: 'Dashboard', query: this.$route.query, params: { company_id: responseCreate.data.data.id } })
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_DATA}`) updateUser: any
  @State('UserModule') state: any
}
