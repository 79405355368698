































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component({
  components: {}
})
export default class AddNewLegalNames extends Vue {
  newLegalNames: any = {
    namesMatch: false,
    checkLegalNames: false,
    existLegalNames: false,
    repeatLegalNames: false,
    legalNames: [
      { name: '', equal: false, valid: false, repeat: false, minLength: true, exist: false, id: 1 },
      { name: '', equal: false, valid: false, repeat: false, minLength: true, exist: false, id: 2 },
      { name: '', equal: false, valid: false, repeat: false, minLength: true, exist: false, id: 3 }
    ]
  }
  company_type = ''
  types: Array<string> = ['sapi', 'sa', 'srl']

  mounted () {
    this.newLegalNames = this.$attrs.value
    this.company_type = this.state.company.company_type
  }

  filterLegalName (value: string) { return value.replace(/[^a-zA-Z0-9ÑñÁáÉéÍíÓóÚú ]/g, '').trimStart() }

  validate () {
    // Separates denominations into lowercase words
    const array1 = this.newLegalNames.legalNames[0].name === ''
      ? [] : this.newLegalNames.legalNames[0].name.split(' ').filter((word: string) => word.length > 0).map((word: string) => { return word.toLowerCase() })
    const array2 = this.newLegalNames.legalNames[1].name === ''
      ? [] : this.newLegalNames.legalNames[1].name.split(' ').filter((word: string) => word.length > 0).map((word: string) => { return word.toLowerCase() })
    const array3 = this.newLegalNames.legalNames[2].name === ''
      ? [] : this.newLegalNames.legalNames[2].name.split(' ').filter((word: string) => word.length > 0).map((word: string) => { return word.toLowerCase() })

    // Reset values
    this.newLegalNames.legalNames[0].equal = this.newLegalNames.legalNames[1].equal = this.newLegalNames.legalNames[2].equal = this.newLegalNames.namesMatch = false
    this.newLegalNames.legalNames[0].exist = this.newLegalNames.legalNames[1].exist = this.newLegalNames.legalNames[2].exist = this.newLegalNames.existLegalNames = false
    this.newLegalNames.legalNames[0].repeat = this.newLegalNames.legalNames[1].repeat = this.newLegalNames.legalNames[2].repeat = this.newLegalNames.repeatLegalNames = false

    // Validation 1 - Have 2 words or more
    this.newLegalNames.legalNames[0].minLength = array1.length >= 2 || array1.length === 0
    this.newLegalNames.legalNames[1].minLength = array2.length >= 2 || array2.length === 0
    this.newLegalNames.legalNames[2].minLength = array3.length >= 2 || array3.length === 0
    this.newLegalNames.checkLegalNames = array1.length >= 2 && array2.length >= 2 && array3.length >= 2

    // Validation 2 - Are not the same as those rejected
    this.state.company.legal_names.map((legal_name: any) => {
      if (this.newLegalNames.legalNames[0].name.toLowerCase().trimEnd() === legal_name.name.toLowerCase()) this.newLegalNames.legalNames[0].exist = true
      if (this.newLegalNames.legalNames[1].name.toLowerCase().trimEnd() === legal_name.name.toLowerCase()) this.newLegalNames.legalNames[1].exist = true
      if (this.newLegalNames.legalNames[2].name.toLowerCase().trimEnd() === legal_name.name.toLowerCase()) this.newLegalNames.legalNames[2].exist = true
    })

    // Validation 3 - Not have repeated words
    /* array1.map((word: any) => {
      if (array2 && (array2.indexOf(word) > -1)) this.newLegalNames.legalNames[1].equal = true
      if (array3 && (array3.indexOf(word) > -1)) this.newLegalNames.legalNames[2].equal = true
    })
    array2.map((word: any) => {
      if (array3 && (array3.indexOf(word) > -1)) this.newLegalNames.legalNames[2].equal = true
    }) */

    // Validation 4 - Not have repeated options
    const exist0 = this.newLegalNames.legalNames[0].name !== ''
    const exist1 = this.newLegalNames.legalNames[1].name !== ''
    const exist2 = this.newLegalNames.legalNames[2].name !== ''
    if (exist0 && exist1 && this.newLegalNames.legalNames[0].name.toLowerCase().trimEnd() === this.newLegalNames.legalNames[1].name.toLowerCase().trimEnd()) this.newLegalNames.legalNames[0].repeat = this.newLegalNames.legalNames[1].repeat = true
    if (exist0 && exist2 && this.newLegalNames.legalNames[0].name.toLowerCase().trimEnd() === this.newLegalNames.legalNames[2].name.toLowerCase().trimEnd()) this.newLegalNames.legalNames[0].repeat = this.newLegalNames.legalNames[2].repeat = true
    if (exist1 && exist2 && this.newLegalNames.legalNames[1].name.toLowerCase().trimEnd() === this.newLegalNames.legalNames[2].name.toLowerCase().trimEnd()) this.newLegalNames.legalNames[1].repeat = this.newLegalNames.legalNames[2].repeat = true

    // Confirmation of validations 2, 3 and 4
    if (!this.newLegalNames.legalNames[0].equal && !this.newLegalNames.legalNames[1].equal && !this.newLegalNames.legalNames[2].equal) this.newLegalNames.namesMatch = true
    if (!this.newLegalNames.legalNames[0].exist && !this.newLegalNames.legalNames[1].exist && !this.newLegalNames.legalNames[2].exist) this.newLegalNames.existLegalNames = true
    if (!this.newLegalNames.legalNames[0].repeat && !this.newLegalNames.legalNames[1].repeat && !this.newLegalNames.legalNames[2].repeat) this.newLegalNames.repeatLegalNames = true
  }

  @State('UserModule') state: any;
}
