















// Utils
import { Component, Vue } from 'vue-property-decorator'

@Component({
})
export default class TooltipLegalRepresentation extends Vue {
}
