




















// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
})
export default class TooltipCFIForeing extends Vue {
  @Prop({default: ''}) color!: string
  points = [{
      point: 'El de tu país de residencia con tu numero de identificación fiscal.',
      url: ''
    },{
      point: 'En caso de E.U. puedes subir tu número de seguridad social.',
      url: ''
    }
  ]
}
