









// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import Benefit from '@/components/benefits/Benefit.vue'

@Component({
  components: { Footer, Benefit }
})
export default class Around extends Vue {
  benefitData: any = {
    name: 'Around',
    textReturn: 'Regresar',
    openAccount: {
      img1: {
        url: require('@/assets/images/Benefits/Around.png'),
        style1: 'width: 132px; height: 38px;',
        style2: ''
      },
      img2: {
        url: require('@/assets/images/Benefits/Around2.png'),
        classStyle: 'col-12 col-lg-6 col-xl-5 mt-0 px-0'
      },
      title: 'Oficinas en renta que se adaptan a tu equipo',
      subTitle: '',
      text: '',
      url: 'https://tallylegals.notion.site/Renta-una-oficina-sin-plazos-forzosos-d8a40c58820a44a39b61f516db96bc3f',
      point: '¡Deja atrás los espacios de trabajo tradicionales! Renta una oficina sin plazos forzosos y con todo lo que necesitas para potenciar el crecimiento de tu empresa',
      points: [],
    },
    companyDocs: {
      title: 'Sube tus documentos',
      subTitle: 'Los necesitamos para crear tu cuenta empresarial',
      msj: {
        Title: 'Revisa tus documentos',
        Text: 'Deberás subir tus documentos originales, sin tachaduras ni enmendaduras y con fecha de expedición no mayor a 2 meses.'
      }
    },
    authorization: {
      title: 'Autorizas el envío de tus documentos',
      subTitle: '',
      protection: 'Tu información está protegida',
      authorization: ''
    },
    complete: {
      title: '¡Solicitud realizada con éxito!',
      subTitle: '',
      button: 'Finalizar',
      points: [
      ],
    }
  }

  @State('UserModule') state: any
}
