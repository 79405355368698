
























// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
// Types
import UserTypes from '@/store/types/UserTypes'

@Component({
  components: { Footer  }
})
export default class AnalyzingData extends Vue {
  verification = true
  partner: any = {}
  interval: any = null
  cont = 0
  maxCont = 0

  created () {
    this.interval = setInterval(()=>{
      this.cont++
      if (this.cont === 150) {
        this.isValidate()
        this.cont = 0
        this.maxCont++
      }
      if (this.maxCont === 15) {
        clearInterval(this.interval)
      }
    }, 1000)
    this.isValidate()
  }

  async isValidate () {
    await this.updateCompany(this.state.company.id)
    this.state.company.partners.map((partner: any) => { if (this.state.email === partner.email) { this.partner = partner } })
    if (this.partner.metamap_process === true) {
      clearInterval(this.interval)
      return this.$router.push({ name: 'ValidationComplete', query: this.$route.query, params: { company_id: this.state.company.id } }).catch(() => {return})
    } else if (this.partner.metamap_process === false && !this.partner.mati_verification) {
      this.verification = false
    }
  }
  
  beforeDestroy(){
    clearInterval(this.interval)
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any;
}
