





















































































































// Utils
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import moment from 'moment'
// Components
import InputFile from '@/components/InputFile.vue'
import TooltipCFI from '@/components/partners/TooltipCFI.vue'
import TooltipCFIForeing from '@/components/partners/TooltipCFIForeing.vue'
import TooltipAddress from '@/components/partners/TooltipAddress.vue'
import ModalCSFExample from '@/components/partners/ModalCSFExample.vue'
import ModalTermsConditions from '@/components/partners/ModalTermsConditions.vue'
import PartnerMexicanWithoutRFC from '@/components/partners/PartnerMexicanWithoutRFC.vue'
import Loader from '@/components/Loader.vue'
// Types
import InvitedTypes from '@/store/types/InvitedTypes'
// Services
import PartnersService from '@/services/Partners'
import ImageService from '@/services/Image'

@Component({
  components: { InputFile, TooltipCFI, TooltipCFIForeing, TooltipAddress, ModalCSFExample, ModalTermsConditions, PartnerMexicanWithoutRFC, Loader }
})
export default class InvitedPartnerComplementaries extends Vue {
  @Prop() address: string|undefined
  @Prop() address2: string|undefined
  @Prop() nationalities!: any
  modal: any = { showModalCSFExample: false, showModalTermsConditions: false, update: false, accept: false, termsAndConditions: '' }
  partnerSelect: any = { RFC: '' }
  partner: any = {}
  loading = false
  open = false
  single = true
  partner_id = ''
  cfi: any = null
  address_document: any = null
  viewCFI = false
  viewAddressDocument = false
  rfc = ''
  nif = ''
  tax_country = ''
  foreign = false
  moment = moment
  renderKey = 0
  countryNifCopies = [
    { country: 'Argentina', nif: 'CUIT (Código Único de Identificación Tributaria)', doc: 'Sube tu Constancia de Inscripción u Opción AFIP', text: 'El documento debe tener una fecha de emisión no mayor a un año.'},
    { country: 'Chile', nif: 'RUT (Rol Único Tributario)', doc: 'Sube tu Cédula de Identidad por ambos lados', text: 'Debe estar vigente.'},
    { country: 'Colombia', nif: 'NIT (Número de Identificación Tributaria)', doc: 'Sube tu Registro Único Tributario de la DIAN', text: 'El documento debe tener una fecha de emisión no mayor a un año.'},
    { country: 'Perú', nif: 'RUC (Registro Único de Contribuyentes)', doc: 'Sube tu Reporte de Ficha RUC emitido por la SUNAT', text: 'El documento debe tener una fecha de emisión no mayor a un año.'},
    { country: 'España', nif: 'NIF (Número de Identificación Fiscal)', doc: 'Sube tu Documento Nacional de Identidad (DNI) por ambos lados.', text: 'Debe estar vigente.'},
    { country: 'Estados Unidos', nif: 'SSN (Social Security Number)', doc: 'Upload your latest Individual Income Tax Return', text: 'The document must be issued within one year prior to the submission date.'},
    { country: 'Canadá', nif: 'SIN (Social Insurance Number)', doc: 'Upload your Social Insurance Card', text: 'The document must be issued within one year prior to the submission date.'},
    { country: 'Venezuela', nif: 'RIF (Registro de Información Fiscal)', doc: 'Sube tu Registro de Información Fiscal emitido por el SENIAT.', text: 'El documento debe tener una fecha de emisión no mayor a un año.'},
    { country: 'China', nif: '纳税人识别号', doc: '上传由国家税务总局颁发的纳税人识别号证件', text: '你应该附上一个简单的西班牙语或英语翻译。提交文件的发行日期必须在提交之日起一年以内。'},
    { country: 'Francia', nif: 'Numéro Fiscal de Référence', doc: `Télécharge ton Avis d'imposition`, text: `Le document doit avoir été émis moins d'un an avant la date de soumission.`},
    { country: 'Italia', nif: 'Cofice Fiscale', doc: "Carica il tuo Codice Fiscale o Carta d'Identità", text: 'Il documento deve essere stato emesso non oltre un anno prima della data di presentazione.'},
    { country: 'Países Bajos', nif: 'BSN (Burgerservicenummer)', doc: 'Upload jouw Identiteitskaart of paspoort waarop je BSN staat', text: 'Je document moet geldig zijn.'},
    { country: 'Guatemala', nif: 'NIT (Número de Identificación Tributaria)', doc: 'Sube tu Constancia de Inscripción al RTU', text: 'El documento debe tener una fecha de emisión no mayor a un año.'},
    { country: 'Costa Rica', nif: 'CIF (Cédula de Identidad Física) ', doc: 'Sube tu Cédula de Identidad', text: 'Debe estar vigente y por ambos lados.'},
    { country: 'Cuba', nif: 'Número de Identificación Nacional (NIN)', doc: 'Sube tu Carnet de Identidad', text: 'Debe estar vigente.'},
    { country: 'Puerto Rico', nif: 'Número de Seguro Social (SSN)', doc: 'Upload your latest Individual Income Tax Return', text: 'The document must be issued within one year prior to the submission date.'},
    { country: 'Brasil', nif: 'CPF (Cadastro de Pessoas Físicas)', doc: 'Envie seu CPF ou CNPJ emitido pela Receita Federal.', text: 'O documento deve ter sido emitido no máximo um ano antes da data de submissão.'},
    { country: 'República Dominicana', nif: 'RNC (Registro Nacional del Contribuyente)', doc: 'Sube tu Cédula de Identidad y Electoral emitida por la JCE por ambos lados', text: 'Debe estar vigente.'},
    { country: 'Panama', nif: 'RUC (Registro Único de Contribuyente)', doc: 'Sube tu Documento de Identidad Personal o tu Notificación de Inscripción en el RUC', text: 'Debe estar vigente.'},
    { country: 'Paraguay', nif: 'RUC (Registro Único de Contribuyentes)', doc: 'Sube tu Cédula de Identidad por ambos lados.', text: 'El documento debe tener una fecha de emisión no mayor a un año.'}
  ]

  @Watch('partnerSelect.RFC')
  reset () {
    if (this.partnerSelect.RFC === 'NO') this.rfc = 'EXTF900101NI1'
    else if (this.partnerSelect.RFC === 'SI') this.rfc = this.partner.rfc === null ? '' : this.partner.rfc
    this.renderKey++
  }

  @Watch('modal.update')
  accept () {
    if (!this.modal.update) return
    this.modal.update = false
    this.next()
  }

  get NifCopy () {
    const copy = this.countryNifCopies.filter((nifCopy: any ) => nifCopy.country === this.tax_country)
    if (copy.length > 0) return copy[0]
    return { nif: 'Número de identificación fiscal', doc: 'Sube tu Comprobante Fiscal', text: 'De preferencia del último año tributado'}
  }

  async created () {
    this.loading = true
    if (this.state.partner.mati_verification === null || !this.state.partner.mati_verification) await this.updateUser({
      partner: this.state.partner.id,
      company_id: this.state.company_id
    })
    this.partner = this.state.partner
    this.foreign = true
    this.partner.nationalities.map((nationality: any) => { if (nationality.name === 'México' || nationality.issue_country === 'MX') this.foreign = false })
    this.partner_id = this.partner.id
    if (this.foreign && this.partnerSelect.RFC === 'NO') this.rfc = 'EXTF900101NI1'
    else this.rfc = this.partner.rfc === null ? '' : this.partner.rfc
    // Tax data
    this.tax_country = this.partner.tax_country === null ? '' : this.partner.tax_country
    this.nif = this.partner.tax_id === null ? '' : this.partner.tax_id
    // Docs
    this.cfi = this.partner.cfi.document_url === null ? null : { name: this.partner.cfi.label, id: this.partner.cfi.id }
    this.address_document = this.partner.address_identity.document_url === null ? null : { name: this.partner.address_identity.label, id: this.partner.address_identity.id }
    // Flags docs
    this.viewCFI = this.partner.cfi.document_url === null ? false : this.partner.cfi.rejection_category === null
    this.viewAddressDocument = this.partner.address_identity.document_url === null ? false : this.partner.address_identity.rejection_category === null
    this.loading = false
  }

  mounted () {
    this.partnerSelect = this.$attrs.value
  }

  rfcUpperCase (value: string) { 
    return value.toUpperCase()
  }

  async upload (file: any, docType: string) {
    if (file === null || !file.name || !file.size) return [null, false]
    const [error] = await ImageService.upload({
      entity_type: 'partner',
      identity: this.partner_id,
      document_type: docType,
      image: file,
      company_id: this.state.company_id
    })
    if (error) {
      this.loading = false
      return [error, true]
    }
    return [null, false]
  }

  async next () {
    this.loading = true
    const [error] = await PartnersService.storeComplements(
      this.partner_id,
      this.rfc,
      this.partner.birthplace,
      this.tax_country,
      this.nif,
      this.partner.civil_status,
      this.modal.accept
    )
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    if (this.cfi) {
      const error = await this.upload(this.cfi, 'CFI')
      if (error[1]) {
        this.loading = false
        return this.$toasted.global.error(error[0])
      }
    }
    /* if (this.address_document && this.partnerSelect.RFC === 'NO') {
      const error = await this.upload(this.address_document, 'address')
      if (error[1]) {
        this.loading = false
        return this.$toasted.global.error(error[0])
      }
    } */
    await this.updateUser({ partner: this.state.partner.id, company_id: this.state.company_id })
    const toAddress = (this.foreign && this.partnerSelect.RFC === 'NO') ? this.address2 : this.address
    return this.$router.push({ name: toAddress, query: this.$route.query }).catch(() => {return})
  }

  async nextOnlyRFC () {
    this.loading = true
    const [error] = await PartnersService.storeComplements(
      this.partner_id,
      this.rfc,
      this.partner.birthplace,
      this.tax_country,
      this.nif,
      this.partner.civil_status,
      this.modal.accept
    )
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    await this.updateUser({ partner: this.state.partner.id, company_id: this.state.company_id })
    const toAddress = (this.foreign && this.partnerSelect.RFC === 'NO') ? this.address2 : this.address
    return this.$router.push({ name: toAddress, query: this.$route.query }).catch(() => {return})
  }

  @Action(`InvitedModule/${InvitedTypes.actions.UPDATE_DATA}`) updateUser: any
  @State('InvitedModule') state: any
}
