import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { State } from '@/store/interfaces/Commissary'
import CommissaryTypes from '@/store/types/CommissaryTypes'
import CommissaryService from '@/services/Commissary'

const state: State = {
  commissary_id: '',
  isMetamapValidated: null,
  isCSFValidated: null
}

const getters: GetterTree<State, any> = {
  [CommissaryTypes.getters.GET_DATA]: stateGet => stateGet
}

const mutations: MutationTree<State> = {
  [CommissaryTypes.mutations.SET_DATA]: async (stateMut, payload: State) => {
    state.commissary_id = payload.commissary_id
    state.isMetamapValidated = payload.isMetamapValidated
    state.isCSFValidated = payload.isCSFValidated
  },
  [CommissaryTypes.mutations.CLEAR_DATA]: async () => {
    state.commissary_id = ''
    state.isMetamapValidated = null
    state.isCSFValidated = null
  }
}

const actions: ActionTree<State, any> = {
  [CommissaryTypes.actions.UPDATE_DATA]: async ({ commit }, {company_id}) => {
    const [err, response] = await CommissaryService.getCommissaryStatus(company_id)
    if (err) return [err]
    await commit(CommissaryTypes.mutations.SET_DATA, response.data.data)
    return [null, response.data.data]
  },
  [CommissaryTypes.actions.CLEAR_DATA]: async ({ commit }) => {
    await commit(CommissaryTypes.mutations.CLEAR_DATA)
    return [null]
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
