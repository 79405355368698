

























































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
// Services
import SignatureService from "@/services/Signature"

@Component({
  components: { Loader }
})
export default class CardFirms extends Vue {
  loading = true
  firms: any = ''
  partnersSignature: any = []

  created () {
    const companyArticlesMock = this.state.company.stages.find((stage: any) => stage.stage_name === 'company_articles_mock').is_completed === true
    const completeFirms = this.state.company.stages.find((stage: any) => stage.stage_name === 'signing_complete').is_completed === true
    if (companyArticlesMock && !completeFirms) this.getSignature()
  }

  async getSignature() {
    const [error, response] = await SignatureService.getFirms(this.state.company.id)
    if (error) {
      return this.$toasted.global.error(error)
    }
    const signers = Object.keys(response.data.data[0].signer_status)
    const signerStatus = response.data.data[0].signer_status
    if (!signers.length) return this.$toasted.global.error({ message: 'Hubo un problema con Docusing' })
    let complete = true
    signers.map((signerAux: any) => { if (signerStatus[signerAux].status !== 'completed') complete = false })
    if (complete && signers.length === this.state.company.partners.length) return this.$router.push({ name: 'CompleteSigning', query: this.$route.query, params: { company_id: this.state.company.id } })
    this.setData(signerStatus)
    this.loading = false
  }

  setData (signerStatus: any) {
    this.partnersSignature = Object.keys(signerStatus).map((signerAux: any) => {
      const partner = this.state.company.partners.find((partner: any) => partner.email === signerStatus[signerAux].email)
      return {
        email: signerStatus[signerAux].email,
        status: signerStatus[signerAux].status,
        fullName: partner.name + ' ' + partner.first_names + (partner.last_names === null ? '' : (' ' + partner.last_names)),
        url: '',// signerStatus[signerAux].signing.url,
        id: partner.id
      }
    })
  }

  copyLink (partnerURL: string) {
    navigator.clipboard.writeText(partnerURL)
    this.$toasted.global.success({ message: 'Se ha copiado el link con éxito' })
  }

  @State('UserModule') state: any
}
