





















// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Types
import UserTypes from '@/store/types/UserTypes'

@Component({
  components: {}
})
export default class PartnerEditDistribution extends Vue {
  @Prop({ default: 0 }) index!: number
  @Prop({ default: '0' }) total!: string
  partners: any = []
  loading = false
  editLimit = false
  show = false

  created () {
    this.partners = this.$attrs.value
  }

  get totalPercentage () {
    let total = 0
    this.partners.map((partner: any) => { total += Number(partner.percentage) })
    return total
  }

  get fullName () {
    const name = this.partners[this.index].name
    const firstNames = this.partners[this.index].first_names
    const lastNames = this.partners[this.index].last_names
    return name + ' ' + firstNames + ' ' + lastNames
  }

  updatePartnersDistribution () {
    this.partners.map((partner: any, index: number) => {
      this.partners[index].numberShares = ((Number(partner.percentage) * Number(this.total.replace(/,/g, "").replace(/[^0-9]/g,''))) / 100).toString().replace(/\d(?=(?:\d{3})+$)/g, '$&,')
    })
  }

  validatePercentage (partner: any) {
    if (Number(partner.percentage) > 25 && partner.isCommissary == true) partner.percentage = '25'
    if (this.totalPercentage > 100) {
      partner.percentage = partner.percentage - (this.totalPercentage - 100)
    }
  }

  onlyNumbersPercentage (value: string) {
    value = value.replace(/[^0-9.]/g,'')
    return Number(value) > 100 ? '100' : Number(value) <= 0 ? '0' : Number(value).toString()
  }

  onlyNumbersActions (value: string) {
    value = value.replace(/,/g, "").replace(/[^0-9]/g,'')
    value = Number(value) > 100000 ? '100000' : Number(value) === 0 ? '0' : Number(value).toString()
    return value.replace(/\d(?=(?:\d{3})+$)/g, '$&,')
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
