







































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
import InputFile from '@/components/InputFile.vue'
// Types
import InvitedTypes from '@/store/types/InvitedTypes'
// Services
import ImageService from '@/services/Image'

@Component({
  components: { Footer, Loader, InputFile }
})
export default class InvitedAddress extends Vue {
  foreign = false
  loading = false
  copys = {
    return: 'Regresar',
    title: 'Sube un Comprobante de Domicilio',
    subTitle: 'Asegúrate que este a tu nombre y sea del último mes',
    address: 'Sube tu Comprobante de Domicilio',
    msj: {
      title: '🤓 ¿Por qué lo pedimos del último mes?',
      text: 'Tener los comprobantes de domicilio actualizados de los socios, evitará que posteriormente deban volver a subirlos para aperturar su cuenta empresarial. Asegurate que este a tu nombre.',
    },
    button: 'Continuar'
  }
  agent = {
    title: '¡Hola soy Pablo, CEO de Tally!',
    subTitle: 'Te ayudaré a constituir una empresa en México',
    text: 'Solo necesitas:'
  }
  points = [
    {img: require('@/assets/icons/register-point-1.svg'), text: 'Decirnos tu nacionalidad'},
    {img: require('@/assets/icons/register-point-2.svg'), text: 'Tener a la mano tu información fiscal'},
    {img: require('@/assets/icons/register-point-4.svg'), text: 'Verificar tu identidad '},
    {img: require('@/assets/icons/register-point-3.svg'), text: 'Confirmar tus generales'}
  ]
  partner_id = ''
  address_document: any = null

  mounted () {
    this.foreign = true
    if (this.state.partner.nationalities !== null && this.state.partner.nationalities.length) this.state.partner.nationalities.map((nationality: any) => { if (nationality.name === 'México' || nationality.issue_country === 'MX') this.foreign = false })
    this.partner_id = this.state.partner.id
  }

  async upload (file: any, docType: string) {
    if (file === null || !file.name || !file.size) return [null, false]
    const [error] = await ImageService.upload({
      entity_type: 'partner',
      identity: this.partner_id,
      document_type: docType,
      image: file,
      company_id: this.state.company_id
    })
    if (error) {
      this.loading = false
      return [error, true]
    }
    return [null, false]
  }

  async saveAddress () {
    this.loading = true
    if (this.address_document) {
      const error = await this.upload(this.address_document, 'address')
      if (error[1]) {
        this.loading = false
        return this.$toasted.global.error(error[0])
      }
    }
    await this.updateUser({ partner: this.state.partner.id, company_id: this.state.company_id })
    return this.$router.push({ name: 'InvitedValidation', query: this.$route.query }).catch(() => {return})
  }

  returnTo () { return this.$router.push({ name: 'InvitedComplementaries', query: this.$route.query }) }

  @Action(`InvitedModule/${InvitedTypes.actions.UPDATE_DATA}`) updateUser: any
  @State('InvitedModule') state: any
}
