
































// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Types
import UserTypes from '@/store/types/UserTypes'
// Components
import Loader from '@/components/Loader.vue'
import InputFile from '@/components/InputFile.vue'
import TooltipCSF from '@/components/benefits/TooltipCSF.vue'
// Services
import ImageService from '@/services/Image'

@Component({
  components: { Loader, InputFile, TooltipCSF }
})
export default class ModalUploadDoc extends Vue {
  @Prop({ default: true }) viewCSF!: boolean
  modal: any = {
    showModalUploadDoc: false,
    update: false,
    doc: {
      partnerID: '',
      name: '',
      type: '',
      new: false
    }
  }
  loading = false
  doc: any = null

  mounted () {
    this.modal = this.$attrs.value
  }

  noSpacesStartRFC (value: string) {
    return value.trimStart().toUpperCase()
  }

  async saveDoc () {
    this.loading = true
    const [error] = await ImageService.upload({
      entity_type: 'partner',
      identity: this.modal.doc.partnerID,
      document_type: this.modal.doc.type,
      image: this.doc,
      company_id: this.state.company.id
    })
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    await this.updateCompany(this.state.company.id)
    this.modal.update = true
    this.loading = false
    this.modal.showModalUploadDoc = false
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
