export default {
  mutations: {
    CLEAR_DATA: 'CLEAR_DATA',
    SET_LEGAL_NAME: 'SET_LEGAL_NAME',
    SET_TYPE_SOCIETY: 'SET_TYPE_SOCIETY',
    SET_COMPANY_TYPE: 'SET_COMPANY_TYPE',
    SET_PACKAGE: 'SET_PACKAGE',
    SET_ACTIVITIES: 'SET_ACTIVITIES',
    SET_COMPANY_ACTIVITY: 'SET_COMPANY_ACTIVITY',
    SET_PHONE: 'SET_PHONE',
    SET_EMAIL: 'SET_EMAIL',
    SET_COUNTRY: 'SET_COUNTRY',
    SET_REPRESENTATION: 'SET_REPRESENTATION',
    SET_NAME_AUTHORIZATION: 'SET_NAME_AUTHORIZATION'
  },
  getters: {
    GET_DATA: 'GET_DATA',
    GET_INCORPORATION_DATA: 'GET_INCORPORATION_DATA'
  },
  actions: {
    CLEAR_DATA: 'CLEAR_DATA',
    SET_LEGAL_NAME: 'SET_LEGAL_NAME',
    SET_TYPE_SOCIETY: 'SET_TYPE_SOCIETY',
    SET_COMPANY_TYPE: 'SET_COMPANY_TYPE',
    SET_PACKAGE: 'SET_PACKAGE',
    SET_ACTIVITIES: 'SET_ACTIVITIES',
    SET_COMPANY_ACTIVITY: 'SET_COMPANY_ACTIVITY',
    SET_PHONE: 'SET_PHONE',
    SET_EMAIL: 'SET_EMAIL',
    SET_COUNTRY: 'SET_COUNTRY',
    SET_REPRESENTATION: 'SET_REPRESENTATION',
    SET_NAME_AUTHORIZATION: 'SET_NAME_AUTHORIZATION'
  }
}
