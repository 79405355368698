















































































































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
import InputFile from '@/components/InputFile.vue'
import Resumen from '@/components/beginning/Resumen.vue'
// Services
import AdministrationService from '@/services/Administration'

@Component({
  components: { Loader, InputFile, Resumen }
})
export default class CardCommissary extends Vue {
  points = [{
      point: 'Agrega a un amigo: ',
      text: 'no puedes agregar familiares de ningún socio.',
      url: ''
    },{
      point: 'Asigna a un socio: ',
      text: 'que no tenga más del 25% de participación y no podrá ocupar otro rol en la empresa.',
      url: ''
    },{
      point: 'Agrega a Tally: ',
      text: 'te ayudaremos a presentar tu asamblea anual y te conectamos con el SAT para que puedas ver tus ingresos y facturación.',
      url: 'https://tallylegal.typeform.com/backoffice'
    }
  ]
  email = ''
  name = ''
  loading = false
  open = false
  validEmail = true
  validationEmail = false
  partnerShare = false
  partnerCouncil = false
  commissary = {name: '', email: ''}
  role = ''
  commissaryTally: any = {
    isTally: true
  }

  @Watch('state.company.council')
  updateProgress () { this.partnerRole() }

  mounted () {
    this.commissaryTally = this.$attrs.value
  }

  get searchPartners () {
    const partners = this.state.company.partners.filter((partner: any) => partner.email.match(this.email) !== null )
    return partners
  }

  get commissaryIsPartner () {
    const partner = this.state.company.partners.filter((partner: any) => partner.email === this.email)
    return partner.length > 0
  }

  partnerRole () {
    const partner = this.state.company.council.filter((member: any) => member.partner !== null && member.partner.email === this.email && member.council_role !== 'commissary')
    if (partner.length === 0) return this.partnerCouncil = false
    this.partnerCouncil = partner[0].council_role !== 'no-member'
    this.role = partner[0].council_role === 'unique_administrator' ? 'Administrador único' :
      partner[0].council_role === 'president' ? 'Presidente' :
      partner[0].council_role === 'secretary' ? 'Secretario' :
      partner[0].council_role === 'treasurer' ? 'Tesorero' : 'Miembro'
  }

  setPartner (partner: any) {
    this.email = partner.email
    this.name = this.getPartnerFullName(partner)
    this.validate(this.email)
    this.validateEmail(this.email)
    this.open = false
  }

  getPartnerFullName (partner: any) {
    let full_name: string = partner.name != null ? partner.name : ''
    full_name = partner.first_names != null ? full_name + ' ' + partner.first_names : full_name
    full_name = partner.last_names != null ? full_name + ' ' + partner.last_names : full_name
    return full_name.trimEnd()
  }

  noSpaces (value: string) {
    return value.trim()
  }

  noSpacesStart (value: string) {
    return value.trimStart()
  }

  validate (email: string) {
    const re = /\S+@\S+\.\S+/
    this.validEmail = email ? re.test(email) : true
  }

  validateEmail (email: string) {
    const re = /\S+@\S+\.\S+/
    this.validationEmail = this.email !== '' && re.test(email)
    this.open = true
    const partner = this.state.company.partners.filter((partner: any) => partner.email === this.email)
    if (this.validationEmail && partner.length > 0) this.validatePartnerShare(partner[0])
    else this.partnerShare = this.partnerCouncil = false
  }

  validatePartnerShare (partner: any) {
    const shares = Number(partner.shares[0].number_of_shares)
    const totalShares = partner.shares[0].serie.total_shares_in_series
    this.partnerShare = ((shares * 100) / totalShares) > 25
    this.partnerRole()
  }

  async saveCommissary () {
    this.loading = true
    const partner = this.state.company.partners.filter((partner: any) => partner.email === this.email)
    let commissary = {
      other_faculty: this.commissaryIsPartner ? 'Partner' : 'Friend',
      partner_id: this.commissaryIsPartner ? partner[0].id : null,
      email: this.email
    }
    const [error] = await AdministrationService.setCommissaryName(this.state.company.id, this.name)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    const [error2] = await AdministrationService.setCommissary(this.state.company.id, commissary)
    if (error2) {
      this.loading = false
      return this.$toasted.global.error(error2)
    }
    this.$toasted.global.success({ message: 'Se guardo el comisario' })
    return this.$router.push({ name: 'Welcome', query: this.$route.query }).catch(() => {return})
  }

  @State('UserModule') state: any
}
