

































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
// Components
import HeaderProgressBar from '@/components/HeaderProgressBar.vue'
import ReturnAddress from '@/components/Return.vue'
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
import EntityType from '@/components/beginning/EntityType.vue'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'

@Component({
  components: { HeaderProgressBar, ReturnAddress, Footer, Loader, EntityType }
})
export default class CompanyType extends Vue {
  loading = false
  types = [{
      type: 'new',
      text: 'Crear una empresa desde cero',
    },{
      type: 'subsidiary',
      text: 'Crear una subsidiaria de otra empresa',
    }
  ]
  typeSelected = ''

  created () {
    this.typeSelected = this.state.company_type
  }

  async next (type: string) {
    this.loading = true
    await this.setCompanyType(type)
    return type === 'new' ?
      this.$router.push({ name: 'Packages', query: this.$route.query }) : 
      this.$router.push({ name: 'CompanySubsidiary', query: this.$route.query })
  }

  @State('CompanyModule') state: any
  @Action(`CompanyModule/${CompanyTypes.actions.SET_COMPANY_TYPE}`) setCompanyType: any;
}
