



























// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import ModalEditCompanyActivities from '@/components/resumen/modals/ModalEditCompanyActivities.vue'

@Component({
  components: { ModalEditCompanyActivities }
})
export default class CardCompanyActivities extends Vue {
  modal: any = {
    showModalCompanyActivities: false,
    update: false,
  }
  editActive = false
  descriptionMaxLength = 300
  activities = [
    'SaaS (Servicios de Software)',
    'Servicios de Consultoría (incluye tecnología)',
    'Fintech',
    'Comercio / E-commerce',
    'Construcción',
    'Fabricación / Producción',
    'Educación (incluye Tecnología)',
    'Otro'
  ]

  get companyActivities () {
    let activities = ''
    this.state.company.business_twists_tmp.map((activity: any, index: number) => {
      if ((index + 1) === this.state.company.business_twists_tmp.length) activities += activity.name
      else activities += activity.name + ', '
    })
    return activities
  }

  mounted () {
    const documentsApproved = this.state.company.stages.find((stage: any) => stage.stage_name === 'documents_approved').is_completed === true
    if (this.state.company.timer === null && !documentsApproved) this.editActive = true
  }

  @State('UserModule') state: any
}
