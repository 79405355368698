









// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import Benefit from '@/components/benefits/Benefit.vue'

@Component({
  components: { Footer, Benefit }
})
export default class Stripe extends Vue {
  benefitData: any = {
    name: 'Stripe',
    textReturn: 'Regresar',
    openAccount: {
      img1: {
        url: require('@/assets/images/Benefits/Stripe.png'),
        style1: 'width: 176px; height: 84px;',
        style2: ''
      },
      img2: {
        url: require('@/assets/images/Benefits/Stripe2.png'),
        classStyle: 'col-12 col-lg-5 col-xl-4 mt-0 px-0'
      },
      title: 'Infraestructura de pagos para Internet',
      subTitle: 'Un paquete completamente integrado de productos para pagos',
      text: '',
      url: 'https://stripe.com/startup-benefits/tally-legal',
      point: '',
      points: [
        'Pagos internacionales',
        'Automatización de ingresos y finanzas',
        'Banca como servicio (BaaS)',
        'E-commerces y SaaS',
        'Marketplaces',
        'Criptomonedas ',
        'Empresas'
      ],
    },
    companyDocs: {
      title: 'Sube tus documentos',
      subTitle: 'Los necesitamos para crear tu cuenta empresarial',
      msj: {
        Title: 'Revisa tus documentos',
        Text: 'Deberás subir tus documentos originales, sin tachaduras ni enmendaduras y con fecha de expedición no mayor a 2 meses.'
      }
    },
    authorization: {
      title: 'Autorizas el envío de tus documentos',
      subTitle: '',
      protection: 'Tu información está protegida',
      authorization: ''
    },
    complete: {
      title: '¡Solicitud realizada con éxito!',
      subTitle: '',
      button: 'Finalizar',
      points: []
    }
  }

  @State('UserModule') state: any
}
