






























// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import ResumenHeader from '@/components/resumen/ResumenHeader.vue'

@Component({
  components: { ResumenHeader }
})
export default class HeaderProgressBar extends Vue {
  @Prop({default: 1}) progress!: number // Select how much charge the active bar has
  @Prop() bar!: number // select the active bar
  resumen: any = { showResumen: false }
  steps = ['Empresa', 'Socios', 'Verificación', 'Revisión', 'Firma']

  created () {
    // if (this.state.company.legal_representation) this.steps = ['Empresa', 'Socios', 'Pago', 'Validación']
  }

  @State('UserModule') state: any
}
