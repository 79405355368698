


































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import HeaderProgressBar from '@/components/HeaderProgressBar.vue'
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
import Activities from '@/components/beginning/CompanyActivities.vue'
import CompanyUnicorn from '@/components/beginning/CompanyUnicorn.vue'
import CompanySubsidiary from '@/components/beginning/CompanySubsidiary.vue'
// Types
import UserTypes from '@/store/types/UserTypes'
// Services
import CompanyService from '@/services/Company'

@Component({
  components: { HeaderProgressBar, Footer, Loader, Activities, CompanyUnicorn, CompanySubsidiary }
})
export default class BeginningBeforePayment extends Vue {
  loading = false
  title = ''
  activities: any = []
  company: any = { step: 1, selectActivities: [] }

  created () {
    this.title = '¿Qué actividad realizará ' + this.state.company.name + '?'
    this.company.step = 1
    if (this.state.company.business_twists_tmp !== null && this.state.company.business_twists_tmp.length > 0) this.company.selectActivities = this.state.company.business_twists_tmp.map((activity: any) => { return {business_twists_id: activity.id, new_category: activity.name} })
    // if (this.state.company.legal_representation && this.company.selectActivities.length > 0) this.company.step = 2
    this.getActivities()
  }

  async getActivities () {
    const [error, response] = await CompanyService.getActivities()
    if (error) return this.$toasted.global.error(error)
    this.activities = response.data
  }

  async next () {
    this.loading = true
    const [error] = await CompanyService.setActivities(this.state.company.id, this.company.selectActivities)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    await this.updateCompany(this.state.company.id)
    /* if (this.state.company.legal_representation) {
      this.company.step++
      return this.loading = false
    } */
    return this.$router.push({ name: 'Welcome', query: this.$route.query, params: { company_id: this.state.company.id } }).catch(() => {return})
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
