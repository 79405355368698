



















































// Utils
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
// Mixins
import ConversMixin from '@/mixins/Converts'
// Components
import ModalConfirmDocument from '@/components/ModalConfirmDocument.vue'
import ModalFileNoPDF from '@/components/ModalFileNoPDF.vue'

@Component({
  components: { ModalConfirmDocument, ModalFileNoPDF },
  mixins: [ConversMixin]
})
export default class InputFiles extends Vue {
  @Prop({ default: 'Arrastra aquí tu archivo' }) title!: string;
  @Prop({ default: '.jpg, .jpeg, .png, .pdf' }) supportedFiles!: string;
  files: any = null
  modal: any = {
    showModalConfirmDocument: false,
    showModalFileNoPDF: false,
    file: {},
    refuse: false
  }

  @Watch('modal.refuse')
  reset () {
    if (!this.modal.refuse) return
    this.files = null
    this.updateFile(this.files)
    this.modal.refuse = false
  }

  updateFile (files: any) {
    if (files === null) {
      this.files = null
    } else {
      files.map((file: any) => {
        if (this.supportedFiles === '.pdf' && file !== null && !file.type.includes("application/pdf")) {
          this.files = null
          this.modal.showModalFileNoPDF = true
        }
      })
    }
    this.$emit('input', files)
  }
}
