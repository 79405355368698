


























// Utils
import { Component, Vue } from 'vue-property-decorator'
// Components
import HeaderProgressBar from '@/components/HeaderProgressBar.vue'
import ReturnAddress from '@/components/Return.vue'
import Footer from '@/components/Footer.vue'

@Component({
  components: { HeaderProgressBar, ReturnAddress, Footer }
})
export default class LegalNameAvailable extends Vue {
}
