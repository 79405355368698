



















































































// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import InputFile from '@/components/InputFile.vue'
import TooltipIDSpouse from '@/components/partners/TooltipIDSpouse.vue'
import Loader from '@/components/Loader.vue'
// Types
import InvitedTypes from '@/store/types/InvitedTypes'
// Services
import PartnersService from '@/services/Partners'
import ImageService from '@/services/Image'

@Component({
  components: { InputFile, TooltipIDSpouse, Loader }
})
export default class InvitedPartnerCivilStatus extends Vue {
  @Prop() address: string|undefined
  loading = false
  open = false
  partner: any = {}
  partner_id = ''
  id_spouse: any = null
  id_spouse_back: any = null
  marriage_certificate: any = null
  viewIDSpouse = false
  viewIDSpouseBack = false
  viewMarriageCertificate = false
  civil_status = ''
  civilStatus = ['soltero', 'casado']
  renderKey = 0

  async created () {
    this.loading = true
    if (this.state.partner.mati_verification === null || !this.state.partner.mati_verification) await this.updateUser({
      partner: this.state.partner.id,
      company_id: this.state.company_id
    })
    this.partner = this.state.partner
    this.partner_id = this.partner.id
    this.civil_status = this.partner.civil_status === null ? '' : this.partner.civil_status
    // Docs
    this.id_spouse = this.partner.id_spouse.document_url === null ? '' : { name: this.partner.id_spouse.label, id: this.partner.id_spouse.id }
    this.id_spouse_back = this.partner.id_spouse_back.document_url === null ? null : { name: this.partner.id_spouse_back.label, id: this.partner.id_spouse_back.id }
    this.marriage_certificate = this.partner.marriage_certificate.document_url === null ? '' : { name: this.partner.marriage_certificate.label, id: this.partner.marriage_certificate.id }
    // Flags docs
    this.viewIDSpouse = this.partner.id_spouse.document_url === null ? false : this.partner.id_spouse.rejection_category === null
    this.viewIDSpouseBack = this.partner.id_spouse_back.document_url === null ? false : this.partner.id_spouse_back.rejection_category === null
    this.viewMarriageCertificate = this.partner.marriage_certificate.document_url === null ? false : this.partner.marriage_certificate.rejection_category === null
    this.loading = false
  }

  async upload (file: any, docType: string) {
    if (file === null || !file.name || !file.size) return [null, false]
    const [error] = await ImageService.upload({
      entity_type: 'partner',
      identity: this.partner_id,
      document_type: docType,
      image: file,
      company_id: this.state.company_id
    })
    if (error) {
      this.loading = false
      return [error, true]
    }
    return [null, false]
  }

  async next () {
    this.loading = true
    const [error] = await PartnersService.storeComplements(
      this.partner_id,
      this.partner.rfc,
      this.partner.birthplace,
      this.partner.tax_country,
      this.partner.tax_id,
      this.civil_status.toLowerCase(),
      true
    )
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    if (this.civil_status === 'casado' && this.id_spouse) {
      const error = await this.upload(this.id_spouse, 'id_spouse')
      if (error[1]) {
        this.loading = false
        return this.$toasted.global.error(error[0])
      }
    }
    if (this.civil_status === 'casado' && this.id_spouse_back) {
      const error = await this.upload(this.id_spouse_back, 'id_spouse_back')
      if (error[1]) {
        this.loading = false
        return this.$toasted.global.error(error[0])
      }
    }
    if (this.civil_status === 'casado' && this.marriage_certificate) {
      const error = await this.upload(this.marriage_certificate, 'marriage_certificate')
      if (error[1]) {
        this.loading = false
        return this.$toasted.global.error(error[0])
      }
    }
    await this.updateUser({ partner: this.state.partner.id, company_id: this.state.company_id })
    return this.$router.push({ name: this.address, query: this.$route.query }).catch(() => {return})
  }

  @Action(`InvitedModule/${InvitedTypes.actions.UPDATE_DATA}`) updateUser: any
  @State('InvitedModule') state: any
}
