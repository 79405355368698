import { GetterTree, ActionTree, MutationTree } from 'vuex'
import State from '@/store/interfaces/Auth'
import AuthTypes from '@/store/types/AuthTypes'
import UserService from '@/services/User'
import axios from '@/utils/axios-api'
import Login from '@/interfaces/Login'

const namespaced = true

const payloadLocalStorage = JSON.parse(localStorage.payload || JSON.stringify({ access_token: '', refresh_token: '' }))

const state: State = {
  access_token: payloadLocalStorage.access_token,
  refresh_token: payloadLocalStorage.refresh_token
}

const getters: GetterTree<State, any> = {
  [AuthTypes.getters.GET_PAYLOAD]: (state) => state
}

const mutations: MutationTree<State> = {
  [AuthTypes.mutations.SET_PAYLOAD]: async (stateMut, payload) => {
    axios.interceptors.request.use(async (config) => {
      config.headers.authorization = `Bearer ${payload.access_token}`
      return config
    })
    state.access_token = payload.access_token
    state.refresh_token = payload.refresh_token
    localStorage.setItem('payload', JSON.stringify(payload))
  },
  [AuthTypes.mutations.REMOVE_TOKEN]: async () => {
    localStorage.removeItem('payload')
  }
}

const actions: ActionTree<State, any> = {
  [AuthTypes.actions.REGISTER]: async ({ commit }, signup: any) => {
    const [err, response]: any = await UserService.signup(signup)
    if (err) return [err]
    commit(AuthTypes.mutations.SET_PAYLOAD, response.data)
    return [null, response.data]
  },
  [AuthTypes.actions.REGISTERGOOGLE]: async ({ commit }, signup: any) => {
    const [err, response]: any = await UserService.signupGoogle(signup)
    if (err) return [err]
    commit(AuthTypes.mutations.SET_PAYLOAD, response.data)
    return [null, response.data]
  },
  [AuthTypes.actions.REGISTERPARTNER]: async ({ commit }, payload: { partner: string, signupPartner: any }) => {
    const [err, response]: any = await UserService.signupPartner(payload.partner, payload.signupPartner)
    if (err) return [err]
    commit(AuthTypes.mutations.SET_PAYLOAD, response.data)
    return [null, response.data]
  },
  [AuthTypes.actions.REGISTERGOOGLEPARTNER]: async ({ commit }, payload: { partner: string, signupPartner: any }) => {
    const [err, response]: any = await UserService.signupGooglePartner(payload.partner, payload.signupPartner)
    if (err) return [err]
    commit(AuthTypes.mutations.SET_PAYLOAD, response.data)
    return [null, response.data]
  },
  [AuthTypes.actions.SET_PAYLOAD]: async ({ commit }, payload: { access_token: string; refresh_token: string }) => {
    commit(AuthTypes.mutations.SET_PAYLOAD, payload)
    return [null, payload]
  },
  [AuthTypes.actions.LOGIN]: async ({ commit }, login: Login) => {
    const [err, response] = await UserService.login(login)
    if (err) return [err]
    commit(AuthTypes.mutations.SET_PAYLOAD, response.data)
    return [null, response.data]
  },
  [AuthTypes.actions.LOGINGOOGLE]: async ({ commit }, login: any) => {
    const [err, response] = await UserService.loginGoogle(login)
    if (err) return [err]
    commit(AuthTypes.mutations.SET_PAYLOAD, response.data)
    return [null, response.data]
  },
  [AuthTypes.actions.LOGOUT]: async ({ commit }) => {
    try {
      const response = await UserService.logout()
      commit(AuthTypes.mutations.REMOVE_TOKEN)
      return [null, response.data]
    } catch (error) {
      commit(AuthTypes.mutations.REMOVE_TOKEN)
      return [error]
    }
  },
  [AuthTypes.actions.REFRESH_TOKEN]: async ({ commit }) => {
    const [err, response] = await UserService.refreshToken()
    if (err) {
      commit(AuthTypes.mutations.REMOVE_TOKEN)
      return [err]
    }
    commit(AuthTypes.mutations.SET_PAYLOAD, response.data)
    return [null, response.data]
  }
}

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions
}
