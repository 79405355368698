


























// Utils
import { Component, Vue } from 'vue-property-decorator'
// Components
import HeaderProgressBar from '@/components/HeaderProgressBar.vue'
import ReturnAddress from '@/components/Return.vue'
import Footer from '@/components/Footer.vue'

@Component({
  components: { HeaderProgressBar, ReturnAddress, Footer }
})
export default class CompanySubsidiary extends Vue {
  title = 'Para facilitar el proceso y evitar apostillar documentos, diseñamos el siguente proceso:'
  texts = [
    'Paso 1. Constituye tu empresa 100% digital con dos personas físicas/naturales (de cualquier nacionalidad)',
    'Paso 2. Ingresa a tu dashboard y solicita la transmisión de las acciones a tu empresa Holding',
    'Este enfoque simplificado garantizará que inicies operaciones en menos de un mes. '
  ]
}
