





















































































































// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
import moment from 'moment'
// Services
import PhoneService from '@/services/Phone'
import EmailService from '@/services/Email'

@Component({
})
export default class ValidateCode extends Vue {
  @Prop() title: string|undefined
  @Prop() send!: string
  @Prop() country!: string
  @Prop() type!: string
  @Prop() address: string|undefined
  @Prop() addressEdit: string|undefined
  @Prop() editText: string|undefined
  moment = moment
  loading = false
  loading2 = false
  validCode = true
  code = ''
  cont = 0
  now = moment().valueOf()
  end = moment().valueOf()
  endTime: any = null

  maxLength (value: string) { 
    return (value.substring(0,5)).replace(/[^0-9.]/g,'');
  }

  resetValidate () {
    this.validCode = true
  }

  get resendRepeat () {
    return this.cont === 0 ? true : this.now >= this.end
  }

  get min (){
    if ((this.end - this.now) <= 0) return '00'
    let m = Math.trunc((this.end - this.now) / 1000 / 60) % 60
    return m <= 0 ? '00' : m > 9 ? m : '0' + m
  }

  get sec (){
    if ((this.end - this.now) <= 0) return '00'
    let s = Math.trunc((this.end - this.now)/1000) % 60
    return s <= 0 ? '00' : s > 9 ? s : '0' + s
  }

  async resendPhone () {
    this.loading2 = true
    const [error] = await PhoneService.newPhone(this.state.id, this.send, this.country )
    if (error) {
      this.loading2 = false
      return this.$toasted.global.error(error)
    }
    this.createTimer()
    this.loading2 = false
    return this.$toasted.global.success({ message: 'Se ha reenviado el código' })
  }

  async resendEmail () {
    this.loading2 = true
    const [error] = await EmailService.newEmail(this.state.id, this.send)
    if (error) {
      this.loading2 = false
      return this.$toasted.global.error(error)
    }
    this.createTimer()
    this.loading2 = false
    return this.$toasted.global.success({ message: 'Se ha reenviado el código' })
  }

  async nextPhone () {
    this.loading = true
    const [error] = await PhoneService.updatePhone(this.state.id, this.send, this.country, this.code )
    if (error) {
      this.validCode = false
      this.loading = false
      return this.$toasted.global.error(error)
    }
    return this.$router.push({ name: this.address, query: this.$route.query }).catch(() => {return})
  }

  async nextEmail () {
    this.loading = true
    const [error] = await EmailService.updateEmail(this.state.id, this.send, Number(this.code))
    if (error) {
      this.validCode = false
      this.loading = false
      return this.$toasted.global.error(error)
    }
    return this.$router.push({ name: this.address, query: this.$route.query }).catch(() => {return})
  }

  createTimer () {
    this.cont++
    this.now = moment().valueOf()
    this.end = moment().valueOf() + (60000 * this.cont)
    this.endTime = setInterval(()=>{
      this.now = moment().valueOf()
      if (this.now >= this.end) {
        clearInterval(this.endTime)
      }
    }, 1000)
  }

  beforeDestroy(){
    clearInterval(this.endTime)
  }

  @State('UserModule') state: any
}
