// Utils
import { RouteConfig } from 'vue-router'
import multiguard from 'vue-router-multiguard'
// Views
import Index from '@/views/signup/Index.vue'
import CompanyActivities from '@/views/signup/CompanyActivities.vue'
import CompanySubsidiary from '@/views/signup/CompanySubsidiary.vue'
import CompanyType from '@/views/signup/CompanyType.vue'
import EditPhone from '@/views/signup/validate/EditPhone.vue'
import EditPhoneAndEmail from '@/views/signup/validate/EditPhoneAndEmail.vue'
import HasRFC from '@/views/signup/HasRFC.vue'
import LegalName from '@/views/signup/LegalName.vue'
import LegalNameAvailable from '@/views/signup/LegalNameAvailable.vue'
import LegalNameOld from '@/views/signup/LegalNameOld.vue'
import Options from '@/views/signup/Options.vue'
import Packages from '@/views/signup/Packages.vue'
import PersonalInformation from '@/views/signup/PersonalInformation.vue'
import Register from '@/views/signup/Register.vue'
import RepresentationInfo from '@/views/signup/RepresentationInfo.vue'
import Representation from '@/views/signup/Representation.vue'
import TypeSociety from '@/views/signup/TypeSociety.vue'
import ValidatePhone from '@/views/signup/validate/ValidatePhone.vue'
import VerifyAccount from '@/views/signup/validate/VerifyAccount.vue'
// Middlewares
import Signup from '@/middlewares/Signup'
import Auth from '@/middlewares/Auth'
import User from '@/middlewares/User'

const routes: Array<RouteConfig> = [
  {
    path: '/register',
    component: Index,
    beforeEnter: Signup.deleteChat,
    children: [
      {
        path: 'legal_name',
        name: 'LegalNameOld',
        component: LegalNameOld,
        beforeEnter: Signup.redirectToRegister
      },
      {
        path: 'validate_phone',
        name: 'ValidatePhone',
        component: ValidatePhone,
        beforeEnter: multiguard([Auth.isLogged, User.validateData]),
      },
      {
        path: 'verify_account',
        name: 'VerifyAccount',
        component: VerifyAccount,
        beforeEnter: multiguard([Auth.isLogged, User.validateData]),
      },
      {
        path: 'edit_phone',
        name: 'EditPhone',
        component: EditPhone
      },
      {
        path: 'edit_phone_email',
        name: 'EditPhoneAndEmail',
        component: EditPhoneAndEmail
      }
    ]
  },
  {
    path: '/register_2',
    component: Index,
    beforeEnter: Signup.deleteChat,
    children: [
      {
        path: '',
        name: 'Options',
        component: Options
      },
      {
        path: 'has_rfc',
        name: 'HasRFC',
        component: HasRFC
      },
      {
        path: 'representation_info',
        name: 'RepresentationInfo',
        component: RepresentationInfo
      },
      {
        path: 'representation',
        name: 'Representation',
        component: Representation
      },
      {
        path: 'welcome',
        name: 'Register',
        component: Register,
        beforeEnter: Signup.checkValidations
      },
      {
        path: 'type_of_society',
        name: 'TypeSociety',
        component: TypeSociety,
        beforeEnter: Signup.checkValidations
      },
      {
        path: 'company_type',
        name: 'CompanyType',
        component: CompanyType,
        beforeEnter: Signup.checkValidations
      },
      {
        path: 'company_subsidiary',
        name: 'CompanySubsidiary',
        component: CompanySubsidiary,
        beforeEnter: Signup.checkValidations
      },
      {
        path: 'packages',
        name: 'Packages',
        component: Packages,
        beforeEnter: Signup.checkValidations
      },
      {
        path: 'legal_name',
        name: 'LegalName',
        component: LegalName,
        beforeEnter: Signup.checkValidations
      },
      {
        path: 'legal_name_available',
        name: 'LegalNameAvailable',
        component: LegalNameAvailable,
        beforeEnter: Signup.checkValidations
      },
      {
        path: 'company_activities',
        name: 'CompanyActivities',
        component: CompanyActivities,
        beforeEnter: Signup.checkValidations
      },
      {
        path: 'personal_information',
        name: 'PersonalInformation',
        component: PersonalInformation,
        beforeEnter: Signup.checkValidations
      }
    ]
  }
]

export default routes
