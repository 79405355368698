// Utils
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import multiguard from 'vue-router-multiguard'
// Router
import AccountRoutes from '@/router/Account'
import AdministrationRoutes from '@/router/Administration'
import AuthRoutes from '@/router/Auth'
import BeginningRoutes from '@/router/Beginning'
import BenefitsRoutes from '@/router/Benefits'
import CompanyRoutes from '@/router/Company'
import CommissaryRoutes from '@/router/Commissary'
import DashboardRoutes from '@/router/Dashboard'
import InvitedPartnerRoutes from '@/router/InvitedPartner'
import IsPartnerRoutes from '@/router/IsPartner'
import PartnersRoutes from '@/router/Partners'
import PaymentRoutes from '@/router/Payment'
import SignupRoutes from '@/router/Signup'
// Views
import Login from '@/views/auth/Login.vue'
import Welcome from '@/views/Welcome.vue'
import ValidationActToUser from "@/views/ValidationActToUser.vue"
// Middlewares
import Signup from '@/middlewares/Signup'
import Auth from '@/middlewares/Auth'
import User from '@/middlewares/User'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    beforeEnter: Signup.deleteChat
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome,
    beforeEnter: multiguard([Auth.isLogged, User.welcomeValidateData])
  },
  {
    path: '/auth/yolo',
    name: 'ValidationActToUser',
    component: ValidationActToUser
  },
  {
    path: '/crear-cuenta',
    name: 'CrearCuenta',
    beforeEnter: Signup.redirectToRegister
  },
  ...SignupRoutes,
  ...AuthRoutes,
  ...DashboardRoutes,
  ...AccountRoutes,
  ...CompanyRoutes,
  ...CommissaryRoutes,
  ...PartnersRoutes,
  ...IsPartnerRoutes,
  ...InvitedPartnerRoutes,
  ...AdministrationRoutes,
  ...BeginningRoutes,
  ...PaymentRoutes,
  ...BenefitsRoutes
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// eslint-disable-next-line
router.afterEach((to, from) => {
  window.posthog.capture("$pageview", { $pathname: to.name, $current_url: to.fullPath })
})

router.beforeEach((to, from, next) => {
  if (from.name !== null) window.posthog.capture("$pageleave", { $pathname: from.name, $current_url: from.fullPath })
  return next()
})

export default router
