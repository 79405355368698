import { GetterTree, ActionTree, MutationTree } from 'vuex'
import State, { CountryCode, CompanyActivity } from '@/store/interfaces/Company'
import CompanyTypes from '@/store/types/CompanyTypes'

const keyLocalStorage = 'CompanyData'

function updateLocalStorage (state: State) {
  localStorage.setItem(keyLocalStorage, JSON.stringify(state))
}

const state: State = JSON.parse(
  localStorage.getItem(keyLocalStorage) ||
  JSON.stringify({
    type_Society: '',
    company_type: '',
    package: '',
    legal_name: '',
    activities: [],
    company_activity: {
      activity: '',
      description: '',
      products: '' 
    },
    phone: '',
    email: '',
    country: {
      lada: '',
      alpha_code: ''
    },
    legal_representation: null,
    name_authorization: false
  })
)

const getters: GetterTree<State, any> = {
  [CompanyTypes.getters.GET_DATA]: (stateGet) => stateGet,
  [CompanyTypes.getters.GET_INCORPORATION_DATA]: (stateGet) => {
    return { legal_name: stateGet.legal_name, type_Society: stateGet.type_Society, company_activity: stateGet.company_activity }
  }
}

const mutations: MutationTree<State> = {
  [CompanyTypes.mutations.CLEAR_DATA]: async () => {
    state.type_Society = ''
    state.company_type = ''
    state.package = ''
    state.legal_name = ''
    state.activities = []
    state.company_activity = { activity: '', description: '', products: '' }
    state.phone = ''
    state.email = ''
    state.country = { lada: '', alpha_code: '' }
    state.legal_representation = null
    state.name_authorization = false
    updateLocalStorage(state)
  },
  [CompanyTypes.mutations.SET_LEGAL_NAME]: async (stateMut, payload: string) => {
    state.legal_name = payload
    updateLocalStorage(state)
  },
  [CompanyTypes.mutations.SET_TYPE_SOCIETY]: async (stateMut, payload: string) => {
    state.type_Society = payload
    updateLocalStorage(state)
  },
  [CompanyTypes.mutations.SET_COMPANY_TYPE]: async (stateMut, payload: string) => {
    state.company_type = payload
    updateLocalStorage(state)
  },
  [CompanyTypes.mutations.SET_PACKAGE]: async (stateMut, payload: string) => {
    state.package = payload
    updateLocalStorage(state)
  },
  [CompanyTypes.mutations.SET_ACTIVITIES]: async (stateMut, payload: Array<any>) => {
    state.activities = payload
    updateLocalStorage(state)
  },
  [CompanyTypes.mutations.SET_COMPANY_ACTIVITY]: async (stateMut, payload: CompanyActivity) => {
    state.company_activity = payload
    updateLocalStorage(state)
  },
  [CompanyTypes.mutations.SET_PHONE]: async (stateMut, payload: string) => {
    state.phone = payload
    updateLocalStorage(state)
  },
  [CompanyTypes.mutations.SET_EMAIL]: async (stateMut, payload: string) => {
    state.email = payload
    updateLocalStorage(state)
  },
  [CompanyTypes.mutations.SET_COUNTRY]: async (stateMut, payload: CountryCode) => {
    state.country = payload
    updateLocalStorage(state)
  },
  [CompanyTypes.mutations.SET_REPRESENTATION]: async (stateMut, payload: boolean) => {
    state.legal_representation = payload
    updateLocalStorage(state)
  },
  [CompanyTypes.mutations.SET_NAME_AUTHORIZATION]: async (stateMut, payload: boolean) => {
    state.name_authorization = payload
    updateLocalStorage(state)
  }
}

const actions: ActionTree<State, any> = {
  [CompanyTypes.actions.CLEAR_DATA]: async ({ commit }) => {
    await commit(CompanyTypes.mutations.CLEAR_DATA)
    return [null]
  },
  [CompanyTypes.actions.SET_LEGAL_NAME]: async ({ commit }, payload: string) => {
    await commit(CompanyTypes.mutations.SET_LEGAL_NAME, payload)
    return [null]
  },
  [CompanyTypes.actions.SET_TYPE_SOCIETY]: async ({ commit }, payload: string) => {
    await commit(CompanyTypes.mutations.SET_TYPE_SOCIETY, payload)
    return [null]
  },
  [CompanyTypes.actions.SET_COMPANY_TYPE]: async ({ commit }, payload: string) => {
    await commit(CompanyTypes.mutations.SET_COMPANY_TYPE, payload)
    return [null]
  },
  [CompanyTypes.actions.SET_PACKAGE]: async ({ commit }, payload: string) => {
    await commit(CompanyTypes.mutations.SET_PACKAGE, payload)
    return [null]
  },
  [CompanyTypes.actions.SET_ACTIVITIES]: async ({ commit }, payload: Array<any>) => {
    await commit(CompanyTypes.mutations.SET_ACTIVITIES, payload)
    return [null]
  },
  [CompanyTypes.actions.SET_COMPANY_ACTIVITY]: async ({ commit }, payload: CompanyActivity) => {
    await commit(CompanyTypes.mutations.SET_COMPANY_ACTIVITY, payload)
    return [null]
  },
  [CompanyTypes.actions.SET_PHONE]: async ({ commit }, payload: string) => {
    await commit(CompanyTypes.mutations.SET_PHONE, payload)
    return [null]
  },
  [CompanyTypes.actions.SET_EMAIL]: async ({ commit }, payload: string) => {
    await commit(CompanyTypes.mutations.SET_EMAIL, payload)
    return [null]
  },
  [CompanyTypes.actions.SET_COUNTRY]: async ({ commit }, payload: CountryCode) => {
    await commit(CompanyTypes.mutations.SET_COUNTRY, payload)
    return [null]
  },
  [CompanyTypes.actions.SET_REPRESENTATION]: async ({ commit }, payload: boolean) => {
    await commit(CompanyTypes.mutations.SET_REPRESENTATION, payload)
    return [null]
  },
  [CompanyTypes.actions.SET_NAME_AUTHORIZATION]: async ({ commit }, payload: boolean) => {
    await commit(CompanyTypes.mutations.SET_NAME_AUTHORIZATION, payload)
    return [null]
  }
}

export default { namespaced: true, state, getters, mutations, actions }
