









// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import Benefit from '@/components/benefits/Benefit.vue'

@Component({
  components: { Footer, Benefit }
})
export default class Kapital extends Vue {
  benefitData: any = {
    name: 'Kapital',
    textReturn: 'Regresar',
    openAccount: {
      img1: {
        url: require('@/assets/images/Benefits/Kapital.png'),
        style1: 'width: 184px; height: 42px;',
        style2: 'width: 262px; height: 84px; background: #000000;'
      },
      img2: {
        url: require('@/assets/images/Benefits/Kapital2.png'),
        classStyle: 'col-12 col-lg-6 mt-0 pl-xl-5 pr-0'
      },
      title: 'Equipando empresas para crecer',
      subTitle: 'Tu empresa lo merece todo, y nosotros te lo damos!',
      text: '',
      url: 'https://www.kapital.mx/',
      point: 'Con tu Cuenta Empresarial y tu Tarjeta Débito podrás centralizar los recursos de tu empresa, pagar proveedores, pagar nómina, realizar cualquier tipo de transferencia bancaria y mucho más. ¡Ten visibilidad completa de las finanzas de tu negocio!',
      points: [],
    },
    companyDocs: {
      title: 'Sube tus documentos',
      subTitle: 'Los necesitamos para crear tu cuenta empresarial',
      msj: {
        Title: 'Revisa tus documentos',
        Text: 'Deberás subir tus documentos originales, sin tachaduras ni enmendaduras y con fecha de expedición no mayor a 2 meses.'
      }
    },
    authorization: {
      title: 'Autorizas el envío de tus documentos',
      subTitle: '',
      protection: 'Tu información está protegida',
      authorization: ''
    },
    complete: {
      title: '¡Solicitud realizada con éxito!',
      subTitle: '',
      button: 'Finalizar',
      points: []
    }
  }

  @State('UserModule') state: any
}
