import { render, staticRenderFns } from "./PaymentRepresentationInfo.vue?vue&type=template&id=ef58b584&"
import script from "./PaymentRepresentationInfo.vue?vue&type=script&lang=ts&"
export * from "./PaymentRepresentationInfo.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports