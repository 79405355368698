

































































// Utils
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
// Mixins
import ConversMixin from '@/mixins/Converts'
// Components
import ModalConfirmDocument from '@/components/ModalConfirmDocument.vue'
import ModalFileNoPDF from '@/components/ModalFileNoPDF.vue'

@Component({
  components: { ModalConfirmDocument, ModalFileNoPDF },
  mixins: [ConversMixin]
})
export default class InputFile extends Vue {
  @Prop({ default: 'Arrastra aquí tu archivo' }) title!: string;
  @Prop({ default: '.jpg, .jpeg, .png, .pdf' }) supportedFiles!: string;
  file: any = null
  fileDetail = { name: '', size: null }
  modal: any = {
    showModalConfirmDocument: false,
    showModalFileNoPDF: false,
    file: {},
    refuse: false
  }

  @Watch('modal.refuse')
  reset () {
    if (!this.modal.refuse) return
    this.file = null
    this.updateFile(this.file)
    this.modal.refuse = false
  }

  mounted () {
    // @ts-ignore
    if (this.$attrs.value && this.$attrs.value.name) this.fileDetail.name = this.$attrs.value.name
    if (this.file === null && this.fileDetail.name) this.file = []
  }

  updateFile (file: any) {
    if (this.supportedFiles === '.pdf' && this.file !== null && !this.file.type.includes("application/pdf")) {
      this.file = null
      this.modal.showModalFileNoPDF = true
    }
    if (this.file !== null && (this.supportedFiles !== '.cer' && this.supportedFiles !== '.key')) this.modal.showModalConfirmDocument = true
    if (this.file !== null && (this.supportedFiles !== '.cer' && this.supportedFiles !== '.key')) this.modal.file = this.file
    this.fileDetail.name = this.file === null ? '' : file.name
    this.fileDetail.size = this.file === null ? null : file.size
    this.$emit('input', file)
  }
}
