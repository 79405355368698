






























































// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Types
import UserTypes from '@/store/types/UserTypes'

@Component({
  components: {}
})
export default class PartnerEdit extends Vue {
  @Prop({ default: 0 }) index!: number
  @Prop({ default: [] }) nationalities!: Array<any>
  partners: any = []
  loading = false
  editLimit = false
  show = false

  created () {
    this.partners = this.$attrs.value
    if (this.partners[this.index].id === '') this.show = true
    const documentsApproved = this.state.company.stages.find((stage: any) => stage.stage_name === 'documents_approved').is_completed === true
    if (this.state.company.timer !== null && documentsApproved) this.editLimit = true
  }

  noSpacesStart (value: string) {
    return value.trimStart()
  }

  validate (partner: any) {
    if (partner.email === '') return partner.validEmail = true
    const re = /\S+@\S+\.\S+/
    partner.validEmail = re.test(partner.email)
  }

  validateCompleteEmail (partner: any) {
    const re = /\S+@\S+\.\S+/
    partner.completeEmail = re.test(partner.email)
    this.partners.map((partner: any, index: number) => { this.partners[index].repeatedEmail = false })
    this.partners.map((partner: any) => { 
      let repeated = this.partners.filter((partnerAux: any) => partnerAux.email.toLowerCase() === partner.email.toLowerCase() && partner.email !== '')
      if (repeated.length >= 2) repeated.map((partner: any) => { this.validateRepeatedEmail(partner.email) })
    })
  }

  validateRepeatedEmail (email: string) {
    this.partners.map((partner: any, index: number) => { if (partner.email.toLowerCase() === email.toLowerCase()) this.partners[index].repeatedEmail = true })
  }

  deletePartner (index: number) {
    this.partners.splice(index, 1)
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
