















// Utils
import { Component, Vue } from 'vue-property-decorator'

@Component({
})
export default class TooltipProtocolizingMinutes extends Vue {
  points = [
    'El proceso de protocolización se refiere a la formalización de un documento legal mediante su registro en una notaría o en una institución pública. En este caso, el documento constitutivo fue enviado a la notaría para su protocolización, es decir, para que se le dé validez legal y se convierta en un instrumento jurídico oficial.'
  ]
}
