


























// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import ModalEditPowers from '@/components/resumen/modals/ModalEditPowers.vue'

@Component({
  components: { ModalEditPowers }
})
export default class CardAttorneys extends Vue {
  modal: any = {
    showModalPowers: false,
    update: false,
  }
  editActive = false
  loading = true
  commissary = {name: '', id: ''}
  statusCommissary = ''
  commissaryPartner = false
  renderKeyPowers = 0

  @Watch('modal.update')
  async update () {
    if(!this.modal.update) return
    this.renderKeyPowers += 1
    this.modal.update = false
  }

  created () {
    const articlesMock = this.state.company.stages.find((stage: any) => stage.stage_name === 'company_articles_mock').is_completed === true
    if (!articlesMock) this.editActive = true
  }

  @State('UserModule') state: any
}
