



















// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
})
export default class TooltipIDSpouse extends Vue {
  @Prop({default: ''}) color!: string
  points = [{
      point: 'Aceptamos los siguientes documentos:',
      url: ''
    },{
      point: 'INE/IFE o ID: Subir frente y reverso',
      url: ''
    },{
      point: 'Pasaporte: Subir parte frontal',
      url: ''
    }
  ]
}
