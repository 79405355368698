






















































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
import { validationMixin } from 'vuelidate'
// Validations
import validations from '@/validations/new-email'
// Components
import ReturnAddress from '@/components/Return.vue'
import EditPhoneUser from '@/components/account/phone/NewPhone.vue'
import HeaderProgressBar from '@/components/HeaderProgressBar.vue'
import Footer from '@/components/Footer.vue'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'
// Services
import UserService from '@/services/User'
import CountryService from '@/services/Country'

@Component({
  components: { Footer, ReturnAddress, HeaderProgressBar, EditPhoneUser },
  mixins: [validationMixin],
  validations: validations
})
export default class EditPhoneAndEmail extends Vue {
  copys = {
    title: 'Editar teléfono o correo',
    subTitle: 'Escribe tu correo electrónico y teléfono donde te enviaremos el código de validación',
    emailLabel: 'Correo electrónico',
    phoneLabel: 'Número de teléfono',
    emailError1: 'Ingresa un correo válido.',
    emailError2: 'Este correo ya está registrado, intenta con otro.',
    textInfo: 'Te enviaremos un código de verificación vía SMS',
    buttonText: 'Guardar'
  }
  loading = true
  email = ''
  phone = ''
  validEmail = 0
  country = { lada: '52', alpha_code: 'MX' }
  countries = []

  mounted () {
    this.getCountries()
    this.email = this.companyState.email === '' ? this.state.email : this.companyState.email
    this.phone = this.companyState.phone === '' ? this.state.phone : this.companyState.phone
  }

  onlyNumbers (value: string) {
    return value.replace(/[^0-9.]/g,'');
  }

  async validate (email: string, value: boolean) {
    return this.validEmail = ( value || ( email === '' ) ) ? 0 : 1
  }

  async getCountries () {
    const [error, response] = await CountryService.all()
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.loading = false
    this.countries = response.data.data
    if (this.companyState.country.lada !== '') {
      this.country = this.companyState.country
    } else {
      const countryCode: any = this.countries.find((country: any) => country.calling_codes[0] === this.state.phone_country_code)
      if (countryCode) {
        this.country.lada = countryCode.calling_codes[0]
        this.country.alpha_code = countryCode.alpha_code
      }
    }
  }

  async next () {
    this.loading = true
    if (this.email !== this.state.email) {
      const [errorEmail, response] = await UserService.availableEmail(this.email)
      if (errorEmail) {
        this.validEmail = 1
        this.loading = false
        return this.$toasted.global.error(errorEmail)
      }
      if (response.data.data) {
        this.loading = false
        return this.validEmail = 2
      }
    }
    const [error] = await UserService.requestNewCode(this.state.id, this.email, this.phone, this.country.lada )
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    await this.setEmail(this.email)
    await this.setPhone(this.phone)
    await this.setCountry(this.country)
    return this.$router.push({ name: 'VerifyAccount', query: this.$route.query }).catch(() => {return})
  }

  @State('UserModule') state: any
  @State('CompanyModule') companyState: any
  @Action(`CompanyModule/${CompanyTypes.actions.SET_EMAIL}`) setEmail: any;
  @Action(`CompanyModule/${CompanyTypes.actions.SET_PHONE}`) setPhone: any;
  @Action(`CompanyModule/${CompanyTypes.actions.SET_COUNTRY}`) setCountry: any;
}
