















































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Types
import UserTypes from '@/store/types/UserTypes'
// Services
import CompanyService from "@/services/Company"

@Component({
})
export default class ValidationInfo extends Vue {
  copys = {
    title: 'Llegaste a la fase de verificación',
    text: '¿Qué sigue?',
    points: [
      'Enviamos un correo a los socios',
      'Deberán verificar su identidad y subir su información',
      'Podrás dar seguimiento desde tu dashboard de admin'
    ]
  }

  async startValidationComplete () {
    /* const data = {
      stage_name: 'council_data',
      company_id: this.state.company.id,
      is_completed: true
    }
    const [error] = await CompanyService.updateStage(data)
    if (error) {
      return this.$toasted.global.error(error)
    }
    await this.updateCompany(this.state.company.id) */
    return this.$router.push({ name: 'Dashboard', query: this.$route.query, params: { company_id: this.state.company.id } })
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
