







































































































































// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { validationMixin } from 'vuelidate'
// Validations
import validations from '@/validations/new-password'
// Components
import Loader from '@/components/Loader.vue'
// Types
import AuthTypes from '@/store/types/AuthTypes'
// Services
import UserService from '@/services/User'

@Component({
  components: { Loader },
  mixins: [validationMixin],
  validations: validations
})
export default class NewPassword extends Vue {
  @Prop() title!: string
  @Prop() address!: string
  @Prop() identity!: string
  @Prop() token!: string
  loading = false
  password = ''
  confirmPassword = ''
  samePassword = true
  passwordFieldType = 'password'
  confirmpasswordFieldType = 'password'
  eye = 'eye'
  eye2 = 'eye'

  validate (same: boolean) {
    if(this.confirmPassword !== ''){
      return this.samePassword = same
    }
    return
  }

  switchVisibility () {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    this.eye = this.eye === 'eye' ? 'eye-slash' : 'eye'
  }

  switchVisibility2 () {
    this.confirmpasswordFieldType = this.confirmpasswordFieldType === 'password' ? 'text' : 'password'
    this.eye2 = this.eye2 === 'eye' ? 'eye-slash' : 'eye'
  }

  async recovery () {
    this.loading = true
    const [error] = await UserService.resetPasswordEntry({ identity: this.identity, password: this.password, token: this.token })
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    const [errorLogin] = await this.login({ email: this.identity, password: this.password })
    if (errorLogin) {
      this.loading = false
      this.$toasted.global.error(error)
      return this.$router.push({ name: 'Login', query: this.$route.query })
    }
    return this.$router.push({ name: this.address, query: this.$route.query }).catch(() => {return})
  }

  async next () {
    this.loading = true
    const [error] = await UserService.updatePassword(this.identity, this.password)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.$toasted.global.success({ message: 'La contraseña fue actualizada con éxito' })
    return this.$router.push({ name: this.address, query: this.$route.query }).catch(() => {return})
  }
  
  @Action(`AuthModule/${AuthTypes.actions.LOGIN}`) login: any;
}
