









// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import Benefit from '@/components/benefits/Benefit.vue'

@Component({
  components: { Footer, Benefit }
})
export default class Krowdy extends Vue {
  benefitData: any = {
    name: 'Krowdy',
    textReturn: 'Regresar',
    openAccount: {
      img1: {
        url: require('@/assets/images/Benefits/Krowdy.png'),
        style1: 'width: 160px; height: 93px;',
        style2: ''
      },
      img2: {
        url: require('@/assets/images/Benefits/Krowdy2.png'),
        classStyle: 'col-12 col-lg-6 col-xl-5 mt-0 px-0'
      },
      title: 'Reclutamos talento por ti',
      subTitle: 'Dedícate a lo que te apasiona, digitaliza el resto.',
      text: '',
      url: 'https://www.krowdy.com/',
      point: '',
      points: [
        'Transforma digitalmente tu reclutamiento',
        'Toda la adquisión de tus candidatos en un solo lugar.',
        'Comunicación con cientos de candidatos.',
        '¿Indicadores al instante? Con Krowdy los tienes.',
        'Todos los datos, pruebas y documentos con un click.',
        'Consigue candidatos con ayuda de empadronadores.',
        'Herramientas digitales para transformar tu equipo.'
      ],
    },
    companyDocs: {
      title: 'Sube tus documentos',
      subTitle: 'Los necesitamos para crear tu cuenta empresarial',
      msj: {
        Title: 'Revisa tus documentos',
        Text: 'Deberás subir tus documentos originales, sin tachaduras ni enmendaduras y con fecha de expedición no mayor a 2 meses.'
      }
    },
    authorization: {
      title: 'Autorizas el envío de tus documentos',
      subTitle: '',
      protection: 'Tu información está protegida',
      authorization: ''
    },
    complete: {
      title: '¡Solicitud realizada con éxito!',
      subTitle: '',
      button: 'Finalizar',
      points: []
    }
  }

  @State('UserModule') state: any
}
