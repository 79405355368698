// Utils
import { RouteConfig } from 'vue-router'
import multiguard from 'vue-router-multiguard'
// Views
import Index from '@/views/administration/Index.vue'
import CommissaryTally from '@/views/administration/CommissaryTally.vue'
import AccountantTally from '@/views/administration/AccountantTally.vue'
import CompanyFiels from '@/views/administration/CompanyFiels.vue'
// Middlewares
import Auth from '@/middlewares/Auth'
import User from '@/middlewares/User'

const routes: Array<RouteConfig> = [
  {
    path: '/administration/:company_id',
    component: Index,
    beforeEnter: multiguard([Auth.isLogged, User.validateData]),
    children: [
      {
        path: 'commissary_tally',
        name: 'CommissaryTally',
        component: CommissaryTally
      },
      {
        path: 'accountant_tally',
        name: 'AccountantTally',
        component: AccountantTally
      },
      {
        path: 'company_fiels',
        name: 'CompanyFiels',
        component: CompanyFiels
      }
    ]
  }
]

export default routes
