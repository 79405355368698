



















































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import HeaderProgressBar from '@/components/HeaderProgressBar.vue'
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
// Services
import PartnersService from '@/services/Partners'

@Component({
  components: { HeaderProgressBar, Footer, Loader }
})
export default class Start extends Vue {
  points = [
    'Decirnos tu nacionalidad',
    'Agregar tu información fiscal',
    'Verificar tu identidad'
  ]
  loading = false

  async next () {
    this.loading = true
    const partner = this.state.company.partners.find((partner: any) => this.state.email === partner.email)
    if (partner.pivot.invitation_accepted === null) {
      // eslint-disable-next-line
      const [error, response] = await PartnersService.invitationResponse(partner.id, this.state.company.id, true)
      if (error) {
        this.loading = false
        return this.$toasted.global.error(error)
      }
    }
    return this.$router.push({ name: 'isPartnerComplementaries', query: this.$route.query, params: { company_id: this.state.company.id } })
  }
  
  @State('UserModule') state: any
}
