import to from 'await-to-js'
import axios from '@/utils/axios-api'

const URL_PHP = process.env.VUE_APP_BASE_URL_PHP

export default class Mati {
  static async setMatiCheck (partner: string) {
    return await to(axios.post(`${URL_PHP}/v2/update-mati-verification-partner/${partner}`)) as any
  }
}
