












































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import OpenAccount from '@/components/benefits/OpenAccount.vue'
import AcceptBenefit from '@/components/benefits/AcceptBenefit.vue'
import CompleteBenefit from '@/components/benefits/CompleteBenefit.vue'
import CompanyDocs from '@/components/benefits/CompanyDocs.vue'

@Component({
  components: { OpenAccount, AcceptBenefit, CompanyDocs, CompleteBenefit }
})
export default class Benefit extends Vue {
  benefitData: any = {
    name: '',
    textReturn: '',
    openAccount: {
      img1: {
        url: '',
        style1: '',
        style2: ''
      },
      img2: {
        url: '',
        classStyle: ''
      },
      imgStyle1: '',
      imgStyle2: '',
      title: '',
      subTitle: '',
      text: '',
      url: '',
      point: '',
      points: ['']
    },
    companyDocs: {
      title: '',
      subTitle: '',
      msj: {
        Title: '',
        Text: ''
      }
    },
    authorization: {
      title: '',
      subTitle: '',
      protection: '',
      authorization: ''
    },
    complete: {
      title: '',
      subTitle: '',
      button: '',
      points: [{ title: '', text: '' }]
    }
  }
  account: any = { name: '', create: false, complete: false }

  async created () {
    this.benefitData = this.$attrs.value
    this.account.name = this.benefitData.name
  }

  @State('UserModule') state: any
}
