















































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Types
import UserTypes from '@/store/types/UserTypes'
// Components
import Loader from '@/components/Loader.vue'
import InputFile from '@/components/InputFile.vue'
import TooltipCSF from '@/components/benefits/TooltipCSF.vue'
import TooltipAddress from '@/components/benefits/TooltipAddress.vue'
// Services
import ImageService from '@/services/Image'

@Component({
  components: { Loader, InputFile, TooltipCSF, TooltipAddress }
})
export default class ModalAddCompanyDocs extends Vue {
  modal: any = { showModalAddCompanyDocs: false, update: false }
  loading = false
  csf: any = null
  address: any = null

  mounted () {
    this.modal = this.$attrs.value
  }

  noSpacesStartRFC (value: string) {
    return value.trimStart().toUpperCase()
  }

  async upload (file: any, docType: string, id: string) {
    if (file === null || !file.name || !file.size) return [null, false]
    const [error] = await ImageService.upload({
      entity_type: 'partner',
      identity: id,
      document_type: docType,
      image: file,
      company_id: this.state.company.id
    })
    if (error) {
      this.loading = false
      return [error, true]
    }
    return [null, false]
  }

  async uploadCompany (file: any, docType: string) {
    if (file === null || !file.name || !file.size) return [null, false]
    const [error] = await ImageService.uploadCompanyDoc({
      entity_type: 'company',
      identity: this.state.company.id,
      document_type: docType,
      image: file
    })
    if (error) {
      this.loading = false
      return [error, true]
    }
    return [null, false]
  }

  async saveCSF () {
    this.loading = true
    if (this.state.company.company_CSF.document_url === null && (this.csf === null || !this.csf)) return this.loading = false
    if (this.address === null || !this.address) return this.loading = false
    if (this.csf) {
      const error = await this.uploadCompany(this.csf, 'CSF_company')
      if (error[1]) {
        this.loading = false
        return this.$toasted.global.error(error[0])
      }
    }
    if (this.address) {
      let senior = this.state.company.council.filter((partner: any ) => partner.council_role === 'president' || partner.council_role === 'unique_administrator')
      const error = await this.upload(this.address, 'address', senior[0].partner.id)
      if (error[1]) {
        this.loading = false
        return this.$toasted.global.error(error[0])
      }
    }
    await this.updateCompany(this.state.company.id)
    this.$toasted.global.success({ message: 'Se guardo el documento con éxito' })
    this.modal.update = true
    this.loading = false
    this.modal.showModalAddCompanyDocs = false
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
