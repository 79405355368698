



























// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import ReturnAddress from '@/components/Return.vue'
import ValidateCode from '@/components/account/ValidateCode.vue'

@Component({
  components: { ReturnAddress, ValidateCode }
})
export default class UserValidatePhone extends Vue {
  email = ''

  created () {
    this.email = this.companyState.email === '' ?  this.state.email : this.companyState.email
  }

  @State('CompanyModule') companyState: any
  @State('UserModule') state: any
}
