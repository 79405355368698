
































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Types
import UserTypes from '@/store/types/UserTypes'
// Components
import Loader from '@/components/Loader.vue'
import InputFile from '@/components/InputFile.vue'
import TooltipCSF from '@/components/dashboard/TooltipCSF.vue'
// Services
import PaymentService from '@/services/Payment'

@Component({
  components: { Loader, InputFile, TooltipCSF }
})
export default class ModalUpdateCard extends Vue {
  modal: any = { showModalUpdateCard: false, update: false }
  loading = false
  cardNumber = ''
  month = ''
  year = ''
  cvv = ''
  csf: any = null

  mounted () {
    this.modal = this.$attrs.value
  }

  noSpacesStart (value: string) {
    return value.replace(/[^0-9.]/g,'').trimStart()
  }

  async save () {
    this.loading = true
    if (this.cardNumber === '' || this.month === '' || this.year === '' || this.cvv === '') return this.loading = false
    const [errorRFC] = await PaymentService.updatePaymentMethod({
      number: this.cardNumber,
      exp_month: this.month,
      exp_year: this.year,
      cvc: this.cvv
    })
    if (errorRFC) {
      this.loading = false
      return this.$toasted.global.error(errorRFC)
    }
    await this.updateCompany(this.state.company.id)
    this.loading = false
    this.modal.showModalUpdateCard = false
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
