





























// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import CardCheckout from '@/components/payment/checkout/CardCheckout.vue'
import CardCheckoutItemsForeigns from '@/components/payment/checkout/CardCheckoutItemsForeigns.vue'
import CardReview from '@/components/payment/CardReview.vue'
import HeaderProgressBar from '@/components/HeaderProgressBar.vue'
import ReturnAddress from '@/components/Return.vue'
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
// Services
import Payments from '@/services/Payment'

@Component({
  components: { HeaderProgressBar, Footer, ReturnAddress, CardCheckout, CardCheckoutItemsForeigns, CardReview, Loader }
})
export default class PaymentRepresentation extends Vue {
  loading = true
  pay: any = {
    items: [{
      id: '',
      name: '',
      price: '',
      price_id: '',
      price_annual: '',
      price_annual_id: ''
    }],
    selected: [{
      id: '',
      item: '',
      cost: '',
      quantity: '',
      price_id: ''
    }]
  }

  async mounted () {
    await this.getPrices()
    await Payments.slackPaymentAlert(this.state.company.id)
  }

  async getPrices () {
    const [error, response] = await Payments.getPrices()
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.pay.items = []
    this.pay.selected = []
    const items = response.data.data.filter((item: any ) => item.name === 'Softlanding' || item.name === 'Import/Export Plus')
    items.map((item: any) => {
      const pricesMonth = item.prices.filter((price: any ) => price.is_default === true && price.type_purchases === 'month')[0]
      const pricesYear = item.prices.filter((price: any ) => price.is_default === true && price.type_purchases === 'year')[0]
      const price = (Math.trunc(Number(pricesMonth.price) + Number(pricesMonth.tax))).toString()
      const priceYear = (Math.trunc(Number(pricesYear.price) + Number(pricesYear.tax))).toString()
      this.pay.items.push({ id: item.id, name: item.name, price: price.replace(/\d(?=(?:\d{3})+$)/g, '$&,'), price_id: pricesMonth.id, price_annual: priceYear.replace(/\d(?=(?:\d{3})+$)/g, '$&,'), price_annual_id: pricesYear.id })
      if (item.name === 'Softlanding') this.pay.selected.unshift({ id: item.id, item: item.name, cost: price.replace(/\d(?=(?:\d{3})+$)/g, '$&,'), quantity: '1', price_id: pricesMonth.id })
    })
    this.loading = false
  }

  @State('UserModule') state: any
}
