













































// Utils
import { Component, Vue } from 'vue-property-decorator'

@Component({
})
export default class RepresentationInfo extends Vue {
  points = [
    'No necesitas a un socio mexicano',
    'Te representamos ante el SAT',
    'Hacemos tus declaraciones fiscales',
    'Te ayudamos a aperturar la cuenta de banco'
  ]
}
