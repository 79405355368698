







































// Utils
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
import moment from 'moment'
// Components
import Loader from '@/components/Loader.vue'
import ModalTaxes from '@/components/accounting/ModalTaxes.vue'
import ModalCaptureLine from '@/components/accounting/ModalCaptureLine.vue'
// Services
import CompanyService from '@/services/Company'

@Component({
  components: { Loader, ModalTaxes, ModalCaptureLine }
})
export default class LastMonth extends Vue {
  @Prop({ default: 0 }) month!: number;
  @Prop({ default: 0 }) year!: number;
  @Prop({ default: [] }) accountStatements!: Array<any>;
  moment = moment
  loading = true
  content: any = { showContent: 'Activities' }
  totalSteps = 5
  captureLines: Array<any> = []
  taxReview: any = null
  modal: any = {
    showModalTaxes: false,
    showModalCaptureLine: false,
    update: false
  }
  copys: any = {
    title: 'Contabilidad de ',
    month: 0,
    year: 0,
    date: '',
    months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    stepActive: {},
    steps: [{
        step: 1,
        stepCount: 'Paso 1 de 5',
        stepName: 'Estados de cuenta',
        status: 'Pendiente',
        textPending: 'Sube tus estados de cuenta antes del 05/',
        textProcess2: '',
        textProcessBold: '',
        textProcess: 'Sube tus estados de cuenta antes del 05/',
        textComplete: 'Sube tus estados de cuenta antes del 05/',
        textDelay: 'Sube tus Estados de Cuenta, sin estos no podemos continuar con nuestros servicios contables y fiscales.',
        actionPending: 'Subir estados de cuenta',
        actionProcess: 'Subir estados de cuenta',
        actionComplete: 'Revisar estados de cuenta',
        actionDelay: 'Subir estados de cuenta'
      },{
        step: 2,
        stepCount: 'Paso 2 de 5',
        stepName: 'Declaración de impuestos',
        status: 'En proceso',
        textPending: '',
        textProcess2: '',
        textProcessBold: '',
        textProcess: 'Estamos declarando tus impuestos, si tenemos alguna duda te buscaremos vía WhatsApp.',
        textComplete: '',
        textDelay: '',
        actionPending: '',
        actionProcess: 'Habla con nosotros',
        actionComplete: '',
        actionDelay: ''
      },{
        step: 3,
        stepCount: 'Paso 3 de 5',
        stepName: 'Revisión de impuestos',
        status: 'Pendiente',
        textPending: 'Ya están listos tus impuestos, revísalos a detalle y apruébalos, tienes hasta el: 17/',
        textProcess2: '',
        textProcessBold: '',
        textProcess: '',
        textComplete: '',
        textDelay: '',
        actionPending: 'Revisar impuestos',
        actionProcess: '',
        actionComplete: '',
        actionDelay: ''
      },{
        step: 4,
        stepCount: 'Paso 4 de 5',
        stepName: 'Preparando pago de impuestos',
        status: 'En proceso',
        textPending: '',
        textProcess: 'Estamos preparando las lineas de captura para el pago de impuestos.',
        textProcess2: 'Tiempo estimado: 48 horas',
        textProcessBold: 'Puedes resolver cualquier duda contable/fiscal en el grupo de WhatsApp',
        textComplete: '',
        textDelay: '',
        actionPending: '',
        actionProcess: '',
        actionComplete: '',
        actionDelay: ''
      },{
        step: 5,
        stepCount: 'Paso 5 de 5',
        stepName: 'Pago de impuestos',
        status: 'Pendiente',
        textPending: 'Paga tus impuestos en tu banco con las líneas de captura, debes realizarlo antes del: 21/',
        textProcess2: '',
        textProcessBold: '',
        textProcess: 'Paga tus impuestos en tu banco con las líneas de captura lo antes posible, para estar al corriente con tu contabilidad.',
        textComplete: '',
        textDelay: 'Paga tus impuestos en tu banco con las líneas de captura lo antes posible, para estar al corriente con tu contabilidad.',
        actionPending: 'Pagar impuestos',
        actionProcess: 'Pagar impuestos',
        actionComplete: '',
        actionDelay: 'Pagar impuestos'
      },{
        step: 6,
        stepCount: '',
        stepName: 'Pago realizado',
        status: 'Completo',
        textPending: '',
        textProcess2: '',
        textProcessBold: '',
        textProcess: '',
        textComplete: '',
        textDelay: '',
        actionPending: '',
        actionProcess: '',
        actionComplete: '',
        actionDelay: ''
      }
    ]
  }
  renderKeyTaxes = 0
  renderKeyCaptureLines = 10

  @Watch('modal.update')
  async updateSteps () {
    if (!this.modal.update) return
    this.setCurrentStep()
    this.modal.update = false
  }

  get currentMonth () {
    if (this.copys.month === 0) return this.copys.months[11]
    return this.copys.months[this.copys.month - 1]
  }

  get currentMonthNumber () {
    if (this.copys.month === 0) return 11
    return this.copys.month - 1
  }

  get currentYear () {
    if (this.copys.month === 0) return this.copys.year - 1
    return this.copys.year
  }

  get stepActive () {
    const step = this.copys.stepActive
    let date = ''
    if (!step) return { border: '', cardStatus: '', text: '' }
    if ((step.step === 1 && step.status !== 'Atraso') || (step.step === 3 && step.status === 'Pendiente') || (step.step === 5 && step.status === 'Pendiente')) date = this.copys.date
    if (step.status === 'Atraso') return { border: 'border-red', cardStatus: 'card-status-refused', text: step.textDelay, action: step.actionDelay, date: date }
    else if (step.status === 'En proceso') return { border: 'border-blue', cardStatus: 'card-status-validate', text: step.textProcess, action: step.actionProcess, date: date }
    else if (step.status === 'Completo') return { border: 'border-green', cardStatus: 'card-status-complete', text: step.textComplete, action: step.actionComplete, date: date }
    else return { border: 'border-primary', cardStatus: 'card-status-pending-primary', text: step.textPending, action: step.actionPending, date: date }
  }

  created () {
    this.content = this.$attrs.value
    this.copys.month = this.month
    this.copys.year = this.year
    this.copys.date = (this.copys.month < 9 ? '0' + (this.copys.month + 1) : (this.copys.month + 1)) + '/' + this.year
  }

  mounted () {
    this.setCurrentStep()
  }

  stepAction () {
    if (this.copys.stepActive.step === 1) this.content.showContent = 'Account statements'
    else if (this.copys.stepActive.step === 2) window.open('https://calendly.com/contabilidadtally/60min?month=2024-01', 'Habla con nosotros')
    else if (this.copys.stepActive.step === 3) this.modal.showModalTaxes = true
    else if (this.copys.stepActive.step === 5) this.modal.showModalCaptureLine = true
    return
  }

  async getAccountStatements () {
    const [error, response] = await CompanyService.getAccountStatements(this.state.company.id)
    if (error) return this.$toasted.global.error(error)
    return response.data.data
  }

  async getTaxDeclaration (month: string, year: string) {
    const [error, response] = await CompanyService.getTaxDeclaration(this.state.company.id, month, year)
    if (error) return this.$toasted.global.error(error)
    this.taxReview = response.data.data
    this.renderKeyTaxes ++
    return
  }

  async getCaptureLines () {
    const [error, response] = await CompanyService.getCaptureLines(this.state.company.id, (this.currentMonthNumber + 1).toString(), this.currentYear.toString())
    if (error) return this.$toasted.global.error(error)
    this.captureLines = response.data.data
    this.renderKeyCaptureLines ++
    return
  }

  async setCurrentStep () {
    this.loading = true
    let month = 0
    const day = moment().date()
    if (moment().month() === 0) month = 11
    else month = moment().month() - 1
    const accounting_period_month: string = this.currentMonthNumber < 9 ? '0' + (this.currentMonthNumber + 1) : (this.currentMonthNumber + 1).toString()
    const accounting_period_year: string = this.currentYear
    const accountStatements = await this.getAccountStatements()
    await this.getTaxDeclaration(accounting_period_month, accounting_period_year)
    await this.getCaptureLines()
    const accountStatementsLastMonth = accountStatements.filter((account: any ) => moment(account.accounting_period).month() === this.currentMonthNumber && moment(account.accounting_period).year() === this.currentYear)
    if (!this.taxReview && ((!accountStatementsLastMonth.length && month === this.currentMonthNumber && day < 13) || (accountStatementsLastMonth.length && month === this.currentMonthNumber && day < 5))) {
      this.copys.stepActive = this.copys.steps[0]
      if (month !== this.currentMonthNumber || day > 5) this.copys.stepActive.status = 'Atraso'
      if (accountStatementsLastMonth.length) this.copys.stepActive.status = 'Completo'
    } else if (!this.taxReview && month === this.currentMonthNumber && day < 17) {
      this.copys.stepActive = this.copys.steps[1]
    } else if (this.taxReview && !this.taxReview.is_approved && month === this.currentMonthNumber && day < 17) {
      this.copys.stepActive = this.copys.steps[2]
    } else if (!this.captureLines.length) {
      this.copys.stepActive = this.copys.steps[3]
    } else if (this.captureLines.length && !this.captureLines[0].pay_confirm) {
      this.copys.stepActive = this.copys.steps[4]
      if (month !== this.currentMonthNumber || day > 20) this.copys.stepActive.status = 'Atraso'
    } else this.copys.stepActive = this.copys.steps[5]
    this.loading = false
    return
  }

  @State('UserModule') state: any
}
