














































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
import moment from 'moment'
// Components
import HeaderProgressBar from '@/components/HeaderProgressBar.vue'
import Footer from '@/components/Footer.vue'
import ValidateCode from '@/components/account/ValidateCode.vue'
// Services
import UserService from '@/services/User'

@Component({
  components: { HeaderProgressBar, Footer, ValidateCode }
})
export default class VerifyAccount extends Vue {
  moment = moment
  copys = {
    title: 'Verifica tu cuenta',
    text: {
      part1: 'Ingresa el código enviado al teléfono',
      part2: 'o al correo',
      countryCode: '',
      phone: '',
      email: ''
    },
    codeError: 'El código no coincide',
    buttonText: 'Validar',
    text2: {
      text: '¿No lo recibiste?',
      textLink: 'Reenviar'
    },
    textLinkToEdit: 'Editar teléfono o correo'
  }
  email = ''
  phone = ''
  countryCode = ''
  code = ''
  loading = false
  loading2 = false
  validCode = true
  cont = 0
  now = moment().valueOf()
  end = moment().valueOf()
  endTime: any = null

  maxLength (value: string) { 
    return (value.substring(0,5)).replace(/[^0-9.]/g,'');
  }

  resetValidate () {
    this.validCode = true
  }

  get resendRepeat () {
    return this.cont === 0 ? true : this.now >= this.end
  }

  get min (){
    if ((this.end - this.now) <= 0) return '00'
    let m = Math.trunc((this.end - this.now) / 1000 / 60) % 60
    return m <= 0 ? '00' : m > 9 ? m : '0' + m
  }

  get sec (){
    if ((this.end - this.now) <= 0) return '00'
    let s = Math.trunc((this.end - this.now)/1000) % 60
    return s <= 0 ? '00' : s > 9 ? s : '0' + s
  }

  created () {
    this.email = this.companyState.email === '' ? this.state.email : this.companyState.email
    this.phone = this.companyState.phone === '' ? this.state.phone : this.companyState.phone
    this.copys.text.countryCode = this.companyState.country.lada === '' ? this.state.phone_country_code : this.companyState.country.lada
    this.copys.text.phone = this.companyState.phone === '' ? this.state.phone : this.companyState.phone
    this.copys.text.email = this.companyState.email === '' ? this.state.email : this.companyState.email
    this.countryCode = this.companyState.country.lada === '' ? this.state.phone_country_code : this.companyState.country.lada
  }

  async resendPhone () {
    this.loading2 = true
    const [error] = await UserService.requestNewCode(this.state.id, this.email, this.phone, this.countryCode )
    if (error) {
      this.loading2 = false
      return this.$toasted.global.error(error)
    }
    this.createTimer()
    this.loading2 = false
    return this.$toasted.global.success({ message: 'Se ha reenviado el código' })
  }

  async verifyCode () {
    this.loading = true
    const [error] = await UserService.verifyCode(this.state.id, this.email, this.phone, this.countryCode, this.code )
    if (error) {
      this.validCode = false
      this.loading = false
      return this.$toasted.global.error(error)
    }
    return this.$router.push({ name: 'Welcome', query: this.$route.query }).catch(() => {return})
  }

  createTimer () {
    this.cont++
    this.now = moment().valueOf()
    this.end = moment().valueOf() + (60000 * this.cont)
    this.endTime = setInterval(()=>{
      this.now = moment().valueOf()
      if (this.now >= this.end) {
        clearInterval(this.endTime)
      }
    }, 1000)
  }

  beforeDestroy(){
    clearInterval(this.endTime)
  }

  @State('CompanyModule') companyState: any
  @State('UserModule') state: any
}
