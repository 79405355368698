
























































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
import ReturnAddress from '@/components/Return.vue'
// Services
import PaymentService from '@/services/Payment'

@Component({
  components: { Footer, Loader, ReturnAddress }
})
export default class PaymentInfoRNIE extends Vue {
  loading = false
  showModalRNIE = false
  price = ''

  async created () {
    await this.getPrices()
  }

  async getPrices () {
    const [error, response] = await PaymentService.getPrices()
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    response.data.data.map((item: any) => {
      if (item.name === 'RNIE') {
        const priceDefault = item.prices.filter((price: any ) => price.is_default === true)
        this.price = (Math.trunc(Number(priceDefault[0].price) + Number(priceDefault[0].tax))).toString().replace(/\d(?=(?:\d{3})+$)/g, '$&,')
      }
    })
  }

  addService () {
    return this.$router.push({ name: 'Checkout', query: { ...this.$route.query, rnie: 'true'} })
  }

  @State('UserModule') state: any
}
