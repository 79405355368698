

































// Utils
import { Component, Vue } from 'vue-property-decorator'

@Component({
})
export default class EntityType extends Vue {
  company: any = { company_type: '' }
  types = [{
      type: 'sapi',
      title: 'S.A.P.I de C.V.',
      text: 'La favorita de los fondos de inversión, este tipo de sociedad te permite tener un consejo, emitir acciones a trabajadores clave y a inversionistas con diferentes derechos de voto.',
      img: require('@/assets/images/Circle-rocket.png'),
    },{
      type: 'sa',
      title: 'S.A. de C.V.',
      text: 'La Sociedad Anónima es ideal para cualquier tipo de negocio. Puedes nombrar a un administrador único o consejo de administración.',
      img: require('@/assets/images/Circle-chess.png'),
    },{
      type: 'srl',
      title: 'S. de R.L. de C.V.',
      text: 'Recomendada para empresas familiares donde se pretende que los socios no varien a lo largo del tiempo, puede ser administrada por una o varias personas. ',
      img: require('@/assets/images/mexican.png'),
    }
  ]

  mounted () {
    this.company = this.$attrs.value
  }
}
