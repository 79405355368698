













// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import moment from 'moment'
// Components
import UserDropdown from '@/components/UserDropdown.vue'
import HeaderButtons from '@/components/dashboard/HeaderButtons.vue'
import Promo from '@/components/Promo.vue'
// Types
import AuthTypes from '@/store/types/AuthTypes'

@Component({
  components: { UserDropdown, HeaderButtons, Promo }
})
export default class Header extends Vue {
  moment = moment
  userName = ''
  startDate = moment('11-18-2022 00:00', 'MM-DD-YYYY HH:mm').valueOf()
  endDate = moment('11-23-2022 00:00', 'MM-DD-YYYY HH:mm').valueOf()
  now = moment().valueOf()
  startime: any = null

  created () {
    this.startime = setInterval(()=>{
      this.now = moment().valueOf()
      if (this.now >= this.startDate) {
        clearInterval(this.startime)
      }
    }, 1000)
  }

  async mounted () {
    this.getName()
  }

  get viewName () {
    const name = this.$route.name
    return name
  }

  beforeDestroy(){
    clearInterval(this.startime)
  }

  get promoCheck () {
    const isPayment = this.$route.path === '/payment' || this.$route.path === '/payment/'
    const isTime = (this.startDate - this.now) <= 0
    const isTimeEnd = (this.endDate - this.now) > 0
    return isPayment && isTime && isTimeEnd
  }

  getName () {
    if (this.state.name === null) return
    const spaceName = this.state.name.indexOf(' ')
    const spaceFirstName = this.state.first_names.indexOf(' ')
    this.userName = spaceName !== -1 ? this.state.name.slice(0, spaceName) : this.state.name
    this.userName = spaceFirstName !== -1 ? this.userName + ' ' + this.state.first_names.slice(0, spaceFirstName) : this.userName + ' ' + this.state.first_names
  }

  async signOff () {
    await this.logout()
    return this.$router.push({ name: 'Login', query: this.$route.query })
  }

  @Action(`AuthModule/${AuthTypes.actions.LOGOUT}`) logout: any
  @State('UserModule') state: any
}
