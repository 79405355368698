







// Utils
import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'
// Components
import Header from '@/components/signup/Header.vue'
import Main from '@/components/Main.vue'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'

@Component({
  components: { Main, Header }
})
export default class Index extends Vue {
  async created () {
    if (
      this.$route.name !== 'TypeSociety' && this.$route.name !== 'LegalName' &&
      this.$route.name !== 'PersonalInformation' && this.$route.name !== 'RepresentationInfo' &&
      this.$route.name !== 'Representation' && this.$route.name !== 'Register' &&
      this.$route.name !== 'CompanyType' && this.$route.name !== 'CompanySubsidiary' &&
      this.$route.name !== 'Packages' && this.$route.name !== 'CompanyActivities'
    ) { await this.clearData() }
  }
  
  @Action(`CompanyModule/${CompanyTypes.actions.CLEAR_DATA}`) clearData: any;
}
