









// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import Benefit from '@/components/benefits/Benefit.vue'

@Component({
  components: { Footer, Benefit }
})
export default class Jeeves extends Vue {
  benefitData: any = {
    name: 'Jeeves',
    textReturn: 'Regresar',
    openAccount: {
      img1: {
        url: require('@/assets/images/Benefits/Jeeves.png'),
        style1: 'width: 192px; height: 58px;',
        style2: 'width: 262px; height: 84px; background: #000000;'
      },
      img2: {
        url: require('@/assets/images/Benefits/Jeeves2.png'),
        classStyle: 'col-12 col-lg-6 col-xl-5 mt-0 px-0'
      },
      title: 'Todos los gastos bajo una sola plataforma',
      subTitle: 'Realice un seguimiento y administre los gastos globales facilmente en una sola plataforma.',
      text: '',
      url: 'https://www.tryjeeves.com/es',
      point: '',
      points: [
        'Tarjetas globales para su equipo en crecimiento',
        'Financiamiento amigable para los fundadores',
        'Soluciones globales para sus necesidades locales'
      ],
    },
    companyDocs: {
      title: 'Sube tus documentos',
      subTitle: 'Los necesitamos para crear tu cuenta empresarial',
      msj: {
        Title: 'Revisa tus documentos',
        Text: 'Deberás subir tus documentos originales, sin tachaduras ni enmendaduras y con fecha de expedición no mayor a 2 meses.'
      }
    },
    authorization: {
      title: 'Autorizas el envío de tus documentos',
      subTitle: '',
      protection: 'Tu información está protegida',
      authorization: ''
    },
    complete: {
      title: '¡Solicitud realizada con éxito!',
      subTitle: '',
      button: 'Finalizar',
      points: []
    }
  }

  @State('UserModule') state: any
}
