















































































































// Utils
import { Component, Vue } from "vue-property-decorator"
import { Action } from "vuex-class"
import { validationMixin } from "vuelidate"
// Helpers
import { Posthog } from '@/helpers/Posthog'
import { UTM } from '@/helpers/UTM'
// Validations
import validations from "@/validations/login"
// Components
import Footer from "@/components/Footer.vue"
import Loader from "@/components/Loader.vue"
// Types
import AuthTypes from "@/store/types/AuthTypes"
import UserTypes from "@/store/types/UserTypes"
import CompanyTypes from "@/store/types/CompanyTypes"

@Component({
  components: { Footer, Loader },
  mixins: [validationMixin],
  validations: validations,
})
export default class Login extends Vue {
  loading = false
  email = ''
  password = ''
  passwordFieldType = 'password'
  eye = 'eye'
  validEmail = true;
  validPassword = true;

  async created () {
    await this.clearData()
    await this.clearDataUser()
    Posthog.reset()
  }

  async mounted () {
    UTM.updateUTMs(this.$router, this.$route)
    UTM.setLocalUTMs(this.$router)
    window.google.accounts.id.initialize({
      client_id: process.env.VUE_APP_GOOGLE_ID,
      callback: this.handleCredentialResponse
    });
    window.google.accounts.id.renderButton(
      document.getElementById('buttonDiv'),
      { theme: 'outline', width: 300, logo_alignment: 'left' }
    );
    window.google.accounts.id.prompt();
  }

  switchVisibility () {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    this.eye = this.eye === 'eye' ? 'eye-slash' : 'eye'
  }

  async handleCredentialResponse (response: any) {
    if (!response || !response.credential) return
    try {
      this.loading = true;
      const [err] = await this.loginGoogle(response)
      if (err) {
        this.loading = false
        return this.$toasted.global.error(err)
      }
      return this.$router
        .push({ name: 'Welcome', query: this.$route.query })
        .catch(() => {
          return;
        });
    } catch (e) {
      this.loading = false
      return this.$toasted.global.error(e)
    }
  }

  validate (email: string, value: boolean) {
    this.validEmail = email === '' ? true : value
  }

  async login () {
    try {
      this.loading = true;
      const [err] = await this.loginUser({ email: this.email, password: this.password })
      if (err) {
        this.loading = this.validPassword = false
        return this.$toasted.global.error({ message: 'Credenciales invalidas' })
      }
      return this.$router
        .push({ name: 'Welcome', query: this.$route.query })
        .catch(() => {
          return;
        });
    } catch (e) {
      this.loading = this.validPassword = false
      return this.$toasted.global.error({ message: 'Credenciales invalidas' })
    }
  }

  @Action(`AuthModule/${AuthTypes.actions.LOGIN}`) loginUser: any;
  @Action(`AuthModule/${AuthTypes.actions.LOGINGOOGLE}`) loginGoogle: any;
  @Action(`CompanyModule/${CompanyTypes.actions.CLEAR_DATA}`) clearData: any;
  @Action(`UserModule/${UserTypes.actions.CLEAR_DATA}`) clearDataUser: any;
}
