export default {
  mutations: {
    SET_PAYLOAD: 'SET_TOKEN',
    REMOVE_TOKEN: 'REMOVE_TOKEN'
  },
  getters: {
    GET_PAYLOAD: 'GET_PAYLOAD'
  },
  actions: {
    SET_PAYLOAD: 'SET_PAYLOAD',
    REGISTER: 'REGISTER',
    REGISTERGOOGLE: 'REGISTERGOOGLE',
    REGISTERPARTNER: 'REGISTERPARTNER',
    REGISTERGOOGLEPARTNER: 'REGISTERGOOGLEPARTNER',
    LOGOUT: 'LOGOUT',
    LOGIN: 'LOGIN',
    LOGINGOOGLE: 'LOGINGOOGLE',
    REFRESH_TOKEN: 'REFRESH_TOKEN'
  }
}
