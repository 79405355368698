import store from '@/store'
import CommissaryTypes from '@/store/types/CommissaryTypes'

export default class Invited {
  static async validateCommissary (to: any, from: any, next: any) {
    if (to.query.company === undefined) return next({ name: 'Login', query: to.query })
    const [error, commissary] =  await store.dispatch(`CommissaryModule/${CommissaryTypes.actions.UPDATE_DATA}`, {
      company_id: to.query.company.toString()
    })
    if (error) return next({ name: 'Login', query: to.query })
    else if (to.name === 'CommissaryStart' && (commissary.isMetamapValidated === null || !commissary.isMetamapValidated)) return next()
    else if (to.name !== 'CommissaryComplementaries' && (commissary.isCSFValidated === null || !commissary.isCSFValidated)) return next({ name: 'CommissaryComplementaries', query: to.query })
    else if (to.name === 'CommissaryComplementaries' && (commissary.isCSFValidated === null || !commissary.isCSFValidated)) return next()
    else if (to.name !== 'CommissaryEnd') return next({ name: 'CommissaryEnd', query: to.query })
    else if (to.name === 'CommissaryEnd') return next()
    return next({ name: 'Login', query: to.query })
  }
}
