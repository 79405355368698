
















// Utils
import { Component, Vue } from 'vue-property-decorator'
// Components
import UserDropdown from '@/components/UserDropdown.vue'

@Component({
  components: { UserDropdown }
})
export default class Header extends Vue {
}
