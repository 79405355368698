import to from 'await-to-js'
import axios from '@/utils/axios-api'

const URL_PHP = process.env.VUE_APP_BASE_URL_PHP

export default class Partners {
  static async storeShares (company: string, total_shares: number, partners: Array<any>) {
    return await to(axios.post(`${URL_PHP}/v2/partners-shares/${company}`, {total_shares, partners})) as any
  }
  
  static async updateShares (company: string, total_shares: number, partners: Array<any>) {
    return await to(axios.put(`${URL_PHP}/v2/partners-shares-update/${company}`, {total_shares, partners})) as any
  }
}
