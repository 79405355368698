
























// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import HeaderProgressBar from '@/components/HeaderProgressBar.vue'
import InvitedPartnerCivilStatus from '@/components/invitedPartner/InvitedPartnerCivilStatus.vue'

@Component({
  components: { Footer, HeaderProgressBar, InvitedPartnerCivilStatus }
})
export default class CivilStatus extends Vue {
  @State('InvitedModule') state: any
}
