









// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import Benefit from '@/components/benefits/Benefit.vue'

@Component({
  components: { Footer, Benefit }
})
export default class Fondeadora extends Vue {
  benefitData: any = {
    name: 'Fondeadora',
    textReturn: 'Regresar',
    openAccount: {
      img1: {
        url: require('@/assets/images/Benefits/Fondeadora.png'),
        style1: 'width: 253px; height: 62px;',
        style2: ''
      },
      img2: {
        url: require('@/assets/images/Benefits/Fondeadora2.png'),
        classStyle: 'col-12 col-lg-6 mt-0 px-0'
      },
      title: 'La cuenta para tu negocio',
      subTitle: 'Rendimiento desde el primer peso',
      text: '',
      url: '',
      point: '',
      points: [
        'Tarjetas de débito Mastercard ilimitadas',
        'Transferencias SPEI ilimitadas las 24 hrs',
        'Usuarios ilimitados con permisos a la medida',
        'Rendimiento del 5% desde el primer peso',
        'Notificaciones en tiempo real de todas tus operaciones',
        'Acceso a beneficios de alianzas exclusivas'
      ],
    },
    companyDocs: {
      title: 'Sube tus documentos',
      subTitle: 'Los necesitamos para crear tu cuenta empresarial',
      msj: {
        Title: 'Revisa tus documentos',
        Text: 'Deberás subir tus documentos originales, sin tachaduras ni enmendaduras y con fecha de expedición no mayor a 2 meses.'
      }
    },
    authorization: {
      title: 'Autorizas el envío de tus documentos',
      subTitle: 'Enviaremos a Fondeadora tu información',
      protection: 'Tu información está protegida',
      authorization: 'Autoriza a Tally compartir mis documentos con Apoyo Multiple, SA de CV, SFP para la apertura de mi cuenta empresarial'
    },
    complete: {
      title: '¡Solicitud realizada con éxito!',
      subTitle: 'Así de fácil se abre una cuenta de banco con Tally y Fondeadora',
      button: 'Finalizar',
      points: [
        { title: 'Verifica tu correo', text: 'Recibirás un correo electrónico de info@negocios.fondeadora.com para validar tu dirección de correo y aceptar los términos y condiciones. Búscalo con este asunto: "Verifica tu correo electrónico en 1 clic."' },
        { title: 'Agenda fecha de firma', text: 'Programa la fecha de la firma para firmar tu contrato en tu domicilio.' },
        { title: '¡Todo listo!', text: 'Inicia sesión en tu nueva cuenta empresarial y empieza a cobrar.' }
      ],
    }
  }

  @State('UserModule') state: any
}
