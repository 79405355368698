






















































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
// Components
import HeaderProgressBar from '@/components/HeaderProgressBar.vue'
import ReturnAddress from '@/components/Return.vue'
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'
// Services
import UserService from '@/services/User'

@Component({
  components: { HeaderProgressBar, ReturnAddress, Footer, Loader }
})
export default class Options extends Vue {
  options = [{
      type: 'foreign',
      title: 'Soy Extranjero',
      text: 'Ninguno de los socios tiene ',
      textBold: 'RFC (número de identificación fiscal mexicano).',
      img: require('@/assets/images/typeSAPI.png'),
    },{
      type: 'mexican',
      title: 'Resido en México',
      text: 'Tú o alguno de tus socios es 100% mexicano o tiene tarjeta de residente y ',
      textBold: 'RFC válido y vigente.',
      img: require('@/assets/images/mexican.png'),
    }
  ]
  optionSelected = ''
  loader = false

  async mounted () {
    await UserService.storeUTMs()
  }

  async next () {
    this.loader = true
    await this.clearData()
    if (this.optionSelected === 'mexican') await this.setRepresentation(false)
    else await this.setRepresentation(true)
    return this.$router.push({ name: 'Register', query: this.$route.query })
  }

  @State('CompanyModule') state: any
  @Action(`CompanyModule/${CompanyTypes.actions.CLEAR_DATA}`) clearData: any;
  @Action(`CompanyModule/${CompanyTypes.actions.SET_REPRESENTATION}`) setRepresentation: any;
}
