













































































































































































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import { debounce } from 'lodash'
// Components
import Loader from '@/components/Loader.vue'
// Services
import SharesService from '@/services/Shares'
import PartnersService from '@/services/Partners'
import CountryService from '@/services/Country'
// Types
import UserTypes from '@/store/types/UserTypes'

@Component({
  components: { Loader }
})
export default class ModalEditDistribution extends Vue {
  modal: any = {
    showModalCompanyDistribution: false,
    checkDistribution: false,
    update: false,
  }
  loading = false
  addPartner = false
  numberSharesZero = false
  partners: any = []
  delete_partners: any = []
  nationalities = []
  countries = []
  total = ''
  partner = {
    name: '',
    first_names: '',
    last_names: '',
    email: '',
    phone: '',
    phone_country_code: '52',
    nationality: 'MX',
    id: '',
    validEmail: true,
    completeEmail: true,
    repeatedEmail: false,
    exist: false,
    loading: false
  }

  searchPartnerUser = debounce(async (partner: any, search: boolean) => {
    if (search) await this.existPartnerUser(partner)
  }, 2000)

  @Watch('state.company.council')
  async update () {
    this.getPartnersDistribution()
  }

  @Watch('modal.showModalCompanyDistribution')
  async updateAddPartner () {
    if (!this.modal.showModalCompanyDistribution) {
      this.addPartner = false
      this.setPartnerDefault()
      this.getPartnersDistribution()
    }
  }

  get documentsApproved () {
    const approved = this.state.company.stages.find((stage: any) => stage.stage_name === 'documents_approved').is_completed === true
    return approved
  }

  get isValidation () {
    const validation = this.state.company.stages.find((stage: any) => stage.stage_name === 'documents_approved').is_completed === true
    return validation
  }

  get totalPercentage () {
    let total = 0
    this.numberSharesZero = false
    this.partners.map((partner: any) => {
      total += Number(partner.percentage)
      if (Number(partner.numberShares.replace(/,/g, "").replace(/[^0-9]/g,'')) === 0) this.numberSharesZero = true
    })
    return total.toFixed(2)
  }

  get totalNumberShares () {
    let total = 0
    this.numberSharesZero = false
    this.partners.map((partner: any) => {
      total += Number(partner.numberShares.replace(/,/g, "").replace(/[^0-9]/g,''))
      if (Number(partner.numberShares.replace(/,/g, "").replace(/[^0-9]/g,'')) === 0) this.numberSharesZero = true
    })
    return total
  }

  get completePartners () {
    let complete = true
    if (this.partner.name === '' || this.partner.first_names === '' || this.partner.email === '' || !this.partner.completeEmail) complete = false
    return complete
  }

  created () {
    this.getCountries()
    this.getNationalities()
  }

  mounted () {
    this.modal = this.$attrs.value
    this.getPartnersDistribution()
  }

  setPartnerDefault () {
    this.partner = {
      name: '',
      first_names: '',
      last_names: '',
      email: '',
      phone: '',
      phone_country_code: '52',
      nationality: 'MX',
      id: '',
      validEmail: true,
      completeEmail: true,
      repeatedEmail: false,
      exist: false,
      loading: false
    }
  }

  noSpacesStart (value: string) { return value.trimStart() }

  onlyNumbers (value: string) { return value.replace(/[^0-9.]/g,'') }

  getPartnersDistribution () {
    let total = 0
    const commissary = this.state.company.council.filter((member: any) => member.council_role === 'commissary')
    const idCommissary = !commissary.length ? null : commissary[0].partner_id
    this.partners = []
    this.state.company.partners.map((partner: any) => {
      const shares = partner.shares.filter((shares: any ) => shares.serie.company_id === this.state.company.id)
      this.partners.push({
        name: partner.name + ' ' + partner.first_names + ' ' + partner.last_names,
        percentage: shares.length === 0 ? 0 : shares[0].percentage,
        numberShares: shares.length === 0 ? '1' : shares[0].number_of_shares.replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
        id: partner.id,
        isCommissary: idCommissary === null ? false : idCommissary === partner.id
      })
      // total += Number(partner.shares.length === 0 ? '0' : partner.shares[0].number_of_shares)
      total = shares.length === 0 ? total : shares[0].serie.total_shares_in_series
    })
    this.total = total.toString().replace(/\d(?=(?:\d{3})+$)/g, '$&,')
    this.updatePartnersDistribution()
  }

  updatePartnersDistribution () {
    this.partners.map((partner: any, index: number) => {
      this.partners[index].percentage = ((Number(partner.numberShares.replace(/,/g, "").replace(/[^0-9]/g,'')) * 100) / Number(this.total.replace(/,/g, "").replace(/[^0-9]/g,'')))
      if (partner.isCommissary && partner.percentage > 25) {
        partner.percentage = 25
        partner.numberShares = Math.trunc(Number(this.total.replace(/,/g, "").replace(/[^0-9]/g,'')) * (partner.percentage / 100)).toString().replace(/\d(?=(?:\d{3})+$)/g, '$&,')
      }
    })
  }

  updatePartnersDistributionAndNumberShares () {
    this.partners.map((partner: any, index: number) => {
      this.partners[index].numberShares = Math.trunc(Number(this.total.replace(/,/g, "").replace(/[^0-9]/g,'')) * (partner.percentage / 100)).toString().replace(/\d(?=(?:\d{3})+$)/g, '$&,')
    })
    this.partners.map((partner: any, index: number) => {
      this.partners[index].percentage = ((Number(partner.numberShares.replace(/,/g, "").replace(/[^0-9]/g,'')) * 100) / Number(this.total.replace(/,/g, "").replace(/[^0-9]/g,'')))
      if (partner.isCommissary && this.partners[index].percentage > 25) this.partners[index].percentage = 25
    })
    this.partners.map((partner: any, index: number) => { this.validateNumberShares(this.partners[index]) })
  }

  minOneShare (partner: any) {
    if (Number(partner.numberShares.replace(/,/g, "").replace(/[^0-9]/g,'')) === 0 || partner.numberShares === '') partner.numberShares = '1'
    this.updatePartnersDistribution()
  }

  validateNumberShares (partner: any) {
    if (this.totalNumberShares > Number(this.total.replace(/,/g, "").replace(/[^0-9]/g,''))) {
      partner.numberShares = (Number(partner.numberShares.replace(/,/g, "").replace(/[^0-9]/g,'')) - (this.totalNumberShares - Number(this.total.replace(/,/g, "").replace(/[^0-9]/g,'')))).toString().replace(/\d(?=(?:\d{3})+$)/g, '$&,')
    }
  }

  onlyNumbersPercentage (value: string) {
    value = value.replace(/[^0-9.]/g,'')
    return Number(value) > 100 ? '100' : Number(value) <= 0 ? '0' : Number(value).toString()
  }

  onlyNumbersActions (value: string) {
    value = value.replace(/,/g, "").replace(/[^0-9]/g,'')
    value = Number(value) > 100000 ? '100000' : value === '' ? '' : Number(value).toString()
    return value.replace(/\d(?=(?:\d{3})+$)/g, '$&,')
  }

  sharesUpAndDown (value: string, partner: any) {
    if (value === '+') partner.numberShares = (Number(partner.numberShares.replace(/,/g, "").replace(/[^0-9]/g,'')) + 1).toString().replace(/\d(?=(?:\d{3})+$)/g, '$&,')
    else partner.numberShares = (Number(partner.numberShares.replace(/,/g, "").replace(/[^0-9]/g,'')) - 1).toString().replace(/\d(?=(?:\d{3})+$)/g, '$&,')
    this.validateNumberShares(partner)
    this.updatePartnersDistribution()
  }

  deletePartner (id: string, index: number) {
    if (id !== '') this.delete_partners.push(id)
    this.partners.splice(index, 1)
  }

  validate (partner: any) {
    if (partner.email === '') return partner.validEmail = true
    const re = /\S+@\S+\.\S+/
    partner.validEmail = re.test(partner.email)
  }

  validateCompleteEmail (partner: any) {
    const re = /\S+@\S+\.\S+/
    partner.completeEmail = re.test(partner.email)
    this.partner.repeatedEmail = false
    this.state.company.partners.map((partner: any) => { if (partner.email.toLowerCase() === this.partner.email.toLowerCase()) this.partner.repeatedEmail = true })
    if (partner.completeEmail) {
      partner.loading = true
      partner.exist = partner.email !== this.state.email
      this.searchPartnerUser(partner, true)
    } else {
      partner.loading = false
      partner.exist = false
      this.searchPartnerUser(partner, false)
    }
  }

  async existPartnerUser (partner: any) {
    const [error, response] = await PartnersService.existPartnerUser(partner.email)
    if (error) {
      partner.loading = false
      partner.exist = false
    }
    partner.name = response.data.data.name
    partner.first_names = response.data.data.first_names !== null ? response.data.data.first_names : ''
    partner.last_names = response.data.data.last_names !== null ? response.data.data.last_names : ''
    partner.phone = response.data.data.phone !== null ? response.data.data.phone : ''
    partner.phone_country_code = response.data.data.phone_country_code !== null ? response.data.data.phone_country_code : '52'
    partner.loading = false
  }

  async getNationalities () {
    const [error, response] = await CountryService.nationalities()
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.loading = false
    this.nationalities = response.data.data
  }

  async getCountries () {
    const [error, response] = await CountryService.all()
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.loading = false
    this.countries = response.data.data
  }

  async updateDistribution () {
    this.loading = true
    let promise_error = false
    // Save delete partners
    if (this.delete_partners.length > 0) {
      this.modal.checkDistribution = true
      await Promise.all(
        this.delete_partners.map(async (partner: any) => {
          const [error] = await PartnersService.deletePartner(partner, this.state.company.id)
          if (error) {
            promise_error = true
            return this.$toasted.global.error(error)
          }
        })
      )
      if (promise_error) {
        await this.updateCompany(this.state.company.id)
        this.getPartnersDistribution()
        this.loading = false
        return promise_error = false
      }
      this.delete_partners = []
    }
    // Save distribution
    const actions = this.total.replace(/,/g, "").replace(/[^0-9]/g,'')
    const [error] = await SharesService.storeShares(this.state.company.id, Number(actions), this.partners)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    // Get new user info
    await this.updateCompany(this.state.company.id)
    this.modal.showModalCompanyDistribution = false
    this.modal.update = true
    this.modal.checkDistribution = false
    this.loading = false
  }

  async saveNewPartner () {
    this.loading = true
    const [error] = await PartnersService.storeMultiplePartners(this.state.company.id, [this.partner])
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    await this.updateCompany(this.state.company.id)
    this.addPartner = false
    this.setPartnerDefault()
    this.getPartnersDistribution()
    this.loading = false
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
