// Utils
import store from '@/store'
import UserTypes from '@/store/types/UserTypes'
// import moment from 'moment'
// Helpers
import { Posthog } from '@/helpers/Posthog'
import { Chat } from '@/helpers/ChatScript'

export default class User {
  static async validateAccounting (to: any, from: any, next: any) {
    const [err, getUser]: any = await store.dispatch(`UserModule/${UserTypes.actions.UPDATE_DATA}`)
    if (err) return next({ name: 'Login', query: to.query })
    const companiesInvitation = getUser.companies.map((company: any) => { return { ...company, invitation_accepted: true }})
    const all_companies = companiesInvitation.concat(getUser.company_as_a_partner.filter((company: any ) => company.user_id !== getUser.id))
    const all_companies_invitation_accepted = all_companies.filter((company: any ) => company.invitation_accepted)
    if ((all_companies_invitation_accepted.length === 0 || all_companies_invitation_accepted.length > 1) && to.params.company_id === undefined) return next({ name: 'Companies', query: to.query })
    const [error, company]: any = await store.dispatch(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`, to.params.company_id !== undefined ? to.params.company_id : all_companies_invitation_accepted[0].id)
    if (error) return next({ name: 'Login', query: to.query })
    if (!company.beta_accounting) return next({ name: 'Documents', query: to.query, params: { company_id: company.id } })
    return next()
  }
  static async validateCheckout (to: any, from: any, next: any) {
    const [err, getUser]: any = await store.dispatch(`UserModule/${UserTypes.actions.UPDATE_DATA}`)
    if (err) return next({ name: 'Login', query: to.query })
    const companiesInvitation = getUser.companies.map((company: any) => { return { ...company, invitation_accepted: true }})
    const all_companies = companiesInvitation.concat(getUser.company_as_a_partner.filter((company: any ) => company.user_id !== getUser.id))
    const all_companies_invitation_accepted = all_companies.filter((company: any ) => company.invitation_accepted)
    if ((all_companies_invitation_accepted.length === 0 || all_companies_invitation_accepted.length > 1) && to.params.company_id === undefined) return next({ name: 'Companies', query: to.query })
    const [error, company]: any = await store.dispatch(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`, to.params.company_id !== undefined ? to.params.company_id : all_companies_invitation_accepted[0].id)
    if (error) return next({ name: 'Login', query: to.query })
    const orderComplete = company.orders.filter((order: any) => order.status === 'complete')
    const checkoutComplete = company.orders.filter((order: any) => order.status === 'complete' && order.items.filter((item: any) => item.name === 'Complemento Acta Constitutiva').length > 0).length > 0
    const checkoutCommissaryComplete = company.orders.filter((order: any) => order.status === 'complete' && order.items.filter((item: any) => item.name === 'Servicio de Comisario').length > 0).length > 0
    if (to.name === 'Payment' && company.legal_representation && !orderComplete.length) return next({ name: 'PaymentRepresentation', query: to.query, params: { company_id: company.id } })
    if (to.name === 'Payment' && !company.legal_representation && orderComplete.length) return next({ name: 'Welcome', query: to.query, params: { company_id: company.id } })
    else if (to.name === 'PaymentRepresentation' && !company.legal_representation && !orderComplete.length) return next({ name: 'Payment', query: to.query, params: { company_id: company.id } })
    else if (to.name === 'PaymentRepresentation' && company.legal_representation && orderComplete.length) return next({ name: 'Welcome', query: to.query, params: { company_id: company.id } })
    else if (to.name === 'Checkout' && !company.legal_representation && checkoutComplete) return next({ name: 'Welcome', query: to.query, params: { company_id: company.id } })
    else if (to.name === 'Checkout' && company.legal_representation) return next({ name: 'Welcome', query: to.query, params: { company_id: company.id } })
    else if ((to.name === 'CheckoutCommissary' || to.name === 'CheckoutAccountant') && !company.legal_representation && checkoutCommissaryComplete) return next({ name: 'Welcome', query: to.query, params: { company_id: company.id } })
    else if ((to.name === 'CheckoutCommissary' || to.name === 'CheckoutAccountant') && company.legal_representation) return next({ name: 'Welcome', query: to.query, params: { company_id: company.id } })
    return next()
  }
  static async validateData (to: any, from: any, next: any) {
    const [err, getUser]: any = await store.dispatch(`UserModule/${UserTypes.actions.UPDATE_DATA}`)
    if (err) return next({ name: 'Login', query: to.query })
    if (to.name === 'Account') return next()
    const companiesInvitation = getUser.companies.map((company: any) => { return { ...company, invitation_accepted: true }})
    const all_companies = companiesInvitation.concat(getUser.company_as_a_partner.filter((company: any ) => company.user_id !== getUser.id))
    const all_companies_invitation_accepted = all_companies.filter((company: any ) => company.invitation_accepted)
    // User is confirmed
    if ((to.name === 'VerifyAccount' || to.name === 'Companies') && (to.params.company_id === undefined || to.params.company_id === '') && getUser) Posthog.identify(getUser.id.toString(), getUser)
    if (to.name !== 'VerifyAccount' && !getUser.is_confirmed) return next({ name: 'VerifyAccount', query: to.query })
    else if (to.name === 'VerifyAccount' && getUser.is_confirmed) return next({ name: 'Welcome', query: to.query })
    else if (to.name === 'VerifyAccount' && !getUser.is_confirmed) return next()
    // Exists companies
    if ((to.params.company_id === undefined || to.params.company_id === '') && to.name === 'Companies' && (all_companies_invitation_accepted.length === 0 || all_companies_invitation_accepted.length > 1)) return next()
    if ((to.params.company_id === undefined || to.params.company_id === '') && (all_companies_invitation_accepted.length === 0 || all_companies_invitation_accepted.length > 1)) return next({ name: 'Companies', query: to.query })
    const [error, company]: any = await store.dispatch(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`, (to.params.company_id !== undefined && to.params.company_id !== '') ? to.params.company_id : all_companies_invitation_accepted[0].id)
    if (error) return next({ name: 'Login', query: to.query })
    const user = store.getters[`UserModule/${UserTypes.getters.GET_DATA}`]
    Posthog.identify(user.id.toString(), user)
    const orderComplete = company.orders.filter((order: any) => order.status === 'complete')
    if (orderComplete.length) Chat.bodySet('/chatHilos.js', '', 'module')
    if (company === null && to.name !== 'Before')  return next({ name: 'Before', query: to.query, params: { company_id: company.id } })
    else if (company === null && to.name === 'Before') return next()
    return next()
  }
  static async welcomeValidateData (to: any, from: any, next: any) {
    const [err, user]: any = await store.dispatch(`UserModule/${UserTypes.actions.UPDATE_DATA}`)
    if (err) return next({ name: 'Login', query: to.query })
    Posthog.identify(user.id.toString(), user)
    if (!user.is_confirmed) return next({ name: 'VerifyAccount', query: to.query })
    const companiesInvitation = user.companies.map((company: any) => { return { ...company, invitation_accepted: true }})
    const all_companies = companiesInvitation.concat(user.company_as_a_partner.filter((company: any ) => company.user_id !== user.id))
    const all_companies_invitation_accepted = all_companies.filter((company: any ) => company.invitation_accepted)
    if ((to.params.company_id === undefined || to.params.company_id === '') && (all_companies_invitation_accepted.length === 0 || all_companies_invitation_accepted.length > 1)) return next({ name: 'Companies', query: to.query })
    const [error, company]: any = await store.dispatch(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`, (to.params.company_id !== undefined && to.params.company_id !== '') ? to.params.company_id : all_companies_invitation_accepted[0].id)
    if (error) return next({ name: 'Login', query: to.query })
    if (company === null) return next({ name: 'Before', query: to.query, params: { company_id: company.id } })
    const partner = company.partners.filter((partner: any) => partner.email === user.email)
    let partnerV3 = false
    company.partners.map((partner: any ) => {
      const partnerShares = partner.shares.filter((shares: any) => shares.serie.company_id === company.id)
      const sharesV3 = partnerShares.filter((sharesAux: any) => sharesAux.percentage === null)
      if (partnerShares.length === 0 || sharesV3.length > 0) partnerV3 = true
    })
    // const CFI =  partner.length > 0 && partner[0].cfi.document_url !== null && partner[0].cfi.rejection_category === null
    const orderComplete = company.orders.filter((order: any) => order.status === 'complete')
    const checkoutComplete = company.orders.filter((order: any) => order.status === 'complete' && order.items.filter((item: any) => item.name === 'Complemento Acta Constitutiva').length > 0).length > 0
    const documentsApproved = company.stages.find((stage: any) => stage.stage_name === 'documents_approved').is_completed === true
    const completeFirms = company.stages.find((stage: any) => stage.stage_name === 'signing_complete').is_completed === true
    let commissary = false
    company.council.map((partner: any) => {
      if (partner.council_role === 'commissary') commissary = true
    })
    // const existAddress = (partner.length === 0 || partner[0].address_identity.document_url === null) ? false : partner[0].address_identity.rejection_category !== null
    if (completeFirms) return next({ name: 'Dashboard', query: to.query, params: { company_id: company.id } })
    else if (company.legal_representation) {
      if (company.where_we_met === null) return next({ name: 'HearAbout', query: to.query, params: { company_id: company.id } })
      else if (!orderComplete.length && (company.subsidiary === null || company.business_twists === null || company.business_twists.length === 0)) return next({ name: 'BeginningBeforePayment', query: to.query, params: { company_id: company.id } })
      else if (!orderComplete.length) return next({ name: 'PaymentRepresentation', query: to.query, params: { company_id: company.id } })
    } else if (!company.legal_representation) {
      if (company.where_we_met === null && !company.testimony && !company.partners.length) return next({ name: 'HearAbout', query: to.query, params: { company_id: company.id } })
      else if (!orderComplete.length && (company.business_twists === null || company.business_twists.length === 0)) return next({ name: 'BeginningBeforePayment', query: to.query, params: { company_id: company.id } })
      else if (!orderComplete.length) return next({ name: 'Payment', query: to.query, params: { company_id: company.id } })
      else if (!company.testimony && !company.partners.length) return next({ name: 'PaymentComplete', query: to.query, params: { company_id: company.id } })
      else if (!company.testimony && !documentsApproved && !commissary) return next({ name: 'Beginning', query: to.query, params: { company_id: company.id } })
    }
    if (!documentsApproved && !company.testimony && (company.business_twists === null || company.business_twists.length === 0)) return next({ name: 'Beginning', query: to.query, params: { company_id: company.id } })
    else if (!company.testimony && !company.share_series.length) return next({ name: 'Beginning', query: to.query, params: { company_id: company.id } })
    else if (!company.testimony && partnerV3) return next({ name: 'EditAllPartners', query: to.query, params: { company_id: company.id } })
    else if (partner.length > 0) {
      // Civil status
      const civilStatus = partner[0].civil_status !== null && partner[0].civil_status !== ''
      if (!documentsApproved && (!partner[0].rfc || !civilStatus)) return next({ name: 'isPartnerStart', query: to.query, params: { company_id: company.id } })
    }
    // else if (!documentsApproved && partner.length > 0 && (!partner[0].rfc || !partner[0].documents.length || !CFI || !existAddress)) return next({ name: 'isPartnerStart', query: to.query, params: { company_id: company.id } })
    // else if (!documentsApproved && (partner.length > 0 && partner[0].mati_verification === null)) return next({ name: 'isPartnerVerification', query: to.query, params: { company_id: company.id } })
    else if (!company.legal_representation && documentsApproved && !checkoutComplete) return next({ name: 'Dashboard', query: to.query, params: { company_id: company.id } })
    return next({ name: 'Dashboard', query: to.query, params: { company_id: company.id } })
  }
  static async dashboardValidateData (to: any, from: any, next: any) {
    const [err, user]: any = await store.dispatch(`UserModule/${UserTypes.actions.UPDATE_DATA}`)
    if (err) return next({ name: 'Login', query: to.query })
    if (!user.is_confirmed) return next({ name: 'VerifyAccount', query: to.query })
    const companiesInvitation = user.companies.map((company: any) => { return { ...company, invitation_accepted: true }})
    const all_companies = companiesInvitation.concat(user.company_as_a_partner.filter((company: any ) => company.user_id !== user.id))
    const all_companies_invitation_accepted = all_companies.filter((company: any ) => company.invitation_accepted)
    if ((to.params.company_id === undefined || to.params.company_id === '') && (all_companies_invitation_accepted.length === 0 || all_companies_invitation_accepted.length > 1)) return next({ name: 'Companies', query: to.query })
    const [error, company]: any = await store.dispatch(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`, (to.params.company_id !== undefined && to.params.company_id !== '') ? to.params.company_id : all_companies_invitation_accepted[0].id)
    if (error) return next({ name: 'Login', query: to.query })
    if (company === null) return next({ name: 'Before', query: to.query, params: { company_id: company.id } })
    // Exist partner and version
    const partner = company.partners.filter((partner: any) => partner.email === user.email)
    let partnerV3 = false
    company.partners.map((partner: any ) => {
      const partnerShares = partner.shares.filter((shares: any) => shares.serie.company_id === company.id)
      const sharesV3 = partnerShares.filter((sharesAux: any) => sharesAux.percentage === null)
      if (partnerShares.length === 0 || sharesV3.length > 0) partnerV3 = true
    })
    // Payment and stages
    const orderComplete = company.orders.filter((order: any) => order.status === 'complete')
    const documentsApproved = company.stages.find((stage: any) => stage.stage_name === 'documents_approved').is_completed === true
    // Commissary
    let commissary = false
    company.council.map((partner: any) => {
      if (partner.council_role === 'commissary') commissary = true
    })
    if (company.legal_representation) {
      if (company.where_we_met === null) return next({ name: 'HearAbout', query: to.query, params: { company_id: company.id } })
      else if (!orderComplete.length && (company.subsidiary === null || company.business_twists === null || company.business_twists.length === 0)) return next({ name: 'BeginningBeforePayment', query: to.query, params: { company_id: company.id } })
      else if (!orderComplete.length) return next({ name: 'PaymentRepresentation', query: to.query, params: { company_id: company.id } })
    } else if (!company.legal_representation) {
      if (company.where_we_met === null && !company.testimony && !company.partners.length) return next({ name: 'HearAbout', query: to.query, params: { company_id: company.id } })
      else if (!orderComplete.length && (company.business_twists === null || company.business_twists.length === 0)) return next({ name: 'BeginningBeforePayment', query: to.query, params: { company_id: company.id } })
      else if (!orderComplete.length) return next({ name: 'Payment', query: to.query, params: { company_id: company.id } })
      else if (!company.testimony && !company.partners.length) return next({ name: 'PaymentComplete', query: to.query, params: { company_id: company.id } })
      else if (!company.testimony && !documentsApproved && !commissary) return next({ name: 'Beginning', query: to.query, params: { company_id: company.id } })
    }
    if (!documentsApproved && !company.testimony && (company.business_twists === null || company.business_twists.length === 0)) return next({ name: 'Beginning', query: to.query, params: { company_id: company.id } })
    else if (!company.testimony && !company.share_series.length) return next({ name: 'Beginning', query: to.query, params: { company_id: company.id } })
    else if (!company.testimony && partnerV3) return next({ name: 'EditAllPartners', query: to.query, params: { company_id: company.id } })
    else if (partner.length > 0) {
      // Civil status
      const civilStatus = partner[0].civil_status !== null && partner[0].civil_status !== ''
      /* const marriage = civilStatus && partner[0].civil_status === 'casado'
      const uploadMarriage = marriage &&
        partner[0].id_spouse.document_url !== null && partner[0].id_spouse_back.document_url !== null && partner[0].marriage_certificate.document_url !== null &&
        partner[0].id_spouse.rejection_category === null && partner[0].id_spouse_back.rejection_category === null && partner[0].marriage_certificate.rejection_category === null
      const pendingCivilStatus = !civilStatus || (marriage && !uploadMarriage) */
      // let matiCheck = false
      // if (!partner[0].mati_verification && partner[0].mati_status && partner[0].mati_status.length && (partner[0].mati_status[0].status === 'Reject' || partner[0].mati_status[0].status === 'Denied')) matiCheck = true
      // Partner[0] nationality
      // const foreign = partner[0].nationality !== 'México' && partner[0].nationality !== 'MX'
      // Pending Docs
      // const CFI =  partner[0].cfi.document_url !== null && partner[0].cfi.rejection_category === null
      // const addressDocument =  partner[0].address_identity.document_url !== null && partner[0].address_identity.rejection_category === null
      if ((from.name !== null && from.name !== 'isPartnerCivilStatus') && !documentsApproved && (!partner[0].rfc || !civilStatus /* pendingCivilStatus || !partner[0].documents.length || !CFI || (foreign && partner[0].rfc === 'EXTF900101NI1' && !addressDocument)*/)) return next({ name: 'isPartnerStart', query: to.query, params: { company_id: company.id } })
      // else if (!documentsApproved && (partner[0].mati_verification === null || matiCheck)) return next({ name: 'isPartnerVerification', query: to.query, params: { company_id: company.id } })
    }
    return next()
  }
}
