import { GetterTree, ActionTree, MutationTree } from 'vuex'
import State from '@/store/interfaces/UTM'
import UTMTypes from '@/store/types/UTMTypes'

const keyLocalStorage = 'UTMData'

function updateLocalStorage (state: State) {
  localStorage.setItem(keyLocalStorage, JSON.stringify(state))
}

const state: State = JSON.parse(
  localStorage.getItem(keyLocalStorage) ||
  JSON.stringify({
    utm_id: '',
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_term: '',
    utm_content: ''
  })
)

const getters: GetterTree<State, any> = {
  [UTMTypes.getters.GET_DATA]: (stateGet) => stateGet
}

const mutations: MutationTree<State> = {
  [UTMTypes.mutations.CLEAR_DATA]: async () => {
    state.utm_id = ''
    state.utm_source = ''
    state.utm_medium = ''
    state.utm_campaign = ''
    state.utm_term = ''
    state.utm_content = ''
    updateLocalStorage(state)
  },
  [UTMTypes.mutations.UTM_ID]: async (stateMut, payload: string) => {
    state.utm_id = payload
    updateLocalStorage(state)
  },
  [UTMTypes.mutations.UTM_SOURCE]: async (stateMut, payload: string) => {
    state.utm_source = payload
    updateLocalStorage(state)
  },
  [UTMTypes.mutations.UTM_MEDIUM]: async (stateMut, payload: string) => {
    state.utm_medium = payload
    updateLocalStorage(state)
  },
  [UTMTypes.mutations.UTM_CAMPAIGN]: async (stateMut, payload: string) => {
    state.utm_campaign = payload
    updateLocalStorage(state)
  },
  [UTMTypes.mutations.UTM_TERM]: async (stateMut, payload: string) => {
    state.utm_term = payload
    updateLocalStorage(state)
  },
  [UTMTypes.mutations.UTM_CONTENT]: async (stateMut, payload: string) => {
    state.utm_content = payload
    updateLocalStorage(state)
  },
  [UTMTypes.mutations.UTMs]: async (stateMut, payload: State) => {
    state.utm_id = payload.utm_id
    state.utm_source = payload.utm_source
    state.utm_medium = payload.utm_medium
    state.utm_campaign = payload.utm_campaign
    state.utm_term = payload.utm_term
    state.utm_content = payload.utm_content
    updateLocalStorage(state)
  }
}

const actions: ActionTree<State, any> = {
  [UTMTypes.actions.CLEAR_DATA]: async ({ commit }) => {
    await commit(UTMTypes.mutations.CLEAR_DATA)
    return [null]
  },
  [UTMTypes.actions.UTM_ID]: async ({ commit }, payload: string) => {
    await commit(UTMTypes.mutations.UTM_ID, payload)
    return [null]
  },
  [UTMTypes.actions.UTM_SOURCE]: async ({ commit }, payload: string) => {
    await commit(UTMTypes.mutations.UTM_SOURCE, payload)
    return [null]
  },
  [UTMTypes.actions.UTM_MEDIUM]: async ({ commit }, payload: string) => {
    await commit(UTMTypes.mutations.UTM_MEDIUM, payload)
    return [null]
  },
  [UTMTypes.actions.UTM_CAMPAIGN]: async ({ commit }, payload: string) => {
    await commit(UTMTypes.mutations.UTM_CAMPAIGN, payload)
    return [null]
  },
  [UTMTypes.actions.UTM_TERM]: async ({ commit }, payload: string) => {
    await commit(UTMTypes.mutations.UTM_TERM, payload)
    return [null]
  },
  [UTMTypes.actions.UTM_CONTENT]: async ({ commit }, payload: string) => {
    await commit(UTMTypes.mutations.UTM_CONTENT, payload)
    return [null]
  },
  [UTMTypes.actions.UTMs]: async ({ commit }, payload: State) => {
    await commit(UTMTypes.mutations.UTMs, payload)
    return [null]
  }
}

export default { namespaced: true, state, getters, mutations, actions }
