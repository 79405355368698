



















// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
// Services
import CompanyService from '@/services/Company'

@Component({
  components: { Loader }
})
export default class ModalTaxes extends Vue {
  @Prop({ default: null }) taxes!: any;
  copys: any = {
    title: 'Revisa tus impuestos',
    text: 'Revisa la información y apruébelos para realizar tu línea de captura',
    buttonText: 'Aprobar',
    actionText: 'Aclarar dudas',
    actionText2: 'Ver detalle',
    taxes: [
      { type: 'income', name: 'Tus ingresos fueron: ', value: '0'},
      { type: 'bills', name: 'Tus gastos fueron:', value: '0'},
      { type: 'iva', name: 'IVA:', value: '0'},
      { type: 'isr', name: 'ISR:', value: '0'},
      { type: 'retention_isr', name: 'Retención de ISR:', value: '0'},
      { type: 'retention_iva', name: 'Retención de IVA:', value: '0'},
      { type: 'emission_imss', name: 'Emisión (IMSS):', value: '0'},
      { type: 'total', name: 'Total: ', value: '0'}
    ]
  }
  modal: any = {
    showModalTaxes: false,
    update: false
  }

  mounted () {
    this.modal = this.$attrs.value
    let total = 0
    if (this.taxes) this.copys.taxes.map((tax: any, index: number) => {
      if (tax.type !== 'total' && tax.type !== 'income' && tax.type !== 'bills') {
        let taxValue = this.taxes[tax.type]
        let taxValueDecimals = taxValue.split(".").length === 1 ? '.00' : ('.' + taxValue.split(".")[1])
        this.copys.taxes[index].value = Math.floor(Number(taxValue)).toString().replace(/\d(?=(?:\d{3})+$)/g, '$&,') + taxValueDecimals
        total += Number(taxValue)
      } else if (tax.type === 'income' || tax.type === 'bills') {
        let taxValue = this.taxes[tax.type]
        let taxValueDecimals = taxValue.split(".").length === 1 ? '.00' : ('.' + taxValue.split(".")[1])
        this.copys.taxes[index].value = Math.floor(Number(taxValue)).toString().replace(/\d(?=(?:\d{3})+$)/g, '$&,') + taxValueDecimals
      } else {
        this.copys.taxes[index].value =  Math.floor(total).toString().replace(/\d(?=(?:\d{3})+$)/g, '$&,')
      }
    })
  }

  async approvedTaxes () {
    // eslint-disable-next-line
    const [error, response] = await CompanyService.updateTaxDeclaration(this.taxes.id, true)
    if (error) return this.$toasted.global.error(error)
    this.modal.showModalTaxes = false
    this.modal.update = true
    return
  }

  @State('UserModule') state: any
}
