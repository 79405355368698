import to from 'await-to-js'
import axios from '@/utils/axios-api'

const URL_PHP = process.env.VUE_APP_BASE_URL_PHP

export default class Email {
  static async newEmail (id_user: string, email: string) {
    return await to(axios.post(`${URL_PHP}/v2/update-email/${id_user}`, { email })) as any
  }
  
  static async updateEmail (id_user: string, email: string, verification_code: number) {
    return await to(axios.post(`${URL_PHP}/v2/verification-update-email/${id_user}`, { email, verification_code })) as any
  }
}