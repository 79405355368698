





























































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
// Types
import UserTypes from '@/store/types/UserTypes'
// Components
import ModalCommissaryTallyQuit from '@/components/payment/ModalCommissaryTallyQuit.vue'

@Component({
  components: { ModalCommissaryTallyQuit }
})
export default class CardCheckoutItemsForeigns extends Vue {
  pay: any = {
    items: [{
      id: '',
      name: '',
      price: '',
      price_id: '',
      price_annual: '',
      price_annual_id: ''
    }],
    selected: [{
      id: '',
      item: '',
      cost: '',
      quantity: 0,
      price_id: ''
    }],
    update: false
  }
  items: any = [{
      id: '',
      name: 'Softlanding',
      price: '',
      price_id: '',
      price_annual: '',
      price_annual_id: '',
      check: false,
      msj: '',
      points: [
        { format: '', text: 'Constitución de empresa',
          points: [
            'Redacción de estatutos',
            'Gastos notariales',
            'Derechos de inscripción en el registro público',
            'Únicamente accionistas persona física'
          ]
        },
        { format: '', text: 'Representación legal ante el SAT',
          points: [
            'Apoderado legal ante la autoridad fiscal',
            'Obtención de RFC y eFirma',
            'Domicilio fiscal para oír y recibir notificaciones'
          ]
        },
        { format: '', text: 'Apertura de cuenta de banco',
          points: [
            'Cuenta en pesos en BBVA',
            'Cuenta en dólares en BBVA'
          ]
        },
        { format: '', text: 'Registro en Inversión Extranjera',
          points: [
            'Inscripción en el Registro Nacional de Inversión Extranjera',
          ]
        },
        { format: '', text: 'Contabilidad e impuestos',
          points: [
            'Te asignamos a un contador experto.',
            'Cálculo y declaraciones de impuestos mensual (IVA e ISR)',
            'Declaración de impuestos anual',
            'Asesoría fiscal básica',
            'Facturación'
          ]
        }
      ]
    },{
      id: '',
      name: 'Import/Export Plus',
      price: '',
      price_id: '',
      price_annual: '',
      price_annual_id: '',
      check: false,
      msj : '*No incluye trámites migratorios, licitaciones públicas, obtención de licencias o permisos.',
      points: [
        { format: '', text: 'Onboarding en Mercado Libre México y Amazon México',
          points: [
            'Asesoría para apertura de cuenta en las principales plataformas',
          ]
        },
        { format: '', text: 'Alta en Padrón de Importadores y Exportadores',
          points: [
            'Alta en Padrón General y Sectorial',
          ]
        },
        { format: '', text: 'Gestionamos tu transporte de carga de principio a fin',
          points: [
            'Transporta carga marítima, aérea o terrestre',
            'Usa contenedores, pallets o cajas',
            'Mueve de puerto a puerto, multimodal o sumando traslado terrestre'
          ]
        },
        { format: '', text: 'Asesoría integral de comercio exterior',
          points: [
            'Determinación de clasificación arancelaria',
            'Cálculo de contribuciones',
            'Trámites de regulaciones (NOMs)',
            'Restricciones no arancelarias'
          ]
        }
      ]
    }
  ]
  packageSelect = 'Softlanding'
  packagePrice = ''
  packagePriceAnnual = ''
  open = false
  open2 = false
  plus = false
  planMonthly = true
  currency = ['MXN', 'USD']
  coin = 'MXN'
  rate = 0.0585

  get selected () {
    let select = this.items.filter((item: any) => item.check)[0]
    if (select === undefined) select = this.items.filter((item: any) => item.name === this.packageSelect)[0]
    return select
  }

  mounted () {
    this.pay = this.$attrs.value
    this.pay.items.map((item1: any) => {
      this.items.map((item2: any, index: number) => {
        if (item1.name === item2.name) {
          this.items[index].id = item1.id
          this.items[index].price = item1.price
          this.items[index].price_id = item1.price_id
          this.items[index].price_annual = item1.price_annual
          this.items[index].price_annual_id = item1.price_annual_id
        }
      })
    })
    this.items.map((item: any, index: number) => {
      if (item.name !== this.pay.selected[0].item) this.items[index].check = false
      else this.items[index].check = true
    })
  }

  priceFormat (price: string, type: string) {
    let newPrice = Number(price.replace(/,/g, "").replace(/[^0-9]/g,''))
    if (type === 'div') newPrice = Math.trunc(newPrice/12)
    else if (type === 'mlt') newPrice = Math.trunc(newPrice * 12)
    if (this.coin === 'USD') return Math.trunc(newPrice * this.rate).toString().replace(/\d(?=(?:\d{3})+$)/g, '$&,')
    return newPrice.toString().replace(/\d(?=(?:\d{3})+$)/g, '$&,')
  }

  replacePackage (itemName: string, type: string) {
    this.packageSelect = itemName
    this.planMonthly = type === 'month'
    this.open = false
    const item = this.items.filter((item: any) => item.name === itemName)[0]
    const newItem = type === 'month' ? { id: item.id, item: item.name, cost: item.price, quantity: 1, price_id: item.price_id } : { id: item.id, item: item.name, cost: item.price_annual, quantity: 1, price_id: item.price_annual_id }
    this.pay.selected.unshift(newItem)
    this.pay.selected.splice(1, 1)
    this.items.map((item: any, index: number) => {
      if (item.name !== this.pay.selected[0].item) this.items[index].check = false
      else this.items[index].check = true
    })
    return
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
