





























































// Utils
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class CommissaryEnd extends Vue {
}
