




















// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import Buttons from '@/components/benefits/Buttons.vue'
import CardsBenefits from '@/components/benefits/CardsBenefits.vue'

@Component({
  components: { Footer, Buttons, CardsBenefits }
})
export default class Benefits extends Vue {
  buttons = [
    { name: 'Todos', type: 'all', active: true },
    { name: 'Cuenta empresarial', type: 'business account', active: false },
    { name: 'Pagos y facturación', type: 'Payments and billing', active: false },
    { name: 'Tarjeta de crédito', type: 'Credit card', active: false },
    { name: 'Tecnología', type: 'Technology', active: false },
    { name: 'Oficina', type: 'Office', active: false },
    { name: 'Reclutamiento', type: 'Recruitment', active: false },
  ]

  @State('UserModule') state: any
}
