































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
// Components
import HeaderProgressBar from '@/components/HeaderProgressBar.vue'
import ReturnAddress from '@/components/Return.vue'
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
import EntityType from '@/components/beginning/EntityType.vue'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'

@Component({
  components: { HeaderProgressBar, ReturnAddress, Footer, Loader, EntityType }
})
export default class TypeSociety extends Vue {
  type_Society: any = { company_type: '' }
  loading = false

  created () {
    this.type_Society.company_type = this.state.type_Society
  }

  async next () {
    this.loading = true
    await this.setTypeSociety(this.type_Society.company_type)
    return this.$router.push({ name: 'LegalName', query: this.$route.query })
  }

  @State('CompanyModule') state: any
  @Action(`CompanyModule/${CompanyTypes.actions.SET_TYPE_SOCIETY}`) setTypeSociety: any;
}
