




































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import ModalEditCommissary from '@/components/resumen/modals/ModalEditCommissary.vue'
// Services
import AdministrationService from '@/services/Administration'

@Component({
  components: { ModalEditCommissary }
})
export default class CardCommissary extends Vue {
  modal: any = {
    showModalCommissary: false,
    update: false,
  }
  editActive = false
  loading = true
  commissary = {name: '', id: ''}
  statusCommissary = ''
  commissaryPartner = false

  @Watch('modal.update')
  async update () {
    if(!this.modal.update) return
    this.existCommissary()
    this.modal.update = false
  }

  created () {
    const articlesMock = this.state.company.stages.find((stage: any) => stage.stage_name === 'company_articles_mock').is_completed === true
    if (!articlesMock) this.editActive = true
    this.existCommissary()
  }

  existCommissary () {
    this.state.company.council.map((member: any) => {
      if (member.council_role === 'commissary') {
        this.commissary.id = member.id
        this.commissary.name = this.state.company.commissary
        this.commissaryPartner = member.other_faculty === 'Partner'
      }
    })
    if (!this.commissaryPartner) this.getCommissary()
  }

  getCardStatus (style: boolean) {
    const CSF = this.state.company.commissary_CSF
    if (this.statusCommissary === 'Pending' || CSF.document_url === null) return style ? 'card-status-pending' : 'Pendiente'
    if (this.statusCommissary === 'Denied' || this.statusCommissary === 'rejected' || (!CSF.is_approved && CSF.rejection_category)) return style ? 'card-status-refused' : 'Rechazado'
    if (this.statusCommissary === 'Validating' || (CSF.document_url !== null && !CSF.is_approved && !CSF.rejection_category)) return style ? 'card-status-validate' : 'Revisando'
    if (this.statusCommissary === 'Complete' && CSF.is_approved) return style ? 'card-status-complete' : 'Completado'
    return ''
  }

  async getCommissary () {
    const [error, response] = await AdministrationService.getCommissaryStatus(this.state.company.id)
    if (error) {
      this.loading = false
      this.statusCommissary = 'Pending'
      return this.$toasted.global.error(error)
    }
    this.loading = false
    this.statusCommissary = response.data === '' ? 'Pending' : response.data
  }

  @State('UserModule') state: any
}
