import to from 'await-to-js'
import axios from '@/utils/axios-api'

const URL_PHP = process.env.VUE_APP_BASE_URL_PHP

export default class Calendly {
  static async getCalendlyURL (company: string) {
    return await to(axios.get(`${URL_PHP}/v2/calendly_link/${company}`)) as any
  }

  static async getCalendly (company: string) {
    return await to(axios.get(`${URL_PHP}/v2/get-event-calendly/${company}`)) as any
  }

  static async setCalendly (company: string, url: string) {
    return await to(axios.post(`${URL_PHP}/v2/store-event-calendly/${company}`,{ url })) as any
  }
}