















// Utils
import { Component, Vue } from 'vue-property-decorator'

@Component({
})
export default class ModalFileNoPDF extends Vue {
  modal: any = {
    showModalFileNoPDF: false
  }

  mounted () {
    this.modal = this.$attrs.value
  }
}
