















// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'

@Component({
  components: { Loader }
})
export default class ModalRnieQuit extends Vue {
  modal: any = {
    showModalRnieQuit: false,
    updateRnie: false
  }

  mounted () {
    this.modal = this.$attrs.value
  }

  @State('UserModule') state: any
}
