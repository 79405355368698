



































// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
// Services
import SATService from '@/services/SAT'
import CompanyService from '@/services/Company'

@Component({
  components: { Loader }
})
export default class OpenAccount extends Vue {
  @Prop({ default: '' }) service!: string
  @Prop({ default: '' }) title!: string
  @Prop({ default: '' }) subTitle!: string
  @Prop({ default: '' }) url!: string
  @Prop({ default: '' }) text!: string
  @Prop({ default: [] }) points!: Array<string>
  account: any = { name: '', create: false, complete: false }
  modal: any = { showModalAddCompanyCSF: false, update: false }
  loading = false
  fielsOK = false

  async created () {
    this.account = this.$attrs.value
    this.setComplete()
    // await this.getFielStatus()
  }

  setComplete () {
    const complete = this.state.company.banking_services.filter((service: any ) => service.name === this.account.name)
    if (complete.length) return this.account.complete = true
  }

  async getFielStatus () {
    this.loading = true
    const [error, response] = await SATService.getFielStatus(this.state.company.id)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    const fiels = response.data.data
    this.fielsOK = fiels.certificate && fiels.private_key && (fiels.password !== null && fiels.password !== '')
    this.loading = false
  }

  async benefitsLater () {
    if (this.service === 'Fondeadora' && !this.state.company.not_interested_banks) {
      this.loading = true
      const [error] = await CompanyService.updateFlags(this.state.company.id, 'not_interested_banks', true)
      if (error) {
        this.loading = false
        return this.$toasted.global.error(error)
      }
      return this.$router.push({ name: 'Dashboard', query: this.$route.query, params: { company_id: this.state.company.id } })
    }
    return this.$router.push({ name: 'Benefits', query: this.$route.query, params: { company_id: this.state.company.id } })
  }

  async next () {
    if (this.url !== '' && (this.account.name !== 'BancoBase' && this.account.name !== 'BBVASpark')) return window.open(this.url, '_blank')
    else if (this.account.complete && this.url !== '') return window.open(this.url, '_blank')
    // if (this.account.complete && !this.fielsOK) return this.$router.push({ name: 'BenefitsFiels', query: this.$route.query, params: { company_id: this.state.company.id } })
    return this.account.create = true
  }

  @State('UserModule') state: any
}
