




























































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import ReturnAddress from '@/components/Return.vue'
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
import PartnerDocumentsError from '@/components/partners/PartnerDocumentsError.vue'
// Services
import ImageService from '@/services/Image'

@Component({
  components: { ReturnAddress, Footer, Loader, PartnerDocumentsError }
})
export default class RefuseDocuments extends Vue {
  partners: any = []
  loading = false
  
  created () {
    this.state.company.partners.map((partner: any) => {
      const cfi_error = partner.cfi.document_url === null ? false : !partner.cfi.is_approved && partner.cfi.rejection_category
      const id_spouse_error = partner.id_spouse.document_url === null ? false : !partner.id_spouse.is_approved && partner.id_spouse.rejection_category
      const marriage_certificate_error = partner.marriage_certificate.document_url === null ? false : !partner.marriage_certificate.is_approved && partner.marriage_certificate.rejection_category
      if (partner.civil_status === 'casado' && (cfi_error || id_spouse_error || marriage_certificate_error)) {
        this.partners.push({
          name: partner.name,
          first_names: partner.first_names,
          last_names: partner.last_names,
          civil_status: partner.civil_status,
          id: partner.id,
          cfi_error: cfi_error,
          id_spouse_error: id_spouse_error,
          marriage_certificate_error: marriage_certificate_error
        })
      } else if (cfi_error) {
        this.partners.push({
          name: partner.name,
          first_names: partner.first_names,
          last_names: partner.last_names,
          civil_status: partner.civil_status,
          id: partner.id,
          cfi_error: cfi_error,
          id_spouse_error: false,
          marriage_certificate_error: false
        })
      }
    })
  }

  async upload (file: any, docType: string, partner_id: string) {
    if (file === null || !file.name || !file.size) return
    const [error] = await ImageService.upload({
      entity_type: 'partner',
      identity: partner_id,
      document_type: docType,
      image: file,
      company_id: this.state.company.id
    })
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
  }

  async save () {
    this.loading = true
    for (const partner of this.partners) {
      if (partner.cfi_error && partner.cfi !== '') await this.upload(partner.cfi, 'CFI', partner.id)
      if (partner.id_spouse_error && partner.id_spouse !== '') await this.upload(partner.id_spouse, 'id_spouse', partner.id)
      if (partner.marriage_certificate_error && partner.marriage_certificate !== '') await this.upload(partner.marriage_certificate, 'marriage_certificate', partner.id)
    }
    return this.$router.push({ name: 'Dashboard', query: this.$route.query, params: { company_id: this.state.company.id } })
  }

  @State('UserModule') state: any
}
