












































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
import TooltipPowers from '@/components/administration/TooltipPowers.vue'
import ModalEditAdmin from '@/components/resumen/modals/ModalEditAdmin.vue'
// Types
import UserTypes from '@/store/types/UserTypes'
// Services
import AdministrationService from '@/services/Administration'

@Component({
  components: { Loader, TooltipPowers, ModalEditAdmin }
})
export default class CardAdmin extends Vue {
  modal: any = {
    showModalCompanyAdmin: false,
    update: false,
  }
  adminTable = [
    { name: 'Consejo', type: 'role', end: false, colSize: '3'},
    { name: 'Socios', type: 'name', end: false, colSize: '7'},
    { name: 'Poderes', type: 'power', end: true, colSize: '2'}
  ]
  editActive = false
  loader = false
  council: any = []

  @Watch('modal.update')
  async update () {
    if(!this.modal.update) return
    this.formatCouncil()
    this.modal.update = false
  }

  @Watch('state.company.council')
  async updateCouncil () {
    this.formatCouncil()
  }

  created () {
    const articlesMock = this.state.company.stages.find((stage: any) => stage.stage_name === 'company_articles_mock').is_completed === true
    if (!articlesMock) this.editActive = true
    this.formatCouncil()
  }

  formatCouncil () {
    this.council = []
    let council = []
    if (this.state.company.company_type === 'sa' && this.state.company.has_unique_administrator) council.push(this.state.company.council.find((partner: any) => partner.council_role === 'unique_administrator'))
    else {
      council.push(this.state.company.council.find((partner: any) => partner.council_role === 'president'))
      council.push(this.state.company.council.find((partner: any) => partner.council_role === 'secretary'))
      council.push(this.state.company.council.find((partner: any) => partner.council_role === 'treasurer'))
    }
    this.state.company.council.filter((partner: any ) => partner.council_role === 'member').map((member: any) => { council.push(member) })
    council = council.filter((partner: any ) => partner !== undefined)
    council.map((member: any) => {
      this.council.push({ position: this.getCouncilRole(member.council_role), name: this.getPartnerFullName(member.partner), power: member.sat_signature_faculty, id: member.id })
    })
  }

  getPartnerFullName (partner: any) {
    let full_name: string = partner.name != null ? partner.name : ''
    full_name = partner.first_names != null ? full_name + ' ' + partner.first_names : full_name
    full_name = partner.last_names != null ? full_name + ' ' + partner.last_names : full_name
    return full_name.trimEnd()
  }

  getCouncilRole (role: string) {
    if (role === 'unique_administrator') return 'Administrador único'
    if (role === 'president') return 'Presidente'
    if (role === 'secretary') return 'Secretario'
    if (role === 'treasurer') return 'Tesorero'
    if (role === 'member') return 'Miembro'
    return ''
  }

  getMemberData (type: string, member: any) {
    if (type === 'role') return member.position
    if (type === 'name') return member.name
    return ''
  }

  async setPower (member: any) {
    this.loader = true
    const [error] = await AdministrationService.setPower( member.id, member.power )
    if (error) {
      this.loader = false
      return this.$toasted.global.error(error)
    }
    this.loader = false
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
