// Utils
import { RouteConfig } from 'vue-router'
import multiguard from 'vue-router-multiguard'
// Views
import Index from '@/views/payment/Index.vue'
import IndexExternal from '@/views/payment/IndexExternal.vue'
import Checkout from '@/views/payment/Checkout.vue'
import CheckoutCommissary from '@/views/payment/CheckoutCommissary.vue'
import CheckoutAccountant from '@/views/payment/CheckoutAccountant.vue'
import Payment from '@/views/payment/Payment.vue'
import PaymentComplete from '@/views/payment/PaymentComplete.vue'
import PaymentRepresentation from '@/views/payment/PaymentRepresentation.vue'
import PaymentUpdate from '@/views/payment/PaymentUpdate.vue'
import HearAbout from '@/views/payment/HearAbout.vue'
// Middlewares
import Auth from '@/middlewares/Auth'
import User from '@/middlewares/User'
import PaymentExtern from '@/middlewares/PaymentExtern'

const routes: Array<RouteConfig> = [
  {
    path: '/payment/:company_id',
    component: Index,
    beforeEnter: multiguard([Auth.isLogged, User.validateData]),
    children: [
      {
        path: '',
        name: 'Payment',
        component: Payment,
        beforeEnter: User.validateCheckout
      },
      {
        path: 'payment_complete',
        name: 'PaymentComplete',
        component: PaymentComplete
      },
      {
        path: 'payment_representation',
        name: 'PaymentRepresentation',
        component: PaymentRepresentation,
        beforeEnter: User.validateCheckout
      },
      {
        path: 'hear_about',
        name: 'HearAbout',
        component: HearAbout
      },
      {
        path: 'checkout',
        name: 'Checkout',
        component: Checkout,
        beforeEnter: User.validateCheckout
      },
      {
        path: 'checkout_commissary',
        name: 'CheckoutCommissary',
        component: CheckoutCommissary,
        beforeEnter: User.validateCheckout
      },
      {
        path: 'checkout_accountant',
        name: 'CheckoutAccountant',
        component: CheckoutAccountant,
        beforeEnter: User.validateCheckout
      }
    ]
  },
  {
    path: '/pay',
    component: IndexExternal,
    children: [
      {
        path: 'payment_update',
        name: 'PaymentUpdate',
        component: PaymentUpdate,
        beforeEnter: PaymentExtern.validateUserToken
      }
    ]
  }
]

export default routes
