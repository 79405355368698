








































// Utils
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class CardBankFAQs extends Vue {
  FAQs = [{
      name: '¿Cómo ingreso a mi banca en línea?',
      show: false,
      content: [
        { type: 'text', style: '', circle: false, link: 'https://www.bbva.mx/empresas/productos/segmento-pyme.html', linkText: 'https://www.bbva.mx/empresas/productos/segmento-pyme.html', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: 'BBVA PYME', text: 'Ingresando al enlace ', text2: ' o buscando ', text3: ' en el navegador de su preferencia'},
        { type: 'text', style: '', circle: false, link: '', linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: 'Seleccionar “Personas y Gobierno” y dar click al botón de Acceso.', text2: '', text3: ''},
        { type: 'img', style: 'width: 500px;', circle: false, link: require('@/assets/images/FAQsBank/FAQ1-1.png'), linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: '', text2: '', text3: ''},
        { type: 'text', style: '', circle: false, link: '', linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: 'Capturar los 16 números de la Tarjeta de Acceso seguro.', text2: '', text3: ''},
        { type: 'img', style: 'width: 400px;', circle: false, link: require('@/assets/images/FAQsBank/FAQ1-2.png'), linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: '', text2: '', text3: ''},
        { type: 'text', style: '', circle: false, link: '', linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: 'Posteriormente ingresar los 10 dígitos del número de cuenta, escribir ADMINF en Usuario, y en Contraseña la Clave de Acceso.', text2: '', text3: ''},
        { type: 'img', style: 'width: 400px;', circle: false, link: require('@/assets/images/FAQsBank/FAQ1-3.png'), linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: '', text2: '', text3: ''}
      ],
      points: []
    },{
      name: '¿Cómo instalo la app de BBVA Empresa?',
      show: false,
      content: [
        { type: 'text', style: '', circle: false, link: '', linkText: '', link2: 'https://www.bbva.mx/empresas/landings/bbva-empresas-mexico.html', linkText2: 'BBVA Empresas México | Empresas | BBVA México', link3: '', linkText3: '', bold: 'BBVA EMPRESAS MÉXICO', bold2: '', bold3: '', text: 'La app se encuentra disponible en las tiendas Google Play Store y App Store. Se debe buscar ', text2: ' en el navegador de su aplicación y seleccionar instalar Para mayor información consultar el enlace:', text3: ''},
        { type: 'text', style: '', circle: false, link: '', linkText: '', link2: '', linkText2: '', link3: 'https://calendly.com/edgar-consultabanco/30min', linkText3: 'aquí', bold: 'BBVA Empresa,', bold2: '', bold3: 'Clave de Acceso y Token Móvil.', text: 'Una vez instalada la app de ', text2: 'usted deberá notificar a Tally que intentará ingresar a su app, lo cual hará con su ', text3: ' Una vez ingresado el número de token móvil, uno de nuestros representantes recibirá una llamada para confirmar el alta del nuevo teléfono que se intenta registrar. Por esta razón es necesario que nos notifiquen via whatsapp que se intentará realizar el cambio, de lo contrario y por su seguridad no podremos confirmarlo. Si deseas hacerlo en conjunto con un representante de Tally, agenda una llamada '}
      ],
      points: []
    },{
      name: '¿Cómo recibir transferencias internacionales?',
      show: false,
      content: [
        { type: 'text', style: '', circle: false, link: '', linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: 'Para recibir una transferencia internacional deberás poner los siguientes datos:', text2: '', text3: ''},
        { type: 'text', style: 'margin: 0px', circle: false, link: '', linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: 'Beneficiario: [Nombre completo de tu empresa]', text2: '', text3: ''},
        { type: 'text', style: 'margin: 0px', circle: false, link: '', linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: 'Account: [Cuenta CLABE 18 digitos]', text2: '', text3: ''},
        { type: 'text', style: 'margin: 0px', circle: false, link: '', linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: 'SWIFT: BCMRMXMMPYM', text2: '', text3: ''},
        { type: 'text', style: 'margin: 0px', circle: false, link: '', linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: 'Banco: BBVA-MEXICO', text2: '', text3: ''},
        { type: 'text', style: 'margin: 0px', circle: false, link: '', linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: 'Dirección Banco: Av. Paseo de la Reforma 510, Juárez, Cuauhtémoc, 06600 Ciudad de México, CDMX', text2: '', text3: ''}
      ],
      points: []
    },{
      name: '¿Cuánto cuesta la banca en línea (comisiones desglosadas)?',
      show: false,
      content: [
        { type: 'text', style: '', circle: false, link: '', linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: 'Por política de Tally hemos contratado un seguro para la banca en línea, el cual únicamente se deberá pagar por un periodo de 5 meses.', text2: '', text3: ''},
        { type: 'text', style: '', circle: false, link: '', linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: 'Este seguro incluye la reposición gratuita de tokens móviles. Al finalizar la contratación del seguro, ahorras de forma permanente cualquier comisión que se te llegue a generar en un futuro.', text2: '', text3: ''},
        { type: 'text', style: '', circle: false, link: '', linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: 'Esto nos permite tener buena relación con BBVA y hacerles a ustedes un ahorro a largo plazo.', text2: '', text3: ''}
      ],
      points: []
    },{
      name: '¿Cómo utilizar el token bancario?',
      show: false,
      content: [
        { type: 'text', style: '', circle: false, link: '', linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: 'Deberás escanear el código QR cada vez que desees tener acceso a la app o realizar cualquier operación. Es indispensable  que cuides el token ya que sin este no podrás tener acceso a la banca en línea. En caso de perderlo podrás solicitar otro totalmente gratis si cuentas con el seguro BBVA, solo se cobrarán los costos de envío.', text2: '', text3: ''}
      ],
      points: []
    },{
      name: '¿Cómo solicitar una terminal punto de venta?',
      show: false,
      content: [
        { type: 'text', style: '', circle: false, link: '', linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: 'Se solicita directamente al asesor ejecutivo de BBVA.', text2: '', text3: ''}
      ],
      points: []
    },{                                                                         
      name: '¿Cómo me contacto con el banco?',
      show: false,
      content: [
        { type: 'text', style: 'margin: 0px', circle: false, link: '', linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: 'Ejecutivo BBVA: Iván Caballero', text2: '', text3: ''},
        { type: 'text', style: 'margin: 0px', circle: false, link: '', linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: 'Horario de atención:', text2: '', text3: ''},
        { type: 'text', style: 'margin: 0px', circle: true, link: '', linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: '8:00 am - 11:00 am CST', text2: '', text3: ''},
        { type: 'text', style: 'margin: 0px', circle: true, link: '', linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: '5:00 pm - 7:00 pm CST', text2: '', text3: ''},
        { type: 'text', style: 'margin: 0px', circle: false, link: '', linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: 'Contacto:', text2: '', text3: ''},
        { type: 'text', style: 'margin: 0px', circle: true, link: '', linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: 'Escribe por WhatsApp para solicitar una llamada. Iván no contesta números desconocidos.', text2: '', text3: ''},
        { type: 'text', style: 'margin: 0px', circle: true, link: '', linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: 'Menciona el nombre de tu empresa mexicana.', text2: '', text3: ''},
        { type: 'text', style: 'margin: 0px', circle: true, link: '', linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: 'Se paciente, ya que Iván recibe numerosos mensajes diarios.', text2: '', text3: ''},
        { type: 'text', style: 'margin: 0px', circle: false, link: '', linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: 'Sucursal:', text2: '', text3: ''},
        { type: 'text', style: 'margin: 0px', circle: true, link: '', linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: 'Av. Pdte. Masaryk 43, Polanco, Miguel Hidalgo, 11560 Ciudad de México, CDMX', text2: '', text3: ''}
      ],
      points: []
    },{
      name: '¿Cómo pago mis impuestos?',
      show: false,
      content: [
        { type: 'text', style: '', circle: false, link: 'https://www.bbva.mx/empresas/servicios-digitales/pago-de-impuestos-empresas.html#soy-cliente', linkText: 'https://www.bbva.mx/empresas/servicios-digitales/pago-de-impuestos-empresas.html#soy-cliente', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: 'Se pagan a través de la banca en línea ingresando al siguiente enlace', text2: '', text3: ''},
        { type: 'img', style: 'width: 500px;', circle: false, link: require('@/assets/images/FAQsBank/FAQ8-1.png'), linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: '', text2: '', text3: ''},
        { type: 'text', style: '', circle: false, link: 'https://www.bbva.mx/empresas/servicios-digitales/pago-de-impuestos/sipare.html', linkText: 'Sipare | Empresas | BBVA México', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: 'Tambien es posible pagar las aportaciones del IMMS RCV e INFONAVIT por medio del siguiente enlace: ', text2: '', text3: ''},
        { type: 'img', style: 'width: 500px;', circle: false, link: require('@/assets/images/FAQsBank/FAQ8-2.png'), linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: '', text2: '', text3: ''}
      ],
      points: []
    },{
      name: '¿Cuáles son las comisiones de BBVA?',
      show: false,
      content: [
        { type: 'text', style: '', circle: false, link: '', linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: 'Por política de Tally, para usuarios de representación, hemos contratado un Seguro de Banca en Línea, el cual únicamente se deberá pagar por 6 meses. Al haber contratado el Seguro no se generarán comisiones por el uso de la Banca en Línea o saldos mínimos menores a los $12,000.00 mxn en la cuenta en pesos y $80.00 usd en la cuenta en dólares, asimismo solicitar la reposición o un nuevo token bancario no tendrá costo alguno más que el de envío.', text2: '', text3: ''},
        { type: 'text', style: '', circle: false, link: '', linkText: '', link2: '', linkText2: '', link3: '', linkText3: '', bold: '', bold2: '', bold3: '', text: 'Si usted ve que se le cobra alguna comisión, deberá comunicarse con el asesor de BBVA.', text2: '', text3: ''}
      ],
      points: []
    },{
      name: '¿Qué otros beneficios tengo por usar BBVA empresas?',
      show: false,
      content: [],
      points: [
        'Derecho a chequera(opcional si el cliente ocupa).',
        'Tarjeta de débito empresarial (reposición gratuita).',
        'Servicio de nómina (dispersión de nómina a través del portal bbva.com gratuito)',
        'Reposición token o tarjeta de acceso (tas) sin costo por reposición.'
      ]
    }
  ]
}
