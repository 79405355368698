// Utils
import { RouteConfig } from 'vue-router'
import multiguard from 'vue-router-multiguard'
// Views
import Index from '@/views/partners/Index.vue'
import EditAllPartners from '@/views/partners/EditAllPartners.vue'
import RefuseDocuments from '@/views/partners/RefuseDocuments.vue'
// Middlewares
import Auth from '@/middlewares/Auth'
import User from '@/middlewares/User'

const routes: Array<RouteConfig> = [
  {
    path: '/partners/:company_id',
    component: Index,
    beforeEnter: multiguard([Auth.isLogged, User.validateData]),
    children: [
      {
        path: 'edit_all_partners',
        name: 'EditAllPartners',
        component: EditAllPartners
      },
      {
        path: 'refuse_documents',
        name: 'RefuseDocuments',
        component: RefuseDocuments
      }
    ]
  }
]

export default routes
