













































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component({
})
export default class PaymentRepresentationInfo extends Vue {
  points = [
    'La documentación de los socios, para lo cual les enviaremos un correo invitándolos a formar parte de su nueva empresa.',
    'Si tu eres socio, este paso lo deberás realizar en la sección después del pago',
    'Una vez verificados los socios podrás revisar tu proyecto y agendar una revisión con tus representantes en México.'
  ]

  @State('UserModule') state: any
}
