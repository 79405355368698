












// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import CompanyDetail from '@/components/detail/CompanyDetail.vue'
import HeaderProgressBar from '@/components/HeaderProgressBar.vue'
// Types
import UserTypes from '@/store/types/UserTypes'

@Component({
  components: { HeaderProgressBar, Footer, CompanyDetail }
})
export default class Detail extends Vue {
  timer = false
  progress_bar = 0
  bar = 0

  mounted () {
    this.timer = this.state.company.timer === null
    if (this.state.company.legal_representation) {
      this.progress_bar = 3
      this.bar = 2
    } else {
      this.progress_bar = 5
      this.bar = 4
    }
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
