


















































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import CardCompanyFiels from '@/components/administration/CardCompanyFiels.vue'
import ReturnAddress from '@/components/Return.vue'
import Footer from '@/components/Footer.vue'

@Component({
  components: { Footer, ReturnAddress, CardCompanyFiels }
})
export default class CompanyFiels extends Vue {
  tip = {
    title: 'Tips',
    text: 'El 45% de las empresas pierde sus claves o no las encuentra, por ello, agrégalas y siempre podrás consultarlas en tus documentos'
  }
  @State('UserModule') state: any
}
