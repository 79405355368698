import to from 'await-to-js'
import axios from '@/utils/axios-api'
import Login from '@/interfaces/Login'
import RecoveryPasswordEmail from '@/interfaces/RecoveryPasswordEmail'
import RecoveryPasswordEntry from '@/interfaces/RecoveryPasswordEntry'
import store from '@/store'
import AuthTypes from '@/store/types/AuthTypes'
import VerificationCode from '@/interfaces/VerificationCode'

const URL_PHP = process.env.VUE_APP_BASE_URL_PHP

export default class User {
  static async login (data: Login) {
    return await to(axios.post(`${URL_PHP}/v1/user/login`, data)) as any
  }

  static async loginGoogle (data: any) {
    return await to(axios.post(`${URL_PHP}/v1/user/login-google`, data)) as any
  }

  static async signup (data: any) {
    // const response = await axios.post(`${URL_PHP}/v1/user/register`, data)
    const response = await axios.post(`${URL_PHP}/v2/user/register`, data)
    return [null, response]
  }

  static async signupGoogle (data: any) {
    // const response = await axios.post(`${URL_PHP}/v1/user/signup-google`, data )
    const response = await axios.post(`${URL_PHP}/v2/user/register-google`, data)
    return [null, response]
  }

  static async signupPartner (partner: string, data: any) {
    const response = await axios.post(`${URL_PHP}/v2/user/create-by-partner/${partner}`, data)
    return [null, response]
  }

  static async signupGooglePartner (partner: string, data: any) {
    const response = await axios.post(`${URL_PHP}/v2/user/create-by-partner/google/${partner}`, data)
    return [null, response]
  }

  static async logout () {
    return await axios.get(`${URL_PHP}/v1/user/logout`)
  }

  static async profile () {
    return await to(axios.get(`${URL_PHP}/v1/user/profile`)) as any
  }

  static async validateCode (data: VerificationCode) {
    return await to(axios.post(`${URL_PHP}/v1/user/verification-code`, data)) as any
  }

  static async resendVerificationCode () {
    return await to(axios.post(`${URL_PHP}/v1/user/send-verify-email`)) as any
  }

  static async resetPasswordEmail (data: RecoveryPasswordEmail) {
    return await to(axios.post(`${URL_PHP}/v1/user/send-recovery-password`, data)) as any
  }

  static async resetPasswordEntry (data: RecoveryPasswordEntry) {
    return await to(axios.post(`${URL_PHP}/v1/user/send-recovery-password-entry`, data)) as any
  }

  static async updatePassword (user: string, password: string) {
    return await to(axios.put(`${URL_PHP}/v2/update-password/${user}`, { password })) as any
  }

  static async availableEmail (email: string) {
    return await to(axios.post(`${URL_PHP}/v2/available-email`, { email: email })) as any
  }

  static async refreshToken () {
    const payload = store.getters[`AuthModule/${AuthTypes.getters.GET_PAYLOAD}`]
    return await to(axios.post(`${URL_PHP}/v1/user/refresh`, {}, {
      headers: { authorization: `Bearer ${payload.refresh_token}` }
    })) as any
  }

  static async requestNewCode (user: string, email: string, phone: string, phone_country_code: string) {
    return await to(axios.get(`${URL_PHP}/v2/request-verification-code-to-update/phone_country_code/${phone_country_code}/phone/${phone}/email/${email}/user/${user}`)) as any
  }

  static async verifyCode (user: string, email: string, phone: string, phone_country_code: string, verification_code: string) {
    return await to(axios.put(`${URL_PHP}/v2/update-phone-email/user/${user}`, { email, phone, phone_country_code, verification_code})) as any
  }

  static async storeUTMs () {
    return await to(axios.get(`${URL_PHP}/v2/store-utms-interaction`)) as any
  }
}
