export default {
  mutations: {
    SET_DATA: 'SET_DATA',
    CLEAR_DATA: 'CLEAR_DATA'
  },
  getters: {
    GET_DATA: 'GET_DATA'
  },
  actions: {
    SAVE_TOKEN: 'SAVE_TOKEN',
    CLEAR_DATA: 'CLEAR_DATA'
  }
}
