



























































































// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'
// Services
import ConstitutiveService from '@/services/Constitutive'

@Component({
  components: { Loader }
})
export default class SetLegalName extends Vue {
  @Prop({ default: '' }) address!: string
  company: any = { step: '2', totalAction: '10,000' }
  legal_name = ''
  company_type = ''
  validName = true
  loading = false
  showModal = false
  register = false
  types: Array<string> = ['sapi', 'sa', 'srl']
  title = 'Ahora confirmaremos el nombre de tu empresa'
  points = [{
      text: 'Evita usar palabras restringidas "banco" o "inversión". ',
      url: 'https://www.tallylegal.io/palabras-prohibidas',
      bold: false
    },{
      text: 'Puede ser diferente al nombre de tu marca.',
      url: '',
      bold: true
    },{
      text: 'Se creativo, puedes usar números.',
      url: '',
      bold: false
    },{
      text: 'No se permiten carácteres especiales.',
      url: '',
      bold: false
    }
  ]

  created () {
    if (this.address === 'PersonalInformation' || this.address === 'LegalNameAvailable' || this.address === 'CompanyActivities') {
      this.register = true
      this.legal_name = this.state.legal_name
      this.company_type = this.state.type_Society
      this.title = this.state.legal_representation ? 'Ahora confirmaremos el nombre de tu sociedad' : '¡Excelente!, ahora agrega el nombre de tu empresa'
    } else {
      this.company = this.$attrs.value
      this.legal_name = this.userState.company.legal_name === this.userState.email ? '' : this.userState.company.legal_name
      this.company_type = this.userState.company.company_type
    }
  }

  reset () {
    this.legal_name = ''
    this.validName = true
  }

  filterLegalName (value: string) { return value.replace(/[^a-zA-Z0-9ÑñÁáÉéÍíÓóÚú ]/g, '').trimStart() }
  
  async advance () {
    if (this.register) {
      await this.setLegalName(this.legal_name)
      await this.setTypeSociety(this.company_type)
      await this.setNameAuthorization(true)
      return this.$router.push({ name: this.address, query: this.$route.query }).catch(() => {return})
    } else {
      this.loading = true
      const [error] = await ConstitutiveService.updateLegalNames(this.userState.company.id, [this.legal_name], true)
      if (error) {
        this.loading = false
        return this.$toasted.global.error(error)
      }
      this.company.step = '2'
    }
  }

  async validate () {
    this.loading = true
    // Validate legal name
    const [error, response] = await ConstitutiveService.validateLegalName(this.legal_name)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    if (!response.data.data) {
      this.loading = false
      return this.validName = false
    }
    // User in registration process
    if (this.register) {
      await this.setLegalName(this.legal_name)
      await this.setTypeSociety(this.company_type)
      await this.setNameAuthorization(false)
      return this.$router.push({ name: this.address, query: this.$route.query }).catch(() => {return})
    }
    // Save legal name
    const [error2] = await ConstitutiveService.updateLegalNames(this.userState.company.id, [this.legal_name], false)
    if (error2) {
      this.loading = false
      return this.$toasted.global.error(error2)
    }
    if (this.address === '') return this.company.step = '3'
    return this.$router.push({ name: this.address, query: this.$route.query }).catch(() => {return})
  }

  @State('CompanyModule') state: any
  @State('UserModule') userState: any
  @Action(`CompanyModule/${CompanyTypes.actions.SET_LEGAL_NAME}`) setLegalName: any;
  @Action(`CompanyModule/${CompanyTypes.actions.SET_TYPE_SOCIETY}`) setTypeSociety: any;
  @Action(`CompanyModule/${CompanyTypes.actions.SET_NAME_AUTHORIZATION}`) setNameAuthorization: any;
}
