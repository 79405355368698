












































































































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import EventBus from '@/event-bus'
import moment from 'moment'
// Components
import Footer from '@/components/Footer.vue'
import BarStatutes from "@/components/statutes/BarStatutes.vue"
import FooterStatutesFirm from "@/components/statutes/FooterStatutesFirm.vue"
import FooterStatutesReview from "@/components/statutes/FooterStatutesReview.vue"
import CardCompany from '@/components/resumen/CardCompany.vue'
import CardCompanyActivities from '@/components/resumen/CardCompanyActivities.vue'
import CardAdmin from '@/components/resumen/CardAdmin.vue'
import CardCommissary from '@/components/resumen/CardCommissary.vue'
import CardAttorneys from '@/components/resumen/CardAttorneys.vue'
import CardAllPartners from '@/components/resumen/CardAllPartners.vue'
import HeaderProgressBar from '@/components/HeaderProgressBar.vue'
import Appointment from '@/components/dashboard/Appointment.vue'
import AppointmentCheck from '@/components/dashboard/AppointmentCheck.vue'
import CardFirms from '@/components/dashboard/CardFirms.vue'
import Timer from '@/components/Timer.vue'
import Statute from "@/components/dashboard/Statute.vue"
import Review from '@/components/dashboard/Review.vue'
import Final from '@/components/dashboard/Final.vue'
import ShippingAddress from '@/components/dashboard/ShippingAddress.vue'
import TableValidations from '@/components/dashboard/TableValidations.vue'
import ResumenLarge from '@/components/beginning/ResumenLarge.vue'
// Types
import UserTypes from '@/store/types/UserTypes'
// Services
import CompanyService from "@/services/Company"
import CalendlyService from '@/services/Calendly'

@Component({
  components: {
    Footer,
    BarStatutes,
    FooterStatutesFirm,
    FooterStatutesReview,
    CardCompany,
    CardCompanyActivities,
    CardAdmin,
    CardCommissary,
    CardAttorneys,
    CardAllPartners,
    HeaderProgressBar,
    Appointment,
    AppointmentCheck,
    CardFirms,
    Timer,
    Statute,
    Review,
    Final,
    ShippingAddress,
    TableValidations,
    ResumenLarge
  }
})
export default class Dashboard extends Vue {
  moment = moment
  showContent = ''
  loading = false
  confirm = false
  timer = false
  timerExist = false
  timerStatute = false
  review = false
  endReviewTimer = 0
  signature = false
  lastSignature = false
  completeFirms = false
  documentsApproved = false
  articlesMock = false
  checkoutComplete = false
  existTestimony = false
  renderKey = 0
  statuteRenderKey = 2
  date = 0
  CommissaryPay = false
  points = [
    'Los socios deben firmar el mismo día',
    'Les llegará un correo a cada socio para firmar por docusign',
    'Usa tu celular para firmar ya que deberás tomarte una selfie :)',
    'Ten a la mano la identificación oficial que usaste al verificarte en Tally.'
  ]

  @Watch('state.company')
  update () {
    this.statuteRenderKey++
  }

  @Watch('state.company.stages')
  updateStages () {
    this.getCompanyStatus()
  }

  async created () {
    EventBus.$on('update-dashboard', () => {
      this.signature = false
      this.lastSignature = false
      this.renderKey += 1
      this.validateValidSignature()
    })
     await this.getCompanyStatus()
  }

  mounted () {
    this.validateValidSignature()
    this.setContent()
  }

  async getCompanyStatus () {
    this.timerExist = this.state.company.timer === null
    this.checkoutComplete = this.state.company.orders.filter((order: any) => order.status === 'complete' && order.items.filter((item: any) => item.name === 'Complemento Acta Constitutiva' || item.name === 'Acta Constitutiva').length > 0).length > 0
    this.CommissaryPay = this.state.company.orders.filter((order: any) => order.status === 'complete' && order.items.filter((item: any) => item.name === 'Servicio de Comisario').length > 0).length > 0
    this.articlesMock = this.state.company.stages.find((stage: any) => stage.stage_name === 'company_articles_mock').is_completed === true
    this.documentsApproved = this.state.company.stages.find((stage: any) => stage.stage_name === 'documents_approved').is_completed === true
    this.completeFirms = this.state.company.stages.find((stage: any) => stage.stage_name === 'signing_complete').is_completed === true
    this.existTestimony = this.state.company.articles_of_incorporation.document_url !== null
    this.timerActive()
    if (this.state.company.timer !== null && !this.articlesMock) await this.getCalendly()
    if (!this.completeFirms && this.existTestimony) await this.signingComplete()
  }

  async getCalendly () {
    if (this.state.company.update_constitutive === true) return
    const [error, response] = await CalendlyService.getCalendly(this.state.company.id)
    if (error) {
      return this.$toasted.global.error(error)
    }
    const calendlly = response.data.resource
    if (calendlly === undefined || calendlly === null || calendlly.status === 'canceled' || calendlly.start_time === null || calendlly.start_time === '') return
    const today = moment().valueOf()
    const endReview = moment(new Date(calendlly.end_time)).valueOf()
    this.endReviewTimer = moment(new Date(calendlly.end_time)).valueOf() + 43200000
    if (today < endReview) this.review = true
    else if (today < this.endReviewTimer) this.timerStatute = true
    return
  }

  async signingComplete () {
    const [error] = await CompanyService.updateStage({ stage_name: 'signing_complete', company_id: this.state.company.id, is_completed: true })
    if (error) return this.$toasted.global.error(error)
    await this.updateCompany(this.state.company.id)
    this.completeFirms = this.state.company.stages.find((stage: any) => stage.stage_name === 'signing_complete').is_completed === true
  }

  timerActive () {
    this.timer = this.state.company.timer !== null
    if (this.timer && this.state.company.update_constitutive === null) {
      this.date = process.env.NODE_ENV === 'production' ?
        moment(this.state.company.timer).valueOf() + 86400000 :
        moment(this.state.company.timer).valueOf() + 300000
      if (/*moment(this.state.company.timer).isoWeekday() === 4 || */moment(this.state.company.timer).isoWeekday() === 5) this.date = this.date + 172800000
      else if (moment(this.state.company.timer).isoWeekday() >= 6) this.date = this.date + 129600000
      else if (moment(this.state.company.timer).isoWeekday() >= 7) this.date = this.date + 43200000
      // const now = moment().valueOf()
      // if ((this.date - now) <= 0) this.timer = false
    } else if (this.state.company.update_constitutive !== null) {
      this.timer = false
    }
  }

  setContent () {
    if (this.state.company.timer === null || !this.documentsApproved) this.showContent = 'validations'
    else if (this.timer && !this.articlesMock && !this.completeFirms) this.showContent = 'timer'
    // else if (!this.articlesMock) this.showContent = 'Act'
    else if (this.state.id === this.state.company.user_id) this.showContent = 'resumen'
  }

  validateValidSignature () {
    const findEvent = this.state.company.events.find((event: any) => event.event_description === 'constituir_empresa')
    if (findEvent) {
      this.signature = true
      this.lastSignature = moment(findEvent.event_timestamp).valueOf() < moment().valueOf()
    }
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any;
}
