


















// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
// Types
import UserTypes from '@/store/types/UserTypes'
// Services
import CompanyService from '@/services/Company'

@Component({
  components: { Loader }
})
export default class CompanySubsidiary extends Vue {
  company: any = {}
  loading = false
  types = [{
      type: 'new',
      text: 'Crear una empresa desde cero',
    },{
      type: 'subsidiary',
      text: 'Crear una subsidiaria de otra empresa',
    }
  ]
  typeSelected = ''

  created () {
    this.typeSelected = this.state.company.subsidiary ? 'subsidiary' : 'new'
  }

  mounted () {
    this.company = this.$attrs.value
  }

  async next (type: string) {
    this.loading = true
    const [error] = await CompanyService.updateFlags(this.state.company.id, 'subsidiary', type === 'subsidiary')
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    await this.updateCompany(this.state.company.id)
    return this.$router.push({ name: 'Welcome', query: this.$route.query }).catch(() => {return})
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
