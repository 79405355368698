











































































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
import ReturnAddress from '@/components/Return.vue'
import PartnerNationalities from '@/components/partners/PartnerNationalities.vue'
import PartnerMexicanWithoutRFC from '@/components/partners/PartnerMexicanWithoutRFC.vue'
import InvitedPartnerComplementaries from '@/components/invitedPartner/InvitedPartnerComplementaries.vue'
// Types
import InvitedTypes from '@/store/types/InvitedTypes'
// Services
import CountryService from '@/services/Country'
import PartnersService from '@/services/Partners'

@Component({
  components: { Footer, Loader, ReturnAddress, PartnerNationalities, PartnerMexicanWithoutRFC, InvitedPartnerComplementaries }
})
export default class InvitedComplementaries extends Vue {
  addressRedirect = ''
  partnerSelect: any = { RFC: '' }
  foreign = false
  loading = false
  step = 0
  nationalities = []
  partner = {
    nationalities: [''],
    birthplace: '',
    tax_country: '',
    tax_id: '',
    id: '',
    rfc: '',
    civil_status: '',
    loading: false
  }
  copys = {
    title: '',
    subTitle: ''
  }
  agent = {
    title: '¡Hola soy Pablo, CEO de Tally!',
    subTitle: 'Te ayudaré a constituir una empresa en México',
    text: 'Solo necesitas:'
  }
  points = [
    {img: require('@/assets/icons/register-point-1.svg'), text: 'Decirnos tu nacionalidad'},
    {img: require('@/assets/icons/register-point-2.svg'), text: 'Tener a la mano tu información fiscal'},
    {img: require('@/assets/icons/register-point-4.svg'), text: 'Verificar tu identidad '},
    {img: require('@/assets/icons/register-point-3.svg'), text: 'Confirmar tus generales'}
  ]

  mounted () {
    this.setCopys()
    this.getNationalities()
    this.getPartnerData()
  }

  getPartnerData () {
    this.foreign = true
    if (this.state.partner.nationalities !== null && this.state.partner.nationalities.length) this.partner.nationalities = this.state.partner.nationalities.map((nationality: any) => {
        if (nationality.name === 'México' || nationality.issue_country === 'MX') this.foreign = false
        return nationality.id
      })
    this.partner.id = this.state.partner.id
    this.partner.rfc = this.state.partner.rfc === null ? '' : this.state.partner.rfc
    this.partner.birthplace = this.state.partner.birthplace === null ? '' : this.state.partner.birthplace
    this.partner.tax_country = this.state.partner.tax_country === null ? '' : this.state.partner.tax_country
    this.partner.tax_id = this.state.partner.tax_id === null ? '' : this.state.partner.tax_id
    this.partner.civil_status = this.state.partner.civil_status === null ? '' : this.state.partner.civil_status
  }

  setCopys () {
    if (this.step === 0) return
    if (this.step === 1) {
      this.copys.title = '¿Cuál es tu nacionalidad?'
      this.copys.subTitle = 'Por ley, debes declarar todas las nacionalidades que tengas'
    } else if (this.step === 2) {
      this.copys.title = '¿Cuentas con RFC?'
      this.copys.subTitle = 'El RFC es el número de identificación fiscal en México'
    }
  }

  async saveNationalities () {
    this.loading = true
    this.partner.loading = true
    let existNewNationalities = this.state.partner.nationalities.length !== this.partner.nationalities.length
    if (!existNewNationalities) {
      this.state.partner.nationalities.map((nationality: any) => {
        const exist = this.partner.nationalities.find((newNationality: any) => nationality.id === newNationality)
        if (exist === undefined) existNewNationalities = true
      })
    }
    // Save nationalities
    if (existNewNationalities) {
      const nationalities = this.partner.nationalities.filter((nationality: any ) => nationality !== null)
      const [error] = await PartnersService.storeNationalities(this.partner.id, nationalities)
      if (error) {
        this.loading = false
        this.partner.loading = false
        return this.$toasted.global.error(error)
      }
    }
    // Save birthplace
    if (this.state.partner.birthplace !== this.partner.birthplace) {
      const [error2] = await PartnersService.storeComplements(
        this.partner.id,
        this.partner.rfc,
        this.partner.birthplace,
        this.partner.tax_country,
        this.partner.tax_id,
        this.partner.civil_status,
        true
      )
      if (error2) {
        this.loading = false
        this.partner.loading = false
        return this.$toasted.global.error(error2)
      }
    }
    await this.updateUser({ partner: this.state.partner.id, company_id: this.state.company_id })
    this.step++
    this.getPartnerData()
    this.setCopys()
    this.loading = false
    this.partner.loading = false
  }

  async getNationalities () {
    const [error, response] = await CountryService.nationalities()
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.loading = false
    this.nationalities = response.data.data
  }

  returnTo () {
    if (this.step === 0) return this.$router.push({ name: 'Start', query: this.$route.query })
    this.step--
    this.setCopys()
  }

  @Action(`InvitedModule/${InvitedTypes.actions.UPDATE_DATA}`) updateUser: any
  @State('InvitedModule') state: any
}
