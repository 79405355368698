import store from '@/store'
import InvitedTypes from '@/store/types/InvitedTypes'

export default class Invited {
  static async validatePartner (to: any, from: any, next: any) {
    if (from.name === 'InvitedComplementaries' && (to.query.partner === undefined || to.query.company === undefined)) {
      const partner = store.getters[`InvitedModule/${InvitedTypes.getters.GET_DATA}`]
      if (partner.company_id === '') return next({ name: 'Login', query: to.query })
      return next()
    }
    else if (to.query.partner === undefined || to.query.company === undefined) return next({ name: 'Login', query: to.query }) 
    const [error, partner] =  await store.dispatch(`InvitedModule/${InvitedTypes.actions.UPDATE_DATA}`, {
      partner: to.query.partner.toString(),
      company_id: to.query.company.toString()
    })
    if (error) return next({ name: 'Login', query: to.query })
    const documentsError = partner.documents.filter((document: any) => !document.is_approved && document.rejection_category)
    if (documentsError.length > 0) return next()
    const complementaries = partner.civil_status === 'soltero' ?
      (partner.cfi.document_url === null ? false : partner.cfi.is_approved && partner.cfi.rejection_category === null) :
      ((partner.cfi.document_url === null || 
      partner.id_spouse.document_url === null ||
      partner.marriage_certificate.document_url === null) ? false :
      partner.cfi.is_approved && partner.cfi.rejection_category === null &&
      partner.id_spouse.is_approved && partner.id_spouse.rejection_category === null &&
      partner.marriage_certificate.is_approved && partner.marriage_certificate.rejection_category === null)
    const address = partner.address_identity.is_approved && partner.address_identity.rejection_category === null
    if ((!address && partner.rfc === 'EXTF900101NI1' || partner.rfc === '' || partner.rfc === null)) return next()
    if (partner.invitation_accepted !== null && partner.mati_verification === true && partner.metamap_process === true && complementaries && partner.rfc !== '') return next({name: 'End', query: to.query})
    else return next()
  }
  
  static async isInvited (to: any, from: any, next: any) {
    const partner = store.getters[`InvitedModule/${InvitedTypes.getters.GET_DATA}`]
    if (partner.company_id === '' && (to.query.partner === undefined || to.query.company === undefined)) return next({ name: 'Login', query: to.query })
    if (partner.company_id === '' && to.query.partner !== undefined && to.query.company !== undefined) return next({ name: 'Start', query: to.query })
    return next()
  }
}
