




































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
import { saveAs } from 'file-saver'
// Components
import ModalUploadDoc from '@/components/documents/ModalUploadDoc.vue'
// Services
import ImageService from '@/services/Image'

@Component({
  components: { ModalUploadDoc }
})
export default class PartnersDocs extends Vue {
  collapsePartner:any = []
  partners: any = [{ id: '', docs: [] }]
  modal: any = {
    showModalUploadDoc: false,
    update: false,
    doc: {
      partnerID: '',
      name: '',
      type: '',
      new: false
    }
  }
  renderKeyUploadDoc = 0

  @Watch('modal.update')
  async update () {
    if(!this.modal.update) return
    this.modal.update = false
    this.renderKeyUploadDoc += 1
    this.setPartnersDocs()
  }

  created () {
    this.setPartnersDocs()
    this.$root.$on('bv::collapse::state', (collapseId: string, isJustShown: boolean) => {
      this.partners.map((partner: any, index: number) => {
        if (collapseId === partner.id) this.collapsePartner[index].show = isJustShown
      })
    })
  }

  setPartnersDocs () {
    this.partners = this.state.company.partners.map((partner: any) => {
      const isNational = (partner.nationality === 'México' || partner.nationality === 'MX')
      const RFC = (partner.rfc !== null && partner.rfc !== '' && partner.rfc !== 'EXTF900101NI1')
      return { id: partner.id, name: this.getPartnerFullName(partner), national: isNational, rfc: RFC, docs: partner.documents }
    })
    this.partners.map((partner: any, index: number) => {
      this.collapsePartner.push({ show: false })
      this.partners[index].docs = partner.docs.map((doc: any) => {
        if (doc.document_type === 'national_identity') return { type: doc.document_type, name: 'Identificación oficial frente', id: doc.id, blob: null, fileType: '', loader: false }
        if (doc.document_type === 'national_identity_back') return { type: doc.document_type, name: 'Identificación oficial reverso', id: doc.id, blob: null, fileType: '', loader: false }
        if (doc.document_type === 'resident_id') return { type: doc.document_type, name: 'Tarjeta de residencia frente', id: doc.id, blob: null, fileType: '', loader: false }
        if (doc.document_type === 'resident_id_back') return { type: doc.document_type, name: 'Tarjeta de residencia  reverso', id: doc.id, blob: null, fileType: '', loader: false }
        if (doc.document_type === 'passport') return { type: doc.document_type, name: 'Pasaporte', id: doc.id, blob: null, fileType: '', loader: false }
        if (doc.document_type === 'address') return { type: doc.document_type, name: 'Comprobante de domicilio', id: doc.id, blob: null, fileType: '', loader: false }
        if (doc.document_type === 'CFI') return { type: doc.document_type, name: 'Constancia de Situación Fiscal', id: doc.id, blob: null, fileType: '', loader: false }
        if (doc.document_type === 'id_spouse') return { type: doc.document_type, name: 'Identificación del cónyuge', id: doc.id, blob: null, fileType: '', loader: false }
        if (doc.document_type === 'id_spouse_back') return { type: doc.document_type, name: 'Reverso de la identificación del cónyuge', id: doc.id, blob: null, fileType: '', loader: false }
        if (doc.document_type === 'marriage_certificate') return { type: doc.document_type, name: 'Acta de matrimonio', id: doc.id, blob: null, fileType: '', loader: false }
        return { type: doc.document_type, name: doc.document_type, id: doc.id, blob: null, fileType: '', loader: false }
      })
      let existAddress = this.partners[index].docs.filter((doc: any ) => doc.type === 'address').length > 0
      if (!existAddress) this.partners[index].docs.push({ type: 'address', name: 'Comprobante de domicilio', id: null, blob: null, fileType: '', loader: false })
    })
  }

  getPartnerFullName (partner: any) {
    let full_name: string = partner.name != null ? partner.name : ''
    full_name = partner.first_names != null ? full_name + ' ' + partner.first_names : full_name
    full_name = partner.last_names != null ? full_name + ' ' + partner.last_names : full_name
    return full_name.trimEnd()
  }

  uploadDoc (partner: string, doc: any, newDoc: boolean) {
    this.modal.doc.partnerID = partner
    this.modal.doc.name = doc.name
    this.modal.doc.type = doc.type
    this.modal.doc.new = newDoc
    this.modal.showModalUploadDoc = true
  }

  async download (doc: any, partner_id: string) {
    doc.loader = true
    if (doc.blob === null) {
      const [error, response] = await ImageService.getDocument('partner', partner_id, doc.type)
      if (error) {
        doc.loader = false
        return this.$toasted.global.error(error)
      }
      doc.fileType = response.headers['content-type'].split('/')[1]
      doc.blob = new Blob([response.data])
      saveAs(doc.blob, `${doc.name + '.' + doc.fileType}`)
    } else saveAs(doc.blob, `${doc.name + '.' + doc.fileType}`)
    doc.loader = false
  }

  @State('UserModule') state: any
}
