

















































// Utils
import { Component, Vue } from 'vue-property-decorator'
import {State} from "vuex-class"

@Component({})
export default class PaymentComplete extends Vue {
  copys = {
    title: '¡Felicidades tu pago fue exitoso!',
    text: '¿Qué sigue?',
    points: [
      'Agregar socios',
      'Subir documentos',
      'Revisar proyecto de Constitución'
    ]
  }

  @State('UserModule') state: any
}
