
























































































// Utils
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import EventBus from '@/event-bus'
import moment from 'moment'
// Components
import Loader from '@/components/Loader.vue'
// Types
import UserTypes from '@/store/types/UserTypes'
// Services
import Notary from '@/services/Notary'

@Component({
  components: { Loader }
})
export default class Appointment extends Vue {
  @Prop({default: false}) review!: boolean
  loading = false
  loading2 = false
  date = new Date()
  today = new Date()
  moment = moment
  events = []
  hourSelected = ''

  @Watch('date')
  changeDate () {
    this.getEvents()
  }

  created () {
    let day = new Date()
    if (day.getDay() === 4) {
      this.today.setDate(this.today.getDate() + 4)
      this.date.setDate(this.date.getDate() + 4)
    } else if (day.getDay() === 5) {
      this.today.setDate(this.today.getDate() + 3)
      this.date.setDate(this.date.getDate() + 3)
    } else {
      this.today.setDate(this.today.getDate() + 2)
      this.date.setDate(this.date.getDate() + 2)
    }
    this.getEvents()
  }

  async getEvents () {
    this.hourSelected = ''
    if (this.date === null) return
    this.loading = true
    const datestring = ("0" + this.date.getDate()).slice(-2) + "-" + ("0"+(this.date.getMonth()+1)).slice(-2) + "-" + this.date.getFullYear()
    const [error, response] = await Notary.getEvents(this.state.company.notary_id, datestring)
    this.loading = false
    if (error) return this.$toasted.global.error(error)
    this.events = response.data.data
  }

  async setEvent () {
    this.loading2 = true
    const dateSelected = ("0" + this.date.getDate()).slice(-2) + "/" + ("0"+(this.date.getMonth()+1)).slice(-2) + "/" + this.date.getFullYear()
    const event_date = dateSelected + ' ' + this.hourSelected + ':00'
    const d = event_date.replace(/[/]/, '').slice(0, 2)
    const m = event_date.replace(/[/]/, '').slice(2, 4)
    const timestampSelected = new Date(m + '/' + d + event_date.slice(5)).getTime()
    const [error] = await Notary.setEvent(this.state.company.id, Number(timestampSelected), event_date.replace(/[/]/g, '-'))
    if (error) {
      this.loading2 = false
      return this.$toasted.global.error(error)
    }
    await this.updateCompany(this.state.company.id)
    this.$toasted.global.success({ message: 'Se agendo cita con èxito' })
    EventBus.$emit('update-dashboard')
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
