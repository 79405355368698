
































// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'
import { debounce } from 'lodash'

@Component({
  components: {}
})
export default class CompanyActivities extends Vue {
  @Prop() activities!: Array<any>
  company: any = { selectActivities: [] }
  open = false
  search = ''

  close = debounce(async (isClose: boolean) => {
    if (!isClose) await this.closeSelectActivity()
  }, 100)

  get filterActivities () {
    let activities = this.activities
    this.company.selectActivities.map((select: any) => { activities = activities.filter((activity: any) => activity.name.toLowerCase() !== select.new_category.toLowerCase() ) })
    return activities
  }

  get searchActivity () {
    const activities = this.filterActivities.filter((activity: any) => activity.name.toLowerCase().match(this.search.toLowerCase()) !== null )
    return activities
  }

  mounted () {
    this.company = this.$attrs.value
  }

  closeSelectActivity () { this.open = false }

  addActivity (id: any, activity: any) {
    this.open = false
    this.search = ''
    return this.company.selectActivities.push({business_twists_id: id, new_category: activity})
  }

  addActivityEnter () {
    this.open = false
    const activity = this.search
    this.search = ''
    const existInSelect = this.company.selectActivities.filter((select: any) => select.new_category.toLowerCase() === activity.toLowerCase())
    if (existInSelect.length > 0) return
    const existActivity = this.activities.filter((aux: any) => aux.name.toLowerCase() === activity.toLowerCase())
    if (existActivity.length > 0) { return this.company.selectActivities.push({business_twists_id: existActivity[0].id, new_category: existActivity[0].name}) }
    return this.company.selectActivities.push({business_twists_id: null, new_category: activity})
  }

  deleteActivity (index: number) { this.company.selectActivities.splice(index, 1) }

  noSpacesStart (value: string) {
    return value.trimStart()
  }
}
