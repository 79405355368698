







































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Types
import UserTypes from '@/store/types/UserTypes'
// Components
import Loader from '@/components/Loader.vue'
// Services
import CompanyService from '@/services/Company'
import AdministrationService from '@/services/Administration'

@Component({
  components: { Loader }
})
export default class ModalEditCompanyType extends Vue {
  modal: any = {
    showModalCompanyType: false,
    update: false,
  }
  types: Array<string> = ['sapi', 'sa', 'srl']
  typeSelect = ''
  loading = false

  mounted () {
    this.modal = this.$attrs.value
    this.typeSelect = this.state.company.company_type
  }

  get legalNameShow () {
    return this.state.company.orders.filter((order: any) => order.status === 'complete').length > 0
  }

  async setDefaultCouncil () {
    const partners = this.state.company.partners.map((partner: any, index: number) => {
      if (this.state.company.company_type === 'sa' && this.state.company.has_unique_administrator) {
        if (index === 0) return { name: this.getPartnerFullName(partner), id: partner.id, role: 'unique_administrator' }
        return { name: this.getPartnerFullName(partner), id: partner.id, role: 'no-member' }
      } else {
        if (index === 0) return { name: this.getPartnerFullName(partner), id: partner.id, role: 'president' }
        else if (index === 1) return { name: this.getPartnerFullName(partner), id: partner.id, role: 'secretary' }
        else if (index === 2) return { name: this.getPartnerFullName(partner), id: partner.id, role: 'treasurer' }
        else return { name: this.getPartnerFullName(partner), id: partner.id, role: 'member' }
      }
    })
    const [error] = await AdministrationService.storeAdministration(this.state.company.id, 'council', partners, false)
    if (error) return this.$toasted.global.error(error)
    await this.updateCompany(this.state.company.id)
  }

  getPartnerFullName (partner: any) {
    let full_name: string = partner.name != null ? partner.name : ''
    full_name = partner.first_names != null ? full_name + ' ' + partner.first_names : full_name
    full_name = partner.last_names != null ? full_name + ' ' + partner.last_names : full_name
    return full_name.trimEnd()
  }

  async updateCompanyType () {
    // Validate select type
    if (this.typeSelect === this.state.company.company_type) return this.modal.showModalCompanyType = false
    // Save select type
    this.loading = true
    const [error] = await CompanyService.updateCompany(this.state.company.id, this.typeSelect)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    // Validate council and set default council
    if (this.typeSelect === 'sapi' && this.state.company.has_unique_administrator) {
      const partners = this.state.company.partners.map((partner: any, index: number) => {
        if (index === 0) return { id: partner.id, role: 'president' }
        if (index === 1) return { id: partner.id, role: 'secretary' }
        return { id: partner.id, role: 'member' }
      })
      const [error] = await AdministrationService.storeAdministration(this.state.company.id, 'council', partners, false)
      if (error) {
        return this.$toasted.global.error(error)
      }
    }
    // Get new user info
    await this.updateCompany(this.state.company.id)
    const existUnique = this.state.company.council.filter((member: any ) => member.council_role === 'unique_administrator').length === 0
    const existPresident = this.state.company.council.filter((member: any ) => member.council_role === 'president').length === 0
    const existSecretary = this.state.company.council.filter((member: any ) => member.council_role === 'secretary').length === 0
    const unique = this.state.company.has_unique_administrator
    if ((this.state.company.company_type === 'sapi' && unique) || (unique && existUnique) || (!unique && (existPresident || existSecretary))) await this.setDefaultCouncil()
    this.modal.showModalCompanyType = false
    this.modal.update = true
    this.loading = false
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
