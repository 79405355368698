
















// Utils
import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'
// Types
import AuthTypes from '@/store/types/AuthTypes'

@Component
export default class Header extends Vue {

  async signOff () {
    await this.logout()
    return this.$router.push({ name: 'Login', query: this.$route.query })
  }

  @Action(`AuthModule/${AuthTypes.actions.LOGOUT}`) logout: any;
}
