import to from 'await-to-js'
import axios from '@/utils/axios-api'

const URL_PHP = process.env.VUE_APP_BASE_URL_PHP

export default class Signature {
  static async getFirms (company: string) {
    // return await to(axios.get(`${URL_PHP}/v2/wetrust/status-document/company/${company}`)) as any
    return await to(axios.get(`${URL_PHP}/v2/company/get-signature-status/${company}`)) as any
  }

  static async startSignature (company: string) {
    return await to(axios.post(`${URL_PHP}/v2/company/send-digital-signature/${company}`)) as any
  }

  static async resetSignature (company: string) {
    return await to(axios.get(`${URL_PHP}/v2/company/reset-signature/${company}`)) as any
  }

  static async startWetrust (company: string) {
    return await to(axios.get(`${URL_PHP}/v2/weetrust/add-document/company/${company}`)) as any
  }

  static async resetWetrust (company: string, document: string) {
    return await to(axios.get(`${URL_PHP}/v2/reset-signature-weetrust/company/${company}/document/${document}`)) as any
  }
}