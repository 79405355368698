







// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Header from '@/components/Header.vue'
import Main from '@/components/Main.vue'

@Component({
  components: { Main, Header }
})
export default class Index extends Vue {
  renderKey = 0

  @Watch('state.company')
  update () { this.renderKey++ }

  @State('UserModule') state: any;
}
