































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
// Components
import HeaderProgressBar from '@/components/HeaderProgressBar.vue'
import ReturnAddress from '@/components/Return.vue'
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
import Activities from '@/components/beginning/CompanyActivities.vue'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'
// Services
import CompanyService from '@/services/Company'

@Component({
  components: { HeaderProgressBar, Footer, Loader, ReturnAddress, Activities }
})
export default class CompanyActivities extends Vue {
  title = '¿Qué actividad realizará?'
  activities: any = []
  company: any = { selectActivities: [] }
  loading = false

  created () {
    this.getActivities()
  }

  async getActivities () {
    const [error, response] = await CompanyService.getActivities()
    if (error) return this.$toasted.global.error(error)
    this.activities = response.data
  }

  async next () {
    await this.setActivities(this.company.selectActivities)
    return this.$router.push({ name: this.state.legal_representation ? 'CompanyType' : 'LegalName', query: this.$route.query })
  }

  @State('CompanyModule') state: any
  @Action(`CompanyModule/${CompanyTypes.actions.SET_ACTIVITIES}`) setActivities: any;
}
