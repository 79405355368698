



































// Utils
import { Component, Vue } from 'vue-property-decorator'

@Component({
})
export default class InvitedValidationComplete extends Vue {
  copys = {
    title: 'Tu verificación fue exitosa',
    text: 'Solo nos falta validar tus datos fiscales'
  }
}
