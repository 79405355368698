








































































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import moment from 'moment'
// Validations
import validations from '@/validations/recover-password'
// Components
import ReturnAddress from '@/components/Return.vue'
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
// Services
import UserService from '@/services/User'

@Component({
  components: { Footer, ReturnAddress, Loader },
  mixins: [validationMixin],
  validations: validations
})
export default class RecoverPassword extends Vue {
  moment = moment
  loading = false
  email = ''
  validEmail = true
  emailSent = false
  cont = 0
  now = moment().valueOf()
  end = moment().valueOf()
  endTime: any = null

  validate (email: string, value: boolean) {
    this.validEmail = email === '' ? true : value
  }

  get resendRepeat () {
    return this.cont === 0 ? true : this.now >= this.end
  }

  get min (){
    if ((this.end - this.now) <= 0) return '00'
    let m = Math.trunc((this.end - this.now) / 1000 / 60) % 60
    return m <= 0 ? '00' : m > 9 ? m : '0' + m
  }

  get sec (){
    if ((this.end - this.now) <= 0) return '00'
    let s = Math.trunc((this.end - this.now)/1000) % 60
    return s <= 0 ? '00' : s > 9 ? s : '0' + s
  }

  async next () {
    this.loading = true
    const [error] = await UserService.resetPasswordEmail({ identity: this.email })
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.loading = false
    return this.emailSent = true
  }

  async resend () {
    this.loading = true
    const [error] = await UserService.resetPasswordEmail({ identity: this.email })
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.createTimer()
    this.loading = false
    return this.$toasted.global.success({ message: 'Se ha reenviado el email' })
  }

  createTimer () {
    this.cont++
    this.now = moment().valueOf()
    this.end = moment().valueOf() + (60000 * this.cont)
    this.endTime = setInterval(()=>{
      this.now = moment().valueOf()
      if (this.now >= this.end) {
        clearInterval(this.endTime)
      }
    }, 1000)
  }

  beforeDestroy(){
    clearInterval(this.endTime)
  }
}
