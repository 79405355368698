import store from '@/store/index'
import CompanyTypes from '@/store/types/CompanyTypes'
import { Chat } from '@/helpers/ChatScript'

export default class Signup {
  static async checkValidations (to: any, from: any, next: any) {
    const payload = store.getters[`CompanyModule/${CompanyTypes.getters.GET_DATA}`]
    if (to.name === 'Options') return next()
    else if (to.name !== 'Options' && payload.legal_representation === null) return next({ name: 'Options', query: to.query })
    else if (to.name === 'Register') return next()
    else if (to.name === 'TypeSociety') return next()
    else if (to.name !== 'TypeSociety' && payload.type_Society === '') return next({ name: 'TypeSociety', query: to.query })
    else if (to.name === 'LegalName') return next()
    else if (to.name !== 'LegalName' && payload.legal_name === '') return next({ name: 'LegalName', query: to.query })
    return next()
  }
  static async checkRepresentation (to: any, from: any, next: any) {
    const payload = store.getters[`CompanyModule/${CompanyTypes.getters.GET_DATA}`]
    if (payload.legal_representation === null) return next({ name: 'Options', query: to.query })
    return next()
  }
  static async checkLegalName (to: any, from: any, next: any) {
    const payload = store.getters[`CompanyModule/${CompanyTypes.getters.GET_DATA}`]
    if (payload.legal_representation === null) return next({ name: 'Options', query: to.query })
    else if (payload.legal_name === '') return next({ name: 'LegalName', query: to.query })
    return next()
  }
  static async checkTypeSociety (to: any, from: any, next: any) {
    const payload = store.getters[`CompanyModule/${CompanyTypes.getters.GET_DATA}`]
    if (payload.legal_representation === null) return next({ name: 'Options', query: to.query })
    else if (payload.type_Society === '') return next({ name: 'TypeSociety', query: to.query })
    return next()
  }
  static async checkCompanyActivities (to: any, from: any, next: any) {
    const payload = store.getters[`CompanyModule/${CompanyTypes.getters.GET_DATA}`]
    if (payload.legal_representation === null) return next({ name: 'Options', query: to.query })
    else if (payload.company_activity.activity === '' || payload.company_activity.description === '' || payload.company_activity.products === '') return next({ name: 'CompanyActivities', query: to.query })
    return next()
  }
  static async redirectToRegister (to: any, from: any, next: any) {
    if (to.name === 'CrearCuenta') next({ name: 'Options', query: to.query })
    if (to.name === 'LegalNameOld') next({ name: 'Options', query: to.query })
    if (to.name === 'PersonalInformationOld') next({ name: 'Options', query: to.query })
    return next()
  }
  static async setChat (to: any, from: any, next: any) {
    Chat.bodyReplace('/chatBeforeLogin.js', '/chatAfterLogin.js', 'conversations-wrap', '')
    return next()
  }
  static async deleteChat (to: any, from: any, next: any) {
    Chat.delete('div', '/chatHilos.js', 'hilos-whatsapp-button')
    return next()
  }
}
