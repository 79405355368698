




















// Utils
import { Component, Vue } from 'vue-property-decorator'
// Components
import ReturnAddress from '@/components/Return.vue'
import Footer from '@/components/Footer.vue'
import NewPasswordUser from '@/components/account/password/NewPassword.vue'

@Component({
  components: { Footer, ReturnAddress, NewPasswordUser }
})
export default class UserNewPassword extends Vue {
}
