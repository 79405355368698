import to from 'await-to-js'
import axios from '@/utils/axios-api'
import Partner from '@/interfaces/Partner'

const URL_PHP = process.env.VUE_APP_BASE_URL_PHP

export default class Partners {
  static async store (company: string, data: Partner) {
    return await to(axios.post(`${URL_PHP}/v2/partner-basic/company/${company}`, data)) as any
  }

  static async storeMultiplePartners (company: string, partners: Array<any>) {
    return await to(axios.post(`${URL_PHP}/v2/add-partners/company/${company}`, { partners })) as any
  }

  static async updatePartners (partners: Array<any>) {
    return await to(axios.put(`${URL_PHP}/v2/update-partners`, { partners })) as any
  }

  static async storeComplements (partner: string, rfc: string, birthplace: string, tax_country : string, tax_id : string, civil_status: string, accept_terms_conditions: boolean) {
    return await to(axios.post(`${URL_PHP}/v2/partner-complements/${partner}`, { rfc, birthplace, tax_country, tax_id, civil_status, accept_terms_conditions })) as any
  }

  static async invitationResponse (partner: string, company_id: string, invitation: boolean) {
    return await to(axios.post(`${URL_PHP}/v2/attach-partner-with-company-by-invitation/partner/${partner}`, { company_id, invitation})) as any
  }

  static async storeNationalities (partner: string, nationalities: any) {
    return await to(axios.post(`${URL_PHP}/v2/partner/set-nationalities/${partner}`, { nationalities })) as any
  }

  static async updateBasics (partner: string, name: string, first_name: string, last_name: string) {
    return await to(axios.put(`${URL_PHP}/v2/update-partner/${partner}`, { name, first_name, last_name })) as any
  }

  static async deletePartner (partner: string, company: string) {
    return await to(axios.post(`${URL_PHP}/v2/delete-partner/${partner}/company/${company}`)) as any
  }

  static async getPartner (partner: string, company: string) {
    return await to(axios.get(`${URL_PHP}/v2/only-partner/${partner}/company/${company}`)) as any
  }

  static async sendInvitations (company: string) {
    return await to(axios.post(`${URL_PHP}/v2/partners/send-invitations/${company}`)) as any
  }

  static async existPartnerUser (email: string) {
    return await to(axios.post(`${URL_PHP}/v2/partner/exists`, { email })) as any
  }
}
