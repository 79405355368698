





































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component({ components: {} })
export default class FinalStepper extends Vue {
  points: any = []
  styleDisabled = 'background: #FFFFFF; border: 2px solid #EEEEEE;'
  styleActivated = 'background: #ECF0F5; border: 2px solid #9D73FF;'
  styleCompleted = 'background: #9D73FF;'
  numbersSteps = 5

  created () {
    this.points = this.$attrs.value
  }

  get getSteppers () {
    const pointsAux = this.points.filter((point: any ) => point.stepper === true)
    this.numbersSteps = pointsAux.length
    return pointsAux
  }

  get NumberStepActive () {
    let pointsAux = this.points.filter((point: any ) => point.stepper === true && !point.complete && point.active)
    if (pointsAux.length === 0) {
      pointsAux = this.points.filter((point: any ) => point.stepper === true && point.complete && point.active)
      return pointsAux.length
    }
    return Number(pointsAux[0].number)
  }

  @State('UserModule') state: any
}
