









































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import CardNewCompany from '@/components/company/CardNewCompany.vue'
import CardCompanyActivities from '@/components/company/CardCompanyActivities.vue'
import CardAllPartners from '@/components/detail/CardAllPartners.vue'

@Component({
  components: { Footer, CardNewCompany, CardCompanyActivities, CardAllPartners }
})
export default class Resume extends Vue {
  company = {
    id: '', 
    type: '',
    activities: {
      activity: '',
      description: '',
      products: ''
    },
    loading: false
  }

  created () {
    this.company.id = this.state.company.id
    this.company.type = this.state.company.company_type
    this.company.activities.activity = this.state.company.company_activity.activity
    this.company.activities.description = this.state.company.company_activity.description
    this.company.activities.products = this.state.company.company_activity.products
  }
  
  @State('UserModule') state: any
}
