import store from '@/store'
import UTMTypes from '@/store/types/UTMTypes'

export class Posthog {
  static reset() {
    window.posthog.reset()
    return
  }
  static identify(id: string, user: any) {
    let UserId = id
    if (UserId.length < 5) {
      for (let i = 0; i < (5 - id.length); i++) UserId = '0' + UserId
    }
    window.posthog.identify(UserId, {
      Email: user.email,
      FirstName: user.name ? user.name : '-',
      LastName: user.first_names ? user.first_names : '-',
      Phone: user.phone ? user.phone : '-',
      CompanyName: user.company ? user.company.name : '-'
    })
    return
  }
  static async event(name: string, props: any) {
    const localUTMs = store.getters[`UTMModule/${UTMTypes.getters.GET_DATA}`]
    await window.posthog.capture(name, { ...props, ...localUTMs })
    return
  }
}
