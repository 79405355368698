































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
// Types
import UserTypes from '@/store/types/UserTypes'
// Services
import CompanyService from "@/services/Company"

@Component({
  components: { Loader }
})
export default class CompleteSigning extends Vue {
  loading = false

  async signingComplete () {
    const data = {
      stage_name: 'signing_complete',
      company_id: this.state.company.id,
      is_completed: true
    }
    const [error] = await CompanyService.updateStage(data)
    if (error) {
      return this.$toasted.global.error(error)
    }
    await this.updateCompany(this.state.company.id)
    return this.$router.push({ name: 'Dashboard', query: this.$route.query, params: { company_id: this.state.company.id } })
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
