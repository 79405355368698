





























































// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Types
import UserTypes from '@/store/types/UserTypes'
// Components
import Loader from '@/components/Loader.vue'
import InputFile from '@/components/InputFile.vue'
// Services
import SATService from '@/services/SAT'
import CompanyService from '@/services/Company'

@Component({
  components: { Loader, InputFile }
})
export default class CardCompanyFiels extends Vue {
  @Prop({ default: 'Welcome' }) address!: string;
  loading = false
  loadingModal = false
  showModal = false
  rfc = ''
  password = ''
  key: any = null
  cer: any = null

  mounted () {
    this.rfc = this.state.company.rfc === null ? '' : this.state.company.rfc
  }

  noSpacesStartRFC (value: string) {
    return value.trimStart().toUpperCase()
  }

  advance () {
    this.loadingModal = true
    return this.$router.push({ name: this.address, query: this.$route.query, params: { company_id: this.state.company.id } })
  }

  async saveFiel (file: any, type: string) {
    const [error] = await SATService.setFIELCredential( this.state.company.id, file, type )
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    return null
  }

  async saveRFC () {
    this.loading = true
    if ((this.cer === null || !this.cer) && (this.key === null || !this.key) && this.rfc === '' && this.password === '') return this.loading = false
    const [errorRFC] = await CompanyService.setRFC(this.state.company.id, this.rfc)
    if (errorRFC) {
      this.loading = false
      return this.$toasted.global.error(errorRFC)
    }
    if (this.cer) {
      if (await this.saveFiel(this.cer, '.cer') !== null) return
    }
    if (this.key) {
      if (await this.saveFiel(this.key, '.key') !== null) return
    }
    const [error] = await SATService.setFIELPassword(this.state.company.id, this.password)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    await this.updateCompany(this.state.company.id)
    this.showModal = true
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
