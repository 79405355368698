











































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
// Services
import CompanyService from '@/services/Company'

@Component({
  components: { Loader }
})
export default class ModalDeliveryDHL extends Vue {
  modal: any = { showModalDeliveryDHL: false, update: false }
  loading = false
  numberGuide = ''
  deliveryStatus: any = null
  link = ''

  @Watch('modal.showModalDeliveryDHL')
  getStatusDelivery () {
    if (!this.modal.showModalDeliveryDHL || this.deliveryStatus !== null) return
    this.getDelivery()
  }

  mounted () {
    this.modal = this.$attrs.value
    this.numberGuide = this.state.company.shipping_delivery
  }

  async getDelivery () {
    this.loading = true
    const [error, response] = await CompanyService.getDelivery(this.state.company.id)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    if (response.data.data === undefined || response.data.data.shippingData.shipments === undefined) return
    this.deliveryStatus = response.data.data.shippingData.shipments[0]
    this.link = response.data.data.linkDHL
    this.loading = false
  }

  @State('UserModule') state: any
}
