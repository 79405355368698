





























// Utils
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: { }
})
export default class CardReview extends Vue {
  review: any = {}
  reviews = [{
    imgCEO: require('@/assets/images/testimonials/InmotionCEO.png'),
    imgTitle: require('@/assets/images/testimonials/Inmotion.png'),
    titleStyle: 'width: 122px; height: 24px;',
    nameCEO: 'Aida Taveras',
    position: 'CEO at Inmotion',
    text: '“Tienen un servicio integral a un precio razonable. Además cuentan con atención personalizada.”',
    band: require('@/assets/icons/flag-dominica-republic.svg')
  },{
    imgCEO: require('@/assets/images/testimonials/KrowdyCEO.png'),
    imgTitle: require('@/assets/images/testimonials/Krowdy.png'),
    titleStyle: 'width: 82px; height: 25px;',
    nameCEO: 'Oscar Villamonte',
    position: 'Managing Partner KROWDY',
    text: '“Te mantienen al tanto de la cosas a medida que va avanzando el trámite”',
    band: require('@/assets/icons/flag-peru.svg')
  },{
    imgCEO: require('@/assets/images/testimonials/FirmaVirtualCEO.png'),
    imgTitle: require('@/assets/images/testimonials/FirmaVirtual.png'),
    titleStyle: 'width: 166px; height: 24px;',
    nameCEO: 'Diego Chávez',
    position: 'Country Manager Firma Virtual Legal',
    text: '“Estamos muy contentos con la opción que hemos podido encontrar en Tally”',
    band: require('@/assets/icons/flag-chile.svg')
  },{
    imgCEO: require('@/assets/images/testimonials/LatitudCEO.png'),
    imgTitle: require('@/assets/images/testimonials/Latitud.png'),
    titleStyle: 'width: 128px; height: 19px;',
    nameCEO: 'Brian Requarth',
    position: 'Co-Founder',
    text: '“Recién creada una subsidiaria gracias a Tally ¡Vamos Latituders!.”',
    band: require('@/assets/icons/flag-brasil.svg')
  },{
    imgCEO: require('@/assets/images/testimonials/InmotionCEO.png'),
    imgTitle: require('@/assets/images/testimonials/Fonoa.png'),
    titleStyle: 'width: 106px; height: 22px;',
    nameCEO: 'Aida Taveras',
    position: 'Co-Founder',
    text: '“Iniciamos operaciones en México en menos de un mes, listos para contratar a nuestros empleados.”',
    band: require('@/assets/icons/flag-croacia.svg')
  },{
    imgCEO: require('@/assets/images/testimonials/PortBlueCEO.png'),
    imgTitle: require('@/assets/images/testimonials/PortBlue.png'),
    titleStyle: 'width: 75px; height: 40px;',
    nameCEO: 'Ismael Triay',
    position: 'Deputy CEO',
    text: '“Impresionante la diferente de Tally y los servicios tradicionales ¡Altamente recomendado!”',
    band: require('@/assets/icons/flag-españa.svg')
  },{
    imgCEO: require('@/assets/images/testimonials/FormulaAirCEO.png'),
    imgTitle: require('@/assets/images/testimonials/FormulaAir.png'),
    titleStyle: 'width: 148px; height: 25px;',
    nameCEO: 'Hà Mạnh Cường',
    position: 'Co-Owner at Formula Air JSC',
    text: '“Un rapido servicio. Algunos procesos son complicados de hacer en Mexico pero con Tally todo fue simple.”',
    band: require('@/assets/icons/flag-vietnam.svg')
  }]
  loop: any = null
  cont = 0

  created () {
    this.review = this.reviews[0]
    this.loop = setInterval(()=>{
      this.review = this.reviews[this.cont]
      if ((this.cont + 1) < this.reviews.length) this.cont++
      else  this.cont = 0
    }, 5000)
  }
  
  beforeDestroy(){
    clearInterval(this.loop)
  }
}
