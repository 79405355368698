















































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Header from '@/components/Header.vue'

@Component({
  components: { Header }
})
export default class Welcome extends Vue {
  @State('UserModule') state: any
}
