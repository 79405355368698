





































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
// Components
import HeaderProgressBar from '@/components/HeaderProgressBar.vue'
import ReturnAddress from '@/components/Return.vue'
import Footer from '@/components/Footer.vue'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'

@Component({
  components: { HeaderProgressBar, ReturnAddress, Footer }
})
export default class Packages extends Vue {
  loading = false
  title = 'Elige un paquete'
  packages = [{
      type: 'Softlanding',
      amount: '$6,500.00 MXN al mes',
      points: [
        'Constitución de empresa',
        'Obtención de RFC y Efirma',
        'Cuenta bancaria BBVA',
        'Domicilio fiscal  ',
        'Contabilidad e impuestos',
        'Registro en Inversión Extranjera',
        'Facturación'
      ]
    },{
      type: 'Expansion',
      amount: '$15,500.00 MXN al mes',
      points: [
        'Todo lo que incluye Softlanding más:',
        'Transmisión de acciones a Holding',
        'Payroll  (incluye alta IMSS)',
        'Representación ante otras autoridades',
        'Gobierno Corporativo',
        'Registro de marca  ante IMPI',
        'Implementación de contratos'
      ]
    },
  ]

  async next (type: string) {
    this.loading = true
    await this.setPackage(type)
    return this.$router.push({ name: 'PersonalInformation', query: this.$route.query })
  }

  @State('CompanyModule') state: any
  @Action(`CompanyModule/${CompanyTypes.actions.SET_PACKAGE}`) setPackage: any;
}
