

































































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import ModalRefuseLegalName from '@/components/company/ModalRefuseLegalName.vue'

@Component({
  components: { ModalRefuseLegalName }
})
export default class CardLegalName extends Vue {
  payment = false
  legalNameApproved = []
  modal: any = {
    showModalRefuseLegalName: false,
    update: false,
  }

  @Watch('modal.update')
  async update () {
    if(!this.modal.update) return
    this.validations()
    this.modal.update = false
  }

  get legalNameStatus () {
    const denominationsRejects = this.state.company.legal_names.filter((legal_name: any) => legal_name.is_approved === false && legal_name.rejection_category !== null && legal_name.rejection_category !== '')
    const denominationsApproved = this.state.company.legal_names.filter((legal_name: any) => legal_name.is_approved === true)
    if (denominationsApproved.length > 0) return 'Complete'
    else if (denominationsRejects.length === this.state.company.legal_names.length) return 'Reject'
    return 'Review'
  }

  mounted () {
    this.validations()
  }

  validations () {
    this.legalNameApproved = this.state.company.legal_names.filter((legal_name: any) => legal_name.is_approved === true)
    this.payment = this.state.company.orders.filter((order: any) => order.status === 'complete').length > 0
  }

  @State('UserModule') state: any
}
