





























































































































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
// Services
import CountryService from '@/services/Country'

@Component({
  components: { Loader }
})
export default class CardAllPartners extends Vue {
  editActive = false
  nationalities: any = []

  async created () {
    await this.getNationalities()
  }

  get companyTotalShares () {
    const companyShares = this.state.company.share_series.find((shares: any) => shares.company_id === this.state.company.id)
    return companyShares
  }

  mounted () {
    const documentsApproved = this.state.company.stages.find((stage: any) => stage.stage_name === 'documents_approved').is_completed === true
    if (this.state.company.timer === null && !documentsApproved) this.editActive = true
  }

  getPartnerShares (partner: any) {
    const companyShares = partner.shares.find((shares: any) => shares.serie.company_id === this.state.company.id)
    return companyShares
  }

  completedComplementaries (partner: any) {
    //Mati status check
    let matiCheck = 'Pending'
    if (partner.mati_verification !== null) {
      matiCheck = partner.mati_status.length ? partner.mati_status[0].status : 'Pending'
      if (matiCheck === 'Reject' || matiCheck === 'Denied') matiCheck = 'Error'
    }

    //Exist documents refused
    const documentsError = partner.documents.filter((document: any) => !document.is_approved && document.rejection_category)
    if (documentsError.length > 0) return 'Error'

    //Complementaries were uploaded
    const missingComplementaries = partner.civil_status === 'soltero' ?
      partner.cfi.document_url === null :
      (partner.cfi.document_url === null || 
        partner.id_spouse.document_url === null ||
        partner.marriage_certificate.document_url === null)
    if (missingComplementaries) {
      return matiCheck === 'Error' ? matiCheck : 'Pending'
    }

    //Mati files were uploaded
    if (partner.mati_verification === null) return matiCheck === 'Error' ? matiCheck : 'Pending'

    //Complementaries check
    let complete_complementaries = partner.civil_status === 'soltero' ?
      partner.cfi.document_url !== null && partner.cfi.is_approved :
      (partner.cfi.document_url !== null && partner.cfi.is_approved && 
      partner.id_spouse.document_url !== null && partner.id_spouse.is_approved &&
      partner.marriage_certificate.document_url !== null && partner.marriage_certificate.is_approved)
    if (!complete_complementaries) return matiCheck === 'Error' ? matiCheck : 'Validating'
    return matiCheck
  }

  async getNationalities () {
    const [error, response] = await CountryService.nationalities()
    if (error) {
      return this.$toasted.global.error(error)
    }
    this.nationalities = response.data.data
  }

  getNationality (issueCountry: string) {
    if (issueCountry.length > 3 || this.nationalities.length === 0) return issueCountry
    if (issueCountry.length === 3) return this.nationalities.filter((partner: any ) => partner.code === issueCountry)[0].name
    return this.nationalities.filter((partner: any ) => partner.issue_country === issueCountry)[0].name
  }

  @State('UserModule') state: any
}
