import Vue from 'vue'
// PLUGINS
import './plugins/bootstrap-vue'
import './plugins/load-script'
import './plugins/vue-calendar'
import './plugins/vue-validate'
import './plugins/vue-toasted'
import './plugins/vue-swiper'
// UTILS
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
