















































// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import { validationMixin } from "vuelidate"
// Components
import Loader from '@/components/Loader.vue'
// Validations
import validations from "@/validations/login"
// Types
import AuthTypes from "@/store/types/AuthTypes"
// Services
import PartnersService from '@/services/Partners'

@Component({
  components: { Loader },
  mixins: [validationMixin],
  validations: validations,
})
export default class NormalLogin extends Vue {
  @Prop({default: 'Welcome'}) address!: string
  @Prop({default: ''}) company_id!: string
  @Prop({default: ''}) userEmail!: string
  loading = false
  email = ''
  password = ''
  passwordFieldType = 'password'
  eye = 'eye'
  validEmail = true
  validPassword = true

  mounted () {
    this.email = this.userEmail
  }

  switchVisibility () {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    this.eye = this.eye === 'eye' ? 'eye-slash' : 'eye'
  }

  validate (email: string, value: boolean) {
    this.validEmail = email === '' ? true : value
  }

  async login () {
    try {
      this.loading = true
      const [err] = await this.loginUser({ email: this.email, password: this.password })
      if (err) {
        this.loading = this.validPassword = false
        return this.$toasted.global.error(err)
      }
      if (this.$route.name === 'Start') {
        // eslint-disable-next-line
        const [error, response] = await PartnersService.invitationResponse(this.invitedState.partner.id, this.invitedState.company_id, true)
        if (error) {
          this.loading = false
          return this.$toasted.global.error(error)
        }
      }
      return this.$router.push({ name: this.address, query: this.$route.query, params: { company_id: this.company_id } }).catch(() => { return })
    } catch (e) {
      this.loading = this.validPassword = false
      return this.$toasted.global.error(e)
    }
  }

  @Action(`AuthModule/${AuthTypes.actions.LOGIN}`) loginUser: any
  @State('InvitedModule') invitedState: any
}
