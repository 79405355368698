















// Utils
import { Component, Vue } from 'vue-property-decorator'
// Components
import ReturnAddress from '@/components/Return.vue'
import Footer from '@/components/Footer.vue'
import NewEmailUser from '@/components/account/email/NewEmail.vue'

@Component({
  components: { Footer, ReturnAddress, NewEmailUser }
})
export default class UserNewEmail extends Vue {
}
