













































































// Utils
import { Component, Vue } from 'vue-property-decorator'
// Components
import Footer from '@/components/Footer.vue'
import ReturnAddress from '@/components/Return.vue'
import TooltipMonthlyRepresentation from '@/components/payment/TooltipMonthlyRepresentation.vue'
// Services
import PaymentService from '@/services/Payment'

@Component({
  components: { Footer, ReturnAddress, TooltipMonthlyRepresentation }
})
export default class Representation extends Vue {
  points = [
    'La Constitución de tu empresa',
    'La obtención del RFC y la firma FIEL',
    'Registro de Inversión Extranjera',
    'Representación ante el SAT',
    'Cuenta de Banco',
    'Domicilio Fiscal',
    'Contabilidad y facturación'
  ]
  currency = ['MXN', 'USD']
  coin = 'MXN'
  RFC = ''
  open = false
  priceUSD = '380'
  priceMXN = '6500'
  price = 6500

  mounted () {
    this.priceMXN = this.price.toString().replace(/\d(?=(?:\d{3})+$)/g, '$&,')
    // this.getRate()
  }

  async getRate () {
    const [error, response] = await PaymentService.getUSDRate('MXN', 'USD')
    if (error) { return }
    return this.priceUSD = Math.trunc(response.data.rates.USD * this.price).toString().replace(/\d(?=(?:\d{3})+$)/g, '$&,')
  }

  async next () {
    return this.RFC === 'SI' ?
      this.$router.push({ name: 'Register', query: this.$route.query }) :
      this.$router.push({ name: 'RepresentationInfo', query: this.$route.query })
  }
}
