
























































// Utils
import { Component, Vue } from 'vue-property-decorator'
// Components
import Footer from '@/components/Footer.vue'
import InputFile from '@/components/InputFile.vue'
import TooltipCFI from '@/components/partners/TooltipCFI.vue'
import Loader from '@/components/Loader.vue'
// Services
import CommissaryService from '@/services/Commissary'

@Component({
  components: { Footer, InputFile, TooltipCFI, Loader }
})
export default class CommissaryComplementaries extends Vue {
  loading = false
  cfi: any = ''
  company = ''

  mounted () {
    this.company = this.$route.query.company.toString()
  }

  async next () {
    this.loading = true
    if (this.cfi === null || !this.cfi.name || !this.cfi.size) return
    const [error] = await CommissaryService.setCommissaryCSF(this.company, this.cfi)
    if (error) {
      this.loading = false
      return
    }
    return this.$router.push({ name: 'CommissaryEnd', query: this.$route.query })
  }
}
