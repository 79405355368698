





























// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import CardCompanyFiels from '@/components/administration/CardCompanyFiels.vue'
import ReturnAddress from '@/components/Return.vue'
import Message from '@/components/Message.vue'
import Footer from '@/components/Footer.vue'

@Component({
  components: { Footer, ReturnAddress, Message, CardCompanyFiels }
})
export default class BenefitsFiels extends Vue {
  title = 'Tips'
  text = 'El 45% de las empresas pierde sus claves o no las encuentra, por ello, agrégalas y siempre podrás consultarlas en tus documentos'

  @State('UserModule') state: any
}
