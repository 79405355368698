

















// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
})
export default class TooltipPowers extends Vue {
  @Prop() id!: string
  divID = 'tooltip-powers'

  created () {
    if (this.id) this.divID = 'tooltip-powers-' + this.id
  }
}
