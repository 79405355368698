// Utils
import { RouteConfig } from 'vue-router'
import multiguard from 'vue-router-multiguard'
// Views
import Index from '@/views/company/Index.vue'
import EditCompany from '@/views/company/EditCompany.vue'
import EditLegalName from '@/views/company/EditLegalName.vue'
import RefuseLegalName from '@/views/company/RefuseLegalName.vue'
// Middlewares
import Auth from '@/middlewares/Auth'
import User from '@/middlewares/User'

const routes: Array<RouteConfig> = [
  {
    path: '/company/:company_id',
    component: Index,
    beforeEnter: multiguard([Auth.isLogged, User.validateData]),
    children: [
      {
        path: 'edit_company',
        name: 'EditCompany',
        component: EditCompany
      },
      {
        path: 'edit_legal_name',
        name: 'EditLegalName',
        component: EditLegalName
      },
      {
        path: 'refuse_legal_name',
        name: 'RefuseLegalName',
        component: RefuseLegalName
      }
    ]
  }
]

export default routes
