






























































































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import { debounce } from 'lodash'
// Components
import Loader from '@/components/Loader.vue'
// Services
import AdministrationService from '@/services/Administration'
// Types
import UserTypes from '@/store/types/UserTypes'

@Component({
  components: { Loader }
})
export default class ModalEditAdmin extends Vue {
  modal: any = {
    showModalCompanyAdmin: false,
    update: false,
  }
  roles: Array<string> = [ 'president', 'secretary', 'member', 'no-member']
  loading = false
  open = false
  partners: any = []
  partnersUnique: any = []
  adminUnique = false
  unique = ''
  uniqueName = ''
  commissaryID = ''

  @Watch('state.company.partners')
  async update () {
    this.getAdmin()
  }

  close = debounce(async (isClose: boolean, id: any) => {
    if (!isClose && id === null) await this.closeSelect()
    else if (!isClose) await this.closeSelectRol(id)
  }, 100)

  get partnerNotCommissary () {
    const partners = this.state.company.partners.filter((partner: any ) => partner.id !== this.commissaryID)
    return partners
  }

  mounted () {
    this.modal = this.$attrs.value
    if (this.state.company.council.length > 0) this.getAdmin()
  }

  closeSelectRol (id: string) {
    this.partners.map((partner: any, index: number) => { if (partner.id === id) this.partners[index].open = false })
    this.partnersUnique.map((partner: any, index: number) => { if (partner.id === id) this.partnersUnique[index].open = false })
  }

  closeSelect () { this.open = false}

  getAdmin () {
    this.adminUnique = this.state.company.has_unique_administrator
    this.roles = this.state.company.partners.length === 2 ?
      ['president', 'secretary'] : this.state.company.partners.length === 3 ?
      ['president', 'secretary', 'treasurer', 'no-member'] : ['president', 'secretary', 'treasurer', 'member', 'no-member']
    this.partners = []
    this.partnersUnique = []
    this.state.company.partners.map((partner: any) => {
      let partnerRole = this.state.company.council.filter((member: any ) => member.partner_id === partner.id)
      const isCommissary = partnerRole.filter((member: any ) => member.council_role === 'commissary')
      if (isCommissary.length > 0) {
        partnerRole = isCommissary
        this.commissaryID = partner.id
      }
      this.partners.push({
        name: this.getPartnerFullName(partner),
        role: partnerRole.length === 0 ? 'no-member' : partnerRole[0].council_role === 'unique_administrator' ? 'no-member' : partnerRole[0].council_role === 'commissary' ? 'commissary' : partnerRole[0].council_role,
        open: false,
        id: partner.id
      })
      this.partnersUnique.push({
        name: this.getPartnerFullName(partner),
        role: partnerRole.length === 0 ? 'no-member' : partnerRole[0].council_role === 'unique_administrator' ? partnerRole[0].council_role : partnerRole[0].council_role === 'commissary' ? 'commissary' : 'no-member',
        open: false,
        id: partner.id
      })
      if (partnerRole.length > 0 && partnerRole[0].council_role === 'unique_administrator') this.unique = partner.id
    })
    this.setDefaultAdmin()
  }

  setDefaultAdmin () {
    const existUnique = this.partnersUnique.filter((member: any ) => member.role === 'unique_administrator').length === 0
    const existPresident = this.partners.filter((member: any ) => member.role === 'president').length === 0
    const existSecretary = this.partners.filter((member: any ) => member.role === 'secretary').length === 0
    if (this.adminUnique && existUnique) {
      this.partnersUnique[0].role = 'unique_administrator'
      this.unique = this.partnersUnique[0].id
      this.uniqueName = this.partnersUnique[0].name
    }
    if (existPresident || existSecretary) {
      this.partners[0].role = 'president'
      this.partners[1].role = 'secretary'
      if (this.partners.length > 2) this.partners[2].role = 'treasurer'
    }
  }

  getPartnerFullName (partner: any) {
    let full_name: string = partner.name != null ? partner.name : ''
    full_name = partner.first_names != null ? full_name + ' ' + partner.first_names : full_name
    full_name = partner.last_names != null ? full_name + ' ' + partner.last_names : full_name
    return full_name.trimEnd()
  }

  getRoleName (role: string) {
    return role === 'president' ? 'Presidente' : role === 'secretary' ? 'Secretario' : role === 'treasurer' ? 'Tesorero' :  role === 'member' ? 'Miembro' : role === 'commissary' ? 'Comisario' : 'No es miembro'
  }

  setAdminUnique (partner: any) {
    this.partnersUnique.map((partnerAux: any, index: number) => { this.partnersUnique[index].role = (partner.id === partnerAux.id) ? 'unique_administrator' : 'no-member' })
    this.unique = partner.id
    this.uniqueName = this.getPartnerFullName(partner)
    this.open = false
  }

  filter (partner: any) {
    // Get the repeating roles, if there are none, terminate
    let repeat = this.partners.filter((member: any ) => (member.role === partner.role) && (member.role !== 'member') && (member.role !== 'no-member'))
    if (repeat.length <= 1) return
    // Filter partner received
    repeat = repeat.filter((member: any ) => member.id !== partner.id)
    // Check if there is the president, the secretary and the treasurer
    const existPresident = this.partners.filter((member: any ) => member.role === 'president').length > 0
    const existSecretary = this.partners.filter((member: any ) => member.role === 'secretary').length > 0
    const existTreasurer = this.partners.filter((member: any ) => member.role === 'treasurer').length > 0
    // Decide the new role of the partner
    const role = (existPresident && existSecretary && existTreasurer) ? 'member' : (!existPresident) ? 'president' : (!existSecretary) ? 'secretary' : 'treasurer'
    // Find and assign the new role
    this.partners.map((partner: any, index: number) => {
      if (partner.id === repeat[0].id) this.partners[index].role = role
    })
  }

  get style () {
    const cant = this.adminUnique ? this.partners.length : this.roles.length
    return cant === 1 ? 'height: 55px;' :
      cant === 2 ? 'height: 110px;' :
      cant === 3 ? 'height: 165px;' :
      cant === 4 ? 'height: 220px;' :
      cant === 5 ? 'height: 275px;' : 'height: 290px;'
  }

  get validateAdmin () {
    if (this.adminUnique) {
      const existUnique = this.partnersUnique.filter((member: any ) => member.role === 'unique_administrator').length === 0
      return existUnique
    } else {
      const existPresident = this.partners.filter((member: any ) => member.role === 'president').length === 0
      const existSecretary = this.partners.filter((member: any ) => member.role === 'secretary').length === 0
      return existPresident || existSecretary
    }
  }

  async updateAdmin () {
    this.loading = true
    let council = this.adminUnique ? this.partnersUnique : this.partners
    council = council.filter((member: any ) => member.id !== this.commissaryID)
    // Save council
    const [error] = await AdministrationService.storeAdministration(this.state.company.id, this.adminUnique ? 'unique' : 'council', council, this.$route.name === 'Beginning')
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    // Get new user info
    await this.updateCompany(this.state.company.id)
    this.modal.showModalCompanyAdmin = false
    this.modal.update = true
    this.loading = false
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
