
















// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'

@Component({
  components: { Loader }
})
export default class ModalAccountStatements extends Vue {
  copys: any = {
    title1: 'Conoce que es un Estado de Cuenta',
    text1: 'El estado de cuenta es un documento oficial emitido por el banco al titular de una cuenta, en el que se pueden revisar todos los movimientos realizados durante un período: consumos, transferencias, pagos recibidos, retiros, uso y pagos, entre otros.',
    title2: '¿Cómo se obtiene un estado de cuenta?',
    text2: 'Puedes acceder a uno entrando a la banca en línea de tu banco, en la opción “estado de cuenta” y descargarlo.',
    message: 'Es importante que subas todos los estados de cuenta que reflejen los movimientos financieros de tu empresa, ya sean en otra moneda como en dólares o euros y los que sean de cuentas fintech, por ejemplo de una tarjeta de crédito.',
    buttonText: 'Entendido'
  }
  modal: any = {
    showModalAccountStatements: false
  }

  mounted () {
    this.modal = this.$attrs.value
  }

  @State('UserModule') state: any
}
