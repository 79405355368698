






































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import CardCheckout from '@/components/payment/checkout/CardCheckout.vue'
import CardCheckoutItems from '@/components/payment/checkout/CardCheckoutItems.vue'
import HeaderProgressBar from '@/components/HeaderProgressBar.vue'
import ReturnAddress from '@/components/Return.vue'
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
// Services
import Payments from '@/services/Payment'
import SATService from '@/services/SAT'

@Component({
  components: { HeaderProgressBar, Footer, ReturnAddress, CardCheckout, CardCheckoutItems, Loader }
})
export default class CheckoutAccountant extends Vue {
  loading = true
  pay = {
    items: [{
      id: '',
      name: '',
      price: '',
      price_id: ''
    }],
    selected: [{
      id: '',
      item: '',
      cost: '',
      quantity: 0,
      price_id: ''
    }],
    update: false
  }
  toAddress = 'CompanyFiels'

  async created () {
    await this.getFielStatus()
  }

  async mounted () {
    await this.getPrices()
  }

  async getFielStatus () {
    const [error, response] = await SATService.getFielStatus(this.state.company.id)
    if (error) {
      return this.$toasted.global.error(error)
    }
    const fiels = response.data.data
    const fielsOK = fiels.certificate && fiels.private_key && (fiels.password !== null && fiels.password !== '')
    if (fielsOK) this.toAddress = 'Welcome'
    else this.toAddress = 'CompanyFiels'
  }

  async getPrices () {
    const [error, response] = await Payments.getPrices()
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.pay.items = []
    this.pay.selected = []
    response.data.data.map((item: any) => {
      let price = ''
      let priceID = ''
      if (item.name === 'Servicio de Comisario') {
        const commissaryPrice = item.prices.filter((price: any ) => price.is_default === true)
        price = (Math.trunc(Number(commissaryPrice[0].price) + Number(commissaryPrice[0].tax))).toString()
        priceID = commissaryPrice[0].id
        this.pay.items.push({id: item.id, name: 'Lleva tu contabilidad', price: price.replace(/\d(?=(?:\d{3})+$)/g, '$&,'), price_id: priceID})
        this.pay.selected.push({id: item.id, item: 'Lleva tu contabilidad', cost: price.replace(/\d(?=(?:\d{3})+$)/g, '$&,'), quantity: 1, price_id: priceID})
      }
    })
    this.loading = false
  }

  @State('UserModule') state: any
}
