


























































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Types
import UserTypes from '@/store/types/UserTypes'
// Components
import Loader from '@/components/Loader.vue'
// Services
import SATService from '@/services/SAT'

@Component({
  components: { Loader }
})
export default class ModalAppointmentSAT extends Vue {
  modal: any = { showModalAppointmentSAT: false, update: false }
  loading = false
  open = false
  partners: any = []
  partner: any = {}
  offices: any = ['']
  officesSAT: any = []

  created () {
    this.partners = this.state.company.partners.filter((partner: any ) => partner.rfc !== '' && partner.rfc !== 'EXTF900101NI1')
    if (this.state.company.shipping_address !== null) this.partner = this.partners.filter((partner: any ) => partner.id === this.state.company.shipping_address.shipping_person)[0]
  }

  mounted () {
    this.modal = this.$attrs.value
    this.getOffices()
  }

  get style () {
    return this.partners.length === 1 || this.partners.length === 0 ?
      'height: 55px;' : this.partners.length === 2 ?
      'height: 110px;' : this.partners.length === 3 ?
      'height: 165px;' : this.partners.length === 4 ?
      'height: 220px;' : this.partners.length === 5 ?
      'height: 275px;' : 'height: 290px;'
  }

  async getOffices () {
    this.loading = true
    const Efirma = (this.state.company.rfc !== null && this.state.company.rfc !== '') || this.state.company.rfc_later || this.state.company.appointment_type_efirma
    const [error, response] = await SATService.getModules(Efirma ? 'efirma' : 'inscripcion_persona_moral')
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.officesSAT = response.data
    this.loading = false
  }

  addOffice () { this.offices.push('') }

  deleteOffice (index: number) { this.offices.splice(index, 1) }

  async setOffices () {
    this.loading = true
    const Efirma = (this.state.company.rfc !== null && this.state.company.rfc !== '') || this.state.company.rfc_later || this.state.company.appointment_type_efirma
    // eslint-disable-next-line
    const [error, response] = await SATService.setAppoinmentSAT(this.state.company.id, { rfc: this.partner.rfc, entity_modules: this.offices, service: Efirma ? 'efirma' : 'inscripcion_persona_moral'})
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    await this.updateCompany(this.state.company.id)
    this.modal.update = true
    this.loading = false
    this.modal.showModalAppointmentSAT = false
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
