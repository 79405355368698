












// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import CardCompany from '@/components/resumen/CardCompany.vue'
import CardTaxInformation from '@/components/resumen/CardTaxInformation.vue'
import CardCompanyActivities from '@/components/resumen/CardCompanyActivities.vue'
import CardAdmin from '@/components/resumen/CardAdmin.vue'
import CardCommissary from '@/components/resumen/CardCommissary.vue'
import CardAttorneys from '@/components/resumen/CardAttorneys.vue'
import CardAllPartners from '@/components/resumen/CardAllPartners.vue'

@Component({
  components: { CardCompany, CardTaxInformation, CardCompanyActivities, CardAdmin, CardCommissary, CardAttorneys, CardAllPartners }
})
export default class CompanyDetail extends Vue {
  paymentComplete = false
  appointmentSAT = false
  CommissaryPay = false

  created () {
    this.paymentComplete = this.state.company.orders.filter((order: any) => order.status === 'complete').length > 0
    this.appointmentSAT = this.state.company.stages.find((stage: any) => stage.stage_name === 'appointment_sat').is_completed
    this.CommissaryPay = this.state.company.orders.filter((order: any) => order.status === 'complete' && order.items.filter((item: any) => item.name === 'Servicio de Comisario').length > 0).length > 0
  }

  @State('UserModule') state: any
}
