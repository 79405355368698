







































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
import InputFile from '@/components/InputFile.vue'
import HeaderProgressBar from '@/components/HeaderProgressBar.vue'
// Services
import ImageService from '@/services/Image'
// Types
import UserTypes from '@/store/types/UserTypes'

@Component({
  components: { Footer, Loader, InputFile, HeaderProgressBar }
})
export default class Address extends Vue {
  loading = false
  copys = {
    return: 'Regresar',
    title: 'Sube un Comprobante de Domicilio',
    subTitle: 'Asegúrate que este a tu nombre y sea del último mes',
    address: 'Sube tu Comprobante de Domicilio',
    msj: {
      title: '🤓 ¿Por qué lo pedimos del último mes?',
      text: 'Tener los comprobantes de domicilio actualizados de los socios, evitará que posteriormente deban volver a subirlos para aperturar su cuenta empresarial. Asegurate que este a tu nombre.',
    },
    button: 'Continuar'
  }
  partner_id = ''
  address_document: any = null

  mounted () {
    this.loading = true
    let isPartner = false
    this.state.company.partners.map((partner: any) => {
      if (this.state.email === partner.email) {
        this.partner_id = partner.id
        isPartner = true
      }
    })
    if (!isPartner) return this.$router.push({ name: 'Dashboard', query: this.$route.query, params: { company_id: this.state.company.id } })
    this.loading = false
  }

  async upload (file: any, docType: string) {
    if (file === null || !file.name || !file.size) return [null, false]
    const [error] = await ImageService.upload({
      entity_type: 'partner',
      identity: this.partner_id,
      document_type: docType,
      image: file,
      company_id: this.state.company.id
    })
    if (error) {
      this.loading = false
      return [error, true]
    }
    return [null, false]
  }

  async next () {
    this.loading = true
    if (this.address_document) {
      const error = await this.upload(this.address_document, 'address')
      if (error[1]) {
        this.loading = false
        return this.$toasted.global.error(error[0])
      }
    }
    await this.updateCompany(this.state.company.id)
    return this.$router.push({ name: 'isPartnerVerification', query: this.$route.query, params: { company_id: this.state.company.id } }).catch(() => {return})
  }

  returnTo () { return this.$router.push({ name: 'isPartnerComplementaries', query: this.$route.query, params: { company_id: this.state.company.id } }) }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any;
}
