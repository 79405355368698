


















// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
// Types
import UserTypes from '@/store/types/UserTypes'
// Services
import CompanyService from '@/services/Company'

@Component({
  components: { Loader }
})
export default class CompanyUnicorn extends Vue {
  company: any = {}
  loading = false
  types = [{
      type: 'startup',
      text: 'Crear el próximo unicornio',
    },{
      type: 'pyme',
      text: 'Tener un negocio propio',
    }
  ]
  typeSelected = ''

  created () {
    if (this.state.company.company_goal !== null && this.state.company.company_goal !== '') this.typeSelected = this.state.company.company_goal
  }

  mounted () {
    this.company = this.$attrs.value
  }

  async next (type: string) {
    this.loading = true
    const [error] = await CompanyService.setCompanyGoal(this.state.company.id, type)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    await this.updateCompany(this.state.company.id)
    this.company.step++
    this.loading = false
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
