









// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'
// Helpers
import { UTM } from '@/helpers/UTM'

@Component
export default class Return extends Vue {
  @Prop() address: string|undefined
  @Prop({default: 'black'}) color: string|undefined

  async returnAdrress () {
    if (this.$route.name === 'TypeSociety' || this.$route.name === 'PersonalInformationOld') await UTM.setLocalUTMs(this.$router)
    return this.$router.push({ name: this.address, query: this.$route.query }).catch(() => {return})
  }
}
