






































// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
import { saveAs } from 'file-saver'
// Components
import Loader from '@/components/Loader.vue'
// Services
import ImageService from '@/services/Image'
import CompanyService from '@/services/Company'

@Component({
  components: { Loader }
})
export default class ModalCaptureLine extends Vue {
  @Prop({ default: 0 }) month!: number;
  @Prop({ default: 0 }) year!: number;
  @Prop({ default: [] }) captureLines!: Array<any>;
  copys: any = {
    title: 'Paga tus impuestos',
    text: 'Descarga la línea de captura y cuando realices el pago, regresa a esta sección y selecciona la opción pago realizado',
    titleRequest: 'Solicitar nueva línea de captura',
    requestNewCaptureLine: 'Solicitar nueva línea',
    preparingNewCaptureLine: 'Preparando nueva linea de captura',
    buttonConfirmRequest: 'Confirmar',
    cancelRequest: 'Cancelar',
    textComplete: 'Felicidades has realizado de forma exitosa la contabilidad del periodo',
    months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    buttonText: 'Pago Realizado',
    buttonCompleteText: 'Continuar'
  }
  modal: any = {
    showModalCaptureLine: false,
    update: false
  }
  complete = false
  request = false
  loading = false
  auxCaptureLines: Array<any> = []
  auxCaptureLine: any = null

  get waitingCaptureLine () {
    const waiting = this.auxCaptureLines.filter((captureLine: any) => captureLine.document_url === null ).length > 0
    return waiting
  }

  mounted () {
    this.modal = this.$attrs.value
    this.formatCaptureLines(this.captureLines)
  }

  formatCaptureLines (captureLines: Array<any>) {
    this.auxCaptureLines = []
    this.auxCaptureLines = captureLines.map((captureLine: any) => {
      let name = ''
      if (captureLine.document_type === 'capture_line_imss') name = 'Línea de captura IMSS'
      else if (captureLine.document_type === 'capture_line_iva') name = 'Línea de captura IVA'
      else if (captureLine.document_type === 'capture_line_isr') name = 'Línea de captura ISR'
      else if (captureLine.document_type === 'capture_line_iva_retnetion') name = 'Línea de captura IVA RETENIDO'
      else if (captureLine.document_type === 'capture_line_isr_retention') name = 'Línea de captura ISR RTENIDO'
      else if (captureLine.document_type === 'capture_line_diot') name = 'Línea de captura DIOT'
      else name = 'Línea de captura'
      return { ...captureLine, captureLineName: name, loader: false, blob: null, fileType: '' }
    })
  }

  async getCaptureLines () {
    const [error, response] = await CompanyService.getCaptureLines(this.state.company.id, (this.month + 1).toString(), this.year.toString())
    if (error) return this.$toasted.global.error(error)
    this.formatCaptureLines(response.data.data)
    return
  }

  async download (doc: any) {
    doc.loader = true
    if (doc.blob === null) {
      const [error, response] = await ImageService.getDocumentAccountStatements(this.state.company.id, doc.id)
      if (error) {
        doc.loader = false
        return this.$toasted.global.error(error)
      }
      doc.fileType = response.headers['content-type'].split('/')[1]
      doc.blob = new Blob([response.data])
      saveAs(doc.blob, `${doc.captureLineName + '.' + doc.fileType}`)
    } else saveAs(doc.blob, `${doc.captureLineName + '.' + doc.fileType}`)
    doc.loader = false
  }

  async newCaptureLine (doc: any) {
    this.loading = true
    // eslint-disable-next-line
    const [error, response] = await CompanyService.requestNewCaptureLine(doc.id)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.auxCaptureLine = null
    await this.getCaptureLines()
    this.request = false
    this.loading = false
  }

  async confirmCaptureLinePayment () {
    this.loading = true
    // eslint-disable-next-line
    const [error, response] = await CompanyService.confirmCaptureLines(this.state.company.id, (this.month + 1).toString())
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.complete = true
    this.loading = false
  }

  @State('UserModule') state: any
}
