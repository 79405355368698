





















// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
// Components
import Footer from '@/components/Footer.vue'
import TableCompanies from '@/components/dashboard/TableCompanies.vue'
import ModalCreateCompany from '@/components/company/ModalCreateCompany.vue'

@Component({
  components: { Footer, TableCompanies, ModalCreateCompany }
})
export default class Companies extends Vue {
  modal: any = {
    showModalCreateCompany: false,
    update: false,
  }
  renderKeyCreateCompany = 0

  @Watch('modal.update')
  async update () {
    if(!this.modal.update) return
    this.renderKeyCreateCompany++
  }
}
