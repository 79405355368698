// Utils
import { RouteConfig } from 'vue-router'
import multiguard from 'vue-router-multiguard'
// Views
import Index from '@/views/isPartner/Index.vue'
import Start from '@/views/isPartner/Start.vue'
import CivilStatus from '@/views/isPartner/CivilStatus.vue'
import Verification from '@/views/isPartner/Verification.vue'
import AnalyzingData from '@/views/isPartner/AnalyzingData.vue'
import Complementaries from '@/views/isPartner/Complementaries.vue'
import Address from '@/views/isPartner/Address.vue'
// Middlewares
import Auth from '@/middlewares/Auth'
import User from '@/middlewares/User'

const routes: Array<RouteConfig> = [
  {
    path: '/isPartner/:company_id',
    component: Index,
    beforeEnter: multiguard([Auth.isLogged, User.validateData]),
    children: [
      {
        path: '',
        name: 'isPartnerStart',
        component: Start
      },
      {
        path: 'complementaries',
        name: 'isPartnerComplementaries',
        component: Complementaries
      },
      {
        path: 'address',
        name: 'isPartnerAddress',
        component: Address
      },
      {
        path: 'verification',
        name: 'isPartnerVerification',
        component: Verification
      },
      {
        path: 'civil_status',
        name: 'isPartnerCivilStatus',
        component: CivilStatus
      },
      {
        path: 'analyzing_data',
        name: 'isPartnerAnalyzingData',
        component: AnalyzingData
      },
    ]
  }
]

export default routes
