
































// Utils
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
import ModalAddCompanyDocs from '@/components/dashboard/modals/ModalAddCompanyDocs.vue'
// Services
import BenefitsService from '@/services/Benefits'
// import SATService from '@/services/SAT'

@Component({
  components: { Loader, ModalAddCompanyDocs }
})
export default class AcceptBenefit extends Vue {
  @Prop({ default: '' }) authorization!: string
  @Prop({ default: '' }) service!: string
  @Prop({ default: '' }) url!: string
  account: any = { name: '', create: false, complete: false }
  modal: any = { showModalAddCompanyDocs: false, update: false }
  points = [
    'Acta Constitutiva',
    'Constancia de Situación Fiscal',
    'Acuse Certificado Fiel',
    'Identificaciones de los socios',
    'Correos de los socios',
    'Datos generales de los socios',
    'Datos generales del representante legal'
  ]
  validation = ''
  accept = false
  loading = false
  renderKeyAddCompanyCSF = 0

  @Watch('modal.update')
  async update () {
    if(!this.modal.update) return
    this.account.create = true
    this.modal.update = false
    this.renderKeyAddCompanyCSF += 1
    this.setBenefit()
  }

  async created () {
    this.account = this.$attrs.value
  }

  noSpacesStart (value: string) {
    return value.trimStart().toUpperCase()
  }

  async setBenefit () {
    const senior = await this.state.company.council.filter((partner: any ) => partner.council_role === 'president' || partner.council_role === 'unique_administrator')
    const partner = await this.state.company.partners.filter((partner: any ) => partner.id === senior[0].partner.id)
    if (this.state.company.company_CSF.document_url === null || partner[0].address_identity.document_url === null) return this.modal.showModalAddCompanyDocs = true
    this.loading = true
    // eslint-disable-next-line
    const [error, response] = await BenefitsService.setBenefit(this.state.company.id, this.service)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.$toasted.global.success({ message: 'Beneficio solicitado con éxito' })
    if (this.url !== '') {
      this.account.create = false
      this.account.complete = true
      return window.open(this.url, '_blank')
    }
    return this.account.complete = true
  }

  @State('UserModule') state: any
}
