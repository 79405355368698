import to from 'await-to-js'
import axios from '@/utils/axios-api'

const URL_PHP = process.env.VUE_APP_BASE_URL_PHP

export default class Notary {
  static async obtainNotary (postalCode: string) {
    return await to(axios.get(`${URL_PHP}/v2/assign-notary`, { params: { 'postal_code' :postalCode } } )) as any
  }

  static async updateNotary (notary: number, company: string) {
    return await to(axios.put(`${URL_PHP}/v2/set-notary/${company}/notary/${notary}`)) as any
  }

  static async getEvents (notary: number, date: string) {
    return await to(axios.get(`${URL_PHP}/v1/notary/availability/${notary}/${date}`)) as any
  }

  static async setEvent (company: string, event_timestamp: number, event_date: string) {
    return await to(axios.post(`${URL_PHP}/v1/company/schedule_event/${company}`, { event_timestamp, event_date })) as any
  }

  static async cancelEvent (company: string) {
    return await to(axios.get(`${URL_PHP}/v1/cancel-event/company/${company}`)) as any
  }

  static async setNotary (company: string, digital_signature: boolean) {
    return await to(axios.post(`${URL_PHP}/v2/set-notary/${company}`, { digital_signature })) as any
  }
}
