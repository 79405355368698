







































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
// Types
import UserTypes from '@/store/types/UserTypes'
// Services
import SignatureService from "@/services/Signature"

@Component({
  components: { Loader }
})
export default class FooterStatutesFirm extends Vue {
  loading = false
  showModalResetSign = false

  async resetSignature() {
    this.loading = true
    // const act = this.state.company.documents.find((doc: any ) => doc.document_type === 'articles_of_incorporation_render')
    const [error] = await SignatureService.resetSignature(this.state.company.id)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    await this.updateCompany(this.state.company.id)
    this.loading = false
    this.showModalResetSign = false
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
