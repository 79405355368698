



















// Utils
import { Component, Vue } from 'vue-property-decorator'

@Component({
})
export default class TooltipBilling extends Vue {
}
