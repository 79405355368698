



































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
import InputFile from '@/components/InputFile.vue'
import TooltipDenomination from '@/components/company/TooltipDenomination.vue'
// Types
import UserTypes from '@/store/types/UserTypes'
// Services
import CompanyService from '@/services/Company'
import ImageService from '@/services/Image'

@Component({
  components: { Loader, InputFile, TooltipDenomination }
})
export default class ModalUploadLegalName extends Vue {
  modal: any = {
    showModalUploadLegalName: false,
    update: false,
  }
  legal_name = ''
  loading = false
  validName = true
  promise_error = false
  denomination: any = null

  mounted () {
    this.modal = this.$attrs.value
    this.legal_name = this.state.company.legal_name
  }

  noSpacesStart (value: string) { return value.trimStart() }

  async upload () {
    this.loading = true
    if (this.denomination === null || !this.denomination) return this.loading = false
    const [error] = await CompanyService.setLegalName(this.state.company.id, this.legal_name.trimEnd())
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    const [error1] = await ImageService.uploadCompanyDoc({
      entity_type: 'company',
      identity: this.state.company.id,
      document_type: 'legal_name_authorization',
      image: this.denomination
    })
    if (error1) {
      this.loading = false
      return this.$toasted.global.error(error1)
    }
    await this.updateCompany(this.state.company.id)
    this.modal.update = true
    this.modal.showModalUploadLegalName = false
    this.$toasted.global.success({ message: 'Se subio la denominacion con éxito' })
    return
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
