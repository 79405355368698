




















// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
})
export default class TooltipCSF extends Vue {
  @Prop({default: ''}) color!: string
  points = [{
      point: 'La Constancia de Situación Fiscal contiene datos de identidad, ubicación y caracterísitcas fiscales de tu empresa, te debieron dar una copia cuando acudiste al SAT, de lo contrario obtenla ',
      url: 'https://www.sat.gob.mx/aplicacion/login/53027/genera-tu-constancia-de-situacion-fiscal'
    }
  ]
}
