





























































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
import EventBus from '@/event-bus'
import moment from 'moment'

@Component({
  components: {}
})
export default class Promo extends Vue {
  moment = moment
  promo = true
  loading = false
  endDate = moment('11-23-2022 00:00', 'MM-DD-YYYY HH:mm').valueOf()
  now = moment().valueOf()
  timer: any = null

  get day (){
    if ((this.endDate - this.now) <= 0) return '00'
    let h = Math.trunc((this.endDate - this.now) / 1000 / 3600 / 24)
    return h <= 0 ? '00' : h > 9 ? h : '0' + h
  }

  get hour (){
    if ((this.endDate - this.now) <= 0) return '00'
    let h = Math.trunc((this.endDate - this.now) / 1000 / 3600) % 24
    return h <= 0 ? '00' : h > 9 ? h : '0' + h
  }

  get min (){
    if ((this.endDate - this.now) <= 0) return '00'
    let m = Math.trunc((this.endDate - this.now) / 1000 / 60) % 60
    return m <= 0 ? '00' : m > 9 ? m : '0' + m
  }

  get sec (){
    if ((this.endDate - this.now) <= 0) return '00'
    let s = Math.trunc((this.endDate - this.now)/1000) % 60
    return s <= 0 ? '00' : s > 9 ? s : '0' + s
  }

  created () {
    this.timer = setInterval(()=>{
      this.now = moment().valueOf()
      if ((this.endDate - this.now) <= 0) {
        clearInterval(this.timer)
        return
      }
      if (this.now > this.endDate) {
        this.now = this.endDate
        clearInterval(this.timer)
      }
    }, 1000)
  }

  beforeDestroy(){
    clearInterval(this.timer)
  }

  setcoupon () {
    this.promo = false
    EventBus.$emit('validate-coupon-promo', 'BUENFIN22')
    return
  }

  @State('UserModule') state: any
}
