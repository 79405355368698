import to from 'await-to-js'
import axios from '@/utils/axios-api'

const URL_PHP = process.env.VUE_APP_BASE_URL_PHP

export default class Phone {
  static async newPhone (user: string, phone: string, phone_country_code: string) {
    return await to(axios.post(`${URL_PHP}/v2/update-phone/${user}`, { phone, phone_country_code })) as any
  }
  
  static async updatePhone (user: string, phone: string, phone_country_code: string, verification_code: string) {
    return await to(axios.post(`${URL_PHP}/v2/verification-update-phone/${user}`, { phone, phone_country_code, verification_code })) as any
  }
}