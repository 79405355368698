


















































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
// Components
import ReturnAddress from '@/components/Return.vue'
import Footer from '@/components/Footer.vue'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'

@Component({
  components: { ReturnAddress, Footer }
})
export default class HasRFC extends Vue {
  RFC = ''

  async next () {
    await this.setRepresentation(this.RFC === 'NO')
    return this.RFC === 'SI' ?
      this.$router.push({ name: 'Register', query: this.$route.query }) :
      this.$router.push({ name: 'RepresentationInfo', query: this.$route.query })
  }

  @State('CompanyModule') state: any
  @Action(`CompanyModule/${CompanyTypes.actions.SET_REPRESENTATION}`) setRepresentation: any;
}
