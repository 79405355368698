// Utils
import { RouteConfig } from 'vue-router'
// Views
import Index from '@/views/account/Index.vue'
import UserNewPhone from '@/views/account/phone/UserNewPhone.vue'
import UserValidatePhone from '@/views/account/phone/UserValidatePhone.vue'
import UserNewPassword from '@/views/account/password/UserNewPassword.vue'
import UserNewEmail from '@/views/account/email/UserNewEmail.vue'
import UserValidateEmail from '@/views/account/email/UserValidateEmail.vue'
// Middlewares
import Auth from '@/middlewares/Auth'

const routes: Array<RouteConfig> = [
  {
    path: '/account',
    component: Index,
    beforeEnter: Auth.isLogged,
    children: [
      {
        path: 'new_phone',
        name: 'UserNewPhone',
        component: UserNewPhone
      },
      {
        path: 'validate_phone',
        name: 'UserValidatePhone',
        component: UserValidatePhone
      },
      {
        path: 'new_password',
        name: 'UserNewPassword',
        component: UserNewPassword
      },
      {
        path: 'new_email',
        name: 'UserNewEmail',
        component: UserNewEmail
      },
      {
        path: 'validate_email',
        name: 'UserValidateEmail',
        component: UserValidateEmail
      }
    ]
  }
]

export default routes
