


























// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component({
  components: {}
})
export default class ModalTermsConditions extends Vue {
  modal: any = { showModalTermsConditions: false, update: false, accept: false, termsAndConditions: '' }
  accept = false
  termsAndConditions = [
    'Este documento constituye evidencia de la entrevista personal llevada a cabo para efectos de identificar al beneficiario controlador. Acepto que en cualquier momento, TALLY y/o el fedatario público encargado de formalizar el acto jurídico, podrán solicitar verificaciones adicionales sobre la información y/o documentos proporcionados.',
    'He presentado los documentos que respaldan la identidad del beneficiario controlador, junto con la información correspondiente. En caso de existir alguna discrepancia entre la información proporcionada por mí y la contenida en los documentos de respaldo, se considerará que la información contenida en los documentos es correcta para todos los efectos legales, y se descartará cualquier otra información presentada.',
    'Si no he proporcionado ciertos documentos y/o información, se debe a que no los poseo, o los documentos pertinentes no están disponibles para mí en mi país de nacimiento y residencia, y/o no son un requisito legal.',
    'Manifiesto que el presente documento ha sido completado por mí, con información verídica, completa y actualizada del beneficiario controlador. Me comprometo a mantener actualizada dicha información, y notificaré cualquier cambio en un plazo máximo de 5 días hábiles desde que tome conocimiento del mismo. Para este propósito, realizaré la notificación a través de los correos electrónicos: @tally y al correo del fedatario público encargado de formalizar este acto jurídico, cuyo correo conozco. De acuerdo con lo establecido en los artículos 32-B Ter-32-B Quater, 32-B Quinquies del Código Fiscal de la Federación, así como en las Reglas aplicables de la Resolución Miscelánea Fiscal respectivas, las cuales conozco y comprendo.',
    '"Manifiesto mi consentimiento mediante mi firma electrónica, y acepto que tanto TALLY como el fedatario público encargado de formalizar el acto jurídico de mi interés, compartan información y protocolos de seguridad relacionados con el cumplimiento de la identificación del beneficiario controlador."',
    'Al firmar este documento, acepto y reconozco haber leído y comprendido todos los términos y condiciones establecidos anteriormente.'
  ]

  mounted () {
    this.modal = this.$attrs.value
  }

  getTermsAndConditions () {
    let termsAndConditions = ''
    this.termsAndConditions.map((paragraph: any, index: number) => {
      if (index !== 0) termsAndConditions += (' ' + paragraph)
      else termsAndConditions += paragraph
    })
    return termsAndConditions
  }

  async agreementTermsAndConditions () {
    this.modal.termsAndConditions = this.getTermsAndConditions()
    this.modal.update = true
    this.modal.accept = true
    return this.modal.showModalTermsConditions = false
  }

  @State('UserModule') state: any
}
