























// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Types
import UserTypes from '@/store/types/UserTypes'
// Components
import Footer from '@/components/Footer.vue'
import ValidateCode from '@/components/account/ValidateCode.vue'

@Component({
  components: { Footer, ValidateCode }
})
export default class InvitedPhoneValidate extends Vue {
  phone = ''
  countryCode = ''

  async created () {
    await this.updateUser()
    this.phone = this.state.phone
    this.countryCode = this.state.phone_country_code
  }


  @Action(`UserModule/${UserTypes.actions.UPDATE_DATA}`) updateUser: any
  @State('UserModule') state: any
}
