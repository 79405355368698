



















// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import Appointment from '@/components/dashboard/Appointment.vue'
// Types
import UserTypes from '@/store/types/UserTypes'
// Services
import CalendlyService from '@/services/Calendly'

@Component({
  components: {Footer, Appointment }
})
export default class ReviewStatutes extends Vue {
  eventUrl: any = {}
  calendlyURL = ''

  @Watch('eventUrl')
  load () {
    this.setCalendly()
  }

  mounted () {
    this.calendly()
  }

  async calendly () {
    let guests: string[] = []
    let user = {name: '', email: ''}
    await this.getCalendlyURL()
    let userIsPartner = false
    this.state.company.partners.map((partner: any) => {
      if (this.state.email === partner.email) {
        userIsPartner =  true
        user.email = this.state.email
        user.name = partner.name + ' ' + partner.first_names + ' ' + partner.last_names
      } else {
        guests.push(partner.email)
      }
    })
    if (!userIsPartner) user.email = this.state.email
    window.Calendly.initInlineWidget({
      url: !guests.length ? this.calendlyURL : this.calendlyURL + '?guests=' + guests.join(','),
      parentElement: document.getElementById('CalendlyDivID'),
      prefill: user,
      utm: {}
    })
    window.addEventListener("message", this.listener)
  }

  async getCalendlyURL () {
    const [error, response] = await CalendlyService.getCalendlyURL(this.state.company.id)
    if (error) {
      return this.$toasted.global.error(error)
    }
    this.calendlyURL = response.data.data
    return
  }

  async setCalendly () {
    const [error] = await CalendlyService.setCalendly(this.state.company.id, this.eventUrl.data.payload.event.uri)
    if (error) {
      return this.$toasted.global.error(error)
    }
    await this.updateCompany(this.state.company.id)
    return
  }

  listener (e: any) {
    if(e !== undefined && e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0 && e.data.event === 'calendly.event_scheduled') {
      this.eventUrl = e
    }
  }

  destroyed () {
    window.removeEventListener('message', this.listener);
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
