









// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import Benefit from '@/components/benefits/Benefit.vue'

@Component({
  components: { Footer, Benefit }
})
export default class Delt extends Vue {
  benefitData: any = {
    name: 'Delt',
    textReturn: 'Regresar',
    openAccount: {
      img1: {
        url: require('@/assets/images/Benefits/Deltai.png'),
        style1: 'width: 184px; height: 60px;',
        style2: ''
      },
      img2: {
        url: require('@/assets/images/Benefits/Deltai2.png'),
        classStyle: 'col-12 col-lg-6 mt-0 pl-xl-5 pr-0'
      },
      title: 'Plataforma financiera que se mueve a la velocidad de tu negocio',
      subTitle: '',
      text: '',
      url: 'https://tallylegals.notion.site/Tu-primera-tarjeta-de-cr-dito-empresarial-1ba96290795245deae65acda62a2b21a',
      point: '',
      points: [
        'Un crédito revolvente para todas las etapas de crecimiento',
        'Transferencias SPEI® rápidas sin horas de espera',
        'Tarjetas Mastercard para todo tu equipo',
        'Incrementos en línea',
        'Dispersiones masivas'
      ],
    },
    companyDocs: {
      title: 'Sube tus documentos',
      subTitle: 'Los necesitamos para crear tu cuenta empresarial',
      msj: {
        Title: 'Revisa tus documentos',
        Text: 'Deberás subir tus documentos originales, sin tachaduras ni enmendaduras y con fecha de expedición no mayor a 2 meses.'
      }
    },
    authorization: {
      title: 'Autorizas el envío de tus documentos',
      subTitle: '',
      protection: 'Tu información está protegida',
      authorization: ''
    },
    complete: {
      title: '¡Solicitud realizada con éxito!',
      subTitle: '',
      button: 'Finalizar',
      points: []
    }
  }

  @State('UserModule') state: any
}
