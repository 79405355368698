// Utils
import { RouteConfig } from 'vue-router'
import multiguard from 'vue-router-multiguard'
// Views
import Index from '@/views/Commissary/Index.vue'
import CommissaryStart from '@/views/Commissary/CommissaryStart.vue'
import CommissaryEnd from '@/views/Commissary/CommissaryEnd.vue'
import CommissaryComplementaries from '@/views/Commissary/CommissaryComplementaries.vue'
// Middlewares
import Commissary from '@/middlewares/Commissary'

const routes: Array<RouteConfig> = [
  {
    path: '/commissary',
    component: Index,
    children: [
      {
        path: '',
        name: 'CommissaryStart',
        component: CommissaryStart,
        beforeEnter: multiguard([Commissary.validateCommissary])
      },
      {
        path: 'complementaries',
        name: 'CommissaryComplementaries',
        component: CommissaryComplementaries,
        beforeEnter: multiguard([Commissary.validateCommissary])
      },
      {
        path: 'end',
        name: 'CommissaryEnd',
        component: CommissaryEnd,
        beforeEnter: multiguard([Commissary.validateCommissary])
      }
    ]
  }
]

export default routes
