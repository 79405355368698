


























































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import moment from 'moment'
// Components
import Loader from '@/components/Loader.vue'
import Footer from '@/components/Footer.vue'
import CardBankFAQs from '@/components/dashboard/CardBankFAQs.vue'
import CardBankData from '@/components/dashboard/CardBankData.vue'
// Types
import UserTypes from '@/store/types/UserTypes'
// Services
import CompanyService from '@/services/Company'

@Component({
  components: { Loader, Footer, CardBankFAQs, CardBankData }
})
export default class Bank extends Vue {
  loading = false
  loading2 = false
  validCode = true
  validate = false
  codeSend = false
  now = moment().valueOf()
  end = moment().valueOf()
  endTime: any = null
  cont = 0
  renderKey = 0
  value:any = []
  codeLength = 5
  phone = ''

  get resendRepeat () { return this.cont === 0 ? true : this.now >= this.end }

  get min (){
    if ((this.end - this.now) <= 0) return '00'
    let m = Math.trunc((this.end - this.now) / 1000 / 60) % 60
    return m <= 0 ? '00' : m > 9 ? m : '0' + m
  }

  get sec (){
    if ((this.end - this.now) <= 0) return '00'
    let s = Math.trunc((this.end - this.now)/1000) % 60
    return s <= 0 ? '00' : s > 9 ? s : '0' + s
  }

  get completeCode (){
    let complete = true
    this.value.map((char: any) => { if (char === '') complete = false })
    return complete
  }

  created () {
    for (let i = 0; i < (this.state.phone_country_code.length + this.state.phone.length); i++) this.phone = this.phone + '*'
    if (this.state.phone.length) this.phone = this.phone + this.state.phone.slice(this.state.phone.length - 2, this.state.phone.length)
  }

  mounted () {
    if (this.state.code.length) {
      this.value = this.state.code.substring(0,this.codeLength).split('')
      this.verifyCode(true)
    } else for (let i = 0; this.value.length < this.codeLength; i++) this.value.push('')
  }

  codeDigits (value: string) {
    const newValue = value.trim().replace(/[^0-9.]/g,'')
    if (newValue.length === 0) return ''
    if (newValue.length > this.codeLength) {
      this.renderKey++
      this.value = newValue.substring(1,this.codeLength + 1).split('')
    }
    else if (newValue.length === this.codeLength) {
      this.renderKey++
      this.value = newValue.substring(0,this.codeLength).split('')
    }
    if (newValue.length === 1) { return newValue.substring(0,1) }
    if (newValue.length === 2) { return newValue.substring(1,2) }
  }

  next (i: number) {
    if (this.value[i -1] === '') return
    let input = document.getElementById(`code-digit-${i + 1}`)
    if (input) input.focus()
  }

  resetValidate () { this.validCode = true }

  async sendCode () {
    this.loading = true
    // eslint-disable-next-line
    const [error, response] = await CompanyService.sendBankCode(this.state.company.id)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.codeSend = true
    this.loading = false
    return this.$toasted.global.success({ message: 'Se ha enviado el código' })
  }

  async resendCode () {
    this.loading2 = true
    // eslint-disable-next-line
    const [error, response] = await CompanyService.resendBankCode(this.state.company.id)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.createTimer()
    this.loading2 = false
    return this.$toasted.global.success({ message: 'Se ha reenviado el código' })
  }

  async verifyCode (pass: boolean) {
    this.loading = true
    const code = this.value.toString().replaceAll(",", "")
    // eslint-disable-next-line
    const [error, response] = await CompanyService.verifyBankCode(this.state.company.id, code)
    if (error) {
      this.loading = false
      if (pass) {
        this.value = []
        for (let i = 0; this.value.length < this.codeLength; i++) this.value.push('')
        return
      }
      return this.$toasted.global.error(error)
    }
    this.setCode(code)
    this.validate = true
    this.loading = false
  }

  createTimer () {
    this.cont++
    this.now = moment().valueOf()
    this.end = moment().valueOf() + (60000 * this.cont)
    this.endTime = setInterval(()=>{
      this.now = moment().valueOf()
      if (this.now >= this.end) {
        clearInterval(this.endTime)
      }
    }, 1000)
  }

  beforeDestroy(){
    clearInterval(this.endTime)
  }

  @Action(`UserModule/${UserTypes.actions.SET_CODE}`) setCode: any
  @State('UserModule') state: any
}
