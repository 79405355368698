






































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
import PartnerEdit from '@/components/partners/PartnerEdit.vue'
import PartnerEditDistribution from '@/components/partners/PartnerEditDistribution.vue'
// Types
import UserTypes from '@/store/types/UserTypes'
// Services
import CountryService from '@/services/Country'
import PartnersService from '@/services/Partners'
import SharesService from '@/services/Shares'

@Component({
  components: { Footer, Loader, PartnerEdit, PartnerEditDistribution }
})
export default class EditAllPartners extends Vue {
  partners: any = []
  nationalities = []
  loading = true
  percentage_zero = false
  repeatedEmail = false
  completeEmail = true
  commissary = false
  promise_error = false
  select_partner = {
    index: 0,
    name: '',
    first_names: '',
    id: '',
    percentage: '',
    exist: false
  }
  total = ''
  renderKey = 0

  get completePartners () {
    let complete = true
    this.partners.map((partner: any) => {
      if (partner.name === '' || partner.first_names === '') complete = false
    })
    return complete
  }

  get completePartnersEmail () {
    let complete = true
    const re = /\S+@\S+\.\S+/
    this.partners.map((partner: any) => {
      if (partner.email === '') complete = false
      else if (!re.test(partner.email)) complete = false
    })
    return complete
  }

  get totalPercentage () {
    let total = 0
    this.percentage_zero = false
    this.partners.map((partner: any) => {
      total += Number(partner.percentage)
      if (Number(partner.percentage) === 0) this.percentage_zero = true
    })
    return total
  }

  created () {
    this.total = this.onlyNumbersActions(this.state.company.share_series[0].total_shares_in_series.toString())
    this.getPartners()
    if (this.state.company.council.length) {
      this.state.company.council.map((member: any) => {
        if (member.council_role === 'commissary') this.commissary = true
      })
    }
  }

  mounted () {
    this.getNationalities()
  }

  onlyNumbersActions (value: string) {
    value = value.replace(/,/g, "").replace(/[^0-9]/g,'')
    value = Number(value) > 100000 ? '100000' : Number(value) === 0 ? '0' : Number(value).toString()
    return value.replace(/\d(?=(?:\d{3})+$)/g, '$&,')
  }

  updatePartnersDistribution () {
    this.partners.map((partner: any, index: number) => { this.partners[index].numberShares = ((Number(partner.percentage) * Number(this.total.replace(/,/g, "").replace(/[^0-9]/g,''))) / 100).toString().replace(/\d(?=(?:\d{3})+$)/g, '$&,') })
    this.renderKey++
  }

  getPartners () {
    const commissary = this.state.company.council.filter((member: any) => member.council_role === 'commissary')
    const idCommissary = !commissary.length ? null : commissary[0].partner_id
    this.partners = []
    this.state.company.partners.map((partner: any, index: number) => {
      this.partners.push({
        number: index,
        name: partner.name,
        first_names: partner.first_names,
        last_names: partner.last_names,
        nationality: partner.nationality,
        percentage: (partner.shares.length === 0 || partner.shares[0].percentage === null) ? 0 : partner.shares[0].percentage,
        numberShares: partner.shares.length === 0 ? '0' : partner.shares[0].number_of_shares.replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
        email: partner.email,
        id: partner.id,
        isCommissary: idCommissary === null ? false : idCommissary === partner.id,
        open: false,
        validEmail: true,
        completeEmail: true,
        repeatedEmail: false
      })
    })
  }

  addPartner () {
    this.partners.push({
      number: this.partners.length,
      name: '',
      first_names: '',
      last_names: '',
      nationality: 'MX',
      percentage: 0,
      numberShares: '0',
      email: '',
      id: '',
      isCommissary: false,
      open: false,
      validEmail: true,
      completeEmail: true,
      repeatedEmail: false
    })
  }

  async getNationalities () {
    const [error, response] = await CountryService.nationalities()
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.loading = false
    this.nationalities = response.data.data
  }

  async save () {
    this.loading = true
    // Find delete partners
    let delete_partners: any = []
    this.state.company.partners.map((partner: any) => {
      const existPartner = this.partners.filter((partnerAux: any ) => partnerAux.id === partner.id)
      if (!existPartner.length) delete_partners.push(partner.id)
    })
    // Save delete partners
    if (delete_partners.length > 0) {
      await Promise.all(
        delete_partners.map(async (partner: any) => {
          const [error] = await PartnersService.deletePartner(partner, this.state.company.id)
          if (error) {
            this.promise_error = true
            return this.$toasted.global.error(error)
          }
        })
      )
      if (this.promise_error) {
        await this.updateCompany(this.state.company.id)
        this.getPartners()
        this.loading = false
        return this.promise_error = false
      }
      delete_partners = []
    }
    // Save partner changes
    const update_partners = this.partners.filter((partner: any) => partner.id !== '')
    if (update_partners.length > 0) {
      const [error] = await PartnersService.updatePartners(update_partners)
      if (error) {
        this.loading = false
        return this.$toasted.global.error(error)
      }
    }
    // Save new partners
    const new_partners = this.partners.filter((partner: any) => partner.id === '')
    if (new_partners.length > 0) {
      const [error] = await PartnersService.storeMultiplePartners(this.state.company.id, new_partners)
      if (error) {
        this.loading = false
        return this.$toasted.global.error(error)
      }
    }
    // Save share partners
    await this.updateCompany(this.state.company.id)
    this.partners.map((partner: any, index: number) => {
      if (partner.id === '') {
        this.state.company.partners.map((partnerAux: any) => { if (partner.email === partnerAux.email) this.partners[index].id = partnerAux.id })
      }
    })
    let partner_shares: any[] = []
    this.partners.map((partner: any) => {
      partner_shares.push({ id: partner.id, percentage: Number(partner.percentage), numberShares: partner.numberShares })
    })
    const actions = this.total.replace(/,/g, "").replace(/[^0-9]/g,'')
    const [error3] = await SharesService.storeShares(this.state.company.id, Number(actions), partner_shares)
    if (error3) {
      this.loading = false
      return this.$toasted.global.error(error3)
    }
    return this.$router.push({ name: 'Welcome', query: this.$route.query }).catch(() => {return})
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
