













































































































































































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
// Types
import UserTypes from '@/store/types/UserTypes'
// Services
import CompanyService from '@/services/Company'

@Component({
  components: { Loader }
})
export default class CardCompanyActivities extends Vue {
  edit = false
  open = false
  editActive = false
  oldCompanyType = ''
  descriptionMaxLength = 300
  oldCompanyActivities: any = {}
  company: any = {
    id: '',
    type: '',
    activities: {
      activity: '',
      description: '',
      products: '',
      flag: true
    },
    loading: false
  }
  activities = [
    'SaaS (Servicios de Software)',
    'Servicios de Consultoría (incluye tecnología)',
    'Fintech',
    'Comercio / E-commerce',
    'Construcción',
    'Fabricación / Producción',
    'Educación (incluye Tecnología)',
    'Otro'
  ]

  @Watch('company.loading')
  load () {
    this.edit = false
  }

  mounted () {
    const documentsApproved = this.state.company.stages.find((stage: any) => stage.stage_name === 'documents_approved').is_completed === true
    if (this.state.company.timer === null && !documentsApproved) this.editActive = true
    this.company = this.$attrs.value
    this.setOldCompanyInfo()
  }

  noSpacesStart (value: string) {
    return value.trimStart()
  }

  setOldCompanyInfo () {
    this.oldCompanyType = this.company.type
    this.oldCompanyActivities = {
      activity: this.company.activities.activity,
      description: this.company.activities.description,
      products: this.company.activities.products,
      flag: true
    }
  }

  async save () {
    this.company.loading = true
    const checkEditType = this.oldCompanyType !== this.company.type
    const chechEditActivities = this.oldCompanyActivities.activity !== this.company.activities.activity ||
      this.oldCompanyActivities.description !== this.company.activities.description ||
      this.oldCompanyActivities.products !== this.company.activities.products
    if (!checkEditType && !chechEditActivities) {
      this.company.loading = false
      return this.edit = !this.edit
    }
    const [error] = await CompanyService.updateCompany( this.company.id, this.company.type)
    if (error) {
      this.company.loading = false
      return this.$toasted.global.error(error)
    }
    if (checkEditType && this.company.type === 'sapi' && this.state.company.has_unique_administrator) return this.$router.push({ name: 'EditBoardDirectors', query: this.$route.query })
    await this.updateCompany(this.state.company.id)
    this.setOldCompanyInfo()
    this.company.loading = false
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
