



















































































// Utils
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import { saveAs } from 'file-saver'
import EventBus from '@/event-bus'
import moment from 'moment'
// Types
import UserTypes from '@/store/types/UserTypes'
// Components
import Loader from '@/components/Loader.vue'
import TooltipBusinessAccount from '@/components/dashboard/tooltips/TooltipBusinessAccount.vue'
import TooltipProtocolizingMinutes from '@/components/dashboard/tooltips/TooltipProtocolizingMinutes.vue'
import TooltipRFC from '@/components/dashboard/tooltips/TooltipRFC.vue'
import ModalAppointmentSAT from '@/components/dashboard/modals/ModalAppointmentSAT.vue'
import ModalRescheduleSAT from '@/components/dashboard/modals/ModalRescheduleSAT.vue'
import ModalAddRFC from '@/components/dashboard/modals/ModalAddRFC.vue'
// Services
import SATService from '@/services/SAT'
import ImageService from '@/services/Image'

@Component({
  components: { Loader, TooltipProtocolizingMinutes, TooltipRFC, TooltipBusinessAccount, ModalAppointmentSAT, ModalRescheduleSAT, ModalAddRFC }
})
export default class FinalCardStep extends Vue {
  @Prop({default: false}) existFiels!: boolean
  modal: any = {
    showModalAppointmentSAT: false,
    showModalRescheduleSAT: false,
    showModalAddRFC: false,
    update: false
  }
  points: any = []
  steps: any = [{
      type: 'Business account',
      representation: true,
      title: 'Obtén tu cuenta empresarial',
      text: '',
      Appointment: { date: { icon: '', text: '' }, partner: { icon: '', text: '' }, module: { icon: '', text: '' }, text: { icon: '', text: '' } },
      button: '',
      style: 'position: absolute; right: 10px; top: -10px; z-index: 0;',
      imgStyle: 'height: 167px;',
      img: require('@/assets/images/Bank.png')
    },{
      type: 'RFC',
      representation: true,
      title: 'Cita agendada',
      text: '',
      Appointment: {
        date: { icon: require('@/assets/icons/business.svg'), text: '' },
        partner: { icon: require('@/assets/icons/user.svg'), text: '' },
        module: { icon: require('@/assets/icons/map.svg'), text: '' },
        text: { icon: require('@/assets/icons/document.svg'), text: 'Un representante de Tally acudirá ese día y hora al SAT a inscribir tu empresa y obtener su RFC y FIEL, con esto podrás abrir una cuenta empresarial.' }
      },
      button: '',
      style: 'position: absolute; right: -50px; top: 5px; z-index: 0;',
      imgStyle: 'height: 245px;',
      img: require('@/assets/images/Final.png')
    },{
      type: 'SAT',
      representation: true,
      title: 'Estamos obteniendo la cita en el SAT',
      text: '',
      Appointment: { date: { icon: '', text: '' }, partner: { icon: '', text: '' }, module: { icon: '', text: '' }, text: { icon: '', text: '' } },
      button: '',
      style: 'position: absolute; right: 20px; top: 10px; z-index: 0;',
      imgStyle: 'height: 150px;',
      img: require('@/assets/images/Timer.png')
    },{
      type: 'Delivery',
      representation: true,
      title: 'Tu acta ya está en camino',
      text: 'El protocolo se esta enviando de la notaria a las oficinas de Tally en México',
      Appointment: { date: { icon: '', text: '' }, partner: { icon: '', text: '' }, module: { icon: '', text: '' }, text: { icon: '', text: '' } },
      button: '',
      style: 'position: absolute; right: 0px; top: 0px; z-index: 0;',
      imgStyle: 'height: 170px;',
      img: require('@/assets/images/Delivery.png')
    },{
      type: 'Accounting',
      representation: false,
      title: '¡Felicidades! has llegado a Contabilidad ;)',
      text: 'Lleva tu contabilidad al día y realiza tu primera declaración de impuestos',
      Appointment: { date: { icon: '', text: '' }, partner: { icon: '', text: '' }, module: { icon: '', text: '' }, text: { icon: '', text: '' } },
      button: '',
      style: 'position: absolute; right: 10px; top: 0px; z-index: 0;',
      imgStyle: 'height: 167px;',
      img: require('@/assets/images/piramide.png')
    },{
      type: 'Business account',
      representation: false,
      title: 'Obtén tu cuenta empresarial',
      text: '',
      Appointment: { date: { icon: '', text: '' }, partner: { icon: '', text: '' }, module: { icon: '', text: '' }, text: { icon: '', text: '' } },
      button: '',
      style: 'position: absolute; right: 10px; top: -10px; z-index: 0;',
      imgStyle: 'height: 167px;',
      img: require('@/assets/images/Bank.png')
    },{
      type: 'RFC',
      representation: false,
      title: 'Cuéntanos como te fue en tu cita en el SAT',
      text: '',
      Appointment: { date: { icon: '', text: '' }, partner: { icon: '', text: '' }, module: { icon: '', text: '' }, text: { icon: '', text: '' } },
      button: '',
      style: 'position: absolute; right: -45px; top: 5px; z-index: 0;',
      imgStyle: 'height: 210px;',
      img: require('@/assets/images/Final.png')
    },{
      type: 'SAT',
      representation: false,
      title: 'Cita agendada',
      text: 'En caso de no poder asistir a tu cita, podrás reagendar una nueva. A partir de la tercera, esta tendrá un costo adicional. Recuerda llevar el Acta Constitutiva de la empresa y tu identificación oficial vigente.',
      Appointment: {
        date: { icon: require('@/assets/icons/business.svg'), text: '' },
        partner: { icon: require('@/assets/icons/user.svg'), text: '' },
        module: { icon: require('@/assets/icons/map.svg'), text: '' },
        text: { icon: require('@/assets/icons/document.svg'), text: 'Recuerda llevar el Acta Constitutiva de la empresa y tu identificación oficial vigente.' }
      },
      button: 'Descargar acuse',
      style: 'position: absolute; right: -50px; top: 5px; z-index: 0;',
      imgStyle: 'height: 245px;',
      img: require('@/assets/images/Final.png')
    },{
      type: 'SAT',
      representation: false,
      title: 'Estamos obteniendo la cita en el SAT',
      text: '',
      Appointment: { date: { icon: '', text: '' }, partner: { icon: '', text: '' }, module: { icon: '', text: '' }, text: { icon: '', text: '' } },
      button: '',
      style: 'position: absolute; right: 20px; top: 10px; z-index: 0;',
      imgStyle: 'height: 150px;',
      img: require('@/assets/images/Timer.png')
    },{
      type: 'Delivery',
      representation: false,
      title: 'Hemos enviado la paqueteria',
      text: 'Tu documentos están en camino :)',
      Appointment: { date: { icon: '', text: '' }, partner: { icon: '', text: '' }, module: { icon: '', text: '' }, text: { icon: '', text: '' } },
      button: '',
      style: 'position: absolute; right: -60px; top: -25px; z-index: 0;',
      imgStyle: 'height: 260px; transform: scaleX(-1);',
      img: require('@/assets/images/CommissaryEnd.png')
    },{
      type: 'Register',
      representation: false,
      title: 'Inscribiendo en el Registro Público :)',
      text: 'Te notificaremos cuando esté lista y en camino',
      Appointment: { date: { icon: '', text: '' }, partner: { icon: '', text: '' }, module: { icon: '', text: '' }, text: { icon: '', text: '' } },
      button: '',
      style: 'position: absolute; right: 10px; top: -25px; z-index: 0;',
      imgStyle: 'height: 220px;',
      img: require('@/assets/images/Login.png')
    },{
      type: 'Protocolization',
      representation: false,
      title: 'Estamos protocolizando tu acta, toma un respiro :)',
      text: '',
      Appointment: { date: { icon: '', text: '' }, partner: { icon: '', text: '' }, module: { icon: '', text: '' }, text: { icon: '', text: '' } },
      button: '',
      style: 'position: absolute; right: 10px; top: -25px; z-index: 0;',
      imgStyle: 'height: 220px;',
      img: require('@/assets/images/Login.png')
    },{
      type: 'rejection',
      representation: false,
      title: 'Estamos corrigiendo tu acta, toma un respiro :)',
      text: 'Te notificaremos cuando esté lista y en camino',
      Appointment: { date: { icon: '', text: '' }, partner: { icon: '', text: '' }, module: { icon: '', text: '' }, text: { icon: '', text: '' } },
      button: '',
      style: 'position: absolute; right: 10px; top: -25px; z-index: 0;',
      imgStyle: 'height: 220px;',
      img: require('@/assets/images/Login.png')
    }
  ]
  doc: any = { name: 'Acuse de cita SAT', type: 'acuse_cita_sat', loading: false, blob: null, fileType: '' }
  loading = false
  requestedAppointment = false
  lowAvailability = false
  scheduledAppointment = false
  possibleCancel = false
  existRFC = false
  numbersSteps = 5
  renderKeyAppointmentSAT = 0
  renderKeyRescheduleSAT = 2
  renderKeyAddRFC = 4

  @Watch('modal.update')
  async update () {
    if(!this.modal.update) return
    this.modal.update = false
    this.renderKeyAppointmentSAT += 1
    this.renderKeyRescheduleSAT += 1
    this.renderKeyAddRFC += 1
    EventBus.$emit('update-final')
  }

  created () {
    this.points = this.$attrs.value
  }

  get getCurrentStep () {
    const pointsAux = this.points.filter((point: any ) => point.complete === true)
    return pointsAux.length + 1
  }

  get step () {
    const pointsAuxStepper = this.points.filter((point: any ) => point.stepper === true)
    this.numbersSteps = pointsAuxStepper.length
    const errorAct = this.state.company.stages.find((stage: any) => stage.stage_name === 'error_acta').is_completed
    if (errorAct === true) {
      const stepScheduled = this.steps.filter((step: any ) => step.type === 'rejection')[0]
      return stepScheduled
    }
    const pointsAux = this.points.map((point: any) => { return point })
    const existTestimony = this.state.company.articles_of_incorporation.document_url !== null
    const companyArticlesMock = this.state.company.stages.find((stage: any) => stage.stage_name === 'signing_complete')
    if (!this.state.company.legal_representation) {
      this.requestedAppointment = this.state.company.status_federal_registration.id !== null
      this.lowAvailability = this.state.company.status_federal_registration.created_at === null ? false : (moment().valueOf() > (moment(this.state.company.status_federal_registration.created_at).valueOf() + 604800000))
      this.scheduledAppointment = !this.requestedAppointment ? false : this.state.company.status_federal_registration.is_scheduled
      this.possibleCancel = !this.scheduledAppointment ? false : (moment().valueOf() < (moment(this.state.company.status_federal_registration.date).valueOf() - 7320000))
    } else if (existTestimony) {
      const act = this.state.company.documents.filter((doc: any ) => doc.document_type === 'articles_of_incorporation')[0]
      this.lowAvailability = (moment().valueOf() > (moment(act.created_on).valueOf() + 604800000))
    }
    for (let i = 0; pointsAux.length > 1 && pointsAux[0].complete === true; i++) pointsAux.shift()
    const step = this.steps.filter((step: any ) => pointsAux[0].type === step.type)
    if (step[0].type === 'Protocolization') return step[0]
    else if (step[0].type === 'Register') {
      const date = moment(moment(companyArticlesMock.updated_on).valueOf() + 518400000).format("DD/MM/YYYY")
      const date2 = moment(moment(companyArticlesMock.updated_on).valueOf() + 777600000).format("DD/MM/YYYY")
      if (moment().valueOf() > (moment(companyArticlesMock.updated_on).valueOf() + 518400000)) step[0].text = 'Fecha estimada de realización ' + date2
      else step[0].text = 'Fecha estimada de realización ' + date
      return step[0]
    } else if (step[0].type === 'Delivery') {
      if (this.state.company.legal_representation) return step.filter((step: any ) => step.representation === true)[0]
      else {
        const delivery = step.filter((step: any ) => step.representation === false)[0]
        // if (this.requestedAppointment) delivery.text = 'Estamos obteniendo la cita en el SAT para que acudas lo antes posible a tramitar el RFC y FIEL de tu nueva empresa'
        return delivery
      }
    } else if (step[0].type === 'SAT') {
      if (this.state.company.legal_representation) {
        const stepSatRepresentation = step.filter((step: any ) => step.representation === true)[0]
        if (this.scheduledAppointment) stepSatRepresentation.text = 'Un representante de Tally acudirá ese día y hora al SAT a inscribir tu empresa y obtener su RFC y FIEL, con esto podrás abrir tu Cuenta Empresarial.'
        else stepSatRepresentation.text = this.lowAvailability ? 'Hay poca disponibilidad en el SAT en estos momentos' : 'Este proceso puede demorar hasta 7 días y te notificaremos cuando esté lista'
        return stepSatRepresentation
      } else if (this.scheduledAppointment) {
        const stepScheduled = step.filter((step: any ) => step.title === 'Cita agendada')[0]
        const aux = moment(new Date(this.state.company.status_federal_registration.date))
        stepScheduled.Appointment.date.text = aux.format('DD') + ' de ' + aux.format('MMMM') + ' del ' + aux.format('YYYY') + ' a las ' + aux.format('hh:mm A') + '.'
        const partnerAux = this.state.company.partners.filter((partner: any ) => partner.rfc === this.state.company.status_federal_registration.rfc)[0]
        if (partnerAux !== undefined) stepScheduled.Appointment.partner.text = this.getPartnerFullName(partnerAux)
        if (this.state.company.status_federal_registration.module !== null) stepScheduled.Appointment.module.text = this.state.company.status_federal_registration.module
        if (this.state.company.legal_representation) stepScheduled.text = 'Un representante de Tally acudirá ese día y hora al SAT a inscribir tu empresa y obtener su RFC y FIEL, con esto podrás abrir tu Cuenta Empresarial.'
        else if (this.state.company.appointment_type_efirma) stepScheduled.text =  'En caso de no poder asistir a tu cita, podrás reagendar una nueva. Recuerda llevar el Acta constitutiva de la empresa en original (no copia certificada), un comprobante de domicilio a nombre de la empresa, emitido hace menos de 3 meses y que cuente con todas sus páginas, la identificación del socio o apoderado que acuda a la cita, la impresión del acuse de cita, una memoria USB y el acuse de inscripción ante el RFC impreso'
        return stepScheduled
      } else {
        const stepAppointment = step.filter((step: any ) => step.title === 'Estamos obteniendo la cita en el SAT')[0]
        stepAppointment.text = this.lowAvailability ? 'Parece que hay poca disponibilidad en el SAT' : 'Este proceso puede demorar hasta 7 días y te notificaremos cuando esté lista'
        return stepAppointment
      }
    } else if (step[0].type === 'RFC') {
      if (this.state.company.legal_representation) {
        const stepScheduled = step.filter((step: any ) => step.representation === true)[0]
        const aux = moment(new Date(this.state.company.status_federal_registration.date))
        stepScheduled.Appointment.date.text = aux.format('DD') + ' de ' + aux.format('MMMM') + ' del ' + aux.format('YYYY') + ' a las ' + aux.format('hh:mm A') + '.'
        return stepScheduled
      } else return step.filter((step: any ) => step.representation === false)[0]
    } else if (step[0].type === 'Business account') {
      if (this.state.company.legal_representation) {
        let businessStep = step.filter((step: any ) => step.representation === true)[0]
        businessStep.text = 'Este proceso dura aproximadamente 7 días, a partir de que llenes el formulario.'
        const complete = this.state.company.banking_services.filter((service: any ) => service.name === 'BBVASpark')
        if (complete.length) businessStep.title = 'Estamos creando tu cuenta'
        // if (this.state.company.flag_data_banking_service) businessStep.title = 'Conoce los detalles de tu cuenta empresarial'
        return businessStep
      }
      let businessStep = step.filter((step: any ) => step.representation === false)[0]
      if (this.existFiels) businessStep.text = 'Desbloquea el siguiente paso, compartiéndonos el RFC y e.Firma de tu nueva empresa'
      // this.existRFC = this.state.company.rfc !== null && this.state.company.rfc !== ''
      /* if (this.existFiels || !this.existRFC) {
        businessStep.title = 'Pasa al siguiente nivel'
        businessStep.text = 'Actualiza tus datos de facturación compartiéndonos el RFC y e.Firma de tu nueva empresa'
      } */
      return businessStep
    } else if (step[0].type === 'Accounting') {
      if (this.state.company.legal_representation) {
        return step.filter((step: any ) => step.representation === true)[0]
      }
      return step.filter((step: any ) => step.representation === false)[0]
    }
    return null
  }

  getPartnerFullName (partner: any) {
    let full_name: string = partner.name != null ? partner.name : ''
    full_name = partner.first_names != null ? full_name + ' ' + partner.first_names : full_name
    full_name = partner.last_names != null ? full_name + ' ' + partner.last_names : full_name
    return full_name.trimEnd()
  }

  async cancelAppointment () {
    this.loading = true
    if (moment().valueOf() > (moment(this.state.company.status_federal_registration.date).valueOf() - 7200000)) {
      this.loading = false
      return this.$toasted.global.error({ message: 'Solo se puede cancelar la cita cuando cuenta con más de 2 horas de diferencia con la hora pautada para la cita' })
    }
    // eslint-disable-next-line
    const [error, response] = await SATService.cancelAppoinmentSAT(this.state.company.status_federal_registration.id)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    await this.updateCompany(this.state.company.id)
    EventBus.$emit('update-final')
    this.loading = false
  }

  async download () {
    this.doc.loading = true
    if (this.doc.blob === null) {
      const [error, response] = await ImageService.getDocument('company', this.state.company.id, this.doc.type)
      if (error) {
        this.doc.loading = false
        return this.$toasted.global.error(error)
      }
      this.doc.fileType = response.headers['content-type'].split('/')[1]
      this.doc.blob = new Blob([response.data])
      saveAs(this.doc.blob, `${this.doc.name + '.' + this.doc.fileType}`)
    } else saveAs(this.doc.blob, `${this.doc.name +  '.' + this.doc.fileType}`)
    this.doc.loading = false
    return this.$toasted.global.success({ message: 'Descarga exitosa' })
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
