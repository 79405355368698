























// Utils
import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'
// Types
import AuthTypes from '@/store/types/AuthTypes'
import UserTypes from '@/store/types/UserTypes'
import CompanyTypes from '@/store/types/CompanyTypes'

@Component
export default class ValidationActToUser extends Vue {
  async created () {
    await this.clearData()
    await this.clearDataUser()
    await this.setPayload(this.$route.query)
    return this.$router.push({ name: 'Welcome' }).catch(() => {return})
  }

  @Action(`AuthModule/${AuthTypes.actions.SET_PAYLOAD}`) setPayload: any;
  @Action(`UserModule/${UserTypes.actions.CLEAR_DATA}`) clearDataUser: any;
  @Action(`CompanyModule/${CompanyTypes.actions.CLEAR_DATA}`) clearData: any;
}
