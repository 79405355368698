
























































































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import { debounce } from 'lodash'
// Components
import Loader from '@/components/Loader.vue'
// Services
import CountryService from '@/services/Country'
import PartnersService from '@/services/Partners'
import AdministrationService from '@/services/Administration'
// Types
import UserTypes from '@/store/types/UserTypes'

@Component({
  components: { Loader }
})
export default class ModalEditPartners extends Vue {
  modal: any = {
    showModalCompanyPartners: false,
    showModalCompanyDistribution: false,
    update: false,
  }
  loading = false
  promise_error = false
  editLimit = false
  partners: any = []
  delete_partners: any = []
  nationalities = []
  countries = []

  searchPartnerUser = debounce(async (partner: any, search: boolean) => {
    if (search) await this.existPartnerUser(partner)
  }, 2000)

  mounted () {
    this.modal = this.$attrs.value
    this.getPartners()
    this.getCountries()
    this.getNationalities()
    const documentsApproved = this.state.company.stages.find((stage: any) => stage.stage_name === 'documents_approved').is_completed === true
    if (this.state.company.timer !== null && documentsApproved) this.editLimit = true
  }

  get completePartners () {
    let complete = true
    this.partners.map((partner: any) => {
      if (!partner.exist && (partner.name === '' || partner.first_names === '' || partner.email === '' || !partner.completeEmail)) complete = false
    })
    return complete
  }

  get validateEmail () {
    let valid = true
    this.partners.map((partner: any) => { if (!partner.validEmail) valid = false })
    return valid
  }

  get repeatEmail () {
    let repeat = false
    this.partners.map((partner: any) => { if (partner.repeatedEmail) repeat = true })
    return repeat
  }

  noSpacesStart (value: string) { return value.trimStart() }

  onlyNumbers (value: string) { return value.replace(/[^0-9.]/g,'') }

  getPartners () {
    this.partners = []
    this.state.company.partners.map((partner: any) => {
      this.partners.push({
        name: partner.name,
        first_names: partner.first_names,
        last_names: partner.last_names,
        email: partner.email,
        phone: partner.phone !== null ? partner.phone : '',
        phone_country_code: partner.phone_country_code !== null ? partner.phone_country_code : '52',
        nationality: partner.nationality,
        id: partner.id,
        show: false,
        validEmail: true,
        completeEmail: true,
        repeatedEmail: false,
        exist: (partner.email !== this.state.email && partner.user_id !== null),
        isUser: partner.user_id !== null,
        loading: false
      })
    })
  }

  addPartner () {
    this.partners.push({
      name: '',
      first_names: '',
      last_names: '',
      email: '',
      phone: '',
      phone_country_code: '52',
      nationality: 'MX',
      id: '',
      show: true,
      validEmail: true,
      completeEmail: true,
      repeatedEmail: false,
      exist: false,
      isUser: false,
      loading: false
    })
  }

  deletePartner (id: string, index: number) {
    if (id !== '') this.delete_partners.push(id)
    this.partners.splice(index, 1)
  }

  validate (partner: any) {
    if (partner.email === '') return partner.validEmail = true
    const re = /\S+@\S+\.\S+/
    partner.validEmail = re.test(partner.email)
  }

  validateCompleteEmail (partner: any) {
    const re = /\S+@\S+\.\S+/
    partner.completeEmail = re.test(partner.email)
    this.partners.map((partner: any, index: number) => { this.partners[index].repeatedEmail = false })
    this.partners.map((partner: any) => { 
      let repeated = this.partners.filter((partnerAux: any) => partnerAux.email.toLowerCase() === partner.email.toLowerCase() && partner.email !== '')
      if (repeated.length >= 2) repeated.map((partner: any) => { this.validateRepeatedEmail(partner.email) })
    })
    if (partner.completeEmail) {
      partner.loading = true
      partner.exist = partner.email !== this.state.email
      this.searchPartnerUser(partner, true)
    } else {
      partner.loading = false
      partner.exist = false
      this.searchPartnerUser(partner, false)
    }
  }

  validateRepeatedEmail (email: string) {
    this.partners.map((partner: any, index: number) => { if (partner.email.toLowerCase() === email.toLowerCase()) this.partners[index].repeatedEmail = true })
  }

  async setDefaultCouncil () {
    const partners = this.state.company.partners.map((partner: any, index: number) => {
      if (this.state.company.company_type === 'sa' && this.state.company.has_unique_administrator) {
        if (index === 0) return { name: this.getPartnerFullName(partner), id: partner.id, role: 'unique_administrator' }
        return { name: this.getPartnerFullName(partner), id: partner.id, role: 'no-member' }
      } else {
        if (index === 0) return { name: this.getPartnerFullName(partner), id: partner.id, role: 'president' }
        else if (index === 1) return { name: this.getPartnerFullName(partner), id: partner.id, role: 'secretary' }
        else if (index === 2) return { name: this.getPartnerFullName(partner), id: partner.id, role: 'treasurer' }
        else return { name: this.getPartnerFullName(partner), id: partner.id, role: 'member' }
      }
    })
    const [error] = await AdministrationService.storeAdministration(this.state.company.id, 'council', partners, false)
    if (error) return this.$toasted.global.error(error)
    await this.updateCompany(this.state.company.id)
  }

  getPartnerFullName (partner: any) {
    let full_name: string = partner.name != null ? partner.name : ''
    full_name = partner.first_names != null ? full_name + ' ' + partner.first_names : full_name
    full_name = partner.last_names != null ? full_name + ' ' + partner.last_names : full_name
    return full_name.trimEnd()
  }

  async getNationalities () {
    const [error, response] = await CountryService.nationalities()
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.loading = false
    this.nationalities = response.data.data
  }

  async getCountries () {
    const [error, response] = await CountryService.all()
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.loading = false
    this.countries = response.data.data
  }

  async existPartnerUser (partner: any) {
    const [error, response] = await PartnersService.existPartnerUser(partner.email)
    if (error) {
      partner.loading = false
      partner.exist = false
    }
    partner.name = response.data.data.name
    partner.first_names = response.data.data.first_names !== null ? response.data.data.first_names : ''
    partner.last_names = response.data.data.last_names !== null ? response.data.data.last_names : ''
    partner.phone = response.data.data.phone !== null ? response.data.data.phone : ''
    partner.phone_country_code = response.data.data.phone_country_code !== null ? response.data.data.phone_country_code : '52'
    partner.loading = false
  }

  async updatePartners () {
    this.loading = true
    // Save delete partners
    if (this.delete_partners.length > 0) {
      this.modal.checkDistribution = true
      await Promise.all(
        this.delete_partners.map(async (partner: any) => {
          const [error] = await PartnersService.deletePartner(partner, this.state.company.id)
          if (error) {
            this.promise_error = true
            return this.$toasted.global.error(error)
          }
        })
      )
      if (this.promise_error) {
        await this.updateCompany(this.state.company.id)
        this.getPartners()
        this.loading = false
        return this.promise_error = false
      }
      this.delete_partners = []
    }
    // Save partner changes
    const update_partners = this.partners.filter((partner: any) => partner.id !== '')
    if (update_partners.length > 0) {
      const [error] = await PartnersService.updatePartners(update_partners)
      if (error) {
        this.loading = false
        return this.$toasted.global.error(error)
      }
    }
    // Save new partners
    const new_partners = this.partners.filter((partner: any) => partner.id === '')
    if (new_partners.length > 0) {
      this.modal.checkDistribution = true
      const [error] = await PartnersService.storeMultiplePartners(this.state.company.id, new_partners)
      if (error) {
        this.loading = false
        return this.$toasted.global.error(error)
      }
    }
    // Get new user info
    await this.updateCompany(this.state.company.id)
    const existUnique = this.state.company.council.filter((member: any ) => member.council_role === 'unique_administrator').length === 0
    const existPresident = this.state.company.council.filter((member: any ) => member.council_role === 'president').length === 0
    const existSecretary = this.state.company.council.filter((member: any ) => member.council_role === 'secretary').length === 0
    const unique = this.state.company.has_unique_administrator
    if ((this.state.company.company_type === 'sapi' && unique) || (unique && existUnique) || (!unique && (existPresident || existSecretary))) await this.setDefaultCouncil()
    this.getPartners()
    this.modal.showModalCompanyPartners = false
    this.modal.showModalCompanyDistribution = this.modal.checkDistribution
    this.modal.update = true
    this.loading = false
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
