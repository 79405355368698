

























// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
import moment from 'moment'
// Services
import CalendlyService from '@/services/Calendly'

@Component
export default class Header extends Vue {
  checkoutComplete = false
  date = ''

  created () {
    this.checkoutComplete = this.state.company.orders.filter((order: any) => order.status === 'complete' && order.items.filter((item: any) => item.name === 'Complemento Acta Constitutiva' || item.name === 'Acta Constitutiva').length > 0).length > 0
  }

  mounted () {
    this.getCalendly()
  }

  async getCalendly () {
    const [error, response] = await CalendlyService.getCalendly(this.state.company.id)
    if (error) {
      return this.$toasted.global.error(error)
    }
    const aux = moment(new Date(response.data.resource.start_time))
    this.date = aux.format('DD') + ' de ' + aux.format('MMMM') + ' del ' + aux.format('YYYY') + ', ' + aux.format('hh:mm A') + '.'
  }

  @State('UserModule') state: any
}
