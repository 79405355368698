












































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
// Services
import ConstitutiveService from '@/services/Constitutive'
// Types
import UserTypes from '@/store/types/UserTypes'

@Component({
  components: { Loader }
})
export default class ModalEditLegalName extends Vue {
  modal: any = {
    showModalLegalName: false,
    update: false,
  }
  legal_name = ''
  loading = false
  validName = true

  mounted () {
    this.modal = this.$attrs.value
    this.legal_name = this.state.company.legal_name
  }

  noSpacesStart (value: string) {
    return value.trimStart()
  }

  async validate () {
    this.loading = true
    // Validate legal name
    const [error, response] = await ConstitutiveService.validateLegalName(this.legal_name)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    if (!response.data.data) {
      this.loading = false
      return this.validName = false
    }
    // Save legal name
    const [error2] = await ConstitutiveService.updateLegalNames(this.state.company.id, [this.legal_name], false)
    if (error2) {
      this.loading = false
      return this.$toasted.global.error(error2)
    }
    // Get new user info
    await this.updateCompany(this.state.company.id)
    this.modal.showModalLegalName = false
    this.modal.update = true
    this.loading = false
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
