































































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
import { saveAs } from 'file-saver'
import moment from 'moment'
// Components
import Loader from '@/components/Loader.vue'
import InputFiles from '@/components/InputFiles.vue'
import ModalAccountStatements from '@/components/accounting/ModalAccountStatements.vue'
import ModalDeleteAccountStatements from '@/components/accounting/ModalDeleteAccountStatements.vue'
// Services
import ImageService from '@/services/Image'
import CompanyService from '@/services/Company'

@Component({
  components: { Loader, InputFiles, ModalAccountStatements, ModalDeleteAccountStatements }
})
export default class AccountStatements extends Vue {
  moment = moment
  day = moment().date()
  modal: any = {
    update: false,
    showModalAccountStatements: false,
    showModalDeleteAccountStatements: false,
    doc: { name: '', id: ''}
  }
  months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
  files: any = null
  renderKeyFiles = 0
  renderDocs = 100
  loading = false
  loadingTable = false
  accountStatements = []

  @Watch('files')
  async update () {
    if (this.files === null) return
    this.saveAccountStatements()
  }

  @Watch('modal.update')
  async updateAccountStatements () {
    if (!this.modal.update) return
    this.getAccountStatements()
    this.modal.update = false
  }

  get accountStatementsMonths () {
    let months: any = []
    let max_year = 0
    let min_year = 9999
    this.accountStatements.map((accountStatement: any) => {
      // get year and month
      const year = moment(accountStatement.accounting_period).year()
      const month = moment(accountStatement.accounting_period).month()
      // get max and min year
      if (year > max_year) max_year = year
      if (year < min_year) min_year = year
      // Establish new months of new year if applicable
      if (!months.length) for (let i = 0; i < 12; i++) { months.push({ year: year, month: this.months[i], accountStatements: [] }) }
      else if (!months.filter((month: any ) => month.year === year).length) for (let i = 0; i < 12; i++) { months.push({ year: year, month: this.months[i], accountStatements: [] }) }
      // Update account statements
      months[month].accountStatements.push({ ...accountStatement, loader: false, blob: null, fileType: '' })
    })
    // Format account statements
    let active_months = months.filter((month: any ) => month.year === max_year)
    for (let year = (min_year + 1); year < max_year; year++) { active_months.concat(months.filter((month: any ) => month.year === year)) }
    return active_months.reverse()
  }

  mounted () {
    this.getAccountStatements()
  }

  async getAccountStatements () {
    this.loadingTable = true
    const [error, response] = await CompanyService.getAccountStatements(this.state.company.id)
    if (error) return this.$toasted.global.error(error)
    this.accountStatements = response.data.data
    this.loadingTable = false
  }

  async upload (file: any, docType: string) {
    if (file === null || !file.name || !file.size) return [null, false]
    const [error] = await CompanyService.uploadAccountStatements({
      company_id: this.state.company.id,
      document_type: docType,
      image: file
    })
    if (error) {
      this.loading = false
      return [error, true]
    }
    return [null, false]
  }

  async saveAccountStatements () {
    this.loading = true
    for (const file of this.files) {
      const error = await this.upload(file, 'account_status')
      if (error[1]) {
        this.loading = false
        return this.$toasted.global.error(error[0])
      }
    }
    this.files = null
    this.renderKeyFiles++
    this.loading = false
    this.getAccountStatements()
    return
  }

  async download (doc: any) {
    doc.loader = true
    this.renderDocs++
    if (doc.blob === null) {
      const [error, response] = await ImageService.getDocumentAccountStatements(this.state.company.id, doc.id)
      if (error) {
        doc.loader = false
        return this.$toasted.global.error(error)
      }
      doc.fileType = response.headers['content-type'].split('/')[1]
      doc.blob = new Blob([response.data])
      saveAs(doc.blob, `${doc.document_name + '.' + doc.fileType}`)
    } else saveAs(doc.blob, `${doc.document_name + '.' + doc.fileType}`)
    doc.loader = false
    this.renderDocs++
  }

  @State('UserModule') state: any
}
