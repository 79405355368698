


















// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Helpers
import { Posthog } from '@/helpers/Posthog'
// Services
// import SATService from '@/services/SAT'

@Component
export default class FinalCards extends Vue {
  cardsAux: any = []
  cards = [/* {
      title: 'Lleva tu contabilidad',
      text: 'Te asignamos un contador y te conectamos al SAT',
      url: '',
      go: '',
      background: require('@/assets/images/Tracking.svg')
    }, */{
      title: 'Documentos',
      text: 'Solicita nuevos documentos, protocolízalos ante notario.',
      url: 'https://tallylegal.typeform.com/to/z3fIcT9l',
      go: '',
      background: require('@/assets/images/Investment.svg')
    },/* {
      title: 'Recibe inversión',
      text: 'Crea un acuerdo de inversión o una resolución de accionistas.',
      url: 'https://tallylegal.typeform.com/to/UPqTigI5',
      go: '',
      background: require('@/assets/images/Diamond.svg')
    }, */{
      title: 'Registra tu marca',
      text: 'Protege tu idea más valiosa.',
      url: 'https://tallylegal.typeform.com/to/sTJawPu9',
      go: '',
      background: require('@/assets/images/Documents.svg')
    },/* {
      title: 'Crea un pacto de socios',
      text: 'Protege la participación de la empresa.',
      url: 'https://tallylegal.typeform.com/to/gOAybYcS',
      go: '',
      background: require('@/assets/images/Investment.svg')
    }, */{
      title: 'Padrón de Importadores',
      text: 'Crea un acuerdo de inversión o una resolución de accionistas',
      url: 'https://tallylegal.typeform.com/padronimport',
      go: '',
      background: require('@/assets/images/Airplane.svg')
    },{
      title: 'Solicita una Visa o residencia',
      text: 'Obtén tu tarjeta como residente permanente',
      url: 'https://tallylegal.typeform.com/to/KSYpDgPX',
      go: '',
      background: require('@/assets/images/Visa.svg')
    }
  ]
  loading = false

  mounted () {
    this.selectCards()
  }

  async selectCards () {
    // Get fiels status
    /* const [error, response] = await SATService.getFielStatus(this.state.company.id)
    if (error) {
      this.loading = false
      //this.cardsAux = this.cards.filter((card: any) => card.title !== 'Lleva tu contabilidad')
      return this.$toasted.global.error(error)
    } */
    // const fiels = response.data.data
    // const viewAccountant = !fiels.certificate && !fiels.private_key && (fiels.password === null || fiels.password === '')
    // Update redirect in card accountant
    /* const checkoutComplete = this.state.company.orders.filter((order: any) => order.status === 'complete' && order.items.filter((item: any) => item.name === 'Servicio de Comisario').length > 0).length > 0
    if (!checkoutComplete) this.cards[0].go = 'AccountantTally'
    else this.cards[0].go = 'CompanyFiels' */
    // Select cards to show
    // if ((!viewAccountant && checkoutComplete) || this.state.company.legal_representation) this.cardsAux = this.cards.filter((card: any) => card.title !== 'Lleva tu contabilidad')
    if (!this.state.company.trademark_registration_later) this.cardsAux = this.cards.filter((card: any) => card.title !== 'Registra tu marca')
    else this.cardsAux = this.cards
  }

  async event (service: string, go: string) {
    await Posthog.event("BF_UpsellService", { service })
    if (go !== null && go !== '') return this.$router.push({ name: go, query: this.$route.query, params: { company_id: this.state.company.id } })
  }
  
  @State('UserModule') state: any
}
