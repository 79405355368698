







































































// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'
// Services
import CountryService from '@/services/Country'
import PhoneService from '@/services/Phone'

@Component({
  components: { Loader }
})
export default class EditPhone extends Vue {
  @Prop() title: string|undefined
  @Prop() subTitle: string|undefined
  @Prop() address: string|undefined
  loading = true
  phone = ''
  country = { lada: '52', alpha_code: 'MX' }
  countries = []

  mounted () {
    this.getCountries()
  }

  onlyNumbers (value: string) {
    return value.replace(/[^0-9.]/g,'');
  }

  async getCountries () {
    const [error, response] = await CountryService.all()
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.loading = false
    this.countries = response.data.data
  }

  async next () {
    this.loading = true
    const [error] = await PhoneService.newPhone(this.state.id, this.phone, this.country.lada )
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    await this.setPhone(this.phone)
    await this.setCountry(this.country)
    return this.$router.push({ name: this.address, query: this.$route.query }).catch(() => {return})
  }

  @State('UserModule') state: any
  @Action(`CompanyModule/${CompanyTypes.actions.SET_PHONE}`) setPhone: any;
  @Action(`CompanyModule/${CompanyTypes.actions.SET_COUNTRY}`) setCountry: any;
}
