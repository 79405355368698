






// Utils
import { Component, Vue } from 'vue-property-decorator'
// Helpers
import { UTM } from '@/helpers/UTM'

@Component
export default class Main extends Vue {
  updated () {
    UTM.updateUTMs(this.$router, this.$route)
  }

  created () {
    window.addEventListener('beforeunload', this.beforeWindowUnload)
  }
  
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  }

  beforeWindowUnload () {
    window.posthog.capture("$pageleave", { $pathname: this.$route.name, $current_url: this.$route.fullPath })
  }
}
