import to from 'await-to-js'
import axios from '@/utils/axios-api'
import PaymentCard from '@/interfaces/PaymentCard'

const URL_PHP = process.env.VUE_APP_BASE_URL_PHP

export default class Payment {
  static async getPrices () {
    return await to(axios.get(`${URL_PHP}/v1/item/all`)) as any
  }

  static async validateCoupon (name: string) {
    return await to(axios.get(`${URL_PHP}/v1/coupon/validate/${name}`)) as any
  }

  static async createOrder (data: any) {
    return await to(axios.post(`${URL_PHP}/v2/payment-order`, data)) as any
  }

  static async paymentWithCard (data: PaymentCard, order: string) {
    return await to(axios.post(`${URL_PHP}/v2/payment-confirm/order/${order}`, data)) as any
  }

  static async slackPaymentAlert (company: string) {
    return await to(axios.get(`${URL_PHP}/v2/alert-payment-slack/${company}`)) as any
  }

  static async whereWeMet (company: string, where_we_met: string) {
    return await to(axios.post(`${URL_PHP}/v2/save-where_we_met/${company}`, { where_we_met })) as any
  }

  static async updatePaymentMethod (card: any) {
    return await to(axios.post(`${URL_PHP}/v2/update-payment-method`, { card })) as any
  }

  static async getUSDRate (base: string, convert: string) {
    return await to(fetch(`https://api.exchangerate.host/latest?base=${base}&symbols=${convert}` , { method: 'GET' })) as any
  }

  static async validateToken (token: string, email: string) {
    return await to(axios.post(`${URL_PHP}/v2/validate/temporal-token`, { token, email })) as any
  }

  static async getBankTranfer (user: string, token: string, company: string) {
    return await to(axios.post(`${URL_PHP}/v2/payment/bank-transfer/${user}/company/${company}`, { token })) as any
  }

  static async updatePaymentMethodExtern (user: string, token: string, card: any) {
    return await to(axios.post(`${URL_PHP}/v2/payment/update-payment-method/temporally-token/${user}`, { token, card })) as any
  }

  static async createPaymentLink (items: Array<any>, company: string, user: string) {
    return await to(axios.post(`${URL_PHP}/v2/payment/create-payment-link`, { items: items, company_id: company , user_id: user }))
  }
}
