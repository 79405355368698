import store from '@/store/index'
import AuthTypes from '@/store/types/AuthTypes'

export default class Auth {
  static async isLogged (to: any, from: any, next: any) {
    const payload = store.getters[`AuthModule/${AuthTypes.getters.GET_PAYLOAD}`]
    if (payload.access_token === '') return next({ name: 'Login', query: to.query })
    return next()
  }
}
