




// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Types
import AuthTypes from "@/store/types/AuthTypes"
// Services
import PartnersService from '@/services/Partners'

@Component({
  components: {  }
})
export default class GoogleLogin extends Vue {
  @Prop({default: 'Welcome'}) address!: string
  @Prop({default: ''}) company_id!: string
  loading = false

  async mounted () {
    await this.mountButtonGoogle()
  }

  async mountButtonGoogle () {
    window.google.accounts.id.initialize({
      client_id: process.env.VUE_APP_GOOGLE_ID,
      callback: this.handleCredentialResponse
    });
    window.google.accounts.id.renderButton(
      document.getElementById('buttonDiv'),
      { theme: 'outline', width: 300, logo_alignment: 'left' }
    );
    window.google.accounts.id.prompt();
  }

  async handleCredentialResponse (response: any) {
    if (!response || !response.credential) return
    try {
      this.loading = true
      const [err] = await this.loginGoogle(response)
      if (err) {
        this.loading = false
        return this.$toasted.global.error(err)
      }
      if (this.$route.name === 'Start') {
        // eslint-disable-next-line
        const [error, response] = await PartnersService.invitationResponse(this.invitedState.partner.id, this.invitedState.company_id, true)
        if (error) {
          this.loading = false
          return this.$toasted.global.error(error)
        }
      }
      if (this.company_id === '') return this.$router.push({ name: this.address, query: this.$route.query }).catch(() => { return })
      else return this.$router.push({ name: this.address, query: this.$route.query, params: { company_id: this.company_id } }).catch(() => { return })
    } catch (e) {
      this.loading = false
      return this.$toasted.global.error(e)
    }
  }
  @Action(`AuthModule/${AuthTypes.actions.LOGINGOOGLE}`) loginGoogle: any
  @State('InvitedModule') invitedState: any
}
