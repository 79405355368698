// Utils
import { RouteConfig } from 'vue-router'
import multiguard from 'vue-router-multiguard'
// Views
import Index from '@/views/InvitedPartner/Index.vue'
import Start from '@/views/InvitedPartner/Start.vue'
import End from '@/views/InvitedPartner/End.vue'
import InvitedRegister from '@/views/InvitedPartner/InvitedRegister.vue'
import InvitedPhoneEdit from '@/views/InvitedPartner/InvitedPhoneEdit.vue'
import InvitedValidation from '@/views/InvitedPartner/InvitedValidation.vue'
import InvitedCivilStatus from '@/views/InvitedPartner/InvitedCivilStatus.vue'
import InvitedAnalyzingData from '@/views/InvitedPartner/InvitedAnalyzingData.vue'
import InvitedPhoneValidate from '@/views/InvitedPartner/InvitedPhoneValidate.vue'
import InvitedComplementaries from '@/views/InvitedPartner/InvitedComplementaries.vue'
import InvitedValidationComplete from '@/views/InvitedPartner/InvitedValidationComplete.vue'
import InvitedAddress from '@/views/InvitedPartner/InvitedAddress.vue'
// Middlewares
import Invited from '@/middlewares/Invited'

const routes: Array<RouteConfig> = [
  {
    path: '/invited',
    component: Index,
    children: [
      {
        path: '',
        name: 'Start',
        component: Start,
        beforeEnter: multiguard([Invited.validatePartner])
      },
      {
        path: 'complementaries',
        name: 'InvitedComplementaries',
        component: InvitedComplementaries,
        beforeEnter: multiguard([Invited.isInvited])
      },
      {
        path: 'address',
        name: 'InvitedAddress',
        component: InvitedAddress,
        beforeEnter: multiguard([Invited.isInvited])
      },
      {
        path: 'validation',
        name: 'InvitedValidation',
        component: InvitedValidation,
        beforeEnter: multiguard([Invited.isInvited])
      },
      {
        path: 'civil_status',
        name: 'InvitedCivilStatus',
        component: InvitedCivilStatus,
        beforeEnter: multiguard([Invited.isInvited])
      },
      {
        path: 'register',
        name: 'InvitedRegister',
        component: InvitedRegister,
        beforeEnter: multiguard([Invited.isInvited])
      },
      {
        path: 'phone_validate',
        name: 'InvitedPhoneValidate',
        component: InvitedPhoneValidate,
        beforeEnter: multiguard([Invited.isInvited])
      },
      {
        path: 'phone_edit',
        name: 'InvitedPhoneEdit',
        component: InvitedPhoneEdit,
        beforeEnter: multiguard([Invited.isInvited])
      },
      {
        path: 'analyzing_data',
        name: 'InvitedAnalyzingData',
        component: InvitedAnalyzingData,
        beforeEnter: multiguard([Invited.isInvited])
      },
      {
        path: 'validation_complete',
        name: 'InvitedValidationComplete',
        component: InvitedValidationComplete,
        beforeEnter: multiguard([Invited.isInvited])
      },
      {
        path: 'end',
        name: 'End',
        component: End,
        beforeEnter: multiguard([Invited.isInvited])
      }
    ]
  }
]

export default routes
