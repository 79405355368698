




















// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
})
export default class TooltipAddress extends Vue {
  @Prop({default: ''}) color!: string
  points = [{
      point: 'Puedes subir un recibo de teléfono fijo, luz o estado de cuenta bancario, con hasta 3 meses de antiguedad.',
      url: ''
    },{
      point: 'Es importante que contenga todas las páginas y que se vea clara la información.',
      url: ''
    }
  ]
}
