// Utils
import { RouteConfig } from 'vue-router'
import multiguard from 'vue-router-multiguard'
// Views
import Index from '@/views/benefits/Index.vue'
import Fondeadora from '@/views/benefits/Fondeadora.vue'
import BancoBase from '@/views/benefits/BancoBase.vue'
import BBVASpark from '@/views/benefits/BBVASpark.vue'
import Kapital from '@/views/benefits/Kapital.vue'
import Stripe from '@/views/benefits/Stripe.vue'
import Gigstack from '@/views/benefits/Gigstack.vue'
import Clara from '@/views/benefits/Clara.vue'
import Delt from '@/views/benefits/Delt.vue'
import Jeeves from '@/views/benefits/Jeeves.vue'
import Around from '@/views/benefits/Around.vue'
import Dgmxtech from '@/views/benefits/Dgmxtech.vue'
import Krowdy from '@/views/benefits/Krowdy.vue'
import BenefitsFiels from '@/views/benefits/BenefitsFiels.vue'
// Middlewares
import Auth from '@/middlewares/Auth'
import User from '@/middlewares/User'

const routes: Array<RouteConfig> = [
  {
    path: '/benefits/:company_id',
    component: Index,
    beforeEnter: multiguard([Auth.isLogged, User.validateData]),
    children: [
      { path: 'fiels', name: 'BenefitsFiels', component: BenefitsFiels },
      { path: 'fondeadora', name: 'Fondeadora', component: Fondeadora },
      { path: 'BancoBase', name: 'BancoBase', component: BancoBase },
      { path: 'BBVASpark', name: 'BBVASpark', component: BBVASpark },
      { path: 'Kapital', name: 'Kapital', component: Kapital },
      { path: 'Stripe', name: 'Stripe', component: Stripe },
      { path: 'Gigstack', name: 'Gigstack', component: Gigstack },
      { path: 'Clara', name: 'Clara', component: Clara },
      { path: 'Delt', name: 'Delt', component: Delt },
      { path: 'Jeeves', name: 'Jeeves', component: Jeeves },
      { path: 'Around', name: 'Around', component: Around },
      { path: 'Dgmxtech', name: 'Dgmxtech', component: Dgmxtech },
      { path: 'Krowdy', name: 'Krowdy', component: Krowdy }
    ]
  }
]

export default routes
