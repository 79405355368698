import to from 'await-to-js'
import axios from '@/utils/axios-api'

const URL_PHP = process.env.VUE_APP_BASE_URL_PHP

export default class Reviews {
  static async reviewTally (company: string, qualification: number) {
    return await to(axios.post(`${URL_PHP}/v2/qualification-company/${company}`, { qualification } )) as any
  }
}
