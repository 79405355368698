















































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import { saveAs } from 'file-saver'
// Components
import ModalAddRFC from '@/components/dashboard/modals/ModalAddRFC.vue'
import ModalShowFields from '@/components/dashboard/modals/ModalShowFields.vue'
// Types
import UserTypes from '@/store/types/UserTypes'
// Services
import ImageService from '@/services/Image'
import SATService from '@/services/SAT'
import CompanyService from '@/services/Company'

@Component({
  components: { ModalAddRFC, ModalShowFields }
})
export default class CardTaxInformation extends Vue {
  modal: any = {
    showModalShowFields: false,
    showModalAddRFC: false,
    update: false,
    validate: false
  }
  loadingUnlock = false
  codeSend = false
  legalName: any = null
  legalNameApproved = []
  companyDocs: any = []
  csf = { type: '', name: '', id: '', blob: null, fileType: '', loader: false, loader2: false }
  fiels: any = []
  password = ''
  passwordFieldType = 'password'
  eye = 'eye'

  created () {
    this.setFiels()
  }

  switchVisibility () {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    this.eye = this.eye === 'eye' ? 'eye-slash' : 'eye'
  }

  setCompanyDocs () {
    this.state.company.documents.map((doc: any) => {
      if (doc.document_type === 'CSF_company') this.csf = { type: doc.document_type, name: 'Constancia de Situación Fiscal', id: doc.id, blob: null, fileType: '', loader: false, loader2: false }
    })
  }

  copyPassword () {
    navigator.clipboard.writeText(this.password)
    this.$toasted.global.success({ message: 'Se ha copiado la contraseña con éxito' })
  }

  unlock () {
    if (this.state.code !== ''){
      this.verifyCode()
    } else {
      if (!this.codeSend) this.sendCode()
      this.modal.showModalShowFields = true
    }
  }

  b64toBlob (b64Data: string, contentType: string) {
    const sliceSize = 512
    const byteCharacters = atob(b64Data)
    const byteArrays = []
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)
      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  async setFiels () {
    const [error, response] = await SATService.getFielStatus(this.state.company.id)
    if (error) {
      return this.$toasted.global.error(error)
    }
    const fiels = response.data.data
    if (fiels.certificate) this.fiels.push({ type: 'certificate', name: 'Certificado', id: '', blob: null, fileType: '', loader: false })
    if (fiels.private_key) this.fiels.push({ type: 'private_key', name: 'Certificado', id: '', blob: null, fileType: '', loader: false })
    if (fiels.password !== null && fiels.password !== '') {
      this.password = fiels.password
      this.fiels.push({ type: 'password', name: 'Contraseña FIEL', id: '', blob: null, fileType: '', loader: false })
    }
  }

  async sendCode () {
    this.loadingUnlock = true
    // eslint-disable-next-line
    const [error, response] = await CompanyService.sendBankCode(this.state.company.id)
    if (error) {
      this.loadingUnlock = false
      return this.$toasted.global.error(error)
    }
    this.codeSend = true
    this.loadingUnlock = false
    return this.$toasted.global.success({ message: 'Se ha enviado el código' })
  }

  async verifyCode () {
    this.loadingUnlock = true
    const code = this.state.code.substring(0,5)
    // eslint-disable-next-line
    const [error, response] = await CompanyService.verifyBankCode(this.state.company.id, code)
    if (error) {
      this.loadingUnlock = false
      this.setCode('')
      if (!this.codeSend) this.sendCode()
      this.modal.showModalShowFields = true
      return
    }
    this.modal.validate = true
    this.loadingUnlock = false
  }

  async download (doc: any) {
    doc.loader = true
    if (doc.type === 'certificate' && doc.blob === null) {
      const [error, response] = await ImageService.getCer(this.state.company.id)
      if (error) {
        doc.loader = false
        return this.$toasted.global.error(error)
      }
      doc.fileType = 'cer'
      doc.blob = this.b64toBlob(response.data.certificate_cer, 'aplication/pkix-cert')
      saveAs(doc.blob, `${doc.name + '.' + doc.fileType}`)
    } else if (doc.type === 'private_key' && doc.blob === null) {
      const [error, response] = await ImageService.getKey(this.state.company.id)
      if (error) {
        doc.loader = false
        return this.$toasted.global.error(error)
      }
      doc.fileType = 'key'
      doc.blob = this.b64toBlob(response.data.certificate_key, 'aplication/octet-stream')
      saveAs(doc.blob, `${doc.name + '.' + doc.fileType}`)
    } else if (doc.blob === null) {
      const [error, response] = await ImageService.getDocument('company', this.state.company.id, doc.type)
      if (error) {
        doc.loader = false
        return this.$toasted.global.error(error)
      }
      doc.fileType = response.headers['content-type'].split('/')[1]
      doc.blob = new Blob([response.data])
      saveAs(doc.blob, `${doc.name + '.' + doc.fileType}`)
    } else saveAs(doc.blob, `${doc.name + '.' + doc.fileType}`)
    doc.loader = false
  }

  @Action(`UserModule/${UserTypes.actions.SET_CODE}`) setCode: any
  @State('UserModule') state: any
}
