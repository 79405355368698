















// Utils
import { Component, Vue } from 'vue-property-decorator'

@Component({
})
export default class TooltipBusinessAccount extends Vue {
  points = [
    'Una cuenta empresarial se utiliza para las operaciones financieras de una empresa, como depósitos, retiros, pagos a proveedores, entre otros. Es importante separar las finanzas personales y empresariales, y una cuenta empresarial es una herramienta útil para ello.'
  ]
}
