














































































































































































// Utils
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
import numeral from 'numeral'
import EventBus from '@/event-bus'
// Helpers
// import { Stripe } from '@/helpers/Stripe'
// Components
import TooltipLegalRepresentation from '@/components/payment/TooltipLegalRepresentation.vue'
import TooltipMonthlyRepresentation from '@/components/payment/TooltipMonthlyRepresentation.vue'
// Services
import PaymentService from '@/services/Payment'

@Component({
  components: { TooltipLegalRepresentation, TooltipMonthlyRepresentation },
  filters: {
    currency (value: number) { return numeral(value).format('0,0.00') }
  }
})
export default class CardCheckout extends Vue {
  @Prop({ default: 'Welcome' }) address!: string;
  loadingCoupon = false
  loadingStripe = false
  legalRepresentation = true
  billing = false
  billing_name = 'Sin nombre'
  billing_rfc = 'Sin RFC'
  billing_email = 'sinEmail@sin.nada'
  billing_cfi: any = ''
  pay: any = {
    items: [{
      id: '',
      name: '',
      price: '',
      price_id: ''
    }],
    selected: [{
      id: '',
      item: '',
      cost: '',
      quantity: 0,
      price_id: ''
    }],
    update: false
  }
  coupon = ''
  couponActive = {
    id: '',
    name: '',
    discount: '0',
    discount_type: 'amount'
  }
  itemsOrder = [{ id: '', name: '', quantity: 0, price_id: '' }]
  total = '0'
  totalUSD = '0'
  stripe: any = null
  name = ''
  cardComplete = false
  cardExpiryComplete = false
  cardCVVComplete = false
  plan_selected = 0
  data_order: any = { plans: [{ count: 0 }], order_id: 0 }
  $loadScript: any
  useCoupon = false
  open = false
  currency = ['MXN', 'USD']
  coin = 'MXN'
  rate = 0.0585

  @Watch('pay.selected')
  newTotal () {
    this.updateTotal()
  }

  @Watch('pay.update')
  newTotalQuantity () {
    if (!this.pay.update) return
    this.updateTotal()
    this.pay.update = false
  }

  @Watch('rate')
  UpdatedPrices () { this.updateTotal() }

  created () {
    this.useCoupon = this.state.company.orders.filter((order: any) => order.status === 'complete' && order.coupon_id !== null).length > 0
  }

  async mounted () {
    this.pay = this.$attrs.value
    // await this.getRate()
    this.updateTotal()
    /* EventBus.$on('validate-coupon-promo', async (coupon: string) => {
      this.loadingCoupon = true
      const [error, response] = await PaymentService.validateCoupon(coupon)
      this.loadingCoupon = false
      if (error) return this.$toasted.global.error({ message: 'No se encontró el cupón' })
      this.couponActive = response.data
      this.updateTotal()
      this.coupon = ''
      return this.$toasted.global.success({ message: 'Se agrego el cupón' })
    })
    try {
      await this.$loadScript('https://js.stripe.com/v3/')
      this.stripe = (window as any).Stripe(process.env.VUE_APP_STRIPE_KEY)
      this.setStripe()
    } catch (e) {
      return this.$toasted.global.error({ message: 'Hubo un problema con stripe' })
    } */
  }

  beforeDestroy () {
    EventBus.$off('validate-coupon-promo')
  }

  updateTotal () {
    let sum = 0
    this.pay.selected.map((select: any) => {
      if (select.quantity === '1') {
        sum += Number(select.cost.replace(/,/g, "").replace(/[^0-9]/g,''))
      } else {
        sum += Number(select.cost.replace(/,/g, "").replace(/[^0-9]/g,'')) * Number(select.quantity)
      }
    })
    if (this.couponActive.name !== '') sum -= Number(this.couponActive.discount)
    this.total = sum.toString().replace(/\d(?=(?:\d{3})+$)/g, '$&,')
    this.totalUSD = Math.trunc(sum * this.rate).toString().replace(/\d(?=(?:\d{3})+$)/g, '$&,')
    // this.getPaymentDetails()
  }

  itemPrice (price: string, quantity: string) {
    let sum = Number(price.replace(/,/g, "").replace(/[^0-9]/g,'')) * Number(quantity)
    return sum.toString().replace(/\d(?=(?:\d{3})+$)/g, '$&,')
  }

  get formComplete () {
    return this.name && this.cardComplete && this.cardExpiryComplete && this.cardCVVComplete &&
      this.plan_selected
  }

  async getRate () {
    const [error, response] = await PaymentService.getUSDRate('MXN', 'USD')
    if (error) { return }
    this.rate = response.data.rates.USD
  }

  async goPaymentLink () {
    this.loadingStripe = true
    let items: any = []
    if (this.pay.selected[0].item === 'Softlanding') {
      const item = this.pay.items.filter((item: any) => item.name === this.pay.selected[0].item)[0]
      if (this.pay.selected[0].price_id === item.price_id) items.push({ name : 'Softlanding', quantity : 1, type_purchases: 'month' })
      else items.push({ name : 'Softlanding', quantity : 1, type_purchases: 'year' })
    } else if (this.pay.selected[0].item === 'Import/Export Plus') {
      const item = this.pay.items.filter((item: any) => item.name === this.pay.selected[0].item)[0]
      if (this.pay.selected[0].price_id === item.price_id) items.push({ name : 'Import/Export Plus', quantity : 1, type_purchases: 'month' })
      else items.push({ name : 'Import/Export Plus', quantity : 1, type_purchases: 'year' })
    } else items = this.pay.selected.map((item: any) => { return { name : item.item === 'Lleva tu contabilidad' ? 'Servicio de Comisario' : item.item, quantity : item.quantity } })
    const [error2, response] = await PaymentService.createPaymentLink(items, this.state.company.id, this.state.id)
    if (error2) {
      this.loadingStripe = false
      return this.$toasted.global.error(error2)
    }
    let linkToPayment = ''
    if (response) linkToPayment = response.data.data.payment_link
    if (linkToPayment !== '') return location.href = linkToPayment
    return this.loadingStripe = false
  }

  /* async setStripe () {
    const elements = this.stripe.elements({ fonts: [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap' }], locale: 'auto' })
    this.stripe.card = elements.create('cardNumber', Stripe.COMPONENT_OPTIONS)
    this.stripe.card.mount('#card-number')
    this.stripe.card_expiry = elements.create('cardExpiry', Stripe.COMPONENT_OPTIONS)
    this.stripe.card_expiry.mount('#date-expired')
    this.stripe.cvv = elements.create('cardCvc', Stripe.COMPONENT_OPTIONS)
    this.stripe.cvv.mount('#cvv')
    this.stripe.card.on('change', (event: any) => {
      if (event.complete) {
        this.cardComplete = true
        this.getPaymentDetails()
      } else this.cardComplete = false
    })
    this.stripe.card_expiry.on('change', (event: any) => {
      if (event.complete) {
        this.cardExpiryComplete = true
        this.getPaymentDetails()
      } else this.cardExpiryComplete = false
    })
    this.stripe.cvv.on('change', (event: any) => {
      if (event.complete) {
        this.cardCVVComplete = true
        this.getPaymentDetails()
      } else this.cardCVVComplete = false
    })
  } */

  /* async getPaymentDetails () {
    if (!(this.cardComplete && this.name !== '' && this.cardExpiryComplete && this.cardCVVComplete)) return
    this.loadingStripe = true
    const [error, response] = await Stripe.createPaymentMethod(this.stripe, this.name)
    if (error || response.error) {
      this.loadingStripe = false
      return this.$toasted.global.error(error)
    }
    this.itemsOrder = []
    this.pay.selected.map((select: any) => {
      this.itemsOrder.push({ id: select.id, name: select.item, quantity: Number(select.quantity), price_id: select.price_id })
    })
    const [err, responseOrder] = await PaymentService.createOrder({
      company_id: this.state.company.id,
      payment_method_id: response.paymentMethod.id,
      items: this.itemsOrder,
      coupon_code: this.couponActive.name === '' ? {} : this.couponActive
    })
    this.loadingStripe = false
    if (err) return this.$toasted.global.error(err)
    this.data_order = await Stripe.formatMsiData(responseOrder.data.data)
    this.plan_selected = this.data_order.plans[0].count
  } */

  /* async validateCoupon () {
    if (this.coupon === '') return
    this.loadingCoupon = true
    const [error, response] = await PaymentService.validateCoupon(this.coupon)
    this.loadingCoupon = false
    if (error) return this.$toasted.global.error({ message: 'No se encontró el cupón' })
    this.couponActive = response.data
    this.updateTotal()
    this.coupon = ''
    return this.$toasted.global.success({ message: 'Se agrego el cupón' })
  } */

  /* async checkout () {
    this.loadingStripe = true
    const [errorPayment] = await PaymentService.paymentWithCard({
      company_id: this.state.company.id,
      installments: this.plan_selected,
      order_id: this.data_order.order_id,
      coupon_code: this.couponActive.name,
      billing_request: this.billing ? 1 : 0,
      billing_to_new_company: this.billing ? 0 : 1,
      billing_name: this.billing_name,
      billing_rfc: this.billing_rfc,
      billing_email: this.billing_email
    }, this.data_order.order_id)
    if (errorPayment) {
      this.loadingStripe = false
      return this.$toasted.global.error(errorPayment)
    }
    if (process.env.NODE_ENV === 'production') {
      window.dataLayer.push({'event': 'AC_PayConfirm_OK'})
      if (this.state.company.legal_representation) window.dataLayer.push({'event': 'Payment_Representacion'})
    }
    this.$toasted.global.success({ message: 'Se completo el pago' })
    return this.$router.push({ name: this.address, query: this.$route.query, params: { company_id: this.state.company.id } })
  } */

  @State('UserModule') state: any
}
