







































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
import moment from 'moment'
// Components
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
import InputFiles from '@/components/InputFiles.vue'
import LastMonth from '@/components/accounting/LastMonth.vue'
import CompanyInfo from '@/components/accounting/CompanyInfo.vue'
import CurrentMonth from '@/components/accounting/CurrentMonth.vue'
import AccountStatements from '@/components/accounting/AccountStatements.vue'
// Services
import CompanyService from '@/services/Company'

@Component({
  components: { Footer, Loader, InputFiles, LastMonth, CompanyInfo, CurrentMonth, AccountStatements }
})
export default class Accounting extends Vue {
  moment = moment
  loading = false
  month = 0
  year = 0
  lastMonth = 0
  lastYear = 0
  content: any = { showContent: 'Activities' }
  files: any = []
  accountStatements: any = []
  pendingLastMonth = false
  renderKeyActivities = 0
  renderKeyAccountStatements = 50

  created () {
    this.month = moment().month()
    this.year = moment().year()
    this.lastMonth = this.month === 0 ? 11 : (this.month - 1)
    this.lastYear = this.month === 0 ? (this.year - 1) : this.year
  }

  mounted () {
    this.getAccountStatements()
  }

  async getAccountStatements () {
    const [error, response] = await CompanyService.getAccountStatements(this.state.company.id)
    if (error) return this.$toasted.global.error(error)
    this.accountStatements = response.data.data
  }

  async getCaptureLines () {
    const lastMonth = this.lastMonth === 0 ? 11 : (this.lastMonth - 1)
    const lastYear = this.lastMonth === 0 ? (this.lastYear - 1) : this.lastYear
    const [error, response] = await CompanyService.getCaptureLines(this.state.company.id, (lastMonth + 1).toString(), lastYear.toString())
    if (error) return this.$toasted.global.error(error)
    const captureLines = response.data.data
    this.pendingLastMonth = (captureLines.length && !captureLines[0].pay_confirm)
    return
  }

  @State('UserModule') state: any
}
