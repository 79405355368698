















































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
import InputFileDocumentsError from '@/components/InputFileDocumentsError.vue'
// Types
import UserTypes from '@/store/types/UserTypes'
// Services
import ImageService from '@/services/Image'

@Component({
  components: { Loader, InputFileDocumentsError }
})
export default class ModalRefusePartnerDocument extends Vue {
  modal: any = {
    showModalRefusePartnerDocument: false,
    update: false,
    partnerId: '',
    docType: '',
    reject: ''
  }
  file: any = null
  loading = false
  foreign = false

  mounted () {
    this.modal = this.$attrs.value
    const partner = this.state.company.partners.find((partner: any) => this.modal.partnerId === partner.id)
    this.foreign = true
    if (partner && partner.nationalities !== null && partner.nationalities.length) partner.nationalities.map((nationality: any) => { if (nationality.name === 'México' || nationality.issue_country === 'MX') this.foreign = false })
  }

  async upload () {
    if (this.file === null || !this.file.name || !this.file.size) return
    this.loading = true
    const [error] = await ImageService.upload({
      entity_type: 'partner',
      identity: this.modal.partnerId,
      document_type: this.modal.docType,
      image: this.file,
      company_id: this.state.company.id
    })
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    await this.updateCompany(this.state.company.id)
    this.modal.update = true
    this.modal.showModalRefusePartnerDocument = false
    this.loading = false
    this.$toasted.global.success({ message: 'Se subio el archivo con éxito' })
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
