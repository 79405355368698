











// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component({
  components: { }
})
export default class CompleteBenefit extends Vue {
  @Prop({ default: [] }) points!: Array<any>
  @State('UserModule') state: any
}
