import to from 'await-to-js'
import axios from '@/utils/axios-api'

const URL_PHP = process.env.VUE_APP_BASE_URL_PHP

export default class SAT {
  static async getModules (service_name: string) {
    return await to(axios.get(`${URL_PHP}/v2/list-modules-sat/service-name/${service_name}`)) as any
  }

  static async getEntityModules (entity_id: string, service_name: string) {
    return await to(axios.get(`${URL_PHP}/v2/modules-appopintment-sat/entity/${entity_id}/service/${service_name}`)) as any
  }

  static async setAppoinmentSAT (company: string, data: any) {
    return await to(axios.post(`${URL_PHP}/v2/schedule-appointment-sat/${company}`, data)) as any
  }

  static async updateAppoinmentSAT (federal_registration: string, data: any) {
    return await to(axios.put(`${URL_PHP}/v2/appointment/federal-registration/${federal_registration}`, data)) as any
  }

  static async cancelAppoinmentSAT (appointmentID: string) {
    return await to(axios.get(`${URL_PHP}/v2/cancel-appointment-sat/${appointmentID}`)) as any
  }

  static async setReasonRejectionSAT (federal_registration: string, reject: string, error_acta: boolean) {
    return await to(axios.post(`${URL_PHP}/v2/reject-appointment-sat/${federal_registration}`, { reject, error_acta })) as any
  }

  static async setFIELCredential (company: string, image: any, document_type: string) {
    const formData = new FormData()
    formData.append('image', image)
    formData.append('document_type', document_type)
    return await to(axios.post(`${URL_PHP}/v2/upload-user-credential/${company}`, formData))
  }

  static async setFIELPassword (company: string, password: string) {
    return await to(axios.post(`${URL_PHP}/v2/upload-user-credential-password/${company}`, { password }))
  }

  static async getFielStatus (company: string) {
    return await to(axios.get(`${URL_PHP}/v2/get-fiel-data-company/${company}`)) as any
  }
}
