import Vue from 'vue'
import Vuex from 'vuex'
import AuthModule from '@/store/modules/AuthModule'
import CommissaryModule from '@/store/modules/CommissaryModule'
import CompanyModule from '@/store/modules/CompanyModule'
import ExternModule from '@/store/modules/ExternModule'
import InvitedModule from '@/store/modules/InvitedModule'
import UserModule from '@/store/modules/UserModule'
import UTMModule from '@/store/modules/UTMModule'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false
  },
  mutations: {
    START_LOADING: (state) => { state.loading = true },
    FINISH_LOADING: (state) => { state.loading = false }
  },
  actions: {
    UPDATE_STATE_LOADING: ({ commit }, status) => {
      if (status) commit('START_LOADING')
      else commit('FINISH_LOADING')
    }
  },
  modules: {
    AuthModule,
    CommissaryModule,
    CompanyModule,
    ExternModule,
    InvitedModule,
    UserModule,
    UTMModule
  }
})
