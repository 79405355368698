

















// Utils
import { Component, Vue } from 'vue-property-decorator'
// Components
import ReturnAddress from '@/components/Return.vue'
import EditPhoneUser from '@/components/account/phone/NewPhone.vue'
import HeaderProgressBar from '@/components/HeaderProgressBar.vue'
import Footer from '@/components/Footer.vue'

@Component({
  components: { Footer, ReturnAddress, HeaderProgressBar, EditPhoneUser }
})
export default class EditPhone extends Vue {
}
