




















// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
// Types
import UserTypes from '@/store/types/UserTypes'
// Services
import MatiService from '@/services/Mati'

@Component({
  components: { Loader }
})
export default class ModalRefuseMati extends Vue {
  modal: any = {
    showModalRefuseMati: false,
    update: false,
    partnerId: '',
    reject: ''
  }
  loading = false
  mati = ''
  client = ''
  flow = ''

  // Event MATI
  // eslint-disable-next-line
  load (detail: EventListenerOrEventListenerObject) { return }

  // Event MATI
  // eslint-disable-next-line
  fin (detail: EventListenerOrEventListenerObject) { this.matiCheck() }

  // Event MATI
  // eslint-disable-next-line
  exit (detail: EventListenerOrEventListenerObject) { return }

  // Events MATI
  eventListeners () {
    return {
      '&mati:loaded': this.load, // & = passive
      '&mati:userFinishedSdk': this.fin, // & = passive
      '&mati:exitedSdk': this.exit // & = passive
    }
  }

  mounted () {
    this.modal = this.$attrs.value
    const partner = this.state.company.partners.find((partner: any) => this.modal.partnerId === partner.id)
    if (partner === undefined) return
    let foreign = true
    if (partner && partner.nationalities !== null && partner.nationalities.length) partner.nationalities.map((nationality: any) => { if (nationality.name === 'México' || nationality.issue_country === 'MX') foreign = false })
    this.client = process.env.VUE_APP_MATI_CLIENT_ID
    this.flow = (!foreign && (partner.rfc === null || partner.rfc === 'EXTF900101NI1')) ?
      process.env.VUE_APP_MATI_FLOW_ID_PARTNER_WITHOUT_RFC : !foreign ?
      process.env.VUE_APP_MATI_FLOW_ID_PARTNER : (partner.rfc === null || partner.rfc === 'EXTF900101NI1') ?
      process.env.VUE_APP_MATI_FLOW_ID_PARTNER_FOREIGN_RFC : process.env.VUE_APP_MATI_FLOW_ID_PARTNER_FOREIGN
    this.mati = '{"partner_id":"' + partner.id +
      '","company_id": "' + this.state.company.id +
      '","check": "false"}'
  }

  async matiCheck () {
    this.loading = true
    await MatiService.setMatiCheck(this.modal.partnerId)
    await this.updateCompany(this.state.company.id)
    this.modal.update = true
    this.modal.showModalRefuseMati = false
    this.loading = false
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
