








































// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Types
import UserTypes from '@/store/types/UserTypes'
// Components
import Loader from '@/components/Loader.vue'
import InputFile from '@/components/InputFile.vue'
import TooltipCSF from '@/components/benefits/TooltipCSF.vue'
// Services
// import ImageService from '@/services/Image'
import CompanyService from '@/services/Company'

@Component({
  components: { Loader, InputFile, TooltipCSF }
})
export default class ModalAddRFC extends Vue {
  @Prop({ default: true }) viewCSF!: boolean
  modal: any = { showModalAddRFC: false, update: false }
  loading = false
  rfc = ''
  csf: any = null

  mounted () {
    this.modal = this.$attrs.value
    this.rfc = this.state.company.rfc !== null ? this.state.company.rfc : ''
  }

  noSpacesStartRFC (value: string) {
    return value.trimStart().toUpperCase()
  }

  async saveRFC () {
    this.loading = true
    if (this.rfc === '') return this.loading = false
    const [errorRFC] = await CompanyService.setRFC(this.state.company.id, this.rfc)
    if (errorRFC) {
      this.loading = false
      return this.$toasted.global.error(errorRFC)
    }
    await this.updateCompany(this.state.company.id)
    this.modal.update = true
    this.loading = false
    this.modal.showModalAddRFC = false
  }

  async postponeRFC () {
    this.loading = true
    const [error] = await CompanyService.updateFlags(this.state.company.id, 'rfc_later', true)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    await this.updateCompany(this.state.company.id)
    this.modal.update = true
    this.loading = false
    this.modal.showModalAddRFC = false
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
