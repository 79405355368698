import to from 'await-to-js'
import axios from '@/utils/axios-api'
import CompanyData from '@/interfaces/Company'

const URL_PHP = process.env.VUE_APP_BASE_URL_PHP

export default class Company {
  static async store (data: CompanyData) {
    return await to(axios.post(`${URL_PHP}/v2/company/register`, data)) as any
  }

  static async updateCompany (company: string, company_type: string) {
    return await to(axios.put(`${URL_PHP}/v2/update-company/${company}`, {company_type})) as any
  }

  static async getCompany (company: string) {
    return await to(axios.get(`${URL_PHP}/v2/company/get-company-profile/${company}`)) as any
  }

  static async updateLegalNames (company: string, legalNames: Array<string>, authorization: boolean) {
    return await to(axios.post(`${URL_PHP}/v1/company/${company}/update-legal-name`, { legal_names: legalNames, name_authorization: authorization }))
  }

  static async setLegalName (company: string, legalName: string) {
    return await to(axios.post(`${URL_PHP}/v2/set-approved-denomination/company/${company}`, { legal_name: legalName }))
  }

  static async updateStage (data: {stage_name: string, company_id: string, is_completed: boolean}) {
    return await to(axios.put(`${URL_PHP}/v1/stage/${data.stage_name}/company/${data.company_id}`, data)) as any
  }

  static async setAddress (company: string, address: { shipping_person: string, street: string, num_ext: string, num_int: string, suburb: string, postal_code: string, city: string, state: string, entity_modules: any, references: string, who_receives: string }) {
    return await to(axios.post(`${URL_PHP}/v2/set-shipping-address/${company}`, address))
  }

  static async getDelivery (company: string) {
    return await to(axios.get(`${URL_PHP}/v2/delivery-status/${company}`)) as any
  }

  static async setRFC (company: string, rfc: string) {
    return await to(axios.post(`${URL_PHP}/v2/company/save-rfc/${company}`, { rfc }))
  }

  static async getActivities () {
    return await to(axios.get(`${URL_PHP}/v2/business-twists/get`)) as any
  }

  static async setActivities (company: string, activities: any) {
    return await to(axios.put(`${URL_PHP}/v2/company/set-company-category/${company}`, { activities }))
  }

  static async getBankAccounts (company: string) {
    return await to(axios.get(`${URL_PHP}/v2/data-banking/company/${company}`)) as any
  }

  static async setFiscalResident (company: string, fiscalResident: string) {
    return await to(axios.post(`${URL_PHP}/v2/company/save-fiscal-residence/${company}`, { fiscal_residence: fiscalResident }))
  }
  
  static async setCompanyGoal (company: string, company_goal: string) {
    return await to(axios.post(`${URL_PHP}/v2/update-company-goal/${company}`, { company_goal }))
  }
  
  static async setAttorneys (company: string, legal_representatives: any) {
    return await to(axios.post(`${URL_PHP}/v2/company/legal-representatives/new/${company}`, { legal_representatives }))
  }
  
  static async setPermissions (partner: string, company_id: string, permision_to_edit: boolean) {
    return await to(axios.post(`${URL_PHP}/v2/partner/permission-edit/${partner}`, { company_id, permision_to_edit }))
  }
  
  static async deleteAttorneys (legal_representative: string) {
    return await to(axios.post(`${URL_PHP}/v2/company/legal-representatives/delete`, { legal_representative }))
  }

  static async sendBankCode (company: string) {
    return await to(axios.get(`${URL_PHP}/v2/company/${company}/two-factor/set`)) as any
  }

  static async resendBankCode (company: string) {
    return await to(axios.get(`${URL_PHP}/v2/company/${company}/two-factor/resend`)) as any
  }
  
  static async verifyBankCode (company: string, code: string) {
    return await to(axios.post(`${URL_PHP}/v2/company/${company}/two-factor/verify`, { code }))
  }

  static async getAccountStatements (company: string) {
    return await to(axios.get(`${URL_PHP}/v2/company/get-account-status/${company}`)) as any
  }

  static async deleteAccountStatements (company: string, account_status_id: string) {
    return await to(axios.post(`${URL_PHP}/v2/company/drop-account-status/${company}`, { account_status_id })) as any
  }

  static async uploadAccountStatements (data: any) {
    const formData = new FormData()
    formData.append('company_id', data.company_id)
    formData.append('document_type', data.document_type)
    formData.append('image', data.image)
    return await to(axios.post(`${URL_PHP}/v2/upload/account-status`, formData)) as any
  }

  static async getTaxDeclaration (company: string, month: string, year: string) {
    return await to(axios.post(`${URL_PHP}/v2/tax-declaration/${company}/get`, { month, year })) as any
  }

  static async updateTaxDeclaration (tax_declaration: string, status: boolean) {
    return await to(axios.post(`${URL_PHP}/v2/tax-declaration/${tax_declaration}/set-status`, { status })) as any
  }
  
  static async getCaptureLines (company: string, month: string, year: string) {
    return await to(axios.post(`${URL_PHP}/v2/get-accounting-documents/company/${company}`, { month, year })) as any
  }

  static async requestNewCaptureLine (accountingDocument: string) {
    return await to(axios.get(`${URL_PHP}/v2/request-capture-line/${accountingDocument}`)) as any
  }

  static async confirmCaptureLines (company: string, month: string) {
    return await to(axios.put(`${URL_PHP}/v2/pay-confirm-capture-line/company/${company}`, { month })) as any
  }
  
  static async shareDoc (company_id: string, type: string, email: string) {
    return await to(axios.post(`${URL_PHP}/v2/alert-document`, { company_id, type, email })) as any
  }
  
  static async updateFlags (company: string, type: string, is_aprroved: boolean) {
    return await to(axios.put(`${URL_PHP}/v2/company/update-flags/${company}`, { type, is_aprroved }))
  }
}
