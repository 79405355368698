




































































































































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
import Resumen from '@/components/beginning/Resumen.vue'
// Services
import SharesService from '@/services/Shares'
// Types
import UserTypes from '@/store/types/UserTypes'

@Component({
  components: { Resumen, Loader }
})
export default class CompanyDistribution extends Vue {
  company: any = {
    step: '2',
    totalAction: '10,000'
  }
  tip = {
    title: '¿Qué son las acciones?',
    text: 'Las acciones son las partes en las que se compone una empresa. Entre más acciones tengas, mayor será tu participación dentro de esta.'
  }
  loading = false
  distribution = false
  tally = false
  total = 0
  numberSharesZero = false
  partners: any = []

  @Watch('company.totalAction')
  async update () {
    this.total = Number(this.company.totalAction.replace(/,/g, "").replace(/[^0-9]/g,''))
    if (!this.tally) this.partners.map((partner: any, index: number) => {
      const percentage = Number((this.partners[index].percentage / 100).toFixed(5))
      this.partners[index].numberShares = (this.total * percentage).toFixed(0).toString().replace(/\d(?=(?:\d{3})+$)/g, '$&,')
    })
  }

  @Watch('state.company.partners')
  async updatePartners () {
    this.getPartnersDistribution()
  }

  get totalPercentage () {
    let total = 0
    this.numberSharesZero = false
    this.partners.map((partner: any) => {
      total += Number(partner.percentage)
      if (Number(partner.numberShares.replace(/,/g, "").replace(/[^0-9]/g,'')) === 0) this.numberSharesZero = true
    })
    return total.toFixed(2)
  }

  get totalNumberShares () {
    let total = 0
    this.numberSharesZero = false
    this.partners.map((partner: any) => {
      total += Number(partner.numberShares.replace(/,/g, "").replace(/[^0-9]/g,''))
      if (Number(partner.numberShares.replace(/,/g, "").replace(/[^0-9]/g,'')) === 0) this.numberSharesZero = true
    })
    return total
  }

  mounted () {
    this.company = this.$attrs.value
    this.total = Number(this.company.totalAction.replace(/,/g, "").replace(/[^0-9]/g,''))
    this.getPartnersDistribution()
  }

  getPartnersDistribution () {
    this.partners = []
    this.state.company.partners.map((partner: any) => {
      const shares = partner.shares.filter((shares: any ) => shares.serie.company_id === this.state.company.id)
      this.partners.push({
        name: partner.name + ' ' + partner.first_names + ' ' + partner.last_names,
        percentage: shares.length === 0 ? 0 : shares[0].percentage,
        numberShares: shares.length === 0 ? '1' : shares[0].number_of_shares.replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
        id: partner.id
      })
    })
    this.updatePartnersDistribution()
  }

  updatePartnersDistribution () {
    this.partners.map((partner: any, index: number) => {
      this.partners[index].percentage = ((Number(partner.numberShares.replace(/,/g, "").replace(/[^0-9]/g,'')) * 100) / this.total)
    })
  }

  minOneShare (partner: any) {
    if (Number(partner.numberShares.replace(/,/g, "").replace(/[^0-9]/g,'')) === 0 || partner.numberShares === '') partner.numberShares = '1'
    this.updatePartnersDistribution()
  }

  validateNumberShares (partner: any) {
    if (this.totalNumberShares > this.total) {
      partner.numberShares = (Number(partner.numberShares.replace(/,/g, "").replace(/[^0-9]/g,'')) - (this.totalNumberShares - this.total)).toString().replace(/\d(?=(?:\d{3})+$)/g, '$&,')
    }
  }

  onlyNumbersPercentage (value: string) {
    value = value.replace(/[^0-9.]/g,'')
    return Number(value) > 100 ? '100' : Number(value) <= 0 ? '0' : Number(value).toString()
  }

  onlyNumbersActions (value: string) {
    value = value.replace(/,/g, "").replace(/[^0-9]/g,'')
    value = Number(value) > 100000 ? '100000' : value === '' ? '' : Number(value).toString()
    return value.replace(/\d(?=(?:\d{3})+$)/g, '$&,')
  }

  setDeafult () {
    this.company.totalAction = '100,000'
    this.partners.map((partner: any, index: number) => {
      this.partners[index].numberShares = (Math.floor(100000/this.partners.length) + ((this.partners.length % 2 !== 0 && index === 0) ? 1 : 0)).toString().replace(/\d(?=(?:\d{3})+$)/g, '$&,')
    })
    this.updatePartnersDistribution()
  }

  async saveDistribution () {
    this.loading = true
    // Save distribution
    const actions = this.total
    const [error] = await SharesService.storeShares(this.state.company.id, Number(actions), this.partners)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    // Get new user info
    await this.updateCompany(this.state.company.id)
    if (this.state.company.legal_representation) return this.$router.push({ name: 'Welcome', query: this.$route.query }).catch(() => {return})
    else this.company.step = '5'
    this.loading = false
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
