import store from '@/store'
import ExternTypes from '@/store/types/ExternTypes'

export default class PaymentExtern {
  static async validateUserToken (to: any, from: any, next: any) {
    let user: any = { token: '', email: ''}
    if (to.query.token === undefined || to.query.email === undefined || to.query.company_id === undefined) {
      user = store.getters[`ExternModule/${ExternTypes.getters.GET_DATA}`]
      if (user.token === '' || user.email === '') return next({ name: 'Login', query: to.query })
    } else { user = { token: to.query.token.toString(), email: to.query.email.toString(), company_id: to.query.company_id.toString() } }
    // eslint-disable-next-line
    const [error, response] =  await store.dispatch(`ExternModule/${ExternTypes.actions.SAVE_TOKEN}`, { token: user.token, email: user.email, company_id: user.company_id })
    if (error) return next({ name: 'Login', query: to.query })
    return next()
  }
}
