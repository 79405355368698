































// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
import { PDFDocument } from 'pdf-lib'
// Components
import pdf from 'vue-pdf'
import Loader from '@/components/Loader.vue'
import Timer from '@/components/Timer.vue'
import ModalCheckout from '@/components/dashboard/modals/ModalCheckout.vue'
// Services
import ImageService from '@/services/Image'

@Component({
  components: { Loader, Timer, ModalCheckout, pdf }
})
export default class Statute extends Vue {
  @Prop() endTimer!: number
  @Prop() timer!: boolean
  loading = true
  fullURL = ''
  urlLocalHost = process.env.VUE_APP_BASE_URL_RENDER_DOCS_FILES
  filePDF: any = null
  showWord = false
  select = {option: '', page: 0}
  renderKey = 0
  navigateOptions: any = {}
  checkoutComplete = false
  modal: any = {
    showModalCheckout: false
  }
  src: any = null
	numPages: any = 0
  isAndroid: any = false

  created () {
    this.setNavigateOptions()
    if (navigator.userAgent.match(/Android/i)) this.isAndroid = true
  }

  mounted () {
    this.checkoutComplete = this.state.company.orders.filter((order: any) => order.status === 'complete' && order.items.filter((item: any) => item.name === 'Complemento Acta Constitutiva' || item.name === 'Acta Constitutiva').length > 0).length > 0
    if (!this.timer) this.viewDoc()
    else this.loading = false
  }

  setNavigateOptions () {
    this.navigateOptions.Empresa = {option: 'Empresa', page: this.state.company.company_type.toLowerCase() === 'sapi' ? 1 : 1}
    this.navigateOptions.Actividad = {option: 'Actividad', page: this.state.company.company_type.toLowerCase() === 'sapi' ? 2 : 2}
    this.navigateOptions.Administracion = {option: 'Administracion', page: this.state.company.company_type.toLowerCase() === 'sapi' ? 17 : 15}
    this.navigateOptions.Socios = {option: 'Socios', page: this.state.company.company_type.toLowerCase() === 'sapi' ? 17 : 15}
    this.navigateOptions.Comisario = {option: 'Comisario', page: this.state.company.company_type.toLowerCase() === 'sapi' ? 17 : 16}
    this.navigateOptions.Poderes = {option: 'Poderes', page: this.state.company.company_type.toLowerCase() === 'sapi' ? 18 : 16}
  }

  updateRender (option: string, page: number) {
    this.select.option = option
    this.select.page = page
    if (!this.checkoutComplete && !this.state.company.legal_representation && option !== 'Empresa' && option !== 'Actividad') this.modal.showModalCheckout = true
    else this.renderKey++
  }

  async viewDoc () {
    this.loading = true
    const [error, response] = await ImageService.getDocument('company', this.state.company.id, 'articles_of_incorporation_render')
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    let pdfBytes: any = response.data
    if (!this.checkoutComplete && !this.state.company.legal_representation) {
      const pdfDoc = await PDFDocument.load(pdfBytes)
      const subDocument = await PDFDocument.create()
      const [copiedPage1] = await subDocument.copyPages(pdfDoc, [0])
      const [copiedPage2] = await subDocument.copyPages(pdfDoc, [1])
      subDocument.addPage(copiedPage1)
      subDocument.addPage(copiedPage2)
      pdfBytes = await subDocument.save()
    }
    const reader = new FileReader()
    reader.readAsDataURL(new Blob([pdfBytes]))
    reader.onload = () => {
      if (reader.result) {
        if (!this.isAndroid && typeof reader.result === 'string') {
          this.filePDF = reader.result.replace('application/octet-stream', 'application/pdf')
        } else if (this.isAndroid && typeof reader.result === 'string') {
          this.filePDF = reader.result.replace('application/octet-stream', 'application/pdf')
          this.src = pdf.createLoadingTask(reader.result)
          this.src.promise.then((pdf: any) => { this.numPages = pdf.numPages })
        } else this.filePDF = reader
      }
    }
    this.showWord = true
    this.loading = false
  }

  @State('UserModule') state: any
}
