


































































































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
import ModalEditLegalName from '@/components/resumen/modals/ModalEditLegalName.vue'
import ModalEditCompanyType from '@/components/resumen/modals/ModalEditCompanyType.vue'
import ModalEditCompanyActivities from '@/components/resumen/modals/ModalEditCompanyActivities.vue'
import ModalEditPartners from '@/components/resumen/modals/ModalEditPartners.vue'
import ModalEditDistribution from '@/components/resumen/modals/ModalEditDistribution.vue'
import ModalEditAdmin from '@/components/resumen/modals/ModalEditAdmin.vue'
import ModalEditCommissary from '@/components/resumen/modals/ModalEditCommissary.vue'
// Types
import UserTypes from '@/store/types/UserTypes'

@Component({
  components: { Loader, ModalEditLegalName, ModalEditCompanyType, ModalEditCompanyActivities, ModalEditPartners, ModalEditDistribution, ModalEditAdmin, ModalEditCommissary }
})
export default class ResumenHeader extends Vue {
  modal: any = {
    showModalLegalName: false,
    showModalCompanyType: false,
    showModalCompanyActivities: false,
    showModalCompanyPartners: false,
    showModalCompanyDistribution: false,
    showModalCompanyAdmin: false,
    showModalCommissary: false,
    checkDistribution: false,
    update: false,
  }
  resumen: any = {
    showResumen: false
  }
  loading = false
  renderKeyLegalName = 0
  renderKeyCompanyType = 2
  renderKeyCompanyActivities = 4
  renderKeyCompanyPartners = 6
  renderKeyCompanyDistribution = 8
  renderKeyCompanyAdmin = 10
  renderKeyCommissary = 12
  orderComplete = []

  @Watch('modal.update')
  async update () {
    if(!this.modal.update) return
    this.loading = true
    await this.updateCompany(this.state.company.id)
    this.modal.update = false
    this.renderKeyLegalName += 1
    this.renderKeyCompanyType += 1
    this.renderKeyCompanyActivities += 1
    this.renderKeyCompanyPartners += 1
    this.renderKeyCompanyDistribution += 1
    this.renderKeyCompanyAdmin += 1
    this.renderKeyCommissary += 1
    this.loading = false
  }

  mounted () {
    this.resumen = this.$attrs.value
    this.orderComplete = this.state.company.orders.filter((order: any) => order.status === 'complete')
  }

  get companyActivities () {
    let activities = ''
    this.state.company.business_twists_tmp.map((activity: any, index: number) => {
      if ((index + 1) === this.state.company.business_twists_tmp.length) activities += activity.name
      else activities += activity.name + ', '
    })
    return activities
  }

  get presidentOrAdmin () {
    if (this.state.company.has_unique_administrator) {
      let admin = this.state.company.council.filter((member: any) => member.council_role === 'unique_administrator')
      return this.getPartnerFullName(admin[0].partner)
    } else {
      let presi = this.state.company.council.filter((member: any) => member.council_role === 'president')
      return this.getPartnerFullName(presi[0].partner)
    }
  }

  get existCommissary () {
    const commissary = this.state.company.council.filter((member: any) => member.council_role === 'commissary' )
    return commissary
  }

  getPartnerFullName (partner: any) {
    let full_name: string = partner.name != null ? partner.name : ''
    full_name = partner.first_names != null ? full_name + ' ' + partner.first_names : full_name
    full_name = partner.last_names != null ? full_name + ' ' + partner.last_names : full_name
    return full_name.trimEnd()
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
