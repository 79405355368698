
















































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
import AddNewLegalNames from '@/components/company/AddNewLegalNames.vue'
// Types
import UserTypes from '@/store/types/UserTypes'
// Services
import CompanyService from '@/services/Company'
import ConstitutiveService from '@/services/Constitutive'

@Component({
  components: { Loader, AddNewLegalNames }
})
export default class ModalRefuseLegalName extends Vue {
  modal: any = {
    showModalLegalName: false,
    update: false,
  }
  legal_name = ''
  loading = false
  validName = true
  promise_error = false
  legalNameUpload = false
  newLegalNames: any = {
    namesMatch: false,
    checkLegalNames: false,
    existLegalNames: false,
    repeatLegalNames: false,
    legalNames: [
      { name: '', equal: false, valid: false, repeat: false, minLength: true, exist: false, id: 1 },
      { name: '', equal: false, valid: false, repeat: false, minLength: true, exist: false, id: 2 },
      { name: '', equal: false, valid: false, repeat: false, minLength: true, exist: false, id: 3 }
    ]
  }

  mounted () {
    this.modal = this.$attrs.value
    this.legal_name = this.state.company.legal_name
    this.legalNameUpload = (this.state.company.legal_names.length === 1) && this.state.company.name_authorization
  }

  get allOptionFill () {
    return this.newLegalNames.legalNames.findIndex((legalName: any) => legalName.name === '') !== -1
  }

  get validLegalNames () {
    return this.newLegalNames.legalNames.filter((legalName: any ) => legalName.valid === true).length > 0
  }

  get getRejection () {
    const rejected = this.state.company.legal_names[0].rejection_category
    if (rejected === '' || rejected === null || rejected === 'rejected_by_default') return null
    return rejected
  }

  async add () {
    this.loading = true
    await Promise.all(
      this.newLegalNames.legalNames.map(async (legalName: any) => {
        const [error, response] = await ConstitutiveService.validateLegalName(legalName.name)
        if (error) {
          this.promise_error = true
          return this.$toasted.global.error(error)
        }
        if (!response.data.data) legalName.valid = true
      })
    )
    if (this.promise_error) return this.promise_error = this.loading = false
    const validLegalName = this.newLegalNames.legalNames.filter((legalName: any ) => legalName.valid === true)
    if (validLegalName.length) return this.loading = false
    const [error] = await CompanyService.updateLegalNames(this.state.company.id, [this.newLegalNames.legalNames[0].name.trimEnd(), this.newLegalNames.legalNames[1].name.trimEnd(), this.newLegalNames.legalNames[2].name.trimEnd()], false)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    await this.updateCompany(this.state.company.id)
    this.modal.update = true
    this.modal.showModalRefuseLegalName = false
    this.$toasted.global.success({ message: 'Se actualizaron las denominaciones' })
    return
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
