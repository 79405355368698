

























// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
// Components
import pdf from 'vue-pdf'

@Component({
  components: { pdf }
})
export default class ModalConfirmDocument extends Vue {
  previewPDF = false
  file: any = ''
  modal: any = {
    showModalConfirmDocument: false,
    file: {},
    refuse: false
  }
  src: any = null
	numPages: any = 0
  isAndroid: any = false

  @Watch('modal.file')
  reset () {
    if (!this.modal.file) return
    this.getDocument()
  }

  created () {
    if (navigator.userAgent.match(/Android/i)) this.isAndroid = true
  }

  mounted () {
    this.modal = this.$attrs.value
  }

  getDocument () {
    if (this.modal.file.type === undefined) return
    const reader = new FileReader()
    reader.readAsDataURL(new Blob([this.modal.file]))
    reader.onload = () => {
      if (reader.result) {
        this.previewPDF = this.modal.file.type.includes('application/pdf')
        if (!this.isAndroid && typeof reader.result === 'string' && this.previewPDF) {
          this.file = reader.result.replace('application/octet-stream', 'application/pdf')
        } else if (this.isAndroid && typeof reader.result === 'string' && this.previewPDF) {
          this.src = pdf.createLoadingTask(reader.result)
          this.src.promise.then((pdf: any) => { this.numPages = pdf.numPages })
        } else this.file = reader.result
      }
    }
  }

  refuse () {
    this.modal.file = {}
    this.modal.refuse = true
    this.modal.showModalConfirmDocument = false
  }
}
