

































































































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
// Types
import AuthTypes from '@/store/types/AuthTypes'
// Services
import CountryService from '@/services/Country'

@Component({
  components: { Footer, Loader }
})
export default class InvitedRegister extends Vue {
  loading = true
  loadingModal = false
  email = ''
  phone = ''
  password = ''
  name = ''
  first_names = ''
  legal_name = ''
  company_type = ''
  agreement = false
  country = { lada: '52', alpha_code: 'MX' }
  countries = []
  nationalities = []
  passwordFieldType = 'password'
  confirmpasswordFieldType = 'password'
  eye = 'eye'
  eye2 = 'eye'
  googleCredential: any = ''
  showModal = false

  get validateEmail () {
    const re = /\S+@\S+\.\S+/
    return this.email ? re.test(this.email) : true
  }

  created () {
    this.legal_name = this.state.legal_name
    this.company_type = this.state.type_Society
    this.email = this.state.partner.email
    this.phone = this.state.partner.phone
  }

  async mounted () {
    await this.getCountries()
    window.google.accounts.id.initialize({
      client_id: process.env.VUE_APP_GOOGLE_ID,
      callback: this.handleCredentialResponse
    });
    window.google.accounts.id.renderButton(
      document.getElementById("buttonDiv"),
      { theme: "outline", width: 320, text: "continue_with", logo_alignment: "left" }
    );
    window.google.accounts.id.prompt();
  }

  switchVisibility () {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    this.eye = this.eye === 'eye' ? 'eye-slash' : 'eye'
  }

  handleCredentialResponse(response: any) {
    if (!response || !response.credential) return
    this.googleCredential = response
    this.showModal = true
  }

  switchVisibility2 () {
    this.confirmpasswordFieldType = this.confirmpasswordFieldType === 'password' ? 'text' : 'password'
    this.eye2 = this.eye2 === 'eye' ? 'eye-slash' : 'eye'
  }

  onlyNumbers (value: string) {
    return value.replace(/[^0-9.]/g,'');
  }

  async getCountries () {
    const [error, response] = await CountryService.all()
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.loading = false
    this.countries = response.data.data
  }

  async completeRegisterPartner () {
    this.loading = true
    try {
      await this.registerPartner( {
        partner: this.state.partner.id, 
        signupPartner: {
          email: this.email,
          password: this.password,
          phone: this.phone,
          phone_country_code: this.country.lada,
        }
      })
    } catch (e) {
      this.loading = false
      return this.$toasted.global.error(e)
    }
    return this.$router.push({ name: 'InvitedPhoneValidate', query: this.$route.query, params: { company_id: this.state.company_id } })
  }

  async completeRegisterGooglePartner () {
    this.loading = true
    this.loadingModal = true
    try {
      await this.registerGooglePartner( {
        partner: this.state.partner.id, 
        signupPartner: {
          googleCredential:  this.googleCredential,
          phone: this.phone,
          country_iso_code: this.country.alpha_code,
          phone_country_code: this.country.lada,
        }
      })
    } catch (e) {
      this.loading = false
      this.loadingModal = false
      return this.$toasted.global.error(e)
    }
    return this.$router.push({ name: 'InvitedPhoneValidate', query: this.$route.query, params: { company_id: this.state.company_id } })
  }

  @Action(`AuthModule/${AuthTypes.actions.REGISTERPARTNER}`) registerPartner: any;
  @Action(`AuthModule/${AuthTypes.actions.REGISTERGOOGLEPARTNER}`) registerGooglePartner: any;
  @State('InvitedModule') state: any
}
