import to from 'await-to-js'
import axios from '@/utils/axios-api'

const URL_PHP = process.env.VUE_APP_BASE_URL_PHP

export default class Administration {
  static async storeAdministration (company: string, council_type: string, partners: Array<any>, segment_event: boolean) {
    return await to(axios.post(`${URL_PHP}/v2/company/admin-faculties/${company}`, {council_type, partners, segment_event})) as any
  }

  static async setCommissary (company: string, data: any) {
    return await to(axios.post(`${URL_PHP}/v2/set-commissary/${company}`, data)) as any
  }

  static async setCommissaryName (company: string, commissary: string) {
    return await to(axios.post(`${URL_PHP}/v2/set-name-commissary/${company}`, { commissary })) as any
  }

  static async getCommissaryStatus (company: string) {
    return await to(axios.get(`${URL_PHP}/v2/get-status-commissary/${company}`)) as any
  }

  static async setPower (member: string, powers: boolean) {
    return await to(axios.post(`${URL_PHP}/v2/company/set-powers/${member}`, { powers })) as any
  }
}
