




















// Utils
import { Component, Vue } from 'vue-property-decorator'

@Component({
})
export default class TooltipMonthlyRepresentation extends Vue {
}
