


































































































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
// Services
import PaymentService from '@/services/Payment'

@Component({
  components: { Footer, Loader }
})
export default class PaymentUpdate extends Vue {
  types = [{
      type: 'updateCard',
      text: 'Tarjeta de crédito o débito',
    },{
      type: 'paySPEI',
      text: 'Transferencia SPEI',
    }
  ]
  typeSelected = ''
  cardNumber = ''
  month = ''
  year = ''
  cvv = ''
  selected = false
  loading = false
  SPEIData: any = {
    reference: '',
    payment_total: '',
    bank_name: '',
    clabe: '',
    beneficiary: ''
  }

  noSpacesStart (value: string) { return value.replace(/[^0-9.]/g,'').trimStart() }

  async optionSelected () {
    if (this.typeSelected === 'updateCard') return this.selected = true
    await this.SPEI()
    this.selected = true
  }

  async SPEI () {
    this.loading = true
    const [error, response] = await PaymentService.getBankTranfer(this.state.id, this.state.token, this.state.company_id)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    if (response.data.data.complete) {
      this.$toasted.global.success({ message: 'Pago completado con éxito' })
      return this.$router.push({ name: 'Login', query: this.$route.query })
    }
    this.SPEIData = response.data.data
    this.SPEIData.payment_total = this.SPEIData.payment_total.replace(/\d(?=(?:\d{3})+$)/g, '$&,')
    this.loading = false
  }

  async updateCard () {
    this.loading = true
    const [error] = await PaymentService.updatePaymentMethodExtern(this.state.id, this.state.token, {
      number: this.cardNumber,
      exp_month: this.month,
      exp_year: this.year,
      cvc: this.cvv
    })
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.$toasted.global.success({ message: 'Se actualizo la tarjeta con éxito' })
    return this.$router.push({ name: 'Login', query: this.$route.query })
  }

  copyText (text: string) {
    navigator.clipboard.writeText(text)
    this.$toasted.global.success({ message: 'Se ha copiado con éxito' })
  }

  @State('ExternModule') state: any
}
