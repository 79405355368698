





























































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
import { saveAs } from 'file-saver'
// Components
import Loader from '@/components/Loader.vue'
import ModalEditCompanyType from '@/components/resumen/modals/ModalEditCompanyType.vue'
// Services
import ImageService from '@/services/Image'
import CompanyService from '@/services/Company'

@Component({
  components: { ModalEditCompanyType, Loader }
})
export default class CardCompany extends Vue {
  modal: any = {
    showModalCompanyType: false,
    update: false,
  }
  loading = false
  modalLoading = false
  editActive = false
  validEmail = true
  validationEmail = false
  legalName: any = null
  legalNameApproved = []
  act = { type: '', name: '', id: '', blob: null, fileType: '', loader: false, loader2: false }
  rpc = { type: '', name: '', id: '', blob: null, fileType: '', loader: false, loader2: false }
  password = ''
  email = ''
  modalShareDoc: any = {
    show: false,
    doc: null
  }

  get legalNameStatus () {
    const denominationsRejects = this.state.company.legal_names.filter((legal_name: any) => legal_name.is_approved === false && legal_name.rejection_category !== null && legal_name.rejection_category !== '')
    const denominationsApproved = this.state.company.legal_names.filter((legal_name: any) => legal_name.is_approved === true)
    if (denominationsApproved.length > 0) return 'Complete'
    else if (denominationsRejects.length === this.state.company.legal_names.length) return 'Reject'
    return 'Review'
  }

  created () {
    const documentsApproved = this.state.company.stages.find((stage: any) => stage.stage_name === 'documents_approved').is_completed === true
    this.legalNameApproved = this.state.company.legal_names.filter((legal_name: any) => legal_name.is_approved === true)
    if (this.state.company.timer === null && !documentsApproved) this.editActive = true
    this.setCompanyDocs()
  }

  setCompanyDocs () {
    this.state.company.documents.map((doc: any) => {
      if (doc.document_type === 'articles_of_incorporation') this.act = { type: doc.document_type, name: 'Acta constitutiva', id: doc.id, blob: null, fileType: '', loader: false, loader2: false }
      if (doc.document_type === 'electronic_commercial_folio' || doc.document_type === 'public_commercial_registry') this.rpc = { type: doc.document_type, name: 'Registro Público de Comercio (RPC)', id: doc.id, blob: null, fileType: '', loader: false, loader2: false }
    })
  }

  validate (email: string) {
    const re = /\S+@\S+\.\S+/
    this.validEmail = email ? re.test(email) : true
  }

  validateEmail (email: string) {
    const re = /\S+@\S+\.\S+/
    this.validationEmail = this.email !== '' && re.test(email)
  }

  noSpaces (value: string) { return value.trim() }

  async download (doc: any) {
    doc.loader = true
    if (doc.blob === null) {
      const [error, response] = await ImageService.getDocument('company', this.state.company.id, doc.type)
      if (error) {
        doc.loader = false
        return this.$toasted.global.error(error)
      }
      doc.fileType = response.headers['content-type'].split('/')[1]
      doc.blob = new Blob([response.data])
      saveAs(doc.blob, `${doc.name + '.' + doc.fileType}`)
    } else saveAs(doc.blob, `${doc.name + '.' + doc.fileType}`)
    doc.loader = false
  }

  async shareDoc (doc: any) {
    if (doc === null) return
    doc.loader2 = true
    this.modalLoading = true
    let type = ''
    if (doc.name === 'Acta constitutiva') type = 'ac'
    if (doc.name === 'Registro Público de Comercio (RPC)') type = 'rpc'
    // eslint-disable-next-line
    const [error, response] = await CompanyService.shareDoc(this.state.company.id, type, this.email)
    if (error) {
      doc.loader2 = false
      this.modalLoading = false
      return this.$toasted.global.error(error)
    }
    this.modalShareDoc.show = false
    this.email = ''
    doc.loader2 = false
    this.modalLoading = false
    return this.$toasted.global.success({ message: 'Se ha enviado el documento exitosamente' })
  }

  @State('UserModule') state: any
}
