




































































// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
import { validationMixin } from 'vuelidate'
// Validations
import validations from '@/validations/new-email'
// Components
import Loader from '@/components/Loader.vue'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'
// Services
import UserService from '@/services/User'
import EmailService from '@/services/Email'

@Component({
  components: { Loader },
  mixins: [validationMixin],
  validations: validations
})
export default class NewEmail extends Vue {
  @Prop() address: string|undefined
  loader = false
  email = ''
  validEmail = 0

  async validate (email: string, value: boolean) {
    return this.validEmail = ( value || ( email === '' ) ) ? 0 : 1
  }

  async existEmail () {
    const [error, response] = await UserService.availableEmail(this.email)
    if (error) {
      this.validEmail = 1
      return this.$toasted.global.error(error)
    }
    if (response.data.data) return this.validEmail = 2
  }

  async next () {
    this.loader = true
    this.existEmail()
    const [error] = await EmailService.newEmail(this.state.id, this.email)
    if (error) {
      this.loader = false
      return this.$toasted.global.error(error)
    }
    await this.setEmail(this.email)
    return this.$router.push({ name: this.address, query: this.$route.query }).catch(() => {return})
  }
  
  @State('UserModule') state: any
  @Action(`CompanyModule/${CompanyTypes.actions.SET_EMAIL}`) setEmail: any;
}
