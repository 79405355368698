































































































































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import { debounce } from 'lodash'
// Components
import Loader from '@/components/Loader.vue'
import Resumen from '@/components/beginning/Resumen.vue'
// Services
import CountryService from '@/services/Country'
import PartnersService from '@/services/Partners'
import AdministrationService from '@/services/Administration'
import CompanyService from '@/services/Company'
// Types
import UserTypes from '@/store/types/UserTypes'

@Component({
  components: { Loader, Resumen }
})
export default class AddPartners extends Vue {
  company: any = {
    step: '1',
    totalAction: '10,000'
  }
  countries = []
  loading = false
  partners: any = []
  nationalities = []
  subsidiary = false

  searchPartnerUser = debounce(async (partner: any, search: boolean) => {
    if (search) await this.existPartnerUser(partner)
  }, 2000)

  mounted () {
    this.company = this.$attrs.value
    this.getNationalities()
    this.getCountries()
    this.partners = []
    this.addPartner()
    this.addPartner()
  }

  get completePartners () {
    let complete = true
    this.partners.map((partner: any) => {
      if (partner.name === '' || partner.first_names === '' || partner.email === '' || !partner.completeEmail) complete = false
    })
    return complete
  }

  get validateEmail () {
    let valid = true
    this.partners.map((partner: any) => { if (!partner.validEmail) valid = false })
    return valid
  }

  get repeatEmail () {
    let repeat = false
    this.partners.map((partner: any) => { if (partner.repeatedEmail) repeat = true })
    return repeat
  }

  isFounder (partner: any) {
    if (partner.isFounder) this.setFounder(partner)
    else this.resetFounder(partner)
  }

  setFounder (partner: any) {
    partner.exist = false
    partner.isFounder = true
    partner.validEmail = true
    partner.email = this.state.email
    partner.name = this.state.name
    partner.first_names = this.state.first_names !== null ? this.state.first_names : ''
    partner.last_names = this.state.last_names !== null ? this.state.last_names : ''
    partner.phone = this.state.phone !== null ? this.state.phone : ''
    partner.phone_country_code = this.state.phone_country_code !== null ? this.state.phone_country_code : '52'
  }

  resetFounder (partner: any) {
    partner.email = ''
    partner.name = ''
    partner.first_names = ''
    partner.last_names = ''
  }

  noSpacesStart (value: string) {
    return value.trimStart()
  }

  async getNationalities () {
    const [error, response] = await CountryService.nationalities()
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.loading = false
    this.nationalities = response.data.data
  }

  addPartner () {
    this.partners.push({
      name: '',
      first_names: '',
      last_names: '',
      email: '',
      phone: '',
      phone_country_code: '52',
      nationality: 'MX',
      id: '',
      show: false,
      validEmail: true,
      completeEmail: true,
      repeatedEmail: false,
      exist: false,
      isPartner: false,
      loading: false
    })
  }

  deletePartner (index: number) {
    this.partners.splice(index, 1)
  }

  validate (partner: any) {
    if (partner.email === '') return partner.validEmail = true
    const re = /\S+@\S+\.\S+/
    partner.validEmail = re.test(partner.email)
  }

  validateCompleteEmail (partner: any) {
    const re = /\S+@\S+\.\S+/
    partner.completeEmail = re.test(partner.email)
    this.partners.map((partner: any, index: number) => { this.partners[index].repeatedEmail = false })
    this.partners.map((partner: any) => { 
      let repeated = this.partners.filter((partnerAux: any) => partnerAux.email.toLowerCase() === partner.email.toLowerCase() && partner.email !== '')
      if (repeated.length >= 2) repeated.map((partner: any) => { this.validateRepeatedEmail(partner.email) })
    })
    if (partner.completeEmail && !partner.repeatedEmail && partner.email === this.state.email) {
      this.setFounder(partner)
    } else if (partner.completeEmail) {
      partner.loading = true
      partner.exist = true
      this.searchPartnerUser(partner, true)
    } else {
      partner.loading = false
      partner.exist = false
      this.searchPartnerUser(partner, false)
    }
  }

  validateRepeatedEmail (email: string) {
    this.partners.map((partner: any, index: number) => { if (partner.email.toLowerCase() === email.toLowerCase()) this.partners[index].repeatedEmail = true })
  }

  onlyNumbers (value: string) { return value.replace(/[^0-9.]/g,'') }

  async setDefaultCouncil () {
    const partners = this.state.company.partners.map((partner: any, index: number) => {
      if (this.state.company.company_type === 'sa' && this.state.company.has_unique_administrator) {
        if (index === 0) return { name: this.getPartnerFullName(partner), id: partner.id, role: 'unique_administrator' }
        return { name: this.getPartnerFullName(partner), id: partner.id, role: 'no-member' }
      } else {
        if (index === 0) return { name: this.getPartnerFullName(partner), id: partner.id, role: 'president' }
        else if (index === 1) return { name: this.getPartnerFullName(partner), id: partner.id, role: 'secretary' }
        else if (index === 2) return { name: this.getPartnerFullName(partner), id: partner.id, role: 'treasurer' }
        else return { name: this.getPartnerFullName(partner), id: partner.id, role: 'member' }
      }
    })
    const [error] = await AdministrationService.storeAdministration(this.state.company.id, 'council', partners, false)
    if (error) return this.$toasted.global.error(error)
    await this.updateCompany(this.state.company.id)
  }

  getPartnerFullName (partner: any) {
    let full_name: string = partner.name != null ? partner.name : ''
    full_name = partner.first_names != null ? full_name + ' ' + partner.first_names : full_name
    full_name = partner.last_names != null ? full_name + ' ' + partner.last_names : full_name
    return full_name.trimEnd()
  }

  async existPartnerUser (partner: any) {
    const [error, response] = await PartnersService.existPartnerUser(partner.email)
    if (error) {
      partner.loading = false
      partner.exist = false
    }
    partner.name = response.data.data.name
    partner.first_names = response.data.data.first_names !== null ? response.data.data.first_names : ''
    partner.last_names = response.data.data.last_names !== null ? response.data.data.last_names : ''
    partner.phone = response.data.data.phone !== null ? response.data.data.phone : ''
    partner.phone_country_code = response.data.data.phone_country_code !== null ? response.data.data.phone_country_code : '52'
    partner.loading = false
  }

  async getCountries () {
    const [error, response] = await CountryService.all()
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.loading = false
    this.countries = response.data.data
  }

  async saveSubsidiary () {
    const [error] = await CompanyService.updateFlags(this.state.company.id, 'subsidiary', true)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
  }

  async savePartners () {
    this.loading = true
    // Save new partners
    let new_partners = this.partners.filter((partner: any) => partner.id === '')
    new_partners = this.partners.map((partner: any) => {
        partner.email = partner.email.toLowerCase()
        return partner
      })
    if (new_partners.length > 0) {
      const [error] = await PartnersService.storeMultiplePartners(this.state.company.id, new_partners)
      if (error) {
        this.loading = false
        return this.$toasted.global.error(error)
      }
    }
    // Save subsidiary
    // if (this.subsidiary) await this.saveSubsidiary()
    // Get new user info
    await this.updateCompany(this.state.company.id)
    await this.setDefaultCouncil()
    this.company.step = '4'
    this.loading = false
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
