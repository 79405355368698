

















































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import InputFileDocumentsError from '@/components/InputFileDocumentsError.vue'

@Component({
  components: { InputFileDocumentsError }
})
export default class PartnerDocumentsError extends Vue {
  partner: any = {}
  full_name = ''

  created () {
    this.partner = this.$attrs.value
    this.partner.cfi = ''
    this.partner.id_spouse = ''
    this.partner.marriage_certificate = ''
    this.full_name = this.partner.name != null ? this.partner.name : ''
    this.full_name = this.partner.first_names != null ? this.full_name + ' ' + this.partner.first_names : this.full_name
    this.full_name = this.partner.last_names != null ? this.full_name + ' ' + this.partner.last_names : this.full_name
    this.full_name = this.full_name.trimEnd()
  }
  
  @State('UserModule') state: any
}
