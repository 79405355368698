










































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
import ModalSendToSign from '@/components/resumen/modals/ModalSendToSign.vue'

@Component({
  components: { Loader, ModalSendToSign }
})
export default class BarStatutes extends Vue {
  modal: any = {
    showModalSendToSign: false
  }
  checkoutComplete = false
  accept = false

  created () {
    this.checkoutComplete = this.state.company.orders.filter((order: any) => order.status === 'complete' && order.items.filter((item: any) => item.name === 'Complemento Acta Constitutiva' || item.name === 'Acta Constitutiva').length > 0).length > 0
  }

  @State('UserModule') state: any
}
