

















































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import ReturnAddress from '@/components/Return.vue'

@Component({
  components: { ReturnAddress }
})
export default class CheckoutInfo extends Vue {
  points = [
    'Ver la versión final de tu proyecto y agendar revisión con tu asesor por cualquier duda que tengas.',
    'Firmar tu Acta de forma 100% digital.',
    'Agendar tu cita para el Alta del SAT y desbloquear todos los beneficios.'
  ]
  foreign = false

  created () {
    this.state.company.partners.map((partner: any) => {
      let national = false
      partner.nationalities.map((nationality: any) => { if (nationality.name === 'México' || nationality.issue_country === 'MX') national = true })
      if (partner.nationalities.length && !national) this.foreign = true
    })
  }

  @State('UserModule') state: any
}
