
























































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import TooltipCFI from '@/components/partners/TooltipCFI.vue'
import Loader from '@/components/Loader.vue'
// Services
import CommissaryService from '@/services/Commissary'

@Component({
  components: { TooltipCFI, Loader }
})
export default class CommissaryStart extends Vue {
  loading = false
  mati = ''
  client = ''
  flow = ''

  // Event MATI
  // eslint-disable-next-line
  load (detail: EventListenerOrEventListenerObject) {
    return
  }

  // Event MATI
  // eslint-disable-next-line
  fin (detail: EventListenerOrEventListenerObject) {
    this.matiNext()
  }

  // Event MATI
  // eslint-disable-next-line
  exit (detail: EventListenerOrEventListenerObject) {
    return
  }

  // Events MATI
  eventListeners () {
    return {
      '&mati:loaded': this.load, // & = passive
      '&mati:userFinishedSdk': this.fin, // & = passive
      '&mati:exitedSdk': this.exit // & = passive
    }
  }

  mounted () {
    this.client = process.env.VUE_APP_MATI_CLIENT_ID
    this.flow = process.env.VUE_APP_MATI_FLOW_ID_COMMISSARY
    this.mati = '{"company_id":"' + this.$route.query.company.toString() +
      '","owner_id": "' + this.state.commissary_id +
      '","check": "false"}'
  }

  async matiNext () {
    this.loading = true
    await CommissaryService.setCommissaryMatiStatus(this.state.commissary_id)
    return this.state.isCSFValidated === true ?
      this.$router.push({ name: 'CommissaryEnd', query: this.$route.query }) :
      this.$router.push({ name: 'CommissaryComplementaries', query: this.$route.query })
  }

  async next () {
    this.loading = true
    return this.state.isCSFValidated === true ?
      this.$router.push({ name: 'CommissaryEnd', query: this.$route.query }) :
      this.$router.push({ name: 'CommissaryComplementaries', query: this.$route.query })
  }

  @State('CommissaryModule') state: any
}
