import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { State } from '@/store/interfaces/User'
import UserTypes from '@/store/types/UserTypes'
import UserService from '@/services/User'
import CompanyService from '@/services/Company'
import moment from 'moment'

const keyLocalStorage = 'UserVerify'

function updateLocalStorageCode (code: string) {
  localStorage.setItem(keyLocalStorage, code)
}

const state: State = {
  id: 0,
  code: localStorage.getItem(keyLocalStorage) || '',
  last_update_user: 0,
  last_update_company: 0,
  name: '',
  first_names: '',
  last_names: '',
  full_name: '',
  email: '',
  is_confirmed: false,
  phone: '',
  phone_country_code: '',
  pm_last_four: '',
  nationality: {
    code: '',
    created_at: '',
    id: 0,
    name: '',
    updated_at: ''
  },
  nationality_id: 0,
  notary_id: 0,
  companies: [],
  company_as_a_partner: [],
  company: {
    id: '',
    partners: [],
    stages: [],
    documents: [],
    fiscal_identity: { document_url: null },
    commissary_national_identity: { document_url: null },
    commissary_CSF: { document_url: null },
    articles_of_incorporation: { document_url: null },
    acuse_cita_sat: { document_url: null },
    articles_of_incorporation_render: { document_url: null },
    legal_representative_address_rfc: { document_url: null },
    legal_name_authorization: { document_url: null },
    company_CSF: { document_url: null }
  },
  orders: []
}

function getDocumentCompanyByApproved (type: string, label: string) {
  const findDocument = state.company.documents.find((document: any) => document.document_type === type && document.is_approved)
  if (findDocument) {
    return {
      document_url: findDocument.document_url,
      is_approved: findDocument.is_approved,
      rejection_category: findDocument.rejection_category,
      owner_category: findDocument.owner_category,
      id: findDocument.id,
      label,
      type,
      company_id: state.company.id
    }
  }
  return { document_url: null }
}

function getDocumentCompanyByType (type: string, label: string, approvedManual = false) {
  const findDocument = state.company.documents.find((document: any) => document.document_type === type)
  if (findDocument) {
    return {
      document_url: findDocument.document_url,
      is_approved: approvedManual || findDocument.is_approved,
      rejection_category: findDocument.rejection_category,
      owner_category: findDocument.owner_category,
      id: findDocument.id,
      label,
      type,
      company_id: state.company.id
    }
  }
  return { document_url: null }
}

function getDocumentPartnerByType (partner: any, type: string, label: string) {
  const findDocument = partner.documents.find((document: any) => document.document_type === type)
  if (findDocument) {
    return {
      document_url: findDocument.document_url,
      is_approved: findDocument.is_approved,
      rejection_category: findDocument.rejection_category,
      id: findDocument.id,
      owner_category: findDocument.owner_category,
      partner_id: partner.id,
      label,
      type,
      partner,
      company_id: state.company.id
    }
  }
  return { document_url: null }
}

function formattedDataPartners () {
  state.company.partners = state.company.partners.map((partner: any) => {
    const nationalIdentity = getDocumentPartnerByType(partner, 'national_identity', 'Identificación oficial frente')
    if (nationalIdentity.document_url) {
      partner.national_identity = nationalIdentity
      partner.national_identity_back = getDocumentPartnerByType(partner, 'national_identity_back', 'Identificación oficial reverso')
    }
    const passport = getDocumentPartnerByType(partner, 'passport', 'Pasaporte')
    if (passport.document_url) partner.passport = passport
    partner.address_identity = getDocumentPartnerByType(partner, 'address', 'Comprobante de domicilio')
    partner.cfi = getDocumentPartnerByType(partner, 'CFI', 'Constancia de Situación Fiscal')
    partner.id_spouse = getDocumentPartnerByType(partner, 'id_spouse', 'Identificación del cónyuge')
    partner.id_spouse_back = getDocumentPartnerByType(partner, 'id_spouse_back', 'Reverso de la identificación del cónyuge')
    partner.marriage_certificate = getDocumentPartnerByType(partner, 'marriage_certificate', 'Acta de matrimonio')
    if (partner.is_moral_person) {
      partner.articles_of_incorporation = getDocumentPartnerByType(partner, 'articles_of_incorporation', 'Acta constitutiva')
      partner.legal_representative_proof = getDocumentPartnerByType(partner, 'legal_representative_proof', 'Poder del rep. legal')
      partner.fiscal_identity = getDocumentPartnerByType(partner, 'fiscal_identity', 'Cedula fiscal')
    }
    return partner
  })
}

function formattedDataCompany () {
  state.company.articles_of_incorporation = getDocumentCompanyByType('articles_of_incorporation', 'Acta constitutiva', true)
  state.company.acuse_cita_sat = getDocumentCompanyByType('acuse_cita_sat', 'Acuse de cita SAT', true)
  state.company.articles_of_incorporation_render = getDocumentCompanyByType('articles_of_incorporation_render', 'Acta renderizada', true)
  state.company.legal_representative_address_rfc = getDocumentCompanyByType('legal_representative_address_rfc', 'Comprobante de domicilio')
  state.company.fiscal_identity = getDocumentCompanyByType('fiscal_identity', 'RFC', true)
  state.company.commissary_national_identity = getDocumentCompanyByType('commissary_national_identity', 'Identificación del comisario')
  state.company.commissary_CSF = getDocumentCompanyByType('commissary_CSF', 'Constancia de Situación Fiscal del comisario')
  state.company.legal_name_authorization = getDocumentCompanyByApproved('legal_name_authorization', 'Autorización de denominación')
  state.company.company_CSF = getDocumentCompanyByType('CSF_company', 'Constancia de Situación Fiscal de la empresa')
}

const getters: GetterTree<State, any> = {
  [UserTypes.getters.GET_DATA]: stateGet => stateGet,
  [UserTypes.getters.GET_COMPANY]: stateGet => stateGet.company
}

const mutations: MutationTree<State> = {
  [UserTypes.mutations.SET_DATA]: async (stateMut, payload: State) => {
    state.id = payload.id
    state.name = payload.name
    state.first_names = payload.first_names
    state.last_names = payload.last_names
    state.full_name = payload.full_name
    state.email = payload.email
    state.is_confirmed = payload.is_confirmed
    state.phone = payload.phone
    state.phone_country_code = payload.phone_country_code
    state.pm_last_four = payload.pm_last_four
    state.nationality = payload.nationality
    state.nationality_id = payload.nationality_id
    state.notary_id = payload.notary_id
    state.orders = payload.orders
    state.companies = payload.companies
    state.company_as_a_partner = payload.company_as_a_partner
  },
  [UserTypes.mutations.SET_COMPANY_DATA]: async (stateMut, payload: State) => {
    state.company = payload.company
    formattedDataCompany()
    formattedDataPartners()
  },
  [UserTypes.mutations.CLEAR_DATA]: async () => {
    state.id = 0
    state.code = ''
    state.last_update_user = 0
    state.last_update_company = 0
    state.name = ''
    state.first_names = ''
    state.last_names = ''
    state.full_name = ''
    state.email = ''
    state.is_confirmed = false
    state.phone = ''
    state.phone_country_code = ''
    state.pm_last_four = ''
    state.nationality = {
      code: '',
      created_at: '',
      id: 0,
      name: '',
      updated_at: ''
    }
    state.nationality_id = 0
    state.notary_id = 0
    state.companies = []
    state.company_as_a_partner = []
    state.company = {
      id: '',
      partners: [],
      stages: [],
      documents: [],
      fiscal_identity: { document_url: null },
      commissary_national_identity: { document_url: null },
      commissary_CSF: { document_url: null },
      articles_of_incorporation: { document_url: null },
      acuse_cita_sat: { document_url: null },
      articles_of_incorporation_render: { document_url: null },
      legal_representative_address_rfc: { document_url: null },
      legal_name_authorization: { document_url: null },
      company_CSF: { document_url: null }
    }
    state.orders = []
  },
  [UserTypes.mutations.SET_CODE]: async (stateMut, payload: string) => {
    state.code = payload
    updateLocalStorageCode(payload)
  }
}

const actions: ActionTree<State, any> = {
  [UserTypes.actions.UPDATE_DATA]: async ({ commit }) => {
    if ((moment().valueOf() - state.last_update_user) < 5000) return [null, state]
    const [err, response]: any = await UserService.profile()
    if (err) return [err]
    await commit(UserTypes.mutations.SET_DATA, response.data.data)
    state.last_update_user = moment().valueOf()
    return [null, response.data.data]
  },
  [UserTypes.actions.UPDATE_COMPANY_DATA]: async ({ commit }, payload: string) => {
    if (state.company.id === payload && (moment().valueOf() - state.last_update_company) < 5000) return [null, state.company]
    const [err, response]: any = await CompanyService.getCompany(payload)
    if (err) return [err]
    await commit(UserTypes.mutations.SET_COMPANY_DATA, { company: response.data.data})
    state.last_update_company = moment().valueOf()
    return [null, response.data.data]
  },
  [UserTypes.actions.FORCE_UPDATE_COMPANY_DATA]: async ({ commit }, payload: string) => {
    const [err, response]: any = await CompanyService.getCompany(payload)
    if (err) return [err]
    await commit(UserTypes.mutations.SET_COMPANY_DATA, { company: response.data.data})
    state.last_update_company = moment().valueOf()
    return [null, response.data.data]
  },
  [UserTypes.actions.CLEAR_DATA]: async ({ commit }) => {
    await commit(UserTypes.mutations.CLEAR_DATA)
    return [null]
  },
  [UserTypes.actions.SET_CODE]: async ({ commit }, payload: string) => {
    await commit(UserTypes.mutations.SET_CODE, payload)
    return [null]
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
