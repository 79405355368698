





































// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
})
export default class PartnerNationalities extends Vue {
  @Prop() nationalities!: any
  partner: any = {}
  renderKey = 100

  created () {
    this.partner = this.$attrs.value
    if (this.partner.nationalities === undefined || this.partner.nationalities === null) this.partner.nationalities = ['']
    if (this.partner.nationalities.length === 0) this.partner.nationalities = this.getNationality()
  }

  getNationality () {
    if (this.partner.nationality === null || this.partner.nationality === undefined || this.partner.nationality === '') return []
    const aux: any = this.nationalities.find((nationality: any ) => nationality.name === this.partner.nationality || nationality.issue_country === this.partner.nationality)
    if (aux === undefined || aux === null) return []
    else return [aux.id]
  }

  getSelectedItem(nationalityIndex: number) {
    this.partner.nationalities.map((nationality: any, index: number) => {
      if (this.partner.nationalities[nationalityIndex] === nationality && nationalityIndex !== index) this.partner.nationalities[index] = ''
    })
    this.partner.nationality = this.nationalities.find((nationality: any ) => nationality.id === this.partner.nationalities[0]).name
    this.renderKey++
  }

}
