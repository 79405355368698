export default {
  mutations: {
    CLEAR_DATA: 'CLEAR_DATA',
    UTM_ID: 'UTM_ID',
    UTM_SOURCE: 'UTM_SOURCE',
    UTM_MEDIUM: 'UTM_MEDIUM',
    UTM_CAMPAIGN: 'UTM_CAMPAIGN',
    UTM_TERM: 'UTM_TERM',
    UTM_CONTENT: 'UTM_CONTENT',
    UTMs: 'UTMs'
  },
  getters: {
    GET_DATA: 'GET_DATA'
  },
  actions: {
    CLEAR_DATA: 'CLEAR_DATA',
    UTM_ID: 'UTM_ID',
    UTM_SOURCE: 'UTM_SOURCE',
    UTM_MEDIUM: 'UTM_MEDIUM',
    UTM_CAMPAIGN: 'UTM_CAMPAIGN',
    UTM_TERM: 'UTM_TERM',
    UTM_CONTENT: 'UTM_CONTENT',
    UTMs: 'UTMs'
  }
}
