


















































































































































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
// Services
import CompanyService from '@/services/Company'
import PaymentService from '@/services/Payment'
import AdministrationService from '@/services/Administration'
// Types
import UserTypes from '@/store/types/UserTypes'

@Component({
  components: { Loader }
})
export default class ModalEditPowers extends Vue {
  modal: any = {
    showModalPowers: false,
    update: false,
  }
  attorneys = [{ name: '', position: '', id: '', power: false }]
  attorneysAux = [{ name: '', rfc: '', id: '', power: false }]
  deleteAttorneys: any = []
  typeSelected = ''
  linkToPayment = ''
  msj = 'Poder para administrar la empresa, abrir cuentas bancarias y realizar trámites fiscales.'
  loading = false
  addAttorney = false
  payment = false
  goToPayment = false
  newAttorneys = [{ name: '', rfc: '' }]
  price = ''

  get isValidation () {
    const validation = this.state.company.stages.find((stage: any) => stage.stage_name === 'documents_approved').is_completed === true
    return validation
  }

  get completePayment () {
    const payment = this.state.company.orders.filter((order: any) => order.status === 'complete' && order.items.filter((item: any) => item.name === 'Apoderado').length > 0).length > 0
    return payment
  }

  get completeAttorneys () {
    let complete = true
    this.newAttorneys.map((attorney: any) => {
      if (attorney.name === '' || attorney.rfc === '') complete = false
    })
    return complete
  }

  async created () {
    await this.getPrices()
  }

  mounted () {
    this.modal = this.$attrs.value
    this.getAttorneys()
  }

  getAttorneys () {
    const council = this.state.company.council.filter((member: any ) => member.council_role !== 'commissary' && member.council_role !== 'no-member')
    const attorneys = council.map((member: any) => { return { position: this.getCouncilRole(member.council_role), name: this.getPartnerFullName(member.partner), power: member.sat_signature_faculty, id: member.id} })
    this.attorneys = attorneys
    this.attorneysAux = this.state.company.legal_representatives.filter((attorney: any ) => attorney.company_id === this.state.company.id)
  }

  getPartnerFullName (partner: any) {
    let full_name: string = partner.name != null ? partner.name : ''
    full_name = partner.first_names != null ? full_name + ' ' + partner.first_names : full_name
    full_name = partner.last_names != null ? full_name + ' ' + partner.last_names : full_name
    return full_name.trimEnd()
  }

  getCouncilRole (role: string) {
    if (role === 'unique_administrator') return 'Administrador único'
    if (role === 'president') return 'Presidente'
    if (role === 'secretary') return 'Secretario'
    if (role === 'treasurer') return 'Tesorero'
    if (role === 'member') return 'Miembro'
    return ''
  }

  noSpacesStartRFC (value: string) {
    return value.trimStart().toUpperCase()
  }

  setAttorneys () {
    if (!this.completePayment) this.getPaymentLink()
    else this.setNewAttorneys()
  }

  deleteAttorney (id: string, index: number) {
    if (id !== '') this.deleteAttorneys.push(id)
    this.attorneysAux.splice(index, 1)
  }

  async setNewAttorneys () {
    this.loading = true
    const [error] = await CompanyService.setAttorneys(this.state.company.id, this.newAttorneys)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    await this.updateCompany(this.state.company.id)
    this.modal.showModalPowers = false
    this.modal.update = true
    this.loading = false
  }

  async getPaymentLink () {
    this.loading = true
    const [error2, response] = await PaymentService.createPaymentLink([{ name : 'Apoderado', quantity : 1 }], this.state.company.id, this.state.id)
    if (error2) {
      this.loading = false
      return this.$toasted.global.error(error2)
    }
    if (response) this.linkToPayment = response.data.data.payment_link
    this.payment = true
    this.loading = false
  }

  async getPrices () {
    const [error, response] = await PaymentService.getPrices()
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    const itemPrice = response.data.data.find((item: any ) => item.name === 'Apoderado').prices.find((price: any) => price.is_default === true)
    const price = Number(itemPrice.price) + Number(itemPrice.tax)
    this.price = price.toString().replace(/\d(?=(?:\d{3})+$)/g, '$&,')
  }

  async saveDelete () {
    if (this.deleteAttorneys.length === 0) return this.modal.showModalPowers = false
    this.loading = true
    let promise_error = false
    // Save delete partners
    this.modal.checkDistribution = true
    await Promise.all(
      this.deleteAttorneys.map(async (attorney: any) => {
        const [error] = await CompanyService.deleteAttorneys(attorney)
        if (error) {
          promise_error = true
          return this.$toasted.global.error(error)
        }
      })
    )
    if (promise_error) {
      await this.updateCompany(this.state.company.id)
      this.loading = false
      return promise_error = false
    }
    this.deleteAttorneys = []
    await this.updateCompany(this.state.company.id)
    this.getAttorneys()
    this.modal.showModalPowers = false
    this.modal.update = true
    this.loading = false
  }

  async setPower (attorney: any) {
    if (attorney.position !== 'Tesorero' && attorney.position !== 'Miembro') return
    attorney.power = !attorney.power
    const [error] = await AdministrationService.setPower( attorney.id, attorney.power )
    if (error) { return this.$toasted.global.error(error) }
  }

  goToLink () {
    window.open(this.linkToPayment, '_blank')
    this.goToPayment = true
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
