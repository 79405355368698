import { Component, Vue } from 'vue-property-decorator'

@Component
export default class QueryParams extends Vue {
  getUtmQueryParams() {
    return {
      utm_id: this.$route.query?.utm_id ?? null,
      utm_source: this.$route.query?.utm_source ?? null,
      utm_medium: this.$route.query?.utm_medium ?? null,
      utm_campaign: this.$route.query?.utm_campaign ?? null,
      utm_term: this.$route.query?.utm_term ?? null,
      utm_content: this.$route.query?.utm_content ?? null
    }
  }
}