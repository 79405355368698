




























// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
// Types
import UserTypes from '@/store/types/UserTypes'
// Services
import SignatureService from "@/services/Signature"

@Component({
  components: { Loader }
})
export default class ModalSendToSign extends Vue {
  modal: any = {
    showModalSendToSign: false,
    update: false,
  }
  loading = false

  mounted () {
    this.modal = this.$attrs.value
  }

  async setSignature() {
    this.loading = true
    const [error] = await SignatureService.startSignature(this.state.company.id)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    await this.updateCompany(this.state.company.id)
    this.loading = false
    this.modal.showModalSendToSign = false
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
