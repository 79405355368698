export default {
  mutations: {
    SET_DATA: 'SET_DATA',
    SET_COMPANY_DATA: 'SET_COMPANY_DATA',
    CLEAR_DATA: 'CLEAR_DATA',
    SET_CODE: 'SET_CODE'
  },
  getters: {
    GET_DATA: 'GET_DATA',
    GET_COMPANY: 'GET_COMPANY'
  },
  actions: {
    UPDATE_DATA: 'UPDATE_DATA',
    UPDATE_COMPANY_DATA: 'UPDATE_COMPANY_DATA',
    FORCE_UPDATE_COMPANY_DATA: 'FORCE_UPDATE_COMPANY_DATA',
    CLEAR_DATA: 'CLEAR_DATA',
    SET_CODE: 'SET_CODE'
  }
}
