






















































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
// Types
import InvitedTypes from '@/store/types/InvitedTypes'
// Services
import MatiService from '@/services/Mati'

@Component({
  components: { Footer, Loader }
})
export default class InvitedValidation extends Vue {
  loading = false
  mati = ''
  client = ''
  flow = ''
  matiCheck = true
  complementaries = false
  cont = 0

  // Event MATI
  // eslint-disable-next-line
  load (detail: EventListenerOrEventListenerObject) {
    return
  }

  // Event MATI
  // eslint-disable-next-line
  fin (detail: EventListenerOrEventListenerObject) {
    this.matiNext()
  }

  // Event MATI
  // eslint-disable-next-line
  exit (detail: EventListenerOrEventListenerObject) {
    return
  }

  // Events MATI
  eventListeners () {
    return {
      '&mati:loaded': this.load, // & = passive
      '&mati:userFinishedSdk': this.fin, // & = passive
      '&mati:exitedSdk': this.exit // & = passive
    }
  }
  
  mounted () {
    this.client = process.env.VUE_APP_MATI_CLIENT_ID
    let foreign = true
    if (this.state.partner.nationalities !== null && this.state.partner.nationalities.length) this.state.partner.nationalities.map((nationality: any) => { if (nationality.name === 'México' || nationality.issue_country === 'MX') foreign = false })
    this.flow = (!foreign && (this.state.partner.rfc === null || this.state.partner.rfc === 'EXTF900101NI1')) ?
      process.env.VUE_APP_MATI_FLOW_ID_PARTNER_WITHOUT_RFC : !foreign ?
      process.env.VUE_APP_MATI_FLOW_ID_PARTNER : (this.state.partner.rfc === null || this.state.partner.rfc === 'EXTF900101NI1') ?
      process.env.VUE_APP_MATI_FLOW_ID_PARTNER_FOREIGN_RFC : process.env.VUE_APP_MATI_FLOW_ID_PARTNER_FOREIGN
    this.mati = '{"partner_id":"' + this.state.partner.id +
      '","company_id": "' + this.state.company_id +
      '","check": "false"}'
    this.checkPartner()
  }

  checkPartner () {
    // Exist documents refused mati
    const documentsError = this.state.partner.documents.filter((document: any) => !document.is_approved && document.rejection_category)
    const passport = documentsError.filter((document: any) => document.document_type === 'passport')
    const national_identity = documentsError.filter((document: any) => document.document_type === 'national_identity')
    if (passport.length > 0 || national_identity.length > 0 || this.state.partner.mati_verification !== true) this.matiCheck = false
    // Mati status check
    if (this.state.partner.mati_verification !== null && this.state.partner.mati_status && this.state.partner.mati_status.length && (this.state.partner.mati_status[0].status === 'Reject' || this.state.partner.mati_status[0].status === 'Denied')) this.matiCheck = false
    if (this.matiCheck === false && this.state.partner.mati_status && this.state.partner.mati_status.length && (this.state.partner.mati_status[0].status !== 'Reject' && this.state.partner.mati_status[0].status !== 'Denied')) this.matiCheck = true
  }

  nextTo () {
    return this.$router.push({ name: 'InvitedCivilStatus', query: this.$route.query })
  }

  async matiNext () {
    this.loading = true
    await MatiService.setMatiCheck(this.state.partner.id)
    await this.updateUser({ partner: this.state.partner.id, company_id: this.state.company_id })
    this.nextTo()
  }

  @Action(`InvitedModule/${InvitedTypes.actions.UPDATE_DATA}`) updateUser: any
  @State('InvitedModule') state: any
}
