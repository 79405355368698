

















// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
import moment from 'moment'

@Component({
})
export default class Timer extends Vue {
  @Prop() endDate!: number // Select how much charge the active bar has
  moment = moment
  now = moment().valueOf()
  timer: any = null

  get hour (){
    let h = Math.trunc((this.endDate - this.now) / 1000 / 3600)
    return h <= 0 ? '00' : h > 9 ? h : '0' + h
  }

  get min (){
    let m = Math.trunc((this.endDate - this.now) / 1000 / 60) % 60
    return m <= 0 ? '00' : m > 9 ? m : '0' + m
  }

  get sec (){
    let s = Math.trunc((this.endDate - this.now)/1000) % 60
    return s <= 0 ? '00' : s > 9 ? s : '0' + s
  }

  created () {
    this.timer = setInterval(()=>{
      this.now = moment().valueOf()
      if ((this.endDate - this.now) <= 0) {
        clearInterval(this.timer)
        return
      }
      if(this.now > this.endDate){
        this.now = this.endDate
        clearInterval(this.timer)
      }
    }, 1000)
  }
  
  beforeDestroy(){
    clearInterval(this.timer)
  }

  @State('UserModule') state: any
}
