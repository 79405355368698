




































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
import InputFile from '@/components/InputFile.vue'
import TooltipCSF from '@/components/dashboard/TooltipCSF.vue'

@Component({
  components: { Loader, InputFile, TooltipCSF }
})
export default class ModalCompanyBank extends Vue {
  modal: any = {
    showModalCompanyBank: true,
    update: false,
    accountType: '',
    account: '',
    clabe: '',
    swift: ''
  }

  mounted () {
    this.modal = this.$attrs.value
  }

  copyBankData () {
    if (this.modal.accountType === 'mxn') navigator.clipboard.writeText(`
        Beneficiario: ${ this.state.company.name + ' ' + (this.state.company.company_type === 'sapi' ? 'S.A.P.I de C.V.' : 'S.A. de C.V.') }
        Cuenta: ${ this.modal.account }
        Clabe: ${ this.modal.clabe }
        Banco: BBVA-MEXICO
        Dirección Banco: Av. Paseo de la Reforma 510, Juárez, Cuauhtémoc, 06600 Ciudad de México, CDMX
      `)
    else navigator.clipboard.writeText(`
        Beneficiario: ${ this.state.company.name + ' ' + (this.state.company.company_type === 'sapi' ? 'S.A.P.I de C.V.' : 'S.A. de C.V.') }
        Cuenta: ${ this.modal.account }
        Clabe: ${ this.modal.clabe }
        SWIFT: ${ this.modal.swift }
        Banco: BBVA-MEXICO
        Dirección Banco: Av. Paseo de la Reforma 510, Juárez, Cuauhtémoc, 06600 Ciudad de México, CDMX
      `)
    this.$toasted.global.success({ message: 'Se ha copiado con éxito' })
  }

  @State('UserModule') state: any
}
