













































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
import HeaderProgressBar from '@/components/HeaderProgressBar.vue'
import PartnerNationalities from '@/components/partners/PartnerNationalities.vue'
import PartnerComplementaries from '@/components/partners/PartnerComplementaries.vue'
import PartnerMexicanWithoutRFC from '@/components/partners/PartnerMexicanWithoutRFC.vue'
// Services
import CountryService from '@/services/Country'
import PartnersService from '@/services/Partners'
// Types
import UserTypes from '@/store/types/UserTypes'

@Component({
  components: { Footer, Loader, HeaderProgressBar, PartnerNationalities, PartnerComplementaries, PartnerMexicanWithoutRFC }
})
export default class Complementaries extends Vue {
  partnerSelect: any = { RFC: '' }
  foreign = false
  loading = false
  step = 0
  nationalities = []
  partner = {
    nationalities: [''],
    id: '',
    birthplace: '',
    tax_country: '',
    tax_id: '',
    rfc: '',
    civil_status: '',
    loading: false
  }
  copys = {
    title: '',
    subTitle: ''
  }

  mounted () {
    this.getPartnerData()
    this.setCopys()
    this.getNationalities()
  }

  getPartnerData () {
    const partner = this.state.company.partners.find((partner: any) => this.state.email === partner.email)
    this.foreign = true
    if (partner.nationalities !== null && partner.nationalities.length) this.partner.nationalities = partner.nationalities.map((nationality: any) => {
        if (nationality.name === 'México' || nationality.issue_country === 'MX') this.foreign = false
        return nationality.id
      })
    this.partner.id = partner.id
    this.partner.rfc = partner.rfc === null ? '' : partner.rfc
    this.partner.birthplace = partner.birthplace === null ? '' : partner.birthplace
    this.partner.tax_country = partner.tax_country === null ? '' : partner.tax_country
    this.partner.tax_id = partner.tax_id === null ? '' : partner.tax_id
    this.partner.civil_status = partner.civil_status === null ? '' : partner.civil_status
  }

  setCopys () {
    if (this.step === 0) {
      this.copys.title = '¿Cuál es tu nacionalidad?'
      this.copys.subTitle = 'Por ley, debes declarar todas las nacionalidades que tengas'
    } else if (this.step === 1) {
      this.copys.title = '¿Cuentas con RFC?'
      this.copys.subTitle = 'El RFC es el número de identificación fiscal en México'
    }
  }

  async saveNationalities () {
    this.loading = true
    this.partner.loading = true
    const partner = this.state.company.partners.find((partner: any) => this.state.email === partner.email)
    let existNewNationalities = partner.nationalities.length !== this.partner.nationalities.length
    if (!existNewNationalities) {
      partner.nationalities.map((nationality: any) => {
        const exist = this.partner.nationalities.find((newNationality: any) => nationality.id === newNationality)
        if (exist === undefined) existNewNationalities = true
      })
    }
    // Save nationalities
    if (existNewNationalities) {
      const nationalities = this.partner.nationalities.filter((nationality: any ) => nationality !== null)
      const [error] = await PartnersService.storeNationalities(this.partner.id, nationalities)
      if (error) {
        this.loading = false
        this.partner.loading = false
        return this.$toasted.global.error(error)
      }
    }
    // Save birthplace
    if (partner.birthplace !== this.partner.birthplace) {
      const [error2] = await PartnersService.storeComplements(
        this.partner.id,
        this.partner.rfc,
        this.partner.birthplace,
        this.partner.tax_country,
        this.partner.tax_id,
        this.partner.civil_status,
        true
      )
      if (error2) {
        this.loading = false
        this.partner.loading = false
        return this.$toasted.global.error(error2)
      }
    }
    await this.updateCompany(this.state.company.id)
    this.step = 1
    this.getPartnerData()
    this.setCopys()
    this.loading = false
    this.partner.loading = false
  }

  async getNationalities () {
    const [error, response] = await CountryService.nationalities()
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.loading = false
    this.nationalities = response.data.data
  }

  returnTo () {
    if (this.step === 0) return this.$router.push({ name: 'isPartnerStart', query: this.$route.query, params: { company_id: this.state.company.id } })
    this.step--
    this.setCopys()
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any;
}
