











































































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
// Services
import CompanyService from '@/services/Company'
import PaymentService from '@/services/Payment'
// Types
import UserTypes from '@/store/types/UserTypes'

@Component({
  components: { Loader }
})
export default class ModalEditRegisteredOffice extends Vue {
  modal: any = {
    showModalRegisteredOffice: false,
    update: false,
  }
  types = [ 'Mazatlán', 'CDMX']
  typeSelected = ''
  tooltipMSJ = [
    'Nosotros trabajamos con la notaria 248 de Mazatlán, donde el Notario Ernesto Cardenas, uno de los fundadores de Weetrust, empresa de firma digital y aliada de Tally, nos proporciona el Servicio Notarial.',
    'El Domicilio Social es diferente al Fiscal y todas tus actas posteriores pordrán ser firmadas de forma digital.'
  ]
  loading = false
  payment = false
  goToPayment = false
  showMsjPayment = false
  linkToPayment = ''
  price = ''

  get completePayment () {
    const payment = this.state.company.orders.filter((order: any) => order.status === 'complete' && order.items.filter((item: any) => item.name === 'Cambio de domicilio fiscal').length > 0).length > 0
    return payment
  }

  async created () {
    this.typeSelected = this.state.company.fiscal_residence
    await this.getPrices()
  }

  mounted () {
    this.modal = this.$attrs.value
  }

  registeredOffice () {
    if (this.typeSelected === this.state.company.fiscal_residence) return this.modal.showModalRegisteredOffice = false
    else if (!this.completePayment) this.getPaymentLink()
    else this.setRegisteredOffice()
  }

  async setRegisteredOffice () {
    this.loading = true
    const [error] = await CompanyService.setFiscalResident(this.state.company.id, this.typeSelected)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    await this.updateCompany(this.state.company.id)
    this.modal.showModalRegisteredOffice = false
    this.modal.update = true
    this.loading = false
  }

  async getPaymentLink () {
    this.loading = true
    const [error2, response] = await PaymentService.createPaymentLink([{ name : 'Cambio de domicilio fiscal', quantity : 1 }], this.state.company.id, this.state.id)
    if (error2) {
      this.loading = false
      return this.$toasted.global.error(error2)
    }
    if (response) this.linkToPayment = response.data.data.payment_link
    this.payment = true
    this.loading = false
  }

  async getPrices () {
    const [error, response] = await PaymentService.getPrices()
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    const itemPrice = response.data.data.find((item: any ) => item.name === 'Cambio de domicilio fiscal').prices.find((price: any) => price.is_default === true)
    const price = Number(itemPrice.price) + Number(itemPrice.tax)
    this.price = price.toString().replace(/\d(?=(?:\d{3})+$)/g, '$&,')
  }

  goToLink () {
    window.open(this.linkToPayment, '_blank')
    this.goToPayment = true
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
