
















// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component({
})
export default class ModalCheckout extends Vue {
  modal: any = { showModalCheckout: false }

  mounted () {
    this.modal = this.$attrs.value
  }

  @State('UserModule') state: any
}
