
























// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
// Types
import InvitedTypes from '@/store/types/InvitedTypes'

@Component({
  components: { Footer  }
})
export default class InvitedAnalyzingData extends Vue {
  verification = true
  partner: any = {}
  interval: any = null
  cont = 0
  maxCont = 0

  created () {
    this.interval = setInterval(()=>{
      this.cont++
      if (this.cont === 150) {
        this.isValidate()
        this.cont = 0
        this.maxCont++
      }
      if (this.maxCont === 15) {
        clearInterval(this.interval)
      }
    }, 1000)
  }

  async isValidate () {
    await this.updateUser({ partner: this.state.partner.id, company_id: this.state.company_id })
    if (this.state.partner.metamap_process === true) {
      clearInterval(this.interval)
      return this.$router.push({ name: 'InvitedValidationComplete', query: this.$route.query, params: { company_id: this.state.company.id } }).catch(() => {return})
    } else if (this.state.partner.metamap_process === false && !this.state.partner.mati_verification) {
      this.verification = false
    }
  }
  
  beforeDestroy(){
    clearInterval(this.interval)
  }

  @Action(`InvitedModule/${InvitedTypes.actions.UPDATE_DATA}`) updateUser: any
  @State('InvitedModule') state: any
}
