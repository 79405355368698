




















// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
import { saveAs } from 'file-saver'
// Components
import Loader from '@/components/Loader.vue'
// Services
import ImageService from '@/services/Image'
import SATService from '@/services/SAT'

@Component({
  components: { Loader }
})
export default class CompanyInfo extends Vue {
  companyDocs: any = []
  loading = false
  password = ''
  passwordFieldType = 'password'
  eye = 'eye'

  created () {
    this.setCompanyDocs()
  }

  switchVisibility () {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    this.eye = this.eye === 'eye' ? 'eye-slash' : 'eye'
  }

  async setCompanyDocs () {
    this.loading = true
    this.companyDocs = this.state.company.documents.filter((doc: any ) => doc.owner_category === 'company' && doc.document_type !== 'acuse_cita_sat' && doc.document_type !== 'commissary_CSF' && doc.document_type !== 'articles_of_incorporation_render').map((doc: any) => {
      if (doc.document_type === 'legal_name_authorization' && doc.is_approved && doc.rejection_category === null) return { type: doc.document_type, name: 'Autorización de denominación', id: doc.id, blob: null, fileType: '', loader: false }
      if (doc.document_type === 'articles_of_incorporation') return { type: doc.document_type, name: 'Acta constitutiva', id: doc.id, blob: null, fileType: '', loader: false }
      if (doc.document_type === 'CSF_company') return { type: doc.document_type, name: 'Constancia de Situación Fiscal', id: doc.id, blob: null, fileType: '', loader: false }
      if (doc.document_type === 'acuse_cita_sat') return { type: doc.document_type, name: 'Acuse de cita SAT', id: doc.id, blob: null, fileType: '', loader: false }
      if (doc.document_type === 'electronic_commercial_folio' || doc.document_type === 'public_commercial_registry') return { type: doc.document_type, name: 'Folio Mercantil Electrónico (FME)', id: doc.id, blob: null, fileType: '', loader: false }
      return { type: doc.document_type, name: doc.document_type, id: doc.id, blob: null, fileType: '', loader: false }
    })
    this.companyDocs = this.companyDocs.filter((doc: any ) => doc.name !== 'legal_name_authorization')
    const [error, response] = await SATService.getFielStatus(this.state.company.id)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    const fiels = response.data.data
    this.loading = false
    if (fiels.certificate) this.companyDocs.push({ type: 'certificate', name: 'Certificado', id: '', blob: null, fileType: '', loader: false })
    if (fiels.private_key) this.companyDocs.push({ type: 'private_key', name: 'Certificado', id: '', blob: null, fileType: '', loader: false })
    // if (fiels.prublic_key) this.companyDocs.push({ type: 'prublic_key', name: 'Certificado', id: '', blob: null, fileType: '', loader: false })
    if (fiels.password !== null && fiels.password !== '') {
      this.password = fiels.password
      this.companyDocs.push({ type: 'password', name: 'Contraseña FIEL', id: '', blob: null, fileType: '', loader: false })
    }
  }

  async download (doc: any) {
    doc.loader = true
    if (doc.type === 'certificate' && doc.blob === null) {
      const [error, response] = await ImageService.getCer(this.state.company.id)
      if (error) {
        doc.loader = false
        return this.$toasted.global.error(error)
      }
      doc.fileType = response.headers['content-type'].split('/')[1]
      doc.blob = new Blob([response.data])
      saveAs(doc.blob, `${doc.name + '.' + doc.fileType}`)
    } else if (doc.type === 'private_key' && doc.blob === null) {
      const [error, response] = await ImageService.getKey(this.state.company.id)
      if (error) {
        doc.loader = false
        return this.$toasted.global.error(error)
      }
      doc.fileType = response.headers['content-type'].split('/')[1]
      doc.blob = new Blob([response.data])
      saveAs(doc.blob, `${doc.name + '.' + doc.fileType}`)
    } else if (doc.blob === null) {
      const [error, response] = await ImageService.getDocument('company', this.state.company.id, doc.type)
      if (error) {
        doc.loader = false
        return this.$toasted.global.error(error)
      }
      doc.fileType = response.headers['content-type'].split('/')[1]
      doc.blob = new Blob([response.data])
      saveAs(doc.blob, `${doc.name + '.' + doc.fileType}`)
    } else saveAs(doc.blob, `${doc.name + '.' + doc.fileType}`)
    doc.loader = false
  }

  copyPassword () {
    navigator.clipboard.writeText(this.password)
    this.$toasted.global.success({ message: 'Se ha copiado la contraseña con éxito' })
  }

  @State('UserModule') state: any
}
