
























// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import HeaderProgressBar from '@/components/HeaderProgressBar.vue'
import Footer from '@/components/Footer.vue'
import ValidateCode from '@/components/account/ValidateCode.vue'

@Component({
  components: { HeaderProgressBar, Footer, ValidateCode }
})
export default class ValidatePhone extends Vue {
  phone = ''
  countryCode = ''

  created () {
    this.phone = this.companyState.phone === '' ?  this.state.phone : this.companyState.phone
    this.countryCode = this.companyState.country.lada === '' ?  this.state.phone_country_code : this.companyState.country.lada
  }

  @State('CompanyModule') companyState: any
  @State('UserModule') state: any
}
