























// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
import EventBus from '@/event-bus'
import moment from 'moment'
// Components
import FinalCards from '@/components/dashboard/FinalCards.vue'
import FinalStepper from '@/components/dashboard/FinalStepper.vue'
import FinalToBeDone from '@/components/dashboard/FinalToBeDone.vue'
import FinalCardStep from '@/components/dashboard/FinalCardStep.vue'
import FinalButtonsStepper from '@/components/dashboard/FinalButtonsStepper.vue'
// Services
import SATService from '@/services/SAT'

@Component({
  components: { FinalCards, FinalStepper, FinalToBeDone, FinalCardStep, FinalButtonsStepper }
})
export default class Final extends Vue {
  points: any = [
    { number: '1', complete: false, active: true, stepper: true, days: 'de 2 a 3 días', point: 'Registro Público', type: 'Register' },
    { number: '1.1', complete: false, active: false, stepper: false, days: 'de 1 a 2 días', point: 'Paquetería', type: 'Delivery' },
    { number: '2', complete: false, active: false, stepper: true, days: 'de 4 a 7 días', point: 'Cita SAT', type: 'SAT' },
    { number: '3', complete: false, active: false, stepper: true, days: '1 día', point: 'RFC y FIEL', type: 'RFC' },
    { number: '4', complete: false, active: false, stepper: true, days: 'de 1 a 5 días', point: 'Cuenta empresarial', type: 'Business account' },
    { number: '5', complete: false, active: false, stepper: true, days: 'de 1 a 2 días', point: 'Contabilidad', type: 'Accounting' }
  ]
  existFiels = false
  renderKeyCardStep = 0
  renderKeyToBeDone = 2

  created () {
    EventBus.$on('update-final', () => {
      this.getFielsStatus()
    })
    this.getFielsStatus()
  }

  async getFielsStatus () {
    // Get fiels status
    const [error, response] = await SATService.getFielStatus(this.state.company.id)
    if (error) return this.$toasted.global.error(error)
    const fiels = response.data.data
    this.existFiels = !fiels.certificate || !fiels.private_key || (fiels.password === null || fiels.password === '')
    this.setPoints()
    this.renderKeyCardStep++
    this.renderKeyToBeDone++
  }

  async setPoints () {
    // reset points
    this.points.map((point: any, index: number) => {
      this.points[index].complete = false
      if (this.points[index].number !== '1') this.points[index].active = false
    })
    // Get validations
    const companyArticlesMock = this.state.company.stages.find((stage: any) => stage.stage_name === 'signing_complete')
    const deliveryStatus = this.state.company.stages.find((stage: any) => stage.stage_name === 'delivery_status').is_completed
    const appointmentSAT = this.state.company.stages.find((stage: any) => stage.stage_name === 'appointment_sat').retries_count > 0
    const appointmentSATPay = this.state.company.stages.find((stage: any) => stage.stage_name === 'appointment_sat').retries_count >= 2
    const errorAct = this.state.company.stages.find((stage: any) => stage.stage_name === 'error_acta').is_completed
    const isProtocolization = moment().valueOf() > (moment(companyArticlesMock.updated_on).valueOf() + 345600000)
    const isRegister = moment().valueOf() > (moment(companyArticlesMock.updated_on).valueOf() + 604800000)
    const isDelivery = moment().valueOf() > (moment(companyArticlesMock.updated_on).valueOf() + 777600000)
    const existDelivery = (this.state.company.shipping_delivery !== null && this.state.company.shipping_delivery !== '')
    const existTestimony = this.state.company.articles_of_incorporation.document_url !== null
    const payAppointment = this.state.company.status_federal_registration.payment
    const requestedAppointment = this.state.company.status_federal_registration.id !== null
    const scheduledAppointment = !requestedAppointment ? false : this.state.company.status_federal_registration.is_scheduled
    const appointmentCompleted = !scheduledAppointment ? false : (moment().valueOf() > (moment(this.state.company.status_federal_registration.date).valueOf() + 7200000))
    const rfcCompleted = (this.state.company.rfc !== null && this.state.company.rfc !== '') || this.state.company.rfc_later
    const representation = this.state.company.legal_representation
    // Set current point
    this.points.map((point: any, index: number) => {
      if (point.type === 'Protocolization' && (isProtocolization || existTestimony || existDelivery || (scheduledAppointment && !representation) || appointmentSAT || rfcCompleted)) {
        this.points[index].complete = true
        this.points[index + 1].active = true
      } else if (point.type === 'Register' && (errorAct === null || errorAct === false) && ((isRegister && existDelivery) || (isRegister && representation) || existTestimony || existDelivery || (scheduledAppointment && !representation) || appointmentSAT || rfcCompleted)) {
        this.points[index].complete = true
        this.points[index + 1].active = true
      } else if (point.type === 'Delivery' && (errorAct === null || errorAct === false) && (deliveryStatus || ((((((existDelivery && isDelivery) || existTestimony) && requestedAppointment) || scheduledAppointment) && !representation) || appointmentSAT || rfcCompleted || ((isDelivery || scheduledAppointment) && representation)))) {
        this.points[index].complete = true
        this.points[index + 1].active = true
      } else if (point.type === 'SAT' && (errorAct === null || errorAct === false) && ((appointmentCompleted && !representation) || (scheduledAppointment && representation) || (appointmentCompleted && appointmentSAT) || (!appointmentCompleted && appointmentSATPay && !payAppointment) || (rfcCompleted && !this.state.company.appointment_type_efirma) || !this.existFiels)) {
        this.points[index].complete = true
        this.points[index + 1].active = true
      } else if (point.type === 'RFC' && (errorAct === null || errorAct === false) && ((rfcCompleted && !this.state.company.appointment_type_efirma) || (appointmentCompleted && representation) || !this.existFiels)) {
        this.points[index].complete = true
        this.points[index + 1].active = true
      } else if (point.type === 'Business account'  && (errorAct === null || errorAct === false) && (this.state.company.flag_data_banking_service || (this.state.company.not_interested_banks && !representation)) && rfcCompleted && !this.existFiels) {
        this.points[index].complete = true
        this.points[index + 1].active = true
      }
    })
    this.renderKeyToBeDone++
  }

  @State('UserModule') state: any;
}
