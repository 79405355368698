


















// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Main extends Vue {
  // Select how many bars are active
  @Prop() progress!: number
  // Number of bars
  @Prop() bars!: number
}
