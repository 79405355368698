// Utils
import { RouteConfig } from 'vue-router'
import multiguard from 'vue-router-multiguard'
// Views
import Index from '@/views/dashboard/Index.vue'
import Account from '@/views/dashboard/Account.vue'
import Accounting from '@/views/dashboard/Accounting.vue'
import Bank from '@/views/dashboard/Bank.vue'
import Dashboard from '@/views/dashboard/Dashboard.vue'
import Companies from '@/views/dashboard/Companies.vue'
import Detail from '@/views/dashboard/Detail.vue'
import Documents from '@/views/dashboard/Documents.vue'
import Benefits from '@/views/dashboard/Benefits.vue'
import PaymentInfo from '@/views/dashboard/PaymentInfo.vue'
import PaymentInfoRNIE from '@/views/dashboard/PaymentInfoRNIE.vue'
import CheckoutInfo from '@/views/dashboard/CheckoutInfo.vue'
import ValidationInfo from '@/views/dashboard/ValidationInfo.vue'
import ValidationComplete from '@/views/dashboard/ValidationComplete.vue'
import PreparingMinutes from '@/views/dashboard/PreparingMinutes.vue'
import RevisionStatutes from '@/views/dashboard/RevisionStatutes.vue'
import PaymentRepresentationInfo from '@/views/dashboard/PaymentRepresentationInfo.vue'
import CompleteSigning from '@/views/dashboard/CompleteSigning.vue'
import ReviewStatutes from "@/views/projectStatutes/ReviewStatutes.vue"
// Middlewares
import Auth from '@/middlewares/Auth'
import User from '@/middlewares/User'

const routes: Array<RouteConfig> = [
  {
    path: '/dashboard/:company_id',
    component: Index,
    beforeEnter: multiguard([Auth.isLogged, User.validateData]),
    children: [
      {
        path: 'home',
        name: 'Dashboard',
        component: Dashboard,
        beforeEnter: User.dashboardValidateData
      },
      {
        path: 'accounting',
        name: 'Accounting',
        component: Accounting,
        beforeEnter: User.validateAccounting
      },
      {
        path: 'detail',
        name: 'Detail',
        component: Detail
      },
      {
        path: 'documents',
        name: 'Documents',
        component: Documents
      },
      {
        path: 'benefits',
        name: 'Benefits',
        component: Benefits
      },
      {
        path: 'bank',
        name: 'Bank',
        component: Bank
      },
      {
        path: 'appointment_review_statutes',
        name: 'ReviewStatutes',
        component: ReviewStatutes
      },
      {
        path: 'payment_info',
        name: 'PaymentInfo',
        component: PaymentInfo
      },
      {
        path: 'payment_info_rnie',
        name: 'PaymentInfoRNIE',
        component: PaymentInfoRNIE
      },
      {
        path: 'checkout_info',
        name: 'CheckoutInfo',
        component: CheckoutInfo
      },
      {
        path: 'validation_info',
        name: 'ValidationInfo',
        component: ValidationInfo
      },
      {
        path: 'validation_complete',
        name: 'ValidationComplete',
        component: ValidationComplete
      },
      {
        path: 'preparing_minutes',
        name: 'PreparingMinutes',
        component: PreparingMinutes
      },
      {
        path: 'revisions_statutes',
        name: 'RevisionStatutes',
        component: RevisionStatutes
      },
      {
        path: 'payment_representation_info',
        name: 'PaymentRepresentationInfo',
        component: PaymentRepresentationInfo
      },
      {
        path: 'complete_signing',
        name: 'CompleteSigning',
        component: CompleteSigning
      }
    ]
  },
  {
    path: '',
    component: Index,
    beforeEnter: multiguard([Auth.isLogged, User.validateData]),
    children: [
      {
        path: 'account',
        name: 'Account',
        component: Account
      },
      {
        path: 'companies',
        name: 'Companies',
        component: Companies
      }
    ]
  }
]

export default routes
