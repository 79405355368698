import to from 'await-to-js'
import axios from '@/utils/axios-api'
import UploadImage from '@/interfaces/UploadImage'

const URL_PHP = process.env.VUE_APP_BASE_URL_PHP

export default class Image {
  static async upload (data: UploadImage) {
    const formData = new FormData()
    formData.append('entity_type', data.entity_type)
    formData.append('identity', data.identity)
    if (data.company_id) formData.append('company_id', data.company_id)
    formData.append('document_type', data.document_type)
    formData.append('image', data.image)
    return await to(axios.post(`${URL_PHP}/v2/partner-files`, formData)) as any
  }

  static async uploadCompanyDoc (data: UploadImage) {
    const formData = new FormData()
    formData.append('entity_type', data.entity_type)
    formData.append('identity', data.identity)
    if (data.company_id) formData.append('company_id', data.company_id)
    formData.append('document_type', data.document_type)
    formData.append('image', data.image)
    return await to(axios.post(`${URL_PHP}/v2/upload-cif-company`, formData)) as any
  }

  static async getDocument (entity_type: string, identity: string, document_type: string) {
    return await to(axios.get(`${URL_PHP}/v1/image`, { params: { entity_type, identity, document_type }, responseType: 'arraybuffer' })) as any
  }

  static async getDocumentAccountStatements (company: string, document_id: string) {
    return await to(axios.get(`${URL_PHP}/v2/company/${company}/download-account-status/${document_id}`, { responseType: 'arraybuffer' })) as any
  }

  static async getKey (company: string) {
    return await to(axios.get(`${URL_PHP}/v2/get-credentials-key-company/${company}`)) as any
  }

  static async getCer (company: string) {
    return await to(axios.get(`${URL_PHP}/v2/get-credentials-cer-company/${company}`)) as any
  }
}
