






















































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
// Types
import UserTypes from '@/store/types/UserTypes'
// Services
import MatiService from '@/services/Mati'

@Component({
  components: { Footer, Loader }
})
export default class Verification extends Vue {
  loading = false
  mati = ''
  client = ''
  flow = ''
  matiCheck = true
  partner: any = {}
  cont = 0
  nextView = 'isPartnerCivilStatus'

  // Event MATI
  // eslint-disable-next-line
  load (detail: EventListenerOrEventListenerObject) {
    return
  }

  // Event MATI
  // eslint-disable-next-line
  fin (detail: EventListenerOrEventListenerObject) {
    this.matiNext()
  }

  // Event MATI
  // eslint-disable-next-line
  exit (detail: EventListenerOrEventListenerObject) {
    return
  }

  // Events MATI
  eventListeners () {
    return {
      '&mati:loaded': this.load, // & = passive
      '&mati:userFinishedSdk': this.fin, // & = passive
      '&mati:exitedSdk': this.exit // & = passive
    }
  }
  
  mounted () {
    this.state.company.partners.map((partner: any) => {
      if (this.state.email === partner.email) this.partner = partner
    })
    this.client = process.env.VUE_APP_MATI_CLIENT_ID
    let foreign = true
    if (this.partner.nationalities !== null && this.partner.nationalities.length) this.partner.nationalities.map((nationality: any) => { if (nationality.name === 'México' || nationality.issue_country === 'MX') foreign = false })
    this.flow = (!foreign && (this.partner.rfc === null || this.partner.rfc === 'EXTF900101NI1')) ?
      process.env.VUE_APP_MATI_FLOW_ID_PARTNER_WITHOUT_RFC : !foreign ?
      process.env.VUE_APP_MATI_FLOW_ID_PARTNER : (this.partner.rfc === null || this.partner.rfc === 'EXTF900101NI1') ?
      process.env.VUE_APP_MATI_FLOW_ID_PARTNER_FOREIGN_RFC : process.env.VUE_APP_MATI_FLOW_ID_PARTNER_FOREIGN
    this.mati = '{"partner_id":"' + this.partner.id +
      '","company_id": "' + this.state.company.id +
      '","check": "false"}'
    this.checkPartner()
  }

  checkPartner () {
    //Exist documents refused mati
    const documentsError = this.partner.documents.filter((document: any) => !document.is_approved && document.rejection_category)
    const passport = documentsError.filter((document: any) => document.document_type === 'passport')
    const national_identity = documentsError.filter((document: any) => document.document_type === 'national_identity')
    if (passport.length > 0 || national_identity.length > 0 || this.partner.mati_verification !== true) this.matiCheck = false
    //Mati status check
    if (this.partner.mati_verification !== null && this.partner.mati_status && this.partner.mati_status.length && (this.partner.mati_status[0].status === 'Reject' || this.partner.mati_status[0].status === 'Denied')) this.matiCheck = false
    if (this.matiCheck === false && this.partner.mati_status && this.partner.mati_status.length && (this.partner.mati_status[0].status !== 'Reject' && this.partner.mati_status[0].status !== 'Denied')) this.matiCheck = true
    // Marriage docs
    const civilStatus = this.partner.civil_status !== null && this.partner.civil_status !== ''
    const marriage = civilStatus && this.partner.civil_status === 'casado'
    const uploadMarriage = marriage &&
      this.partner.id_spouse.document_url !== null && this.partner.id_spouse_back.document_url !== null && this.partner.marriage_certificate.document_url !== null &&
      this.partner.id_spouse.rejection_category === null && this.partner.id_spouse_back.rejection_category === null && this.partner.marriage_certificate.rejection_category === null
    this.nextView = (!civilStatus || (marriage && !uploadMarriage)) ? 'isPartnerCivilStatus' : 'Dashboard'
  }

  async matiNext () {
    this.loading = true
    await MatiService.setMatiCheck(this.partner.id)
    await this.updateCompany(this.state.company.id)
    return this.$router.push({ name: this.nextView, query: this.$route.query, params: { company_id: this.state.company.id } }).catch(() => {return})
  }

  async next () {
    return this.$router.push({ name: this.nextView, query: this.$route.query, params: { company_id: this.state.company.id } }).catch(() => {return})
  }
  
  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
