










































































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
// Types
import UserTypes from '@/store/types/UserTypes'
// Components
import ModalRnieQuit from '@/components/payment/ModalRnieQuit.vue'
import ModalCommissaryTallyQuit from '@/components/payment/ModalCommissaryTallyQuit.vue'

@Component({
  components: { ModalRnieQuit, ModalCommissaryTallyQuit }
})
export default class CardCheckoutItems extends Vue {
  numbers = [1, 2, 3, 4, 5]
  numbersCopy = 1
  checkoutComplete = false
  modal: any = {
    showModalCommissaryTallyQuit: false,
    showModalRnieQuit: false,
    update: false,
    updateRnie: false
  }
  pay: any = {
    items: [{
      id: '',
      name: '',
      price: '',
      price_id: ''
    }],
    selected: [{
      id: '',
      item: '',
      cost: '',
      quantity: 0,
      price_id: ''
    }],
    update: false
  }
  items: any = {
    advanceAct: {
      id: '',
      name: 'Anticipo Acta Constitutiva',
      price: '',
      price_id: '',
      check: true,
      points: []
    },
    constitutiveAct: {
      id: '',
      name: 'Acta Constitutiva',
      price: '',
      price_id: '',
      check: true,
      points: [
        'Solicitud de autorización de nombre legal',
        'Redacción de documento constitutivo',
        'Tramitamos ante la Notaría tu proceso',
        'Acreditamos tu empresa ante el Registro Público',
        'Hacemos tu Pago de derechos',
        'Generamos tu Primer testimonio',
        'Te conseguimos la cita para Alta en el SAT',
        'Te ayudamos a obtener una cuenta empresarial'
      ]
    },
    commissaryService: {
      id: '',
      name: 'Servicio de Comisario',
      price: '',
      price_id: '',
      check: false,
      points: [
        'Contabilidad',
        'Declaraciones Fiscales',
        'Acta de Asamblea Anual',
        'Te asignamos un comisario',
        'Te conectamos con el SAT'
      ]
    },
    accountantService: {
      id: '',
      name: 'Lleva tu contabilidad',
      price: '',
      price_id: '',
      check: false,
      points: [
        'Contabilidad',
        'Cálculo de impuestos',
        'Declaraciones Fiscales',
        'Asesoría Fiscal y Contable',
        'Te asignamos un contador',
        'Creamos un grupo de whatsapp',
        'Te conectamos con el SAT',
      ]
    },
    legalRepresentation: {
      id: '',
      name: 'Representación Legal',
      price: '',
      price_id: '',
      check: true,
      points: [
        'La Constitución de tu empresa',
        'La obtención del RFC y la firma FIEL ',
        'Domicilio Fiscal',
        'Representación ante el SAT',
        'Comisario (por ley, se debe agregar)',
        'Contabilidad y facturación',
        'Cuenta de Banco',
        'Acta de Asamblea Anual'
      ]
    },
    certifiedCopy: {
      id: '',
      name: 'Copia Certificada',
      price: '',
      price_id: '',
      check: false,
      points: [
        'Te sirve para tener un respaldo',
        'La puedes usar para tramites administrativos',
        'Se la puedes dar a uno de los socios'
      ]
    },
    tradeMark: {
      id: '',
      name: 'Registro de marca',
      price: '',
      price_id: '',
      check: false,
      points: [
        'Protege tu marca y tu logo.',
        'Te acompañamos hasta que nos entreguen el título de tu marca.',
        'Incluye: análisis de viabilidad y pago de derechos IMPI ($2,814)'
      ]
    },
    holdingShares: {
      id: '',
      name: 'Transmisión de Acciones Holding',
      price: '',
      price_id: '',
      check: false,
      points: [
        'Resolución de asamblea para transmitir acciones a Holding Company',
        'Protocolización del documento'
      ]
    },
    rnie: {
      id: '',
      name: 'RNIE',
      price: '',
      price_id: '',
      check: false,
      points: [
        'Solicitud de inscripción.',
        'Desahogo de requerimientos.',
        'Seguimiento y obtención de registro de inscripción.'
      ]
    }
  }

  @Watch('numbersCopy')
  updateCopys () {
    if (this.pay.selected.findIndex((item: any) => item.item === 'Copia Certificada') === -1) return
    this.pay.selected[this.pay.selected.findIndex((item: any) => item.item === 'Copia Certificada')].quantity = this.numbersCopy
    this.pay.update = true
  }

  @Watch('modal.update')
  async update () {
    if(!this.modal.update) return
    this.modal.update = false
    this.updatedItem(this.items.commissaryService, 1)
  }

  @Watch('modal.updateRnie')
  async updateRnie () {
    if(!this.modal.updateRnie) return
    this.modal.updateRnie = false
    this.updatedItem(this.items.rnie, 1)
  }

  mounted () {
    this.pay = this.$attrs.value
    let foreign = false
    this.state.company.partners.map((partner: any) => {
      let national = false
      partner.nationalities.map((nationality: any) => { if (nationality.name === 'México' || nationality.issue_country === 'MX') national = true })
      if (partner.nationalities.length && !national) foreign = true
    })
    this.checkoutComplete = this.state.company.orders.filter((order: any) => order.status === 'complete' && order.items.filter((item: any) => item.name === 'Complemento Acta Constitutiva' || item.name === 'Acta Constitutiva').length > 0).length > 0
    const commissary = this.state.company.council.filter((member: any) => member.council_role === 'commissary')
    this.pay.items.map((item: any) => {
      if (!this.state.company.legal_representation && item.name === 'Anticipo Acta Constitutiva') {
        this.items.advanceAct.id = item.id
        this.items.advanceAct.price = item.price
        this.items.advanceAct.price_id = item.price_id
      } else if (!this.state.company.legal_representation && item.name === 'Acta Constitutiva') {
        this.items.constitutiveAct.id = item.id
        this.items.constitutiveAct.price = item.price
        this.items.constitutiveAct.price_id = item.price_id
      } else if (!this.state.company.legal_representation && item.name === 'Copia Certificada') {
        this.items.certifiedCopy.id = item.id
        this.items.certifiedCopy.price = item.price
        this.items.certifiedCopy.price_id = item.price_id
      } else if (!this.state.company.legal_representation && item.name === 'Registro de marca') {
        this.items.tradeMark.id = item.id
        this.items.tradeMark.price = item.price
        this.items.tradeMark.price_id = item.price_id
      } else if (!this.state.company.legal_representation && item.name === 'Servicio de Comisario') {
        this.items.commissaryService.id = item.id
        this.items.commissaryService.price = item.price
        this.items.commissaryService.price_id = item.price_id
        const addService = this.checkoutComplete ?
          true : (commissary.length > 0 && commissary[0].other_faculty === 'Tally')
        const commissaryService = this.pay.selected.filter((item: any ) => item.id === this.items.commissaryService.id)
        if (addService && (commissaryService.length === 0)) this.updatedItem(this.items.commissaryService, 1)
        else if (commissaryService.length) this.items.commissaryService.check = true
      } else if (!this.state.company.legal_representation && item.name === 'Lleva tu contabilidad') {
        this.items.accountantService.id = item.id
        this.items.accountantService.price = item.price
        this.items.accountantService.price_id = item.price_id
        this.items.accountantService.check = true
      } else if (!this.state.company.legal_representation && foreign && item.name === 'RNIE') {
        this.items.rnie.id = item.id
        this.items.rnie.price = item.price
        this.items.rnie.price_id = item.price_id
        if (this.$route.query.rnie !== undefined && this.$route.query.rnie) this.updatedItem(this.items.rnie, 1)
      }
    })
  }

  updatedItem (item: any, quantity: number) {
    let exist = -1
    if (item.name === 'Copia Certificada') this.numbersCopy = quantity
    this.pay.selected.map((select: any, index: number) => {
      if (select.item === item.name) exist = index
    })
    item.check = !item.check
    const newItem = { id: item.id, item: item.name, cost: item.price, quantity: quantity, price_id: item.price_id }
    return exist !== -1 ? this.pay.selected.splice(exist, 1) : this.pay.selected.push(newItem)
  }

  isTally (item: any, quantity: number) {
    const commissary = this.state.company.council.filter((member: any) => member.council_role === 'commissary')
    if (commissary.length > 0 && commissary[0].other_faculty === 'Tally') return this.modal.showModalCommissaryTallyQuit = true
    return this.updatedItem(item, quantity)
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
