
















































































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
import ReturnAddress from '@/components/Return.vue'
import AddNewLegalNames from '@/components/company/AddNewLegalNames.vue'
// Services
import CompanyService from '@/services/Company'

@Component({
  components: { Footer, Loader, ReturnAddress, AddNewLegalNames }
})
export default class RefuseLegalName extends Vue {
  loading = false
  newLegalNames: any = {
    namesMatch: false,
    checkLegalNames: false,
    existLegalNames: false,
    repeatLegalNames: false,
    legalNames: [
      { name: '', equal: false, repeat: false, minLength: true, exist: false, id: 1 },
      { name: '', equal: false, repeat: false, minLength: true, exist: false, id: 2 },
      { name: '', equal: false, repeat: false, minLength: true, exist: false, id: 3 }
    ]
  }

  get allOptionFill () {
    return this.newLegalNames.legalNames.findIndex((legalName: any) => legalName.name === '') !== -1
  }

  async add () {
    this.loading = true
    const [error] = await CompanyService.updateLegalNames(this.state.company.id, [this.newLegalNames.legalNames[0].name.trimEnd(), this.newLegalNames.legalNames[1].name.trimEnd(), this.newLegalNames.legalNames[2].name.trimEnd()], false)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.$toasted.global.success({ message: 'Se actualizaron las denominaciones' })
    return this.$router.push({ name: 'Dashboard', query: this.$route.query, params: { company_id: this.state.company.id } })
  }

  @State('UserModule') state: any;
}
