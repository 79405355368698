













































































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import HeaderProgressBar from '@/components/HeaderProgressBar.vue'
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
// Types
import UserTypes from '@/store/types/UserTypes'
// Services
import CompanyService from '@/services/Company'
import ConstitutiveService from '@/services/Constitutive'

@Component({
  components: {
    HeaderProgressBar, Footer, Loader
  }
})
export default class Beginning extends Vue {
  loading = false
  legal_name = ''
  validName = true

  reset () {
    this.legal_name = ''
    this.validName = true
  }

  filterLegalName (value: string) { return value.replace(/[^a-zA-Z0-9ÑñÁáÉéÍíÓóÚú ]/g, '').trimStart() }

  async validate () {
    this.loading = true
    // Validate legal name
    const [error, response] = await ConstitutiveService.validateLegalName(this.legal_name)
    if (error) {
      this.validName = false
      this.loading = false
      return this.$toasted.global.error(error)
    }
    if (!response.data.data) {
      this.loading = false
      return this.validName = false
    }
    if (this.validName) await this.save()
  }

  async save () {
    this.loading = true
    const [error] = await CompanyService.store({
      company_name: this.legal_name,
      legal_names: [this.legal_name],
      company_type: 'sapi',
      legal_representation: false,
      name_authorization: false
    })
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    await this.updateCompany(this.state.company.id)
    return this.$router.push({ name: 'BeginningBeforePayment', query: this.$route.query, params: { company_id: this.state.company.id } })
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
