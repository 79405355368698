// Utils
import { RouteConfig } from 'vue-router'
// Views
import Index from '@/views/auth/Index.vue'
import RecoverPassword from '@/views/auth/RecoverPassword.vue'
import NewPassword from '@/views/auth/NewPassword.vue'
// Middlewares
import Signup from '@/middlewares/Signup'

const routes: Array<RouteConfig> = [
  {
    path: '/auth',
    component: Index,
    beforeEnter: Signup.deleteChat,
    children: [
      {
        path: 'recover_password',
        name: 'RecoverPassword',
        component: RecoverPassword
      },
      {
        path: 'new_password',
        name: 'NewPassword',
        component: NewPassword
      }
    ]
  }
]

export default routes
