
























// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'

@Component({
  components: { Loader }
})
export default class ModalCommissaryTallyQuit extends Vue {
  modal: any = {
    showModalCommissaryTallyQuit: false,
    update: false,
  }

  mounted () {
    this.modal = this.$attrs.value
  }

  @State('UserModule') state: any
}
