




































































































































































































































































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import ModalRefuseMati from '@/components/company/ModalRefuseMati.vue'
import ModalRefuseLegalName from '@/components/company/ModalRefuseLegalName.vue'
import ModalUploadLegalName from '@/components/company/ModalUploadLegalName.vue'
import ModalRefusePartnerDocument from '@/components/company/ModalRefusePartnerDocument.vue'
import ModalRefuseCommissaryDocument from '@/components/company/ModalRefuseCommissaryDocument.vue'
// Services
import AdministrationService from '@/services/Administration'

@Component({
  components: { ModalRefuseMati, ModalRefuseLegalName, ModalUploadLegalName, ModalRefusePartnerDocument, ModalRefuseCommissaryDocument }
})
export default class TableValidations extends Vue {
  loading = true
  legalNameUpload = false
  url = ''
  commissary = {name: 'Sin comisario', id: ''}
  commissaryPartner = false
  statusCommissary: any = ''
  renderTable = 0
  renderKeyRefuseMati = 0
  modal: any = {
    showModalRefuseMati: false,
    showModalRefuseLegalName: false,
    showModalUploadLegalName: false,
    showModalRefusePartnerDocument: false,
    showModalRefuseCommissaryDocument: false,
    update: false,
    partnerId: '',
    docType: '',
    reject: ''
  }

  get legalNameStatus () {
    const denominationsRejects = this.state.company.legal_names.filter((legal_name: any) => legal_name.is_approved === false && legal_name.rejection_category !== null && legal_name.rejection_category !== '')
    const denominationsApproved = this.state.company.legal_names.filter((legal_name: any) => legal_name.is_approved === true)
    if (denominationsApproved.length > 0) return 'Complete'
    else if (denominationsRejects.length === this.state.company.legal_names.length) return 'Reject'
    return 'Review'
  }

  @Watch('modal.update')
  async update () {
    if(!this.modal.update) return
    this.renderTable++
    this.modal.update = false
  }

  created () {
    this.legalNameUpload = (this.state.company.legal_names.length === 1) && this.state.company.name_authorization
    this.url = process.env.VUE_APP_URL
    this.existCommissary()
  }

  existCommissary () {
    this.state.company.council.map((member: any) => {
      if (member.council_role === 'commissary') {
        this.commissary.id = member.id
        this.commissary.name = this.state.company.commissary
        this.commissaryPartner = member.other_faculty === 'Partner'
      }
    })
    if (!this.commissaryPartner) this.getCommissary()
  }

  async getCommissary () {
    const [error, response] = await AdministrationService.getCommissaryStatus(this.state.company.id)
    if (error) {
      this.loading = false
      this.statusCommissary = 'Pending'
      return this.$toasted.global.error(error)
    }
    this.loading = false
    this.statusCommissary = response.data === '' ? 'Pending' : response.data
  }

  getDocumentStatus (doc: Array<any>) {
    return !doc.length ? 'Pending' : doc[0].is_approved ? 'Complete' : (!doc[0].is_approved && doc[0].rejection_category) ? 'Refuse' : 'Validating'
  }

  getPartnersDocumentsStatus (partner: any) {
    // Get all documents
    const document_passport = partner.documents.filter((document: any) => document.document_type === 'passport')
    const document_national_identity = partner.documents.filter((document: any) => document.document_type === 'national_identity')
    const document_national_identity_back = partner.documents.filter((document: any) => document.document_type === 'national_identity_back')
    const document_resident_identity = partner.documents.filter((document: any) => document.document_type === 'resident_id')
    const document_resident_identity_back = partner.documents.filter((document: any) => document.document_type === 'resident_id_back')
    const document_CFI = partner.documents.filter((document: any) => document.document_type === 'CFI')
    const document_address_identity = partner.documents.filter((document: any) => document.document_type === 'address')
    const document_id_spouse = partner.documents.filter((document: any) => document.document_type === 'id_spouse')
    const document_id_spouse_back = partner.documents.filter((document: any) => document.document_type === 'id_spouse_back')
    const document_marriage_certificate = partner.documents.filter((document: any) => document.document_type === 'marriage_certificate')
    // Validate documents status
    const status_passport = this.getDocumentStatus(document_passport)
    const status_national_identity = this.getDocumentStatus(document_national_identity)
    const status_national_identity_back = this.getDocumentStatus(document_national_identity_back)
    const status_resident_identity = this.getDocumentStatus(document_resident_identity)
    const status_resident_identity_back = this.getDocumentStatus(document_resident_identity_back)
    const status_CFI = this.getDocumentStatus(document_CFI)
    const status_address_identity = this.getDocumentStatus(document_address_identity)
    const status_id_spouse = this.getDocumentStatus(document_id_spouse)
    const status_id_spouse_back = this.getDocumentStatus(document_id_spouse_back)
    const status_marriage_certificate = this.getDocumentStatus(document_marriage_certificate)
    // Validate Partner nationality and rfc
    let isNational = false
    if (partner.nationalities !== null && partner.nationalities.length) partner.nationalities.map((nationality: any) => { if (nationality.name === 'México' || nationality.issue_country === 'MX') isNational = true })
    else isNational = true
    const RFC = (partner.rfc !== null && partner.rfc !== '' && partner.rfc !== 'EXTF900101NI1')
    // Format partner status
    let partnerStatus = []
    if (status_passport !== 'Pending' || (!isNational && RFC)) partnerStatus.push({name: 'passport', status: status_passport, rejection_category: document_passport.length ? document_passport[0].rejection_category : ''})
    if ((isNational || (!isNational && !RFC)) && (status_passport === 'Pending' || (status_national_identity !== 'Pending' && status_national_identity !== 'Refuse'))) {
      partnerStatus.push({
        name: 'national_identity',
        status: status_national_identity === 'Pending' && partner.mati_verification !== null ? 'Validating' : status_national_identity,
        rejection_category: document_national_identity.length ? document_national_identity[0].rejection_category : ''
      })
    }
    else if ((!isNational && RFC) && (status_passport === 'Pending' || (status_resident_identity !== 'Pending'))) {
      partnerStatus.push({
        name: 'residence_card',
        status: status_resident_identity === 'Pending' && partner.mati_verification !== null ? 'Validating' : status_resident_identity,
        rejection_category: document_resident_identity.length ? document_resident_identity[0].rejection_category : ''
      })
    }
    // if (partnerStatus.length >= 2) partnerStatus = partnerStatus.filter((document: any) => document.status !== 'Refuse')
    if (partnerStatus.length >= 2) partnerStatus = partnerStatus.filter((document: any) => document.status !== 'Pending')
    if (partnerStatus.length >= 2) partnerStatus = partnerStatus.filter((document: any) => document.name !== 'passport')
    if (status_national_identity !== 'Pending' && status_national_identity !== 'Refuse') partnerStatus.push({name: 'national_identity_back', status: status_national_identity_back, rejection_category: document_national_identity_back.length ? document_national_identity_back[0].rejection_category : ''})
    if (status_resident_identity !== 'Pending' && status_resident_identity !== 'Refuse') partnerStatus.push({name: 'residence_card_back', status: status_resident_identity_back, rejection_category: document_resident_identity_back.length ? document_resident_identity_back[0].rejection_category : ''})
    partnerStatus.push({name: 'CFI', status: status_CFI, rejection_category: document_CFI.length ? document_CFI[0].rejection_category : ''})
    if (!isNational && (!partner.rfc || partner.rfc === 'EXTF900101NI1')) partnerStatus.push({name: 'address', status: status_address_identity, rejection_category: document_address_identity.length ? document_address_identity[0].rejection_category : ''})
    if (partner.civil_status === 'casado') partnerStatus.push({name: 'id_spouse', status: status_id_spouse, rejection_category: document_id_spouse.length ? document_id_spouse[0].rejection_category : ''})
    if (partner.civil_status === 'casado' && document_id_spouse_back.length) partnerStatus.push({name: 'id_spouse_back', status: status_id_spouse_back, rejection_category: document_id_spouse_back.length ? document_id_spouse_back[0].rejection_category : ''})
    if (partner.civil_status === 'casado') partnerStatus.push({name: 'marriage_certificate', status: status_marriage_certificate, rejection_category: document_marriage_certificate.length ? document_marriage_certificate[0].rejection_category : ''})
    // Return partner status
    return partnerStatus
  }

  copyLink (partner: any) {
    navigator.clipboard.writeText(this.url + "/invited?partner=" + partner.id + "&company=" + this.state.company.id)
    this.$toasted.global.success({ message: 'Se ha copiado el link con éxito' })
  }

  copyLinkCommissary () {
    navigator.clipboard.writeText(this.url + "/commissary?company=" + this.state.company.id)
    this.$toasted.global.success({ message: 'Se ha copiado el link con éxito' })
  }

  isPartner (email: string) {
    return email.toLowerCase() === this.state.email.toLowerCase()
  }

  @State('UserModule') state: any
}
