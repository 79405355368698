





















// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Types
import UserTypes from '@/store/types/UserTypes'
// Components
import Loader from '@/components/Loader.vue'
import Activities from '@/components/beginning/CompanyActivitiesModal.vue'
// Services
import CompanyService from '@/services/Company'

@Component({
  components: { Loader, Activities }
})
export default class ModalEditCompanyActivities extends Vue {
  modal: any = {
    showModalCompanyActivities: false,
    update: false,
  }
  loading = false
  title = ''
  activities: any = []
  companyActivity: any = { selectActivities: [] }

  created () {
    if (this.state.company.business_twists_tmp !== null && this.state.company.business_twists_tmp.length > 0) this.companyActivity.selectActivities = this.state.company.business_twists_tmp.map((activity: any) => { return {business_twists_id: activity.id, new_category: activity.name} })
    this.getActivities()
  }

  mounted () {
    this.modal = this.$attrs.value
  }

  noSpacesStart (value: string) {
    return value.trimStart()
  }

  async getActivities () {
    const [error, response] = await CompanyService.getActivities()
    if (error) return this.$toasted.global.error(error)
    this.activities = response.data
  }

  async updateCompanyActivities () {
    this.loading = true
    const [error] = await CompanyService.setActivities(this.state.company.id, this.companyActivity.selectActivities)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    await this.updateCompany(this.state.company.id)
    this.modal.showModalCompanyActivities = false
    this.modal.update = true
    this.loading = false
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
