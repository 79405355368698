













// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
import { saveAs } from 'file-saver'
import moment from 'moment'
// Components
import ModalDeliveryDHL from '@/components/dashboard/modals/ModalDeliveryDHL.vue'
// Services
import ImageService from '@/services/Image'

@Component({
  components: { ModalDeliveryDHL }
})
export default class FinalButtonsStepper extends Vue {
  buttonsAux: any = []
  buttons = [{
      name: 'Acta constitutiva',
      type: 'articles_of_incorporation',
      text: 'Ya puedes descargar tu acta',
      loading: false,
      blob: null,
      fileType: '',
      icon: require('@/assets/icons/minutes.svg')
    },/*{
      name: 'Acuse de cita SAT',
      type: 'acuse_cita_sat',
      text: 'Descargar acuse de cita',
      loading: false,
      blob: null,
      fileType: '',
      icon: require('@/assets/icons/minutes.svg')
    },*/{
      name: 'Delivery',
      type: 'delivery',
      text: 'Rastrear paquetería',
      loading: false,
      blob: null,
      fileType: '',
      icon: require('@/assets/icons/parcel.svg')
    }
  ]
  modal: any = {
    showModalDeliveryDHL: false,
    update: false
  }
  loading = false
  existDelivery = false
  scheduledAppointment = false

  created () {
    const existTestimony = this.state.company.articles_of_incorporation.document_url !== null
    const existAcknowledge = this.state.company.acuse_cita_sat.document_url !== null
    this.existDelivery = (this.state.company.shipping_delivery !== null && this.state.company.shipping_delivery !== '')
    const requestedAppointment = this.state.company.status_federal_registration.id !== null
    this.scheduledAppointment = !requestedAppointment ? false : this.state.company.status_federal_registration.is_scheduled
    const appointmentCompleted = !this.scheduledAppointment ? false : (moment().valueOf() > (moment(this.state.company.status_federal_registration.date).valueOf() + 7200000))
    this.buttons.map((button: any, index: number) => {
      if (button.type === 'articles_of_incorporation' && existTestimony) {
        if (!this.scheduledAppointment) this.buttons[index].text = 'Descarga tu acta'
        this.buttonsAux.push(button)
      }
      else if (button.type === 'acuse_cita_sat' && existAcknowledge && this.scheduledAppointment && !appointmentCompleted) this.buttonsAux.push(button)
      else if (button.type === 'delivery' && !this.scheduledAppointment && this.existDelivery) this.buttonsAux.push(button)
    })
  }

  async download (doc: any) {
    if (doc.type === 'delivery') return this.modal.showModalDeliveryDHL = true
    doc.loading = true
    if (doc.blob === null) {
      const [error, response] = await ImageService.getDocument('company', this.state.company.id, doc.type)
      if (error) {
        doc.loading = false
        return this.$toasted.global.error(error)
      }
      doc.fileType = response.headers['content-type'].split('/')[1]
      doc.blob = new Blob([response.data])
      saveAs(doc.blob, `${doc.name + '.' + doc.fileType}`)
    } else saveAs(doc.blob, `${doc.name +  '.' + doc.fileType}`)
    doc.loading = false
    return this.$toasted.global.success({ message: 'Descarga exitosa' })
  }
  
  @State('UserModule') state: any
}
