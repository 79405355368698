
















// Utils
import { Component, Vue } from 'vue-property-decorator'

@Component({
})
export default class TooltipAddress extends Vue {
  points = [
    'Si tienes la Constancia de Situación Fiscal Mexicana actualizada, este documento no es necesario.',
    'Debe tener una antigüedad máxima de 2 meses desde su fecha de emisión para que sea válido.',
    'Este documento puede ser un recibo de teléfono fijo, luz o estado de cuenta bancario.',
    'Es importante que contenga todas las páginas y sin tachaduras, enmendaduras o haber borrado datos personales.'
  ]
}
