import to from 'await-to-js'
import axios from '@/utils/axios-api'

const URL_PHP = process.env.VUE_APP_BASE_URL_PHP

export default class Commissary {
  static async getCommissaryStatus (company: string) {
    return await to(axios.get(`${URL_PHP}/v2/check-verification-commissary/company/${company}`)) as any
  }

  static async setCommissaryMatiStatus (commissary: string) {
    return await to(axios.get(`${URL_PHP}/v2/update-verification-mati-owner/${commissary}`)) as any
  }

  static async setCommissaryCSF (company: string, image: any) {
    const formData = new FormData()
    formData.append('image', image)
    return await to(axios.post(`${URL_PHP}/v2/upload-commissary-csf/company/${company}`, formData)) as any
  }

  static async setCommissaryTally (company: string) {
    return await to(axios.get(`${URL_PHP}/v2/assigned-commissary-tally/company/${company}`)) as any
  }
}
