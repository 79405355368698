














// Utils
import { Component, Vue } from 'vue-property-decorator'
// Components
import HeaderProgressBar from '@/components/HeaderProgressBar.vue'
import ReturnAddress from '@/components/Return.vue'
import SetLegalName from '@/components/signup/SetLegalName.vue'
import Footer from '@/components/Footer.vue'

@Component({
  components: { HeaderProgressBar, ReturnAddress, SetLegalName, Footer }
})
export default class LegalName extends Vue {
}
