








































// Utils
import { Component, Vue } from 'vue-property-decorator'
// Components
import SetLegalName from '@/components/signup/SetLegalName.vue'
import ReturnAddress from '@/components/Return.vue'
import Footer from '@/components/Footer.vue'

@Component({
  components: { SetLegalName, ReturnAddress, Footer }
})
export default class EditLegalName extends Vue {
}
