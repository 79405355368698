



















































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Types
import UserTypes from '@/store/types/UserTypes'
// Components
import Loader from '@/components/Loader.vue'
// Services
import AdministrationService from '@/services/Administration'
import CommissaryService from '@/services/Commissary'

@Component({
  components: { Loader }
})
export default class ModalEditCommissary extends Vue {
  modal: any = {
    showModalCommissary: false,
    update: false,
  }
  email = ''
  name = ''
  loading = false
  open = false
  validEmail = true
  validationEmail = false
  partnerShare = false
  partnerCouncil = false
  editLimit = false
  checkoutComplete = false
  isTally = false
  commissary = {name: '', email: ''}
  role = ''

  mounted () {
    this.modal = this.$attrs.value
    this.checkoutComplete = this.state.company.orders.filter((order: any) => order.status === 'complete' && order.items.filter((item: any) => item.name === 'Complemento Acta Constitutiva' || item.name === 'Acta Constitutiva').length > 0).length > 0
    this.getCommissary()
    const documentsApproved = this.state.company.stages.find((stage: any) => stage.stage_name === 'documents_approved').is_completed === true
    if (this.state.company.timer !== null && documentsApproved) this.editLimit = true
  }

  get searchPartners () {
    const partners = this.state.company.partners.filter((partner: any) => partner.email.match(this.email) !== null )
    return partners
  }

  get commissaryIsPartner () {
    const partner = this.state.company.partners.filter((partner: any) => partner.email === this.email)
    return partner.length > 0
  }

  partnerRole () {
    const partner = this.state.company.council.filter((member: any) => member.partner !== null && member.partner.email === this.email && member.council_role !== 'commissary')
    if (partner.length === 0) return this.partnerCouncil = false
    this.partnerCouncil = partner[0].council_role !== 'no-member'
    this.role = partner[0].council_role === 'unique_administrator' ? 'Administrador único' :
      partner[0].council_role === 'president' ? 'Presidente' :
      partner[0].council_role === 'secretary' ? 'Secretario' :
      partner[0].council_role === 'treasurer' ? 'Tesorero' : 'Miembro'
  }

  getCommissary () {
    const commissary = this.state.company.council.filter((member: any) => member.council_role === 'commissary')
    if (!commissary.length) return
    if (commissary[0].other_faculty === 'Tally') return this.isTally = true
    this.commissary.email = this.email = commissary[0].email
    this.commissary.name = this.name = this.state.company.commissary
    this.validate(this.email)
    this.validateEmail(this.email)
  }

  setPartner (partner: any) {
    this.email = partner.email
    this.name = this.getPartnerFullName(partner)
    this.validate(this.email)
    this.validateEmail(this.email)
    this.open = false
  }

  getPartnerFullName (partner: any) {
    let full_name: string = partner.name != null ? partner.name : ''
    full_name = partner.first_names != null ? full_name + ' ' + partner.first_names : full_name
    full_name = partner.last_names != null ? full_name + ' ' + partner.last_names : full_name
    return full_name.trimEnd()
  }

  noSpaces (value: string) {
    return value.trim()
  }

  noSpacesStart (value: string) {
    return value.trimStart()
  }

  validate (email: string) {
    const re = /\S+@\S+\.\S+/
    this.validEmail = email ? re.test(email) : true
  }

  validateEmail (email: string) {
    const re = /\S+@\S+\.\S+/
    this.validationEmail = this.email !== '' && re.test(email)
    this.open = true
    const partner = this.state.company.partners.filter((partner: any) => partner.email === this.email)
    if (this.validationEmail && partner.length > 0) this.validatePartnerShare(partner[0])
    else this.partnerShare = this.partnerCouncil = false
  }

  validatePartnerShare (partner: any) {
    const shares = Number(partner.shares[0].number_of_shares)
    const totalShares = partner.shares[0].serie.total_shares_in_series
    this.partnerShare = ((shares * 100) / totalShares) > 25
    this.partnerRole()
  }

  async updateCommissary () {
    this.loading = true
    // Is tally?
    if (this.email == 'admin@tally.legal') return this.saveCommissaryTally()
    // Save commissary
    if (this.commissary.email === this.email && this.commissary.name === this.name) return this.modal.showModalCommissary = this.modal.update = this.loading = false
    const partner = this.state.company.partners.filter((partner: any) => partner.email === this.email)
    let commissary = {
      other_faculty: this.commissaryIsPartner ? 'Partner' : 'Friend',
      partner_id: this.commissaryIsPartner ? partner[0].id : null,
      email: this.email
    }
    const [error] = await AdministrationService.setCommissaryName(this.state.company.id, this.name)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    if (this.commissary.email === this.email) {
      await this.updateCompany(this.state.company.id)
      this.commissary.name = this.name
      this.modal.showModalCommissary = this.loading = false
      return this.modal.update = true
    }
    const [error2] = await AdministrationService.setCommissary(this.state.company.id, commissary)
    if (error2) {
      this.loading = false
      return this.$toasted.global.error(error2)
    }
    this.commissary.email = this.email
    this.commissary.name = this.name
    this.$toasted.global.success({ message: 'Se guardo el comisario' })
    // Get new user info
    await this.updateCompany(this.state.company.id)
    this.getCommissary()
    this.modal.showModalCommissary = false
    this.modal.update = true
    this.loading = false
  }

  async saveCommissaryTally () {
    this.loading = true
    const commissary = this.state.company.council.filter((member: any) => member.council_role === 'commissary')
    if (commissary[0].other_faculty === 'Tally') {
      this.modal.showModalCommissary = false
      return this.loading = false
    }
    const [error] = await CommissaryService.setCommissaryTally(this.state.company.id)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.$toasted.global.success({ message: 'Se guardo el comisario' })
    // Get new user info
    await this.updateCompany(this.state.company.id)
    this.getCommissary()
    this.modal.showModalCommissary = false
    this.modal.update = true
    this.loading = false
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
