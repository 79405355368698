import store from '@/store'
import UTMTypes from '@/store/types/UTMTypes'

export class UTM {
  static clearUTMs () {
    store.dispatch(`UTMModule/${UTMTypes.actions.CLEAR_DATA}`)
    return
  }
  static updateUTMs (router: any, route: any) {
    const localUTMs = store.getters[`UTMModule/${UTMTypes.getters.GET_DATA}`]
    const params = new URLSearchParams(window.location.search)
    const UTMs: any = {}
    const noUTMs: any = {}
    Object.keys(localUTMs).map(key => {
      UTMs[key] = params.get(key) ? params.get(key) : ''
    })
    noUTMs['partner'] = params.get('partner') ? params.get('partner') : ''
    noUTMs['rnie'] = params.get('rnie') ? params.get('rnie') : ''
    noUTMs['company'] = params.get('company') ? params.get('company') : ''
    noUTMs['email'] = params.get('email') ? params.get('email') : ''
    noUTMs['token'] = params.get('token') ? params.get('token') : ''
    noUTMs['access_token'] = params.get('access_token') ? params.get('access_token') : ''
    noUTMs['refresh_token'] = params.get('refresh_token') ? params.get('refresh_token') : ''
    const checkUTM = UTMs.utm_id || UTMs.utm_source || UTMs.utm_medium || UTMs.utm_campaign || UTMs.utm_term || UTMs.utm_content
    const checkLocalUTM = localUTMs.utm_id || localUTMs.utm_source || localUTMs.utm_medium || localUTMs.utm_campaign || localUTMs.utm_term || localUTMs.utm_content
    const checkLocalNoUTM = noUTMs.partner || noUTMs.rnie || noUTMs.company || noUTMs.email || noUTMs.token || noUTMs.access_token || noUTMs.refresh_token
    if (!checkUTM && !checkLocalUTM) {
      return
    } else if (!checkUTM && checkLocalUTM) {
      if ((!route.name ||
        route.name === 'NewPassword' ||
        route.name === 'Start' ||
        route.name === 'InvitedComplementaries' ||
        route.name === 'InvitedAddress' ||
        route.name === 'InvitedValidation' ||
        route.name === 'PaymentUpdate' ||
        route.name === 'End' ||
        route.name === 'ValidationActToUser' ||
        route.name === 'CommissaryStart' ||
        route.name === 'CommissaryEnd' ||
        route.name === 'CommissaryComplementaries' ||
        route.name === 'Checkout'
      ) && checkLocalNoUTM) {
        return
      } else {
        const query = {  ...this.getLocalUTMs(localUTMs) }
        if (route.name) router.replace({ name: route.name, query }).catch(() => {return})
      }
    } else if (checkUTM && JSON.stringify(localUTMs) !== JSON.stringify(UTMs)) {
      store.dispatch(`UTMModule/${UTMTypes.actions.UTMs}`, UTMs)
    } 
    return
  }
  static async setLocalUTMs (router: any) {
    const localUTMs = store.getters[`UTMModule/${UTMTypes.getters.GET_DATA}`]
    const params = new URLSearchParams(window.location.search)
    const noUTMs: any = {}
    noUTMs['experience'] = params.get('experience') ? params.get('experience') : ''
    if (!noUTMs.experience) return
    const query = {  ...this.getLocalUTMs(localUTMs) }
    await router.replace({ query })
    return
  }
  static getLocalUTMs (localUTMs: any) {
    const UTMs: any = {}
    Object.keys(localUTMs).map(key => {
      if (localUTMs[key]) UTMs[key] = localUTMs[key]
    })
    return UTMs
  }
  static getUrlUTMs (UTMs: any) {
    let url = '?'
    Object.keys(UTMs).map(key => {
      if (UTMs[key]) url += url === '?' ? key + '=' + UTMs[key] : '&' + key + '=' + UTMs[key]
    })
    return url
  }
}
