













// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
// Services
import CompanyService from '@/services/Company'

@Component({
  components: { Loader }
})
export default class ModalDeleteAccountStatements extends Vue {
  copys: any = {
    title: '¿Quieres eliminar el archivo?',
    text: 'Vas a eliminar el archivo',
    buttonText: 'Si, eliminar',
    cancelText: 'Cancelar'
  }
  modal: any = {
    showModalDeleteAccountStatements: false,
    doc: { name: '', id: ''}
  }
  loading = false

  mounted () {
    this.modal = this.$attrs.value
  }

  async deleteAccountStatements () {
    this.loading = true
    const [error] = await CompanyService.deleteAccountStatements(this.state.company.id, this.modal.doc.id)
    if (error) return this.$toasted.global.error(error)
    this.modal.update = true
    this.modal.showModalDeleteAccountStatements = false
    this.loading = false
  }

  @State('UserModule') state: any
}
