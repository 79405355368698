























































// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import { saveAs } from 'file-saver'
import EventBus from '@/event-bus'
import moment from 'moment'
// Types
import UserTypes from '@/store/types/UserTypes'
// Components
import ModalDeliveryDHL from '@/components/dashboard/modals/ModalDeliveryDHL.vue'
import ModalRescheduleSAT from '@/components/dashboard/modals/ModalRescheduleSAT.vue'
// Services
import ImageService from '@/services/Image'
import CompanyService from '@/services/Company'

@Component({ components: { ModalDeliveryDHL, ModalRescheduleSAT } })
export default class FinalStepper extends Vue {
  @Prop({default: false}) existFiels!: boolean
  points: any = []
  tasks: any = [{
    active: false,
    type: 'trademark',
    title: 'Registra tu marca',
    text: 'Protege tu idea más valiosa',
    textBold: '',
    buttonText: 'Iniciar',
    action: 'En otro momento',
    buttonLoader: false,
    actionLoader: false
  },{
    active: false,
    type: 'SAT-FIEL',
    title: 'Agenda cita para la e.Firma (FIEL)',
    text: 'Si no te dieron la e.firma en tu cita de inscripción, agenda una nueva cita.',
    textBold: '',
    buttonText: '',
    action: 'Agendar cita',
    buttonLoader: false,
    actionLoader: false
  },{
    active: false,
    type: 'businessAccount',
    title: 'Cuenta empresarial',
    text: 'Obtenla hoy mismo con nuestro partner ',
    textBold: '',
    buttonText: 'Obtener',
    action: '',
    buttonLoader: false,
    actionLoader: false
  },{
    active: false,
    type: 'accounting',
    title: 'Contabilidad',
    text: 'Agenda una llamada con tu contador',
    textBold: '',
    buttonText: 'Iniciar',
    action: '',
    buttonLoader: false,
    actionLoader: false
  },]
  buttonsAux: any = []
  buttons = [{
      name: 'Acta constitutiva',
      type: 'articles_of_incorporation',
      text: 'Descargar tu acta',
      loading: false,
      blob: null,
      active: false,
      fileType: '',
      icon: require('@/assets/icons/download2.svg')
    },{
      name: 'Delivery',
      type: 'delivery',
      text: 'Rastrear paquetería',
      loading: false,
      blob: null,
      active: false,
      fileType: '',
      icon: require('@/assets/icons/parcel.svg')
    },{
      name: 'RFC',
      type: 'rfc',
      text: 'Subir RFC y e.firma',
      loading: false,
      blob: null,
      active: false,
      fileType: '',
      icon: require('@/assets/icons/minutes.svg')
    }
  ]
  sat = {
    active: false,
    title: 'Asiste a tu cita en el SAT',
    appointment: {
      date: { icon: require('@/assets/icons/business.svg'), text: '' },
      module: { icon: require('@/assets/icons/map.svg'), text: '' },
      text: { icon: '', text: '' }
    },
    action: 'Ver guía',
    action2: 'Re-agendar cita'
  }
  modal: any = {
    showModalDeliveryDHL: false,
    showModalRescheduleSAT: false,
    update: false
  }
  renderKey = 20
  renderKeyDeliveryDHL = 22
  renderKeyRescheduleSAT = 24
  buttonsActive = false
  tasksActive = false

  get getCurrentStep () {
    const pointsAux = this.points.filter((point: any ) => point.complete === true)
    return pointsAux.length + 1
  }

  mounted () {
    this.points = this.$attrs.value
    this.setContent()
  }

  setContent () {
    const existTestimony = this.state.company.articles_of_incorporation.document_url !== null
    const existDelivery = (this.state.company.shipping_delivery !== null && this.state.company.shipping_delivery !== '')
    const requestedAppointment = this.state.company.status_federal_registration.id !== null
    const scheduledAppointment = !requestedAppointment ? false : this.state.company.status_federal_registration.is_scheduled
    const appointmentCompleted = !scheduledAppointment ? false : (moment().valueOf() > (moment(this.state.company.status_federal_registration.date).valueOf() + 7200000))
    const checkoutComplete = this.state.company.orders.filter((order: any) => order.status === 'complete' && order.items.filter((item: any) => item.name === 'Servicio de Comisario').length > 0).length > 0
    const rfcCompleted = (this.state.company.rfc !== null && this.state.company.rfc !== '') || this.state.company.rfc_later
    const existRFC = this.state.company.rfc !== null && this.state.company.rfc !== ''
    const representation = this.state.company.legal_representation
    // Buttons
    if (this.getCurrentStep > 1) this.buttons.map((button: any, index: number) => {
      if (button.type === 'articles_of_incorporation' && existTestimony && !appointmentCompleted && (this.getCurrentStep === 2 || this.getCurrentStep === 3)) this.buttons[index].active = true
      else if (button.type === 'delivery' && !scheduledAppointment && existDelivery && (this.getCurrentStep === 2)) this.buttons[index].active = true
      else if (button.type === 'rfc' && !representation && appointmentCompleted && (!existRFC || this.existFiels)) this.buttons[index].active = true
    })
    // SAT
    if (representation) this.sat.appointment.text.text = ''
    else if (this.state.company.appointment_type_efirma) this.sat.appointment.text.text = 'Recuerda llevar el Acta constitutiva de la empresa en original (no copia certificada), un comprobante de domicilio a nombre de la empresa, emitido hace menos de 3 meses y que cuente con todas sus páginas, la identificación del socio o apoderado que acuda a la cita, la impresión del acuse de cita, una memoria USB y el acuse de inscripción ante el RFC impreso'
    else this.sat.appointment.text.text = 'Recuerda llevar el Acta Constitutiva de la empresa, un comprobante de domicilio y tu identificación oficial vigente.'
    if (scheduledAppointment && !appointmentCompleted) {
      this.sat.active = true
      const aux = moment(new Date(this.state.company.status_federal_registration.date))
      this.sat.appointment.date.text = aux.format('DD') + ' de ' + aux.format('MMMM') + ' del ' + aux.format('YYYY') + ' a las ' + aux.format('hh:mm A') + '.'
      if (this.state.company.status_federal_registration.module !== null) this.sat.appointment.module.text = this.state.company.status_federal_registration.module
    }
    // Task
    this.tasks.map((task: any, index: number) => {
      if (task.type === 'trademark' && !this.state.company.trademark_registration_later) this.tasks[index].active = true
      if (task.type === 'SAT-FIEL' && !representation && rfcCompleted && !this.state.company.appointment_type_efirma && this.existFiels) this.tasks[index].active = true
      if (task.type === 'businessAccount' && rfcCompleted && !this.existFiels && appointmentCompleted && !this.state.company.not_interested_banks && !this.state.company.flag_data_banking_service) {
        this.tasks[index].active = true
        if (representation) {
          this.tasks[index].action = ''
          this.tasks[index].textBold = 'BBVASpark'
        } else {
          this.tasks[index].action = 'No me interesa'
          this.tasks[index].textBold = 'Fondeadora'
        }
      }
      if (task.type === 'accounting' && rfcCompleted && !this.existFiels && appointmentCompleted && (this.state.company.not_interested_banks || this.state.company.flag_data_banking_service)) {
        if (!checkoutComplete && !representation) this.tasks[index].text = 'Contrata nuestro servicio de contabilidad'
        this.tasks[index].active = true
      }
    })
    this.buttonsActive = this.buttons.filter((button: any ) => button.active === true).length > 0
    this.tasksActive = this.tasks.filter((task: any ) => task.active === true).length > 0
    this.renderKey++
  }

  resetContent () {
    this.buttons.map((button: any, index: number) => { this.buttons[index].active = false })
    this.tasks.map((task: any, index: number) => { this.tasks[index].active = false })
    this.sat.active = false
    this.buttonsActive = false
    this.tasksActive = false
    this.renderKey++
  }

  taskAction (type: string, action: number, index: number) {
    if (type === 'trademark') {
      this.trademarkLater(index)
      EventBus.$emit('update-final')
      if (action === 0) return window.open('https://tallylegal.typeform.com/to/sTJawPu9', '_blank')
      else return
    } else if (type === 'SAT-FIEL') {
      if (action === 0) return
      else return this.modal.showModalRescheduleSAT = true
    } else if (type === 'businessAccount') {
      if (action === 0) {
        if (this.state.company.legal_representation) return this.$router.push({ name: 'BBVASpark', query: this.$route.query, params: { company_id: this.state.company.id } })
        else return this.$router.push({ name: 'Fondeadora', query: this.$route.query, params: { company_id: this.state.company.id } })
      } else {
        EventBus.$emit('update-final')
        return this.notInterestedBanks(index)
      }
    } else if (type === 'accounting') {
      const checkoutComplete = this.state.company.orders.filter((order: any) => order.status === 'complete' && order.items.filter((item: any) => item.name === 'Servicio de Comisario').length > 0).length > 0
      const representation = this.state.company.legal_representation
      if (!checkoutComplete && !representation) return this.$router.push({ name: 'AccountantTally', query: this.$route.query, params: { company_id: this.state.company.id } })
      this.tasks[index].buttonLoader = false
      this.tasks[index].actionLoader = false
      return window.open('https://calendly.com/contabilidadtally/60min', '_blank')
    }
  }

  async buttonAction (button: any) {
    if (button.type === 'rfc') {
      button.loading = true
      await this.updateCompany(this.state.company.id)
      return this.$router.push({ name: 'CompanyFiels', query: this.$route.query, params: { company_id: this.state.company.id } })
    } else this.download(button)
  }

  async trademarkLater (index: number) {
    const [error] = await CompanyService.updateFlags(this.state.company.id, 'trademark_registration_later', true)
    if (error) return this.$toasted.global.error(error)
    await this.updateCompany(this.state.company.id)
    this.resetContent()
    this.setContent()
    this.tasks[index].buttonLoader = false
    this.tasks[index].actionLoader = false
  }

  async notInterestedBanks (index: number) {
    const [error] = await CompanyService.updateFlags(this.state.company.id, 'not_interested_banks', true)
    if (error) return this.$toasted.global.error(error)
    await this.updateCompany(this.state.company.id)
    this.resetContent()
    this.setContent()
    this.tasks[index].buttonLoader = false
    this.tasks[index].actionLoader = false
  }

  async download (doc: any) {
    if (doc.type === 'delivery') return this.modal.showModalDeliveryDHL = true
    doc.loading = true
    if (doc.blob === null) {
      const [error, response] = await ImageService.getDocument('company', this.state.company.id, doc.type)
      if (error) {
        doc.loading = false
        return this.$toasted.global.error(error)
      }
      doc.fileType = response.headers['content-type'].split('/')[1]
      doc.blob = new Blob([response.data])
      saveAs(doc.blob, `${doc.name + '.' + doc.fileType}`)
    } else saveAs(doc.blob, `${doc.name +  '.' + doc.fileType}`)
    doc.loading = false
    return this.$toasted.global.success({ message: 'Descarga exitosa' })
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
