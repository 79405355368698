









// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import Benefit from '@/components/benefits/Benefit.vue'

@Component({
  components: { Footer, Benefit }
})
export default class Gigstack extends Vue {
  benefitData: any = {
    name: 'Gigstack',
    textReturn: 'Regresar',
    openAccount: {
      img1: {
        url: require('@/assets/images/Benefits/Gigstack.png'),
        style1: 'width: 300px; height: 44px;',
        style2: ''
      },
      img2: {
        url: require('@/assets/images/Benefits/Gigstack2.png'),
        classStyle: 'col-12 col-lg-6 col-xl-5 mt-4 px-0'
      },
      title: 'Mueve el flujo de trabajo a la velocidad de la automatización',
      subTitle: 'Administra y automatiza todos tus flujos de pagos, gastos y facturación.',
      text: '',
      url: 'https://gigstack.pro/',
      point: '',
      points: [
        'Supervisa y toma mejores decisiones',
        'No pierdas tiempo en tareas manuales',
        'Controla tu negocio desde una única plataforma',
        'Obtén datos clave de tu negocio en tiempo real.'
      ],
    },
    companyDocs: {
      title: 'Sube tus documentos',
      subTitle: 'Los necesitamos para crear tu cuenta empresarial',
      msj: {
        Title: 'Revisa tus documentos',
        Text: 'Deberás subir tus documentos originales, sin tachaduras ni enmendaduras y con fecha de expedición no mayor a 2 meses.'
      }
    },
    authorization: {
      title: 'Autorizas el envío de tus documentos',
      subTitle: '',
      protection: 'Tu información está protegida',
      authorization: ''
    },
    complete: {
      title: '¡Solicitud realizada con éxito!',
      subTitle: '',
      button: 'Finalizar',
      points: []
    }
  }

  @State('UserModule') state: any
}
