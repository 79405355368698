

















// Utils
import { Component, Vue } from 'vue-property-decorator'
// Components
import Footer from '@/components/Footer.vue'
import NewPasswordUser from '@/components/account/password/NewPassword.vue'

@Component({
  components: { Footer, NewPasswordUser }
})
export default class NewPassword extends Vue {
  mounted () {
    if (!this.$route.query.email || this.$route.query.email === '' || !this.$route.query.token || this.$route.query.token === '') {
      return this.$router.push({ name: 'RecoverPassword', query: this.$route.query })
    }
  }
}
