




















// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
})
export default class TooltipCSF extends Vue {
  @Prop({default: ''}) color!: string
  points = [{
      point: 'Es el documento que te dieron en el SAT y contiene los datos de identidad, ubicación y caracterísitcas fiscales de tu empresa, sube las dos páginas. Si la perdiste obtenla con la FIEL ',
      url: 'https://satid.sat.gob.mx'
    }
  ]
}
