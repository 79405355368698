







// Utils
import { Component, Vue } from 'vue-property-decorator'
// Components
import Header from '@/components/auth/Header.vue'
import Main from '@/components/Main.vue'

@Component({
  components: { Main, Header }
})
export default class Index extends Vue {
}
