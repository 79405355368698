import to from 'await-to-js'
import axios from '@/utils/axios-api'

const URL_PHP = process.env.VUE_APP_BASE_URL_PHP

export default class Country {
  static async all () {
    return await to(axios.get(`${URL_PHP}/v1/countries`)) as any
  }

  static async nationalities () {
    return await to(axios.get(`${URL_PHP}/v2/nationalities`)) as any
  }
}
