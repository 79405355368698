



































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
// Types
import UserTypes from '@/store/types/UserTypes'
// Services
import Reviews from '@/services/Reviews'

@Component({
  components: { Loader }
})
export default class Review extends Vue {
  loading = false

  async review (stars: number) {
    this.loading = true
    const [error] = await Reviews.reviewTally(this.state.company.id, stars)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    await this.forceUpdateCompany(this.state.company.id)
    this.loading = false
    this.$toasted.global.success({ message: 'Se califico con éxito' })
  }

  @Action(`UserModule/${UserTypes.actions.FORCE_UPDATE_COMPANY_DATA}`) forceUpdateCompany: any
  @State('UserModule') state: any
}
