












// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
import moment from 'moment'
// Components
import Loader from '@/components/Loader.vue'

@Component({
  components: { Loader }
})
export default class CurrentMonth extends Vue {
  moment = moment
  copys: any = {
    title: 'Contabilidad de ',
    month: 0,
    months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    text: 'Emite las facturas pendientes del mes para iniciar la contabilidad, tienes hasta el último día de mes',
    action: ''
  }

  get currentMonth () { return this.copys.months[this.copys.month] }

  created () {
    this.copys.month = moment().month()
  }

  @State('UserModule') state: any
}
