



































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import CardCheckout from '@/components/payment/checkout/CardCheckout.vue'
import CardCheckoutItems from '@/components/payment/checkout/CardCheckoutItems.vue'
import HeaderProgressBar from '@/components/HeaderProgressBar.vue'
import ReturnAddress from '@/components/Return.vue'
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
// Services
import Payments from '@/services/Payment'

@Component({
  components: { HeaderProgressBar, Footer, ReturnAddress, CardCheckout, CardCheckoutItems, Loader }
})
export default class Checkout extends Vue {
  loading = true
  pay = {
    items: [{
      id: '',
      name: '',
      price: '',
      price_id: ''
    }],
    selected: [{
      id: '',
      item: '',
      cost: '',
      quantity: 0,
      price_id: ''
    }],
    update: false
  }

  async mounted () {
    await this.getPrices()
  }

  async getPrices () {
    const [error, response] = await Payments.getPrices()
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    this.pay.items = []
    this.pay.selected = []
    response.data.data.map((item: any) => {
      let priceActive = item.prices.filter((price: any ) => price.is_default === true)[0]
      let price = (Math.trunc(Number(priceActive.price) + Number(priceActive.tax))).toString()
      let priceID = priceActive.id
      if (item.name === 'Servicio de Comisario') {
        const commissaryPrice = item.prices.filter((price: any ) => price.is_default === true)
        const total = (Math.trunc(Number(commissaryPrice[0].price) + Number(commissaryPrice[0].tax))).toString()
        price = total
        priceID = commissaryPrice[0].id
      }
      this.pay.items.push({id: item.id, name: item.name, price: price.replace(/\d(?=(?:\d{3})+$)/g, '$&,'), price_id: priceID})
      if (item.name === 'Complemento Acta Constitutiva') this.pay.selected.push({id: item.id, item: item.name, cost: price.replace(/\d(?=(?:\d{3})+$)/g, '$&,'), quantity: 1, price_id: priceID})
    })
    this.loading = false
  }

  @State('UserModule') state: any
}
