import to from 'await-to-js'
import axios from '@/utils/axios-api'

const URL_PHP = process.env.VUE_APP_BASE_URL_PHP

export default class Constitutive {
  static async validateLegalName (legalName: string) {
    return await to(axios.post(`${URL_PHP}/v2/available-denominacion`, { name: legalName })) as any
  }

  static async updateLegalNames (company: string, legalNames: Array<string>, authorization: boolean) {
    return await to(axios.post(`${URL_PHP}/v1/company/${company}/update-legal-name`, { legal_names: legalNames, name_authorization: authorization }))
  }

  static async getRenderConstitutive (company: string, is_approved: any) {
    return await to(axios.post(`${URL_PHP}/v2/company/render-constitutive/${company}`, {is_approved})) as any
  }

  static async endEdit (company: string) {
    return await to(axios.post(`${URL_PHP}/v2/set-timer/${company}`)) as any
  }

  static async getStatesByZipCode (zip_code: string) {
    return await to(axios.get(`${URL_PHP}/v1/state/postal_code/${zip_code}`))
  }
}
