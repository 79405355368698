



















// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Message extends Vue {
  @Prop({default: ''}) title!: string
  @Prop({default: ''}) text!: string
  @Prop({default: ''}) MaxWidth!: number
  @Prop({default: ''}) MaxHeight!: number
}
