






































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
// Services
import CompanyService from '@/services/Company'
// Types
import UserTypes from '@/store/types/UserTypes'

@Component({
  components: { Loader }
})
export default class ModalPermission extends Vue {
  modal: any = {
    showModalPermissions: false,
    update: false,
  }
  loading = false
  partners = [{ name: '', id: '', permission: false, founder: false, loading: false }]

  mounted () {
    this.modal = this.$attrs.value
    this.getPartners()
  }

  getPartners () {
    this.partners = this.state.company.partners.map((partner: any) => {
      return {
        name: this.getPartnerFullName(partner),
        permission: partner.pivot.permission_to_edit_company,
        id: partner.id,
        founder: this.state.email === partner.email,
        loading: false
      }
    })
  }

  getPartnerFullName (partner: any) {
    let full_name: string = partner.name != null ? partner.name : ''
    full_name = partner.first_names != null ? full_name + ' ' + partner.first_names : full_name
    full_name = partner.last_names != null ? full_name + ' ' + partner.last_names : full_name
    return full_name.trimEnd()
  }

  async setPermission (partner: any) {
    partner.loading = true
    partner.permission = !partner.permission
    const [error] = await CompanyService.setPermissions(partner.id, this.state.company.id, partner.permission)
    if (error) {
      partner.loading = false
      this.getPartners()
      return this.$toasted.global.error(error)
    }
    await this.updateCompany(this.state.company.id)
    partner.loading = false
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
