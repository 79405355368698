export class Chat {
  static headSet(src: string) {
    if(document.querySelector("script[src='" + src + "']")){ return }
    const script = document.createElement('script')
    script.setAttribute('src', src)
    script.setAttribute('type', 'text/javascript')
    document.head.appendChild(script)
  }

  static headReplace(src: string, srcOld: string) {
    if(document.querySelector("script[src='" + src + "']")){ return }
    const button = document.querySelector("script[id='amo_social_button_script']")
    if(button){ button.remove() }
    const div = document.querySelector("div[class='amo-button-holder amo-vertical']")
    if(div){ div.remove() }
    const chat = document.querySelector("script[src='" + srcOld + "']")
    if(chat){ chat.remove() }
    const script = document.createElement('script')
    script.setAttribute('src', src)
    script.setAttribute('type', 'text/javascript')
    document.head.appendChild(script)
  }

  static bodySet(src: string, data: string, type: string) {
    if(document.querySelector("script[src='" + src + "']")){ return }
    const script = document.createElement('script')
    script.setAttribute('src', src)
    script.setAttribute('id', 'chat')
    script.setAttribute('data-user', data)
    script.setAttribute('type', type)
    document.body.appendChild(script)
  }

  static bodyReplace(src: string, srcOld: string, idChat: string, data: string) {
    // Exist new chat script
    if(document.querySelector("script[src='" + src + "']")){ return }
    // Delete old chat button
    // const div = document.querySelector("div[id='" + idChat + "']")
    // if(div){ div.remove() }
    // Delete old chat script
    const chat = document.querySelector("script[src='" + srcOld + "']")
    if(chat){ chat.remove() }
    // Set new chat script
    const script = document.createElement('script')
    script.setAttribute('src', src)
    script.setAttribute('id', 'chat')
    script.setAttribute('data-user', data)
    script.setAttribute('type', 'text/javascript')
    document.body.appendChild(script)
  }

  static delete(container: string, src: string, id: string) {
    // const button = document.querySelector("script[id='amo_social_button_script']")
    const button = document.querySelector(container + "[id='" + id + "']")
    if(button){ button.remove() }
    const chat = document.querySelector("script[src='" + src + "']")
    if(chat){
      chat.remove()
      location.reload()
    }
  }
}
