import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { State } from '@/store/interfaces/Extern'
import ExternTypes from '@/store/types/ExternTypes'
import PaymentService from '@/services/Payment'

const state: State = {
  token: '',
  email: '',
  id: '',
  company_id: '',
  item: {}
}

const getters: GetterTree<State, any> = {
  [ExternTypes.getters.GET_DATA]: stateGet => stateGet
}

const mutations: MutationTree<State> = {
  [ExternTypes.mutations.SET_DATA]: async (stateMut, payload: State) => {
    state.token = payload.token
    state.email = payload.email
    state.id = payload.id
    state.company_id = payload.company_id
    state.item = payload.item
  },
  [ExternTypes.mutations.CLEAR_DATA]: async () => {
    state.token = ''
    state.email = ''
    state.id = ''
    state.company_id = ''
    state.item = {}
  }
}

const actions: ActionTree<State, any> = {
  [ExternTypes.actions.SAVE_TOKEN]: async ({ commit }, { token, email, company_id }) => {
    const [err, response] = await PaymentService.validateToken(token, email)
    if (err) return [err]
    await commit(ExternTypes.mutations.SET_DATA, { token, email, company_id, ...response.data.data })
    return [null, response.data.data]
  },
  [ExternTypes.actions.CLEAR_DATA]: async ({ commit }) => {
    await commit(ExternTypes.mutations.CLEAR_DATA)
    return [null]
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
