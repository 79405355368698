



















































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
// Services
import Payments from '@/services/Payment'

@Component({
  components: { Loader }
})
export default class HearAbout extends Vue {
  optionSelected = ''
  open = false
  loading = false
  options: Array<string> = [
    'Buscando en Google',
    'Leyendo su blog',
    'Me lo recomendaron',
    'En Linkedin',
    'En Facebook o Instagram',
    'Por una alianza',
    'Por un email',
    'Otro'
  ]

  async next () {
    this.loading = true
    // eslint-disable-next-line
    const [error, response] = await Payments.whereWeMet(this.state.company.id, this.optionSelected)
    if (error) {
      this.loading = false
      return this.$toasted.global.error(error)
    }
    return this.$router.push({ name: 'Welcome', query: this.$route.query, params: { company_id: this.state.company.id } }).catch(() => {return})
  }

  @State('UserModule') state: any
}
