




















// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
})
export default class TooltipCFI extends Vue {
  @Prop({default: ''}) color!: string
  points = [{
      point: 'Consiste en una clave única de registro que sirve para identificar a todas las personas que realizan una actividad económica. Si aún no la tienes agenda una cita en el SAT ',
      url: 'https://satid.sat.gob.mx'
    }
  ]
}
