

















// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import CompanyDocs from '@/components/documents/CompanyDocs.vue'
import PartnersDocs from '@/components/documents/PartnersDocs.vue'

@Component({
  components: { Footer, CompanyDocs, PartnersDocs }
})
export default class Documents extends Vue {
  @State('UserModule') state: any
}
