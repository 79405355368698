



























// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import ReturnAddress from '@/components/Return.vue'
import ValidateCode from '@/components/account/ValidateCode.vue'

@Component({
  components: { ReturnAddress, ValidateCode }
})
export default class UserValidatePhone extends Vue {
  phone = ''
  countryCode = ''

  created () {
    this.phone = this.companyState.phone === '' ?  this.state.phone : this.companyState.phone
    this.countryCode = this.companyState.country.lada === '' ?  this.state.phone_country_code : this.companyState.country.lada
  }

  @State('CompanyModule') companyState: any
  @State('UserModule') state: any
}
