











































































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import ModalUpdateCard from '@/components/dashboard/modals/ModalUpdateCard.vue'

@Component({
  components: { Footer, ModalUpdateCard }
})
export default class Account extends Vue {
  modal: any = {
    showModalUpdateCard: false,
    update: false
  }

  @State('UserModule') state: any
}
