import { required, minLength, email } from 'vuelidate/lib/validators'

const validations = {
  name: {
    required
  },
  first_names: {
    required
  },
  /* last_names: {
    required
  }, */
  email: {
    required,
    email
  },
  country: {
    required
  },
  phone: {
    required
  },
  password: {
    required,
    // containsOneNumber: (value: string) => /[0-9]/.test(value),
    minLength: minLength(8)
  },
  /* confirmPassword: {
    required,
    sameAs: sameAs('password')
  }, */
  agreement: {
    checked: (value: boolean) => value
  }
}

export default validations
