




































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import TooltipCFI from '@/components/partners/TooltipCFI.vue'

@Component({
  components: { Footer, TooltipCFI }
})
export default class End extends Vue {
  copys = {
    title: 'Tu verificación fue exitosa',
    text: 'Solo nos falta validar tus datos fiscales, mientras puedes ver el avance de tu empresa'
  }

  @State('UserModule') state: any
}
