

















// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import HeaderProgressBar from '@/components/HeaderProgressBar.vue'
import AddPartners from '@/components/beginning/AddPartners.vue'
import CompanyDistribution from '@/components/beginning/CompanyDistribution.vue'
import CardCommissary from '@/components/administration/CardCommissary.vue'
import CardCommissaryTally from '@/components/administration/CardCommissaryTally.vue'
import CompanyActivity from '@/components/beginning/CompanyActivity.vue'
import SetLegalName from '@/components/signup/SetLegalName.vue'
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'
// Types
import UserTypes from '@/store/types/UserTypes'

@Component({
  components: { HeaderProgressBar, Footer, Loader, AddPartners, CompanyDistribution, CardCommissary, CardCommissaryTally, CompanyActivity, SetLegalName }
})
export default class Beginning extends Vue {
  partnerV3 = false
  renderKey = 0
  company: any = { step: '2', totalAction: '10,000' }
  commissaryTally: any = { isTally: true }
  progress_bar = 0
  bar = 1

  @Watch('company.step')
  updateProgress () { this.setProgress() }

  created () {
    // if (this.state.company.legal_representation) this.bar = 2
    if (this.state.company.legal_names.length === 0) this.company.step = '1'
    else if (this.state.company.business_twists_tmp === null || this.state.company.business_twists_tmp.length === 0) this.company.step = '2'
    else if (!this.state.company.partners.length) this.company.step = '3'
    else if (this.state.company.partners.length && !this.state.company.share_series.length) this.company.step = '4'
    else if (this.state.company.partners.length && this.state.company.share_series.length && !this.state.company.legal_representation) this.company.step = '5'
    else if (this.state.company.legal_representation) this.$router.push({ name: 'Welcome', query: this.$route.query }).catch(() => {return})
    this.setProgress()
  }

  setProgress () {
    if (this.company.step === '1' || this.company.step === '2') {
      this.progress_bar = 2
      this.bar = 1
    } else {
      this.progress_bar = 3
      this.bar = 2
    }
    this.renderKey += 1
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
