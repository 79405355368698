












































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
// Components
import Loader from '@/components/Loader.vue'
import InputFileDocumentsError from '@/components/InputFileDocumentsError.vue'
// Types
import UserTypes from '@/store/types/UserTypes'
// Services
import CommissaryService from '@/services/Commissary'

@Component({
  components: { Loader, InputFileDocumentsError }
})
export default class ModalRefuseCommissaryDocument extends Vue {
  modal: any = {
    showModalRefuseCommissaryDocument: false,
    update: false,
    docType: '',
    reject: ''
  }
  file: any = null
  loading = false

  mounted () {
    this.modal = this.$attrs.value
  }

  async upload () {
    if (this.file === null || !this.file.name || !this.file.size) return
    this.loading = true
    const [error] = await CommissaryService.setCommissaryCSF(this.state.company.id, this.file)
    if (error) {
      this.loading = false
      return
    }
    await this.updateCompany(this.state.company.id)
    this.modal.update = true
    this.modal.showModalRefuseCommissaryDocument = false
    this.loading = false
    this.$toasted.global.success({ message: 'Se subio el archivo con éxito' })
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
