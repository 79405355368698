














































































































































































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
import numeral from 'numeral'
import EventBus from '@/event-bus'
// Helpers
// import { Stripe } from '@/helpers/Stripe'
// Components
import Loader from '@/components/Loader.vue'
import InputFile from '@/components/InputFile.vue'
import TooltipCFI from '@/components/partners/TooltipCFI.vue'
import TooltipBilling from '@/components/payment/TooltipBilling.vue'
// Services
import PaymentService from '@/services/Payment'
// import PartnersService from '@/services/Partners'
import ConstitutiveService from '@/services/Constitutive'
// eslint-disable-next-line
// import CompanyService from '@/services/Company'
// eslint-disable-next-line
// import ImageService from '@/services/Image'

@Component({
  components: { Loader, TooltipCFI, TooltipBilling, InputFile },
  filters: {
    currency (value: number) { return numeral(value).format('0,0.00') }
  }
})
export default class CardPayment extends Vue {
  loadingAddress = false
  loadingCoupon = false
  loadingStripe = false
  billing = false
  billing_name = 'Sin nombre'
  billing_rfc = 'Sin RFC'
  billing_email = 'sinEmail@sin.nada'
  billing_cfi: any = ''
  completeEmail = true
  validEmail = true
  open = false
  style = ''
  states: any = []
  address = {
    zip_code: '',
    suburb: '',
    state: '',
    street: '',
    outdoor_number: '',
    interior_number: ''
  }
  pay: any = {
    items: [{
      id: '',
      name: '',
      price: '',
      price_id: ''
    }],
    selected: [{
      id: '',
      item: '',
      cost: '',
      quantity: '',
      price_id: ''
    }]
  }
  coupon = ''
  couponActive = {
    id: '',
    name: '',
    discount: '0',
    discount_type: 'amount'
  }
  itemsOrder = [{ id: '', name: '', quantity: 0, price_id: '' }]
  total = '0'
  totalComplement = '0'
  stripe: any = null
  name = ''
  cardComplete = false
  cardExpiryComplete = false
  cardCVVComplete = false
  plan_selected = 0
  data_order: any = { plans: [{ count: 0 }], order_id: 0 }
  $loadScript: any

  @Watch('pay.selected')
  newTotal () {
    this.updateTotal()
  }

  @Watch('address.zip_code')
  changeZipCode () {
    if (this.address.zip_code.length === 5) this.getStatesByZipCode()
  }

  async mounted () {
    this.pay = this.$attrs.value
    this.totalComplement = this.pay.items.filter((item: any ) => item.name === 'Complemento Acta Constitutiva')[0].price
    this.updateTotal()
    /* EventBus.$on('validate-coupon-promo', async (coupon: string) => {
      this.loadingCoupon = true
      const [error, response] = await PaymentService.validateCoupon(coupon)
      this.loadingCoupon = false
      if (error) return this.$toasted.global.error({ message: 'No se encontró el cupón' })
      this.couponActive = response.data
      this.updateTotal()
      this.coupon = ''
      return this.$toasted.global.success({ message: 'Se agrego el cupón' })
    })
    try {
      await this.$loadScript('https://js.stripe.com/v3/')
      this.stripe = (window as any).Stripe(process.env.VUE_APP_STRIPE_KEY)
      this.setStripe()
    } catch (e) {
      return this.$toasted.global.error({ message: 'Hubo un problema con stripe' })
    } */
  }

  beforeDestroy () {
    EventBus.$off('validate-coupon-promo')
  }

  async getStatesByZipCode () {
    this.states = []
    this.loadingAddress = true
    const response = await ConstitutiveService.getStatesByZipCode(this.address.zip_code)
    if (response[1]) {
      this.states = response[1].data.postalCodes.data
      this.styleSetting()
    } else {
      this.$toasted.global.error({ message: 'No se encontro el código postal' })
    }
    this.loadingAddress = false
  }

  updateCity (city: any) {
    this.address.state = city.city;
    this.address.suburb = city.suburb;
    this.open = false;
  }

  styleSetting () {
    const cant = this.states.length
    this.style = cant === 1 ?
      'height: 55px;' : cant === 2 ?
      'height: 110px;' : cant === 3 ?
      'height: 165px;' : cant === 4 ?
      'height: 220px;' : 'height: 275px;'
  }

  onlyNumbers (value: string) {
    return value.replace(/[^0-9]/g,'')
  }

  updateTotal () {
    let sum = 0
    this.pay.selected.map((select: any) => {
      if (select.quantity === '1') {
        sum += Number(select.cost.replace(/,/g, "").replace(/[^0-9]/g,''))
      } else {
        sum += Number(select.cost.replace(/,/g, "").replace(/[^0-9]/g,'')) * Number(select.quantity)
      }
    })
    if (this.couponActive.name !== '') sum -= Number(this.couponActive.discount)
    this.total = sum.toString().replace(/\d(?=(?:\d{3})+$)/g, '$&,')
    // this.getPaymentDetails()
  }

  itemPrice (price: string, quantity: string) {
    let sum = Number(price.replace(/,/g, "").replace(/[^0-9]/g,'')) * Number(quantity)
    return sum.toString().replace(/\d(?=(?:\d{3})+$)/g, '$&,')
  }

  get formComplete () {
    return this.name && this.cardComplete && this.cardExpiryComplete && this.cardCVVComplete &&
      this.plan_selected
  }

  validateCompleteEmail (email: string) {
    const re = /\S+@\S+\.\S+/
    this.completeEmail = re.test(email)
  }

  validate (email: string) {
    const re = /\S+@\S+\.\S+/
    this.validEmail = re.test(email)
  }

  async goPaymentLink () {
    this.loadingStripe = true
    let items: any = []
    items.push({ name : 'Anticipo Acta Constitutiva', quantity : 1 })
    const [error2, response] = await PaymentService.createPaymentLink(items, this.state.company.id, this.state.id)
    if (error2) {
      this.loadingStripe = false
      return this.$toasted.global.error(error2)
    }
    let linkToPayment = ''
    if (response) linkToPayment = response.data.data.payment_link
    if (linkToPayment !== '') return location.href = linkToPayment
    return this.loadingStripe = false
  }

  /* async setStripe () {
    const elements = this.stripe.elements({ fonts: [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap' }], locale: 'auto' })
    this.stripe.card = elements.create('cardNumber', Stripe.COMPONENT_OPTIONS)
    this.stripe.card.mount('#card-number')
    this.stripe.card_expiry = elements.create('cardExpiry', Stripe.COMPONENT_OPTIONS)
    this.stripe.card_expiry.mount('#date-expired')
    this.stripe.cvv = elements.create('cardCvc', Stripe.COMPONENT_OPTIONS)
    this.stripe.cvv.mount('#cvv')
    this.stripe.card.on('change', (event: any) => {
      if (event.complete) {
        this.cardComplete = true
        this.getPaymentDetails()
      } else this.cardComplete = false
    })
    this.stripe.card_expiry.on('change', (event: any) => {
      if (event.complete) {
        this.cardExpiryComplete = true
        this.getPaymentDetails()
      } else this.cardExpiryComplete = false
    })
    this.stripe.cvv.on('change', (event: any) => {
      if (event.complete) {
        this.cardCVVComplete = true
        this.getPaymentDetails()
      } else this.cardCVVComplete = false
    })
  } */

  /* async getPaymentDetails () {
    if (!(this.cardComplete && this.name !== '' && this.cardExpiryComplete && this.cardCVVComplete)) return
    this.loadingStripe = true
    const [error, response] = await Stripe.createPaymentMethod(this.stripe, this.name)
    if (error || response.error) {
      this.loadingStripe = false
      return this.$toasted.global.error(error)
    }
    this.itemsOrder = []
    this.pay.selected.map((select: any) => {
      this.itemsOrder.push({ id: select.id, name: select.item, quantity: Number(select.quantity), price_id: select.price_id })
    })
    const [err, responseOrder] = await PaymentService.createOrder({
      company_id: this.state.company.id,
      payment_method_id: response.paymentMethod.id,
      items: this.itemsOrder,
      coupon_code: this.couponActive.name === '' ? {} : this.couponActive
    })
    this.loadingStripe = false
    if (err) return this.$toasted.global.error(err)
    this.data_order = await Stripe.formatMsiData(responseOrder.data.data)
    this.plan_selected = this.data_order.plans[0].count
  } */

  /* async validateCoupon () {
    if (this.coupon === '') return
    this.loadingCoupon = true
    const [error, response] = await PaymentService.validateCoupon(this.coupon)
    this.loadingCoupon = false
    if (error) return this.$toasted.global.error({ message: 'No se encontró el cupón' })
    this.couponActive = response.data
    this.updateTotal()
    this.coupon = ''
    return this.$toasted.global.success({ message: 'Se agrego el cupón' })
  } */

  /* async sendInvitations () {
    const [error] = await PartnersService.sendInvitations(this.state.company.id)
    if (error) {
      return this.$toasted.global.error(error)
    }
  } */

  /* async checkout () {
    this.loadingStripe = true
    const [errorPayment] = await PaymentService.paymentWithCard({
      company_id: this.state.company.id,
      installments: this.plan_selected,
      order_id: this.data_order.order_id,
      coupon_code: this.couponActive.name,
      billing_request: this.billing ? 1 : 0,
      billing_to_new_company: this.billing ? 0 : 1,
      billing_name: this.billing_name,
      billing_rfc: this.billing_rfc,
      billing_email: this.billing_email
    }, this.data_order.order_id)
    if (errorPayment) {
      this.loadingStripe = false
      return this.$toasted.global.error(errorPayment)
    }
    // await this.sendInvitations()
    if (process.env.NODE_ENV === 'production') {
      window.dataLayer.push({'event': 'AC_PayConfirm_OK'})
      window.dataLayer.push({'event': 'Payment_Constitutiva'})
    }
    this.$toasted.global.success({ message: 'Se completo el pago' })
    return this.$router.push({ name: 'Welcome', query: this.$route.query, params: { company_id: this.state.company.id } })
  } */

  @State('UserModule') state: any
}
