




















































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
// Components
import testimonialComponent from '@/components/payment/testimonialComponent.vue'
// Types
import UserTypes from '@/store/types/UserTypes'

@Component({
  components: { testimonialComponent }
})
export default class CardPaymentItems extends Vue {
  check1 = false
  open = false
  numbers: Array<string> = ['1', '2', '3', '4', '5']
  numbersCopy = 1
  pay: any = {
    items: [{
      id: '',
      name: '',
      price: '',
      price_id: ''
    }],
    selected: [{
      id: '',
      item: '',
      cost: '',
      quantity: ''
    }]
  }
  constitutiveAct = {
    id: '',
    name: '',
    price: '',
    price_id: ''
  }
  certifiedCopy = {
    id: '',
    name: '',
    price: '',
    price_id: ''
  }
  companyInfo = {
    legal_name: ''
  }

  mounted () {
    this.pay = this.$attrs.value
    this.pay.items.map((item: any) => {
      if (item.name === 'Acta Constitutiva') this.constitutiveAct = {id: item.id, name: item.name, price: item.price, price_id: item.price_id}
      if (item.name === 'Copia Certificada') this.certifiedCopy = {id: item.id, name: item.name, price: item.price, price_id: item.price_id}
    })
    this.companyInfo = {legal_name: this.state.company.legal_name}
  }

  updatedItem (item: { id: string, item: string, cost: string, quantity: number, price_id: string }) {
    let exist = -1
    if (this.certifiedCopy.id === item.id) this.numbersCopy = item.quantity
    this.pay.selected.map((select: any, index: number) => {
      if (select.item === item.item) exist = index
    })
    this.check1 = !this.check1
    return exist !== -1 ? this.pay.selected.splice(exist, 1) : this.pay.selected.push(item)
  }

  updatedItemQuantity (item: { id: string, item: string, cost: string, quantity: number, price_id: string }) {
    let exist = -1
    if (this.certifiedCopy.id === item.id) this.numbersCopy = item.quantity
    this.pay.selected.map((select: any, index: number) => {
      if (select.item === item.item) exist = index
    })
    if (exist !== -1) this.pay.selected.splice(exist, 1)
    return this.pay.selected.push(item)
  }

  @Action(`UserModule/${UserTypes.actions.UPDATE_COMPANY_DATA}`) updateCompany: any
  @State('UserModule') state: any
}
