












// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import CardCommissaryTally from '@/components/administration/CardCommissaryTally.vue'
import ReturnAddress from '@/components/Return.vue'
import Footer from '@/components/Footer.vue'

@Component({
  components: { Footer, ReturnAddress, CardCommissaryTally }
})
export default class AccountantTally extends Vue {
  commissaryTally: any = {
    isTally: true
  }

  @State('UserModule') state: any
}
