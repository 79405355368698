




















// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
})
export default class TooltipDenomination extends Vue {
  @Prop({default: ''}) color!: string
  points = [{
      point: 'Ingresa al portal del MUA y descarga la autorización de denomiación que te fue aprobada.',
      url: ''
    },{
      point: 'Asigna tu denominación a la notaria 210, en Mazatlan Sinaloa, a nombre de Fernando García Sais.',
      url: ''
    }
  ]
}
