









// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import Footer from '@/components/Footer.vue'
import Benefit from '@/components/benefits/Benefit.vue'

@Component({
  components: { Footer, Benefit }
})
export default class Clara extends Vue {
  benefitData: any = {
    name: 'Clara',
    textReturn: 'Regresar',
    openAccount: {
      img1: {
        url: require('@/assets/images/Benefits/Clara.png'),
        style1: 'width: 192px; height: 58px;',
        style2: 'max-width: 262px; height: 84px; background: #000000;'
      },
      img2: {
        url: require('@/assets/images/Benefits/Clara2.png'),
        classStyle: 'col-12 col-lg-6 mt-4 px-0'
      },
      title: 'Dirige la administración de gastos de tu empresa como nunca antes',
      subTitle: '',
      text: '',
      url: 'https://landing.clara.com/clara-tally?utm_campaign=SalesReps&utm_source=tally&utm_medium=partnership&utm_term=APPROVED',
      point: '',
      points: [
        'Programa hasta 100 pagos en un solo paso.',
        'Da de alta la información de tus proveedores en minutos, ya sea individualmente o por medio de una carga masiva',
        'Obtén financiamiento de corto plazo que te permita optimizar tus finanzas',
        'Vincula tu cuenta al portal del SAT, y paga las facturas que tienes pendientes'
      ],
    },
    companyDocs: {
      title: 'Sube tus documentos',
      subTitle: 'Los necesitamos para crear tu cuenta empresarial',
      msj: {
        Title: 'Revisa tus documentos',
        Text: 'Deberás subir tus documentos originales, sin tachaduras ni enmendaduras y con fecha de expedición no mayor a 2 meses.'
      }
    },
    authorization: {
      title: 'Autorizas el envío de tus documentos',
      subTitle: '',
      protection: 'Tu información está protegida',
      authorization: ''
    },
    complete: {
      title: '¡Solicitud realizada con éxito!',
      subTitle: '',
      button: 'Finalizar',
      points: []
    }
  }

  @State('UserModule') state: any
}
