































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component({
})
export default class PaymentInfo extends Vue {

  @State('UserModule') state: any
}
