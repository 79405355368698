




















































































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
// Components
import ModalEditPartners from '@/components/resumen/modals/ModalEditPartners.vue'
import ModalEditDistribution from '@/components/resumen/modals/ModalEditDistribution.vue'
// Services
import CountryService from '@/services/Country'

@Component({
  components: { ModalEditPartners, ModalEditDistribution }
})
export default class CardAllPartners extends Vue {
  modal: any = {
    showModalCompanyPartners: false,
    showModalCompanyDistribution: false,
    checkDistribution: false,
    update: false,
  }
  partnersTable = [
    { name: 'Socios', type: 'name', isVerification: false, center: false, colSize: '5'},
    { name: 'Nacionalidad', type: 'nationality', isVerification: false, center: false, colSize: '2'},
    { name: 'Correo electrónico', type: 'email', isVerification: false, center: false, colSize: '5'}
  ]
  distributionTable = [
    { name: 'Participación', type: 'porcentage', colSize: '3'},
    { name: 'Acciones', type: 'actions', colSize: '3'},
    { name: 'socios', type: 'name', colSize: '6'},
  ]
  editActive = false
  nationalities: any = []
  renderKey = 0

  @Watch('modal.update')
  async update () {
    if(!this.modal.update) return
    this.renderKey++
    this.modal.update = false
  }

  async created () {
    await this.getNationalities()
  }

  mounted () {
    const articlesMock = this.state.company.stages.find((stage: any) => stage.stage_name === 'company_articles_mock').is_completed === true
    if (!articlesMock) this.editActive = true
  }

  getPartnerShares (partner: any) {
    const companyShares = partner.shares.find((shares: any) => shares.serie.company_id === this.state.company.id)
    return companyShares
  }

  getPartnerData (type: string, partner: any) {
    if (type === 'name') return this.getPartnerFullName(partner)
    if (type === 'nationality') return this.getNationality(partner)
    if (type === 'email') return partner.email
    if (type === 'porcentage') return this.validatePartnerShare(partner)
    if (type === 'actions') return (partner.shares.length === 0 || this.getPartnerShares(partner) === undefined) ? 0 : this.getPartnerShares(partner).number_of_shares
    if (type === 'verification') {
      const status = this.completedComplementaries(partner) 
      return status === 'Refuse' ? 'Rechazado' : status === 'Pending' ? 'Pendiente' : status === 'Validating' ? 'Revisando' : 'Completado'
    }
    return ''
  }

  getCardStatusStyle (partner: any) {
    const status = this.completedComplementaries(partner)
    return status === 'Refuse' ? 'card-status-refused' :
      status === 'Pending' ? 'card-status-pending' :
      status === 'Validating' ? 'card-status-validate' : 'card-status-complete'
  }

  getPartnerFullName (partner: any) {
    let full_name: string = partner.name != null ? partner.name : ''
    full_name = partner.first_names != null ? full_name + ' ' + partner.first_names : full_name
    full_name = partner.last_names != null ? full_name + ' ' + partner.last_names : full_name
    return full_name.trimEnd()
  }

  validatePartnerShare (partner: any) {
    if (partner.shares.length === 0 || this.getPartnerShares(partner) === undefined) return 0
    return ((Number(this.getPartnerShares(partner).number_of_shares) * 100) / this.getPartnerShares(partner).serie.total_shares_in_series)
  }

  getDocumentStatus (doc: Array<any>) {
    return !doc.length ? 'Pending' : doc[0].is_approved ? 'Complete' : (!doc[0].is_approved && doc[0].rejection_category) ? 'Refuse' : 'Validating'
  }

  completedComplementaries (partner: any) {
    let docStatus = 'Complete'
    // Get all documents
    const document_passport = partner.documents.filter((document: any) => document.document_type === 'passport')
    const document_national_identity = partner.documents.filter((document: any) => document.document_type === 'national_identity')
    const document_national_identity_back = partner.documents.filter((document: any) => document.document_type === 'national_identity_back')
    const document_resident_identity = partner.documents.filter((document: any) => document.document_type === 'resident_id')
    const document_resident_identity_back = partner.documents.filter((document: any) => document.document_type === 'resident_id_back')
    const document_CFI = partner.documents.filter((document: any) => document.document_type === 'CFI')
    const document_address_identity = partner.documents.filter((document: any) => document.document_type === 'address')
    const document_id_spouse = partner.documents.filter((document: any) => document.document_type === 'id_spouse')
    const document_id_spouse_back = partner.documents.filter((document: any) => document.document_type === 'id_spouse_back')
    const document_marriage_certificate = partner.documents.filter((document: any) => document.document_type === 'marriage_certificate')
    // Validate documents status
    const status_passport = this.getDocumentStatus(document_passport)
    const status_national_identity = this.getDocumentStatus(document_national_identity)
    const status_national_identity_back = this.getDocumentStatus(document_national_identity_back)
    const status_resident_identity = this.getDocumentStatus(document_resident_identity)
    const status_resident_identity_back = this.getDocumentStatus(document_resident_identity_back)
    const status_CFI = this.getDocumentStatus(document_CFI)
    const status_address_identity = this.getDocumentStatus(document_address_identity)
    const status_id_spouse = this.getDocumentStatus(document_id_spouse)
    const status_id_spouse_back = this.getDocumentStatus(document_id_spouse_back)
    const status_marriage_certificate = this.getDocumentStatus(document_marriage_certificate)
    // Validate Partner nationality and rfc
    let isNational = false
    if (partner.nationalities !== null && partner.nationalities.length) partner.nationalities.map((nationality: any) => { if (nationality.name === 'México' || nationality.issue_country === 'MX') isNational = true })
    const RFC = (partner.rfc !== null && partner.rfc !== '' && partner.rfc !== 'EXTF900101NI1')
    // Docs marriage
    if (partner.civil_status !== 'soltero') {
      if (status_id_spouse === 'Refuse' || status_id_spouse_back === 'Refuse' || status_marriage_certificate === 'Refuse') return 'Refuse'
      if (status_id_spouse === 'Pending' || status_marriage_certificate === 'Pending') docStatus = 'Pending'
      else if (docStatus !== 'Pending' && (status_id_spouse === 'Validating' || status_id_spouse_back === 'Validating' || status_marriage_certificate === 'Validating')) docStatus = 'Validating'
    }
    // Docs identity
    const identity = [status_passport, (!isNational && RFC) ? status_resident_identity : status_national_identity]
    const identityRefuse = identity.filter((status: any) => status !== 'Refuse')
    if (!identityRefuse.length) return 'Refuse'
    else if (identityRefuse.length === 1 && identityRefuse[0] === 'Pending') return 'Refuse'
    if ((isNational || (!isNational && !RFC)) && status_passport === 'Pending' && status_national_identity === 'Pending' && status_national_identity_back === 'Pending') docStatus = 'Pending'
    else if ((!isNational && RFC) && status_passport === 'Pending' && status_resident_identity === 'Pending' && status_resident_identity_back === 'Pending') docStatus = 'Pending'
    else if (docStatus !== 'Pending' && (status_passport === 'Validating' || status_national_identity === 'Validating' || status_national_identity_back === 'Validating' || status_resident_identity === 'Validating' || status_resident_identity_back === 'Validating')) docStatus = 'Validating'
    // Doc address
    if (!isNational && !RFC && status_address_identity !== 'Complete') {
      if (status_address_identity === 'Refuse') return 'Refuse'
      if (docStatus !== 'Pending') docStatus = status_address_identity
    }
    // Doc CSF
    if (status_CFI !== 'Complete') {
      if (status_CFI === 'Refuse') return 'Refuse'
      if (docStatus !== 'Pending') docStatus = status_CFI
    }
    return docStatus
  }

  async getNationalities () {
    const [error, response] = await CountryService.nationalities()
    if (error) {
      return this.$toasted.global.error(error)
    }
    this.nationalities = response.data.data
  }

  getNationality (partner: any) {
    if (partner.nationalities.length) return partner.nationalities[0].name
    else if ((partner.rfc !== null && partner.rfc !== '') || partner.nationality !== 'MX') {
      let nationality = this.nationalities.filter((nationality: any ) => nationality.issue_country === partner.nationality)
      if (!nationality.length) return nationality = this.nationalities.filter((nationality: any ) => nationality.code === partner.nationality)
      if (!nationality.length) return nationality = this.nationalities.filter((nationality: any ) => nationality.name === partner.nationality)
      if (nationality.length) return nationality[0].name
      return 'Por definir'
    }
    return 'Por definir'
  }

  @State('UserModule') state: any
}
