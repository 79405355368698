



















// Utils
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component
export default class HeaderButtons extends Vue {
  buttons = [
    { name: 'Inicio', route: 'Dashboard', icon: require('@/assets/icons/dashboard.svg') },
    { name: 'Resumen', route: 'Detail', icon: require('@/assets/icons/detail.svg') },
    { name: 'Banco', route: 'Bank', icon: require('@/assets/icons/detail.svg') },
    { name: 'Documentos', route: 'Documents', icon: require('@/assets/icons/documents.svg') },
    { name: 'Contabilidad', route: 'Accounting', icon: require('@/assets/icons/documents.svg') },
    { name: 'Beneficios', route: 'Benefits', icon: require('@/assets/icons/benefit.svg') }
  ]
  articlesMockApproved = false

  created () {
    this.articlesMockApproved = this.state.company.stages.find((stage: any) => stage.stage_name === 'signing_complete').is_completed === true
    const accountingPaymentComplete = this.state.company.orders.filter((order: any) => order.status === 'complete' && order.items.filter((item: any) => item.name === 'Servicio de Comisario').length > 0).length > 0
    if (!this.state.company.flag_data_banking_service || !this.state.company.legal_representation) this.buttons = this.buttons.filter((button: any) => button.name !== 'Banco')
    if (this.state.company.beta_accounting && this.state.company.legal_representation) this.buttons = this.buttons.filter((button: any) => button.name !== 'Documentos')
    else if (this.state.company.beta_accounting && accountingPaymentComplete) this.buttons = this.buttons.filter((button: any) => button.name !== 'Documentos')
    else this.buttons = this.buttons.filter((button: any) => button.name !== 'Contabilidad')
  }

  get paymentComplete () {
    const order = this.state.company.orders.filter((order: any) => order.status === 'complete').length > 0
    return order
  }

  to (route: string) {
    if (this.$route.name !== route) return this.$router.push({ name: route, query: this.$route.query })
    else return
  }

  @State('UserModule') state: any
}
